import React from "react";
import { generatePath, useHistory } from "react-router-dom";
import ButtonLink from "components/atoms/ButtonLink";
import { APP_ROUTES } from "system/router/constants";
import { useTrans } from "system/translations/hooks";
import {
  useDeleteAssignmentRules,
  useGetAssignmentRules,
} from "modules/assignmentRules/apiHooks";
import { useConfirmModal } from "services/modal/predefinedModals";
import { EditIcon, PlusIcon, TrashIcon } from "evergreen-ui";
import SectionTitle from "components/atoms/SectionTitle";

const AssignmentRules = () => {
  const { _t } = useTrans();
  const history = useHistory();
  const { onConfirm } = useConfirmModal();
  const { mutate: onDelete } = useDeleteAssignmentRules();
  const { data, isLoading } = useGetAssignmentRules();
  const onUpdate = ({ id, allData }: any) => {
    const link = generatePath(APP_ROUTES.assignmentRules.update, { id });
    history.push(link, history.location);
  };
  const onDeleteWithConfirm = ({ id }: any) => {
    onConfirm({ onOk: () => onDelete({ id }) });
  };
  const actionMenuItems = [
    { title: _t("edit"), onClick: onUpdate, icon: <EditIcon size={12} /> },
    {
      title: _t("delete"),
      onClick: onDeleteWithConfirm,
      icon: <TrashIcon size={12} />,
      intent: "danger",
    },
  ];
  return (
    <>
      <SectionTitle
        title={_t("assignment_rules")}
        extra={
          <ButtonLink
            kind={"secondary"}
            iconBefore={PlusIcon}
            linkTo={APP_ROUTES.assignmentRules.create}
            label={_t("create_rule")}
          />
        }
      />
    </>
  );
};

export default AssignmentRules;
