import React, { useMemo } from "react";
import { FormConfigType } from "../../services/formBuilder/types";
import { useDefaultFieldsConfig } from "services/formBuilder/hooks";
import { useTrans } from "system/translations/hooks";
import { css } from "@emotion/css";

export const useChangePasswordFormConfig = (params: any): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.oldPassword(),
      fields.formFields.password(),
      fields.formFields.confirm_password(),
      fields.general.divider(),
      fields.general.actionButtons({
        componentProps: {
          wrapClassName: css`
            justify-content: flex-end;
          `,
          isLoading: params?.isLoading,
          onOkKind: "secondary",
          onOkText: _t("change"),
        },
        columnParams: {
          lg: 12,
          md: 12,
        },
      }),
    ];
  }, []);
  return formConfig;
};
