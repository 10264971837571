import React, { useRef } from "react";
import { useTrans } from "system/translations/hooks";
import FormBuilder from "services/formBuilder/FormBuilder";
import {
  useUpdateMerchantBlackList,
  useGetMerchantBlackListById,
} from "modules/merchantBlackList/apiHooks";
import { createEditValidation } from "modules/merchantBlackList/validation";
import { useCreateEditConfig } from "modules/merchantBlackList/constantHooks";
import Loader from "components/atoms/Loader";
import ActionButtons from "components/atoms/ActionButtons";
import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";
import { css } from "@emotion/react";
import { useDrawer } from "services/drawer/hooks";

const styles = createStyles({
  wrapper: {
    display: "grid",
    height: "100%",
    gridTemplateRows: "1fr 72px",
  },
  container: {
    padding: themeOptions.spacing.lg,
  },
  buttonsWrapper: {
    padding: themeOptions.spacing.md,
    borderTop: `1px solid ${themeOptions.colors.gray300}`,
    display: "grid",
    justifyContent: "flex-end",
  },
});

const Update: React.FC<{ merchantId?: number; id?: number }> = ({
  merchantId,
  id,
}) => {
  const { _t } = useTrans();
  const formikRef: any = useRef(null);
  const { hideDrawer } = useDrawer();
  const { mutate: onUpdate, isLoading: isUpdating } =
    useUpdateMerchantBlackList(merchantId);
  const { data, isLoading } = useGetMerchantBlackListById(merchantId, id);
  const formConfig = useCreateEditConfig();
  const onSubmit = async () => {
    formikRef?.current?.formik?.handleSubmit();
    if (formikRef?.current?.formik?.isValid) {
      const values = formikRef?.current?.formik?.values;
      await onUpdate({ data: values, id });
      hideDrawer();
    }
  };
  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <div css={css(styles.wrapper)}>
      <div css={styles.container}>
        <FormBuilder
          formItemsConfig={formConfig}
          showSubmit={false}
          ref={formikRef}
          formProps={{
            submitBtnLabel: _t("update"),
            onSubmit: onSubmit,
            initialValues: { email: data?.email },
            validationSchema: createEditValidation(_t, {
              isEdit: false,
            }),
          }}
        />
      </div>
      <div css={styles.buttonsWrapper}>
        <ActionButtons
          onOkKind={"primary"}
          onOk={onSubmit}
          onCancel={hideDrawer}
          isLoading={isUpdating}
          onOkText={_t("save_changes")}
        />
      </div>
    </div>
  );
};

export default Update;
