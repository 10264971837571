import React from "react";
import ResetPassword from "modules/auth/components/resetPassword";
import NotLoggedTemplate from "templates/NotLogged";

const ResetPasswordPage = () => {
  return (
    <NotLoggedTemplate>
      <ResetPassword />
    </NotLoggedTemplate>
  );
};

export default ResetPasswordPage;
