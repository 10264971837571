import React from "react";
import Sidebar from "components/organisms/Sidebar";
import { useLocation } from "react-router-dom";
import { useConstants } from "../../system/helpers/hooks";

const MainSidebar = ({ isSideMinimized, onSideMinimize }: any) => {
  let location = useLocation();
  const { sidebarMenu } = useConstants();

  return (
    <Sidebar
      isSideMinimized={isSideMinimized}
      onSideMinimize={onSideMinimize}
      menuList={sidebarMenu}
      activeLocation={location.pathname}
    />
  );
};

export default MainSidebar;
