import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  container: {
    position: "relative",
    lineHeight: "20px",
    color: themeOptions.colors.gray800,
    marginBottom: themeOptions.spacing.sm,
    "&:before": {
      position: "absolute",
      left: `-${themeOptions.spacing.xxlg}`,
      padding: "3px 8px",
      background: themeOptions.colors.gray200,
      borderRadius: themeOptions.borders.xs,
      content: 'counter(ordered-list-counter) ""',
      counterIncrement: "ordered-list-counter",
    },
  },
});

export default styles;
