import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_PAYMENTS,
  API_GET_PAYMENT,
  API_DELETE_PAYMENT,
  API_CREATE_PAYMENT,
  API_UPDATE_PAYMENT,
  API_GET_PAYMENTS_NAME_LIST,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { useToast } from "services/toast/hooks";
import { useTrans } from "system/translations/hooks";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import { PaymentDataType, PaymentsDataType } from "modules/payments/types";

const getPaymentsKey = "getPaymentsKey";
const getPaymentKey = "getPaymentKey";
export const useGetPayments = (filter = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPaymentsKey, filter],
    (): Promise<PaymentsDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_PAYMENTS, filter),
        method: "get",
      })
  );
};

export const useGetPayment = (id: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPaymentKey, id],
    (): Promise<PaymentDataType> =>
      apiRequest({
        url: generatePath(API_GET_PAYMENT, { id }),
        method: "get",
      })
  );
};

export const useCreatePayment = () => {
  const { _t } = useTrans();
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_PAYMENT,
        method: "put",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPaymentsKey);
        apiSuccessMessage(_t("successfully_created_new_payment_method"));
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useDeletePayment = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_PAYMENT, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPaymentsKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useUpdatePayment = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_UPDATE_PAYMENT,
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPaymentsKey);
        queryClient.invalidateQueries(getPaymentKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
