import React from "react";
import ForgotPassword from "modules/auth/components/forgotPassword";
import NotLoggedTemplate from "templates/NotLogged";

const ForgotPasswordPage = () => {
  return (
    <NotLoggedTemplate>
      <ForgotPassword />
    </NotLoggedTemplate>
  );
};

export default ForgotPasswordPage;
