import React from "react";
import { Breadcrumb } from "carbon-components-react";
import Crumb from "components/atoms/Crumb/Crumb";
import {
  BreadcrumbsProps,
  itemCrumbProps,
} from "services/breadcrumbs/constant";

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  pathnamesModified,
  pathnamesOriginal,
}) => {
  return (
    <Breadcrumb noTrailingSlash>
      {pathnamesModified?.map((item: itemCrumbProps) => {
        return (
          <Crumb
            key={item.index}
            item={item}
            index={item.index}
            pathnames={pathnamesOriginal}
          />
        );
      })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
