import React from "react";
import { DividerProps } from "./types";
import { css } from "@emotion/react";
import styles from "components/atoms/Divider/styles";

const Divider: React.FC<DividerProps> = ({ text, additionalClass, dashed }) => {
  return (
    <div css={css(styles.dividerContainer, additionalClass && additionalClass)}>
      <div css={css(text && styles.dividerText)}>{text}</div>
      <div css={css(styles.dividerLine, dashed && styles.dividerDashedLine)} />
    </div>
  );
};

export default Divider;
