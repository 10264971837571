import React from "react";

const FieldsArray = ({
  name,
  children,
  onFormChange,
  values = [],
  addTittle = "Add field",
  showAddBtn = true,
}: any) => {
  const add = () => {
    const newVal = [...values];
    newVal.push({});
    onFormChange(name, newVal);
  };

  return (
    <div className={"fields-array"}>
      {children}
      {showAddBtn && (
        <div className={"fields-array__top__add"} onClick={add}>
          + {addTittle}
        </div>
      )}
    </div>
  );
};

export default FieldsArray;
