import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { useGetPaymentMethodsNameList } from "modules/payment/apiHooks";
import { UseModuleDataRes } from "modules/merchantPaymentMethods/types";

export const useModuleData = (): UseModuleDataRes => {
  const { data: paymentMethods = [] } = useGetPaymentMethodsNameList();
  const methodsOptions = arrayOptionsMap(
    paymentMethods,
    {
      labelKey: "name",
      valueKey: "id",
    },
    true
  );

  return { methodsOptions };
};
