import React from "react";
import Checkbox from "components/atoms/Checkbox";
import { CheckboxPanelProps } from "components/molecules/CheckboxPanel/types";
import { css } from "@emotion/react";
import styles from "components/molecules/CheckboxPanel/styles";
import { Text } from "evergreen-ui";

const CheckboxPanel: React.FC<CheckboxPanelProps> = ({
  value,
  onChange,
  title,
  description,
  name,
}) => {
  return (
    <div css={css(styles.container)} key={name}>
      <Checkbox id={name} value={value} onChange={onChange} />
      <div>
        {title && (
          <Text size={400} css={css(styles.infoTitle)}>
            {title}
          </Text>
        )}
        {description && (
          <Text size={300} css={css(styles.infoDescription)}>
            {description}
          </Text>
        )}
      </div>
    </div>
  );
};

export default CheckboxPanel;
