import React, { forwardRef, useImperativeHandle, useState } from "react";
import { DrawerTypes, DrawerParams } from "services/drawer/types";
import { SideSheet } from "evergreen-ui";
import { css } from "@emotion/react";
import styles from "services/drawer/styles";
import { isMobile } from "system/theme/globalStyles";

const Drawer: React.FC<DrawerTypes> = forwardRef(({ hideDrawer }, ref) => {
  const [drawerVisible, setVisible] = useState<any>({});
  const [drawerFocus, setFocus] = useState("default");
  const [drawerParams, setDrawerParams] = useState<
    | {
        [key: string]: DrawerParams;
      }
    | any
  >({});
  /*  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);*/
  useImperativeHandle(ref, () => ({
    drawerOpen(name: any, param: any) {
      document.body.style.overflow = "hidden";
      setVisible({ ...drawerVisible, [name]: true });
      setFocus(name);
      setDrawerParams({ ...drawerParams, [name]: param });
    },
    drawerUpdate(name: any, param: any) {
      setDrawerParams({
        ...drawerParams,
        [name]: { ...param[name], ...param },
      });
    },
    drawerClose(name: string) {
      document.body.style.overflow = "unset";
      const params = { ...drawerParams };
      delete params[name];
      setDrawerParams(params);
      setVisible({ ...drawerVisible, [name]: false });
    },
    drawerCloseAll() {
      document.body.style.overflow = "unset";
      setDrawerParams({});
      setVisible({});
    },
  }));
  const containerWidth = isMobile ? "100%" : "480px";
  const checkFocus = (name: string) => {
    if (name !== drawerFocus) {
      setFocus(name);
    }
  };

  return (
    <>
      {Object.keys(drawerVisible).map((name, idx) => {
        const {
          component: Content,
          componentProps,
          body,
          footer: FooterContent,
          footerProps,
          header: HeaderContent,
          headerProps,
          bodyWrapperProps,
        } = drawerParams?.[name] || { bodyWrapperProps: { hasSpacing: true } };
        return (
          <>
            <SideSheet
              containerProps={{ zIndex: 111111, width: containerWidth }}
              key={name}
              isShown={drawerVisible?.[name]}
              onCloseComplete={() => hideDrawer(name)}
              width={480}
            >
              <div css={css(styles.headerWrapper)}>
                {HeaderContent && <HeaderContent {...headerProps} />}
              </div>
              <div
                css={css(
                  styles.bodyWrapper({
                    withoutFooter: !FooterContent,
                    hasSpacing: bodyWrapperProps?.hasSpacing,
                  })
                )}
              >
                {Content ? <Content {...componentProps} /> : body}
              </div>
              {FooterContent && (
                <div css={FooterContent && css(styles.footerWrapper)}>
                  <FooterContent {...footerProps} />
                </div>
              )}
            </SideSheet>
          </>
        );
      })}
    </>
  );
});

export default Drawer;
