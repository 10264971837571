import React, { useEffect, useMemo, useRef, useState } from "react";
import { MultiSelect as MultiSelectCustom } from "react-multi-select-component";
import { useOutsideClick } from "system/helpers/hooks";
import { useTrans } from "system/translations/hooks";
import { MultipleSelectItemsType } from "./types";
import { css } from "@emotion/react";
import styles from "components/atoms/MultiSelect/styles";
import { CaretDownIcon, Text, CrossIcon, CaretRightIcon } from "evergreen-ui";
import themeOptions from "system/theme";
import { useDrawer } from "services/drawer/hooks";
import HeaderMobileFilters from "components/molecules/HeaderMobileFilters";
import MultiSelectMobile from "../MultiSelectMobile";
import { isMobile } from "system/theme/globalStyles";

const MultiSelect: React.FC<{
  value: any;
  onChange: ({ selectedItems }: { selectedItems: [] }) => void;
  options: any;
  titleText: string;
  labelWithAll?: boolean;
  wrapClassPrefix?: string;
  label?: React.ReactNode | undefined;
  isOpen?: boolean;
  invalidText?: string;
  whiteBackground?: boolean;
  withSelectAll?: boolean;
  singleSelect?: boolean;
  twoColumns?: boolean;
  menuFullWidth?: boolean;
  showFiltersDrawer?: boolean;
  isMobileCreateEdit?: boolean,
}> = ({
  onChange,
  options = [],
  label,
  titleText,
  value,
  labelWithAll = true,
  wrapClassPrefix,
  isOpen,
  invalidText,
  whiteBackground = false,
  withSelectAll = true,
  singleSelect = false,
  twoColumns = false,
  menuFullWidth = true,
  showFiltersDrawer,
  isMobileCreateEdit = false,
  ...rest
}) => {
    const [openState, updateOpenState] = useState(isOpen);
    const [firstRenderFinishedStatus, setFirstRenderFinishedStatus] =
      useState(false);
    const { _t } = useTrans();
    const emptyArray: any = [];
    const { showDrawer, hideDrawer, updateDrawer } = useDrawer();

    const ref = useRef(null);

    const [clickOutSide] = useOutsideClick(ref);

    useEffect(() => {
      !isOpen && firstRenderFinishedStatus && updateOpenState(!clickOutSide);
    }, [clickOutSide]);

    useEffect(() => {
      setFirstRenderFinishedStatus(true);
    }, []);

    const selectLabel =
      labelWithAll && withSelectAll ? (
        <>
          <Text css={css(styles.label)}>{label}:</Text>
          <span css={css(styles.labelQuantity)}>
            {value?.length ? "" : <Text css={css(styles.labelAll)}>All</Text>}
          </span>
        </>
      ) : (
        <Text css={css(styles.singleLabel)}>{label}</Text>
      );

    const selected = useMemo(() => {
      return options?.filter((item: any) => {
        return Array.isArray(value) && value?.includes(item?.value) && item;
      });
    }, [value, options]);

    const valueRenderer = () => {
      return (
        <div css={css(styles.labelWrap)}>
          {selectLabel}
          {!twoColumns && !!selected.length && !singleSelect && (
            <div css={css(styles.quantityValues)}>{selected.length}</div>
          )}
          {singleSelect && (
            <div css={css(styles.labelSingle)}>
              {selected.map((item: any) => item?.label)}
            </div>
          )}
        </div>
      );
    };
    const valueRendererMobile = () => {
      return isMobileCreateEdit 
      ? (
        <div css={css(selected.length ? styles.mobileLabelTextSelected : styles.mobileLabelText)}>
          {selected.length
            ? selected.length + ' ' + _t('selected')
            : _t('select_{multiselect_name}', {
              multiselect_name: `${label}`.toLowerCase()
            })
          }
        </div>
      )
      :(
        <div css={css(styles.label, styles.mobileRenderer)}>
          {selected.length
            ? selected.map((item: any) => item.label).join(', ')
            : _t('all')
          }
        </div>
      )
    };

    const onClickHandler = (e: any) => {
      e.target.outerText === _t("select_all") && updateOpenState(true);
    };

    const handleSingleRemove = (value: number) => {
      const valuesArray = selected.map(
        (item: MultipleSelectItemsType) => item.value
      );
      onChange(valuesArray.filter((item: number) => item !== value));
    };

    useEffect(() => {
      updateDrawer({
        header: HeaderMobileFilters,
        headerProps: {
          title: _t("{form_name}", {
            form_name: `${label}`,
          }),
          onClose: () => hideDrawer("multiSelect"),
        },
        componentProps: {
          onChange,
          options,
          label,
          titleText,
          value,
          labelWithAll,
          wrapClassPrefix,
          isOpen,
          invalidText,
          whiteBackground,
          withSelectAll,
          singleSelect,
          twoColumns,
          menuFullWidth,
        },
        component: MultiSelectMobile,
      }, "multiSelect")
    }, [value]);

    const showMultiSelect = () => {
      showDrawer({
        header: HeaderMobileFilters,
        headerProps: {
          title: _t("{form_name}", {
            form_name: `${label}`,
          }),
          onClose: () => hideDrawer("multiSelect"),
        },
        componentProps: {
          onChange,
          options,
          label,
          titleText,
          value,
          labelWithAll,
          wrapClassPrefix,
          isOpen,
          invalidText,
          whiteBackground,
          withSelectAll,
          singleSelect,
          twoColumns,
          menuFullWidth,
        },
        component: MultiSelectMobile,
      }, "multiSelect");
    }

    return isMobile && showFiltersDrawer
      ? (
        <div
          onClick={() => showMultiSelect()}>
          <div css={styles.mobileContainer}>
            <div >
              <div>{label}:</div>
              {valueRendererMobile()}
            </div>
            <CaretRightIcon />
          </div>
        </div>
      )
      : (
        <div onClick={() => isMobileCreateEdit && showMultiSelect()}>
          {isOpen && valueRenderer()}
          {isMobileCreateEdit && <div css={styles.mobileLabel}>{label}</div>}
          <div
            css={css(
              styles.selectWrap,
              menuFullWidth && styles.menuFullWidth,
              invalidText && styles.isError,
              selected.length && styles.hasSelected,
              twoColumns && styles.twoColumns
              /*        styles.selectWrap({
                isOpen,
                isError: invalidText,
                twoColumns,
                menuFullWidth: true,
              })*/
            )}
            /*        className={classNames(
              "multi-select__wrap",
              isOpen && "multi-select__open",
              wrapClassPrefix && `multi-select__wrap--${wrapClassPrefix}`,
              invalidText && "multi-select__required-error",
              whiteBackground && "multi-select--white-background",
              twoColumns && "multi-select__twoColumns"
            )}*/
            ref={ref}
            onClick={(e: any) => onClickHandler(e)}
          >
            <MultiSelectCustom
              options={options}
              value={selected}
              isOpen={openState}
              ClearSelectedIcon={null}
              valueRenderer={() => (isOpen ? <div /> : isMobileCreateEdit ? valueRendererMobile() : valueRenderer())}
              ArrowRenderer={() => (
                <div css={css(styles.labelArrow)}>
                  <CaretDownIcon />
                </div>
              )}
              onChange={(data: any) => {
                const filteredData = data?.map(
                  (item: MultipleSelectItemsType) => item.value
                );
                const result: any = singleSelect
                  ? filteredData?.length
                    ? [filteredData[filteredData.length - 1]]
                    : []
                  : filteredData;
                return onChange(result);
              }}
              labelledBy={titleText}
              hasSelectAll={withSelectAll}
            />
            {twoColumns && (
              <>
                <div css={styles.twoColumnsRight}>
                  <div css={styles.twoColumnsRightCounter}>
                    {selected.length} {_t("selected_small")}
                  </div>
                  <ul>
                    <li
                      css={styles.twoColumnsRightRemover}
                      onClick={() => onChange(emptyArray)}
                    >
                      <span>{_t("clear_all")}</span>
                    </li>
                    {selected.map((item: MultipleSelectItemsType, key: number) => (
                      <li onClick={() => handleSingleRemove(item.value)} key={key}>
                        <span>{item.label}</span>
                        <CrossIcon size={12} color={themeOptions.colors.gray600} />
                      </li>
                    ))}
                  </ul>
                </div>
                {/*       <div css={styles.twoColumnsBottom}>
                {selected.length} {_t("selected_small")}
              </div>*/}
              </>
            )}
          </div>
          {invalidText && (
            <div className="multi-select__required-error__text">{invalidText}</div>
          )}
        </div>
      );
  };

export default MultiSelect;
