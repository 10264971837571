import React, { useMemo } from "react";
import { FormConfigType } from "../../services/formBuilder/types";
import { useDefaultFieldsConfig } from "services/formBuilder/hooks";
import { useTrans } from "system/translations/hooks";
import { APP_ROUTES } from "system/router/constants";

export const useLoginFormConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const { _t } = useTrans();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.email({
        componentProps: {
          className: "bx--text-input--inverted",
          autoComplete: "username",
        },
      }),
      fields.formFields.password({
        componentProps: {
          className: "bx--text-input--inverted",
          /*       autoComplete: "current-password",*/
        },
      }),
      fields.formFields.rememberMe(),
      fields.general.divider(),
      fields.general.link({
        componentProps: {
          to: APP_ROUTES.forgotPassword,
          label: `${_t("forgot_password")}?`,
        },
      }),
      fields.general.saveBtn({
        componentProps: { label: _t("continue"), kind: "secondary" },
        columnParams: {
          lg: 6,
          md: 6,
          sm: 6,
          xs: 6,
          className: "",
          style: { textAlign: "right" },
        },
      }),
    ];
  }, []);
  return formConfig;
};

export const useForgotPasswordFormConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const { _t } = useTrans();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.email({
        componentProps: {
          className: "bx--text-input--inverted",
        },
      }),
      fields.general.saveBtn({
        componentProps: { label: _t("send_instruction"), kind: "secondary" },
        columnParams: {
          lg: 12,
          md: 12,
          sm: 12,
          className: "",
          style: { textAlign: "right" },
        },
      }),
    ];
  }, []);
  return formConfig;
};

export const useResetPasswordFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.password({
        componentProps: {
          label: _t("new_password"),
          placeholder: _t("new_password_placeholder"),
          className: "bx--text-input--inverted",
          autoComplete: "off",
        },
      }),
      fields.formFields.confirm_password({
        name: "repeatPassword",
        componentProps: {
          className: "bx--text-input--inverted",
          autoComplete: "off",
        },
      }),
      fields.general.saveBtn({
        componentProps: { label: _t("reset"), kind: "secondary" },
        columnParams: {
          lg: 12,
          md: 12,
          sm: 12,
          className: "",
          style: { textAlign: "right" },
        },
      }),
    ];
  }, []);
  return formConfig;
};

export const useTfaEnableFormConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const { _t } = useTrans();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.code({
        componentProps: {
          className: "bx--text-input--inverted",
          autoComplete: "off",
        },
      }),
      fields.general.saveBtn({
        componentProps: { label: _t("verify"), kind: "secondary" },
        columnParams: {
          lg: 12,
          md: 12,
          sm: 12,
          className: "",
          style: { textAlign: "right" },
        },
      }),
    ];
  }, []);
  return formConfig;
};

export const useTfaProcessFormConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const { _t } = useTrans();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.authenticationCode(),
      fields.general.saveBtn({
        componentProps: { label: _t("verify"), kind: "secondary" },
        columnParams: {
          lg: 12,
          md: 12,
          sm: 12,
          className: "",
          style: { textAlign: "right" },
        },
      }),
    ];
  }, []);
  return formConfig;
};
