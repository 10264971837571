import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  API_UPDATE_ASSIGNMENT_RULES,
  API_CREATE_ASSIGNMENT_RULES,
  API_GET_ASSIGNMENT_RULES,
  API_GET_ASSIGNMENT_RULE,
  API_DELETE_ASSIGNMENT_RULES,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  AssignmentRulesDataType,
  AssignmentRuleDataType,
} from "modules/assignmentRules/types";

const getAssignmentRulesKey = "getAssignmentRulesKey";
const getCurrencyKey = "getCurrencyKey";
export const useGetAssignmentRules = (filters: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getAssignmentRulesKey, filters],
    (): Promise<AssignmentRulesDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_ASSIGNMENT_RULES, filters),
        method: "get",
      })
  );
};
export const useGetAssignmentRule = (id: string) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getCurrencyKey, id],
    (): Promise<AssignmentRuleDataType> =>
      apiRequest({
        url: generatePath(API_GET_ASSIGNMENT_RULE, { id }),
        method: "get",
      })
  );
};

export const useCreateAssignmentRules = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_CREATE_ASSIGNMENT_RULES, {
          id: data.id,
        }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getAssignmentRulesKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useDeleteAssignmentRules = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_ASSIGNMENT_RULES, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getAssignmentRulesKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useUpdateAssignmentRules = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_ASSIGNMENT_RULES, {
          id: data.id,
        }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getAssignmentRulesKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
