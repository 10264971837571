import React, { useMemo } from "react";
import config from "system/config";
import { format, subMonths } from "date-fns";
import FormBuilder from "services/formBuilder/FormBuilder";
import Table from "services/table";
import { useTrans } from "system/translations/hooks";
import { useFilters, useListSort } from "system/helpers/hooks";
import { useGetMethodPaymentDetailsHistory } from "modules/merchantPaymentMethods/apiHooks";
import {
  useActionLogTableColumns,
  useActionLogSearchFormConfig,
} from "modules/merchantPaymentMethods/constantHooks";

const ActionLog: React.FC<{ methodId: string }> = ({ methodId }) => {
  const { _t } = useTrans();
  const filterInitialValues = useMemo(
    () => ({
      fromDate: format(subMonths(new Date(), 1), config.dateFormat),
      toDate: format(new Date(), config.dateFormat),
    }),
    []
  );
  const { filters, setFilters } = useFilters(filterInitialValues);
  const { sort, setSort } = useListSort({});
  const requestParams = useMemo(
    () => ({ ...filters, ...sort }),
    [filters, sort]
  );
  const { data, isLoading } = useGetMethodPaymentDetailsHistory(
    methodId,
    requestParams
  );
  const columns = useActionLogTableColumns();
  const searchFormConfig = useActionLogSearchFormConfig();
  const onFilterSubmit = async (data: any) => {
    const normalizeDate = {
      ...data,
    };
    if (data?.date) {
      normalizeDate.fromDate = data?.date?.fromDate;
      normalizeDate.toDate = data?.date?.toDate;
    }
    delete normalizeDate.date;
    delete normalizeDate.searchFrom;
    setFilters(normalizeDate);
  };

  return (
    <>
      <FormBuilder
        formItemsConfig={searchFormConfig}
        formProps={{
          submitBtnLabel: _t("apply_filters"),
          onSubmit: onFilterSubmit,
          initialValues: filterInitialValues,
        }}
      />
      <Table
        isVirtualBody={false}
        showSettingsIcon={false}
        columns={columns}
        onSort={setSort}
        isLoading={isLoading}
        data={data || []}
      />
    </>
  );
};

export default ActionLog;
