import axiosClient from "./axiosConfig";
import { getToken, removeToken } from "system/helpers/helperFunctions";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import { useEffect } from "react";
import { history, queryClient } from "App";
import { useModal } from "services/modal/hooks";

export const useApiRequest = () => {
  const { hideModal } = useModal();
  const apiRequest = (params: any) =>
    axiosClient
      .request(params)
      .then((r: any) => r.data)
      .catch((err: any) => {
        const status = err?.response?.status;
        if (status === 401 || status === 403 || status === 502) {
          history.push(APP_ROUTES.login);
          removeToken();
          queryClient.invalidateQueries();
          hideModal();
        }
        throw {
          errorData: err?.response?.data,
          status: err?.response?.status,
        };
      });

  return [apiRequest];
};
