import React from "react";
import "./styles/index.scss";
import "./styles/app.scss";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import SystemTemplate from "templates/System";
import routes from "system/router/routes";
import { ModalContextProvider } from "services/modal/ModalContext";
import { DrawerContextProvider } from "services/drawer/DrawerContext";
import StoreProvider from "system/store/StoreProvider";
import { ThemeProvider, UploadIcon, DownloadIcon } from "evergreen-ui";
import "normalize.css";
import { Global, css } from "@emotion/react";
import evergreenTheme from "system/theme/evergreenTheme";
import globalStyles from "system/theme/globalStyles";
import { Toaster } from "react-hot-toast";

export const history = createBrowserHistory();
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: 0,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <StoreProvider>
          <ThemeProvider value={evergreenTheme}>
            <Global styles={css(globalStyles)} />
            <ModalContextProvider>
              <DrawerContextProvider>
                <SystemTemplate>{routes}</SystemTemplate>
              </DrawerContextProvider>
            </ModalContextProvider>
          </ThemeProvider>
        </StoreProvider>
      </Router>
      <Toaster />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
