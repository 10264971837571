import React from "react";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "services/formBuilder/FormBuilder";
import { useCreateRole, useGetRolesAuthorities } from "modules/roles/apiHooks";
import { createEditValidation } from "modules/roles/validation";
import { modifyCreateUpdateReqData } from "../helper";
import { useRoleInitialData } from "../hooks";
import Loader from "components/atoms/Loader";
import { useCreateEditConfig } from "modules/roles/constantHooks";
import SectionTitle from "components/atoms/SectionTitle";
import { useHistory } from "react-router-dom";

const Create: React.FC = () => {
  const { _t } = useTrans();
  const isEdit = false;
  const history = useHistory();
  const { mutateAsync: onCreate } = useCreateRole();
  const { data: rolesAuthorities = [], isLoading } = useGetRolesAuthorities();
  const formConfig = useCreateEditConfig({
    rolesAuthorities,
    isEdit,
  });
  const { allAuthorities } = useRoleInitialData(rolesAuthorities, {});
  const onSubmit = async (data: any) => {
    const reqData = modifyCreateUpdateReqData(data);
    await onCreate(reqData);
    history.goBack();
  };

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <>
      <SectionTitle title={_t("create_role")} />
      <Card>
        <FormBuilder
          formItemsConfig={formConfig}
          showSubmit={false}
          formProps={{
            onSubmit: onSubmit,
            initialValues: { authorities: allAuthorities },
            validationSchema: createEditValidation(_t, {
              isEdit: false,
            }),
          }}
        />
      </Card>
    </>
  );
};

export default Create;
