import React, { useRef, useState } from "react";
import { useTrans } from "system/translations/hooks";
import { useGetFormTemplate, useUpdateFormTemplate } from "../apiHooks";
import { useParams } from "react-router-dom";
import {
  createEditValidation,
  createEditGeneralValidation,
} from "../validation";
import {
  useCreateEditFieldsConfig,
  useCreateEditGeneralConfig,
  useCreateEditStylesConfig,
} from "../constantHooks";
import { Heading } from "evergreen-ui";
import Loader from "components/atoms/Loader";
import FormView from "modules/formTemplates/components/FormView";
import { getSubmittedData, useChangeHandlers, useGetInitialProperties, useShowFunctions } from "../hooks";

const Update: React.FC = () => {
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { mutateAsync: onUpdate, isLoading: isUpdating } =
    useUpdateFormTemplate();
  const { data: data, isLoading } = useGetFormTemplate(routeParams?.id);
  const isEdit = true;
  const generalFormRef: any = useRef(null);
  const fieldsFormRef: any = useRef(null);
  const stylesFormRef: any = useRef(null);
  const formGeneralConfig = useCreateEditGeneralConfig({ isEdit });
  const formStylesConfig = useCreateEditStylesConfig({ isEdit });
  const formFieldsConfig = useCreateEditFieldsConfig({ isEdit });
  const [fields, setFields] = useState<any>([]);
  const [styles, setStyles] = useState<any>({});
  const [selectedTab, setSelectedTab] = useState("fields");
  const getHeaderComponent = ({ title = _t("fields_order") }: any) => <Heading size={700}>{title}</Heading>
  const initialProperties = useGetInitialProperties({
    data,
    isLoading,
    setFields,
    setStyles
  })
  const { showOrder, showFields, showStyles } = useShowFunctions({
    fieldsFormRef,
    stylesFormRef,
    getHeaderComponent,
    styles,
    fields,
    _t,
  })
  const { onFieldsValuesChange, onStylesValuesChange }  = useChangeHandlers({ setFields, setStyles })
  const onSubmit = async (data: any) => {
    generalFormRef?.current?.formik?.handleSubmit();

    if (generalFormRef?.current?.formik?.isValid) {
      const { reqData } = getSubmittedData({
        generalFormRef,
        data,
        styles,
        isUpdate: true,
        id: +routeParams?.id,
      });
      await onUpdate({
        id: routeParams?.id,
        data: reqData,
      });
    }
  };
  
  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <>
      <FormView
        sectionTitle={data?.name || _t("edit_template")}
        generalFormRef={generalFormRef}
        generalFormConfig={formGeneralConfig}
        generalFormInitialValues={{
          enabled: data?.enabled,
          paymentMethodId: data?.paymentMethod?.id,
          formTemplateType: data?.type,
        }}
        generalFormValidationSchema={createEditGeneralValidation(_t, {
          isEdit,
        })}
        fields={fields}
        fieldsFormRef={fieldsFormRef}
        fieldsFormConfig={formFieldsConfig}
        fieldsFormInitialValues={{ fields: initialProperties?.fields }}
        fieldsFormCreateEditValidation={createEditValidation(_t, {
          isEdit,
        })}
        onFieldsValuesChange={onFieldsValuesChange}
        stylesFormRef={stylesFormRef}
        stylesFormConfig={formStylesConfig}
        stylesFormInitialValues={initialProperties?.styles}
        onStylesValuesChange={onStylesValuesChange}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        showOrder={showOrder}
        showFields={showFields}
        showStyles={showStyles}
        customStyles={styles?.styles}
        onSubmit={onSubmit}
      />
      {/*
      <SectionTitle title={data?.name || _t("edit_template")} />
      <Card
        wrapperClass={css({
          marginBottom: themeOptions.spacing.sm,
          padding: themeOptions.spacing.sm,
          paddingBottom: themeOptions.spacing.xs,
        })}
      >
        <FormBuilder
          showSubmit={false}
          formItemsConfig={formGeneralConfig}
          ref={generalFormRef}
          formProps={{
            onSubmit: () => {},
            initialValues: {
              enabled: data?.enabled,
              paymentMethodId: data?.paymentMethod?.id,
              formTemplateType: data?.type,
            },
            validationSchema: createEditGeneralValidation(_t, {
              isEdit,
            }),
            rowStyles: { alignItems: "flex-end" },
          }}
        />
      </Card>
      <Row>
        <Col lg={7} md={12}>
          <Pane
            css={css({
              padding: 0,
              display: "flex",
              flexDirection: "row",
              background: "#fff",
              borderRadius: themeOptions.borders.sm,
            })}
          >
            <Tablist
              padding={4}
              borderRight={`1px solid ${themeOptions.colors.gray300}`}
            >
              <SidebarTab
                padding={8}
                onSelect={() => setSelectedTab("fields")}
                key={"fields"}
                id={"fields"}
                aria-controls={`panel-fields`}
                isSelected={"fields" === selectedTab}
              >
                <ThListIcon />
              </SidebarTab>
              <SidebarTab
                padding={8}
                onSelect={() => setSelectedTab("styles")}
                key={"styles"}
                id={"styles"}
                aria-controls={`panel-styles`}
                isSelected={"styles" === selectedTab}
              >
                <StyleIcon />
              </SidebarTab>
            </Tablist>
            <Pane padding={themeOptions.spacing.md} width={"100%"}>
              <Pane
                key={"fields"}
                id={`panel-fields`}
                role="tabpanel"
                aria-labelledby={fields}
                display={"fields" === selectedTab ? "block" : "none"}
              >
                <Pane
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Heading>{_t("form_elements")}</Heading>
                  <DrawerSettings showOrder={showOrder} showJson={showFields} />
                </Pane>
                <FormBuilder
                  showSubmit={false}
                  formItemsConfig={formFieldsConfig}
                  ref={fieldsFormRef}
                  formProps={{
                    onSubmit: onSubmit,
                    initialValues: { fields: initialProperties?.fields },
                    onFormValuesChange: onFieldsValuesChange,
                    validationSchema: createEditValidation(_t, {
                      isEdit,
                    }),
                  }}
                />
              </Pane>
              <Pane
                key={"styles"}
                id={`panel-styles`}
                role="tabpanel"
                aria-labelledby={fields}
                display={"styles" === selectedTab ? "block" : "none"}
              >
                <Pane
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Heading>{_t("style_customization")}</Heading>
                  <DrawerSettings showJson={showStyles} />
                </Pane>
                <FormBuilder
                  showSubmit={false}
                  formItemsConfig={formStylesConfig}
                  ref={stylesFormRef}
                  formProps={{
                    onSubmit: () => {},
                    onFormValuesChange: onStylesValuesChange,
                    initialValues: {
                      ...initialProperties?.styles,
                    },
                    rowStyles: { alignItems: "flex-end" },
                  }}
                />
              </Pane>
            </Pane>
          </Pane>
        </Col>
        <Col lg={5} md={12}>
          <FieldsPreview fields={fields?.fields} customStyles={styles} />
        </Col>
      </Row>*/}
    </>
  );
};

export default Update;
