import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  filterSummaryContainer: {
    marginTop: "8px",
    marginBottom : "-16px"
  }
   

});

export default styles;
