import React from "react";
import { useTrans } from "system/translations/hooks";
import { ActionButtonsProps } from "components/atoms/ActionButtons/types";
import Btn from "components/atoms/Btn";
import { css } from "@emotion/react";
import styles from "components/atoms/ActionButtons/styles";

const ActionButtons: React.FC<ActionButtonsProps> = ({
  onOk,
  onCancel,
  onReset,
  onOkText,
  iconBefore,
  onOkKind = "secondary",
  wrapClassName,
  isLoading,
}) => {
  const { _t } = useTrans();
  return (
    <div css={css(styles.wrapper)} className={wrapClassName}>
      <Btn
        kind="custom"
        type={"reset"}
        onClick={() => {
          onCancel && onCancel();
          onReset && onReset();
        }}
      >
        {_t("cancel")}
      </Btn>
      <Btn
        kind={onOkKind}
        type={"submit"}
        onClick={onOk}
        iconBefore={iconBefore}
        isLoading={isLoading}
      >
        <span>{onOkText ? onOkText : _t("on_ok")}</span>
      </Btn>
    </div>
  );
};

export default ActionButtons;
