import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  wrapper: { position: "relative" },
  icon: {
    position: "absolute",
    right: "10px",
    top: "35px",
    cursor: "pointer",
    svg: {
      fill: themeOptions.colors.gray700,
    },
  },
});

export default styles;
