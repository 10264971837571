import React from "react";
import { SearchProps } from "components/atoms/Search/types";
import { SearchIcon, TextInput, IconButton } from "evergreen-ui";
import { css } from "@emotion/react";
import styles from "components/atoms/Search/styles";

const Search: React.FC<SearchProps> = ({
  id = "input",
  placeholder,
  value,
  onChange,
  showSearch = false,
  disabled = false,
  size = "xl",
  ...rest
}) => {
  return (
    <div css={css(styles.wrapper)}>
      <TextInput
        css={css(styles.searchInput)}
        id={id}
        onChange={(e: any) => onChange(e?.target?.value)}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
      />
      {showSearch && (
        <IconButton
          css={css(styles.searchBtn)}
          type={"submit"}
          icon={SearchIcon}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default Search;
