import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  wrapper: {
    position: "relative",
  },
  valuePreview: {
    cursor: "pointer",
    height: "32px",
    fontSize: "12px",
    color: themeOptions.colors.gray700,
    padding: `12px 8px 0px`,
    "&:hover, &:focus": {
      color: themeOptions.colors.blue400,
      background: themeOptions.colors.gray50,
    },
  },
  valuePreviewIcon: {
    marginLeft: "5px",
    verticalAlign: "bottom",
  },
  container: {
    position: "absolute",
    zIndex: 999,
    border: "1px solid #E5E9EB",
    boxShadow: themeOptions.boxShadow.main,
    left: "-100%",
    ".rdrDateRangePickerWrapper": {
      display: "flex",
      flexDirection: "column-reverse",
    },
    ".rdrDefinedRangesWrapper": {
      width: "100%",
      borderBottom: `1px solid ${themeOptions.colors.gray200}`,
    },
    ".rdrStaticRange": {
      border: "none",
    },
    ".rdrStaticRanges": {
      display: "grid",
      gridTemplateColumns: "repeat(6, 100px)",
    },
    ".rdrInputRanges": {
      padding: "0",
    },
  },
  period: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    background: themeOptions.colors.white,
    padding: "12px 0",
    justifyItems: "center",
  },
  mobileContainer: {
    display: "flex",
    width: "100vw",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${themeOptions.colors.n300}`,
    padding: "10px 24px",
    margin: "-24px -24px 12px",
  },
  mobileTitleText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: themeOptions.colors.n800,
    marginBottom: "2px",
  },
  mobileSubtitleText: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    color: themeOptions.colors.n700,
  },

});

export default styles;
