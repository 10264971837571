import { useQueryClient } from "react-query";
import { useAudioNotification } from "system/helpers/hooks";
import { useGetCurrentAdmin } from "modules/auth/apiHooks";
import React, { useEffect } from "react";
import SockJS from "sockjs-client";
import { convertAmount, getToken } from "system/helpers/helperFunctions";
import webstomp from "webstomp-client";
import { toast } from "react-hot-toast";
import TransactionToast from "components/atoms/TransactionToast";
import { DownloadIcon, UploadIcon } from "evergreen-ui";
import themeOptions from "system/theme";
import { useTrans } from "system/translations/hooks";

export const useTransactionSocket = () => {
  const queryClient = useQueryClient();
  const { playWDNotification, playDPNotification } = useAudioNotification();
  const { data } = useGetCurrentAdmin();
  const { _t } = useTrans();
  useEffect(() => {
    if (data?.id) {
      var socket = new SockJS(
        "https://stage-opay-admin.safepaymentprocessingservice.com/stomp",
        null,
        { transports: ["websocket"] }
      );
      const token: any = getToken();
      var stompClient = webstomp.over(socket);
      stompClient.connect(
        {
          Authorization: token,
        },
        function (frame: any) {
          stompClient.subscribe("/topic/transaction", function (message) {
            try {
              const body = JSON.parse(message.body);
              const transaction = body?.transaction;
              const userMerchants = data?.merchants || [];
              const merchantId = transaction?.merchant?.id;
              if(userMerchants.length && !userMerchants?.includes(merchantId)) {
                return false
              }
              console.log(
                "transactiontransaction",
                body,
              );
              if (body?.type === "CREATE") {
                /*                if (transaction?.transactionStatus === "PENDING") {
                  if (transaction?.transactionType === "DEPOSIT") {
                    playDPNotification();
                    toast.custom(
                      (t) => (
                        <TransactionToast
                          title={_t("deposit")}
                          amount={convertAmount(transaction?.amount)}
                          currency={transaction?.currency?.codeLiteral}
                          icon={
                            <UploadIcon
                              size={24}
                              color={themeOptions.colors.green500}
                            />
                          }
                          toastData={t}
                        />
                      ),
                      {
                        position: "top-right",
                        duration: 5000,
                      }
                    );
                  } else {
                    playWDNotification();
                    toast.custom(
                      (t) => (
                        <TransactionToast
                          title={_t("withdrawal")}
                          amount={convertAmount(transaction?.amount)}
                          currency={transaction?.currency?.codeLiteral}
                          icon={
                            <DownloadIcon
                              size={24}
                              color={themeOptions.colors.red500}
                            />
                          }
                          toastData={t}
                        />
                      ),
                      {
                        position: "top-right",
                      }
                    );
                  }
                }*/
                queryClient.setQueriesData(
                  "pending_transactions",
                  (old: any) => {
                    return {
                      ...old?.content,
                      content: [transaction, ...old?.content],
                      totalElements: old.totalElements + 1,
                      numberOfElements: old.numberOfElements + 1,
                    };
                  }
                );
              } else {
                queryClient.invalidateQueries("pending_transactions");
                queryClient.invalidateQueries("history_transactions");
                if (
                  transaction?.transactionStatus === "PENDING" &&
                  body?.type === "STATUS_UPDATE"
                ) {
                  if (transaction?.transactionType === "DEPOSIT") {
                    playDPNotification();
                    toast.custom(
                      (t) => (
                        <TransactionToast
                          title={_t("deposit")}
                          amount={convertAmount(transaction?.amount)}
                          currency={transaction?.currency?.codeLiteral}
                          icon={
                            <UploadIcon
                              size={24}
                              color={themeOptions.colors.green500}
                            />
                          }
                          toastData={t}
                        />
                      ),
                      {
                        position: "top-right",
                        duration: 5000,
                      }
                    );
                  } else {
                    playWDNotification();
                    toast.custom(
                      (t) => (
                        <TransactionToast
                          title={_t("withdrawal")}
                          amount={convertAmount(transaction?.amount)}
                          currency={transaction?.currency?.codeLiteral}
                          icon={
                            <DownloadIcon
                              size={24}
                              color={themeOptions.colors.red500}
                            />
                          }
                          toastData={t}
                        />
                      ),
                      {
                        position: "top-right",
                      }
                    );
                  }
                }
              }
            } catch (e) {
              console.log("error");
            }
          });
        }
      );
      socket.onclose = function () {
        console.log("close connection");
      };
    }
    return () => {
      console.log("unmount", socket);
      if (socket && stompClient) {
        stompClient.unsubscribe("/topic/transaction");
        stompClient.disconnect();
        socket.close();
      }
    };
  }, [data]);
};
