import React, { useMemo, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import ButtonLink from "components/atoms/ButtonLink";
import FormBuilder from "services/formBuilder/FormBuilder";
import Table from "services/table";
import { APP_ROUTES } from "system/router/constants";
import { useTrans } from "system/translations/hooks";
import {
  useFilters,
  useGetSortMenuMobileItems,
  useListPagination,
  useListSort,
  useMobileFiltersDrawer,
  usePrepareForm,
} from "system/helpers/hooks";
import {
  useDeleteCurrencies,
  useGetCurrencies,
} from "modules/currencies/apiHooks";
import { useConfirmModal } from "services/modal/predefinedModals";
import {
  useSearchFormConfig,
  useTableColumns,
} from "modules/currencies/constantHooks";
import config from "system/config";
import { Button, CaretDownIcon, EditIcon, FilterIcon, PlusIcon, SortAlphabeticalDescIcon, SortAlphabeticalIcon, SortIcon, TrashIcon } from "evergreen-ui";
import SectionTitle from "components/atoms/SectionTitle";
import { useDrawer } from "services/drawer/hooks";
import globalStyles, { isMobile } from "system/theme/globalStyles";
import themeOptions from "system/theme";
import FilterMobileButtons from "components/molecules/FilterMobileButtons";

const Currencies = () => {
  const [q, setQ] = useState(true);
  const { _t } = useTrans();
  const history = useHistory();
  const { hideDrawer } = useDrawer()
  const { onConfirm } = useConfirmModal();
  const { mutate: onDelete } = useDeleteCurrencies();
  const searchFormConfig = useSearchFormConfig();
  const filterInitialValues = {};
  const {
    mobileSearchFormConfig,
    filterParams,
    filters,
    setFilters
  } = usePrepareForm(searchFormConfig, filterInitialValues)
  const { showFilters, filtersMobile, setFiltersMobile } = useMobileFiltersDrawer(filterParams);
  const { sort, setSort } = useListSort({});
  const { pagination, setPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const { sortMenuItems } =  useGetSortMenuMobileItems('Default','codeLiteral', setSort)
  const requestParams = useMemo(
    () => ({ ...filters, ...pagination, ...sort }),
    [filters, pagination, sort]
  );
  const { data, isLoading } = useGetCurrencies(requestParams);  
  const columns = useTableColumns();

  const onFilterSubmit = async (data: any) => {
    isMobile
      ? setFilters({ ...data, ...filtersMobile })
      : setFilters(data);
    hideDrawer('filters');
  };
  const onUpdate = ({ id, allData }: any) => {
    const link = generatePath(APP_ROUTES.currencies.update, { id });
    history.push(link, history.location);
  };
  const onDeleteWithConfirm = ({ id, allData }: any) => {
    onConfirm({
      onOk: () => onDelete({ id }),
      title: `${_t("deactivate")} ${allData?.codeLiteral}?`,
      bodyText: _t("are_you_sure"),
      onOkKind: "danger",
      onOkText: _t("deactivate"),
    });
  };
  const actionMenuItems = [
    { title: _t("edit"), onClick: onUpdate, icon: <EditIcon size={12} /> },
    {
      title: _t("deactivate"),
      onClick: onDeleteWithConfirm,
      icon: <TrashIcon size={12} />,
      intent: "danger",
    },
  ];
  return (
    <>
      <div css={globalStyles.mobileHeader}>
        <SectionTitle
          title={_t("currencies")}
          extra={
            <ButtonLink
              kind={"secondary"}
              iconBefore={PlusIcon}
              linkTo={APP_ROUTES.currencies.create}
              label={_t("create_currency")}
            />
          }
        />
        <FormBuilder
          formItemsConfig={isMobile ? mobileSearchFormConfig : searchFormConfig}
          formProps={{
            submitBtnLabel: _t("apply_filters"),
            onSubmit: onFilterSubmit,
            initialValues: {},
          }}
          showSubmit={false}
        />
        {isMobile && 
          <FilterMobileButtons
            showFilters={showFilters}
            sortMenuItems={sortMenuItems}
            setFiltersMobile={setFiltersMobile}
            setFilters={setFilters}
            filters={filtersMobile}
            totalElements={data?.totalElements || 0}
            filterInitialValues={filterInitialValues}
          />}
      </div>
      <Table
        totalRecords={data?.totalElements}
        columns={columns}
        pageIndex={pagination.page}
        pageSize={pagination.perPage}
        data={data?.content || []}
        isLoading={isLoading}
        isPagination
        onPagination={setPagination}
        onSort={setSort}
        cellProps={{ actionMenuItems }}
      />
    </>
  );
};

export default Currencies;
