import React, { useMemo } from "react";
import { css } from "@emotion/react";
import Input from "components/atoms/Input";
import { Col, Row } from "react-flexbox-grid";
import { useTrans } from "system/translations/hooks";
import Divider from "components/atoms/Divider";
import Btn from "components/atoms/Btn";
import { DeleteIcon } from "evergreen-ui";
import themeOptions from "system/theme";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { createStyles } from "types/emotion-styles";
import { useDeleteMethodPaymentDetail } from "modules/merchantPaymentMethods/apiHooks";
import JsonOrInput from "components/molecules/JsonOrInput";

const styles = createStyles({
  notActiveWrap: {
    gap: "10px 0",
  },
  dividerStyles: {
    margin: "20px 0 0",
  },
  inputWithDeleteWrap: {
    display: "inline-flex",
    gap: themeOptions.spacing.xs,
    alignItems: "flex-end",
    "&>div": {
      width: "100%",
    },
  },
});

const DetailsItem = ({
  name,
  onChange,
  onBlur,
  value,
  onFormValueChange,
}: any) => {
  const { _t } = useTrans();
  const { mutateAsync, isLoading, variables } = useDeleteMethodPaymentDetail();
  const onRemove = async (id: number) => {
    await mutateAsync({ id });
    const newValues = value.filter((item: any) => item.id !== id);
    onFormValueChange(name, newValues);
  };
  const disabledDetails = useMemo(() => {
    return value?.filter((item: any) => !item.enabled && item);
  }, [value?.length]);
  return (
    <>
      <Row css={css(`gap: 10px 0`)}>
        {value?.map((item: any, idx: number) => {
          if (!item.enabled) {
            return null;
          }
          return (
            <>
              <Col md={6}>
                <Input value={item?.key} label={_t("key")} disabled={true} />
              </Col>
              <Col md={6}>
                <JsonOrInput
                  name={`${name}[${idx}].value`}
                  value={item?.value}
                  onBlur={() => onBlur(`${name}[${idx}].value`)}
                  onChange={(value: any) => {
                    const fieldName: string = `${name}[${idx}].value`;
                    onChange(value, fieldName);
                  }} 
                  labelA={_t('value_json')}
                  labelB={_t('value_input')}
                />
              </Col>
            </>
          );
        })}
      </Row>
      {disabledDetails?.length ? (
        <Divider
          additionalClass={css(styles.dividerStyles)}
          text={_t("not_used_credentials")}
        />
      ) : null}
      <Row css={css(styles.notActiveWrap)}>
        {disabledDetails?.map((item: any, idx: number) => {
          return (
            <>
              <Col md={6}>
                <Input value={item?.key} label={_t("key")} disabled={true} />
              </Col>
              <Col md={6} css={css(styles.inputWithDeleteWrap)}>
                <Input
                  value={item?.value}
                  label={_t("value")}
                  disabled={true}
                />
                <RoleWrapper accessTo={"ROLE_DELETE_MERCHANT_METHOD_DETAILS"}>
                  <Btn
                    type={"button"}
                    disabled={variables?.id === item?.id && isLoading}
                    isLoading={variables?.id === item?.id && isLoading}
                    hasIconOnly
                    iconBefore={
                      <DeleteIcon color={themeOptions.colors.red500} />
                    }
                    onClick={() => onRemove(item?.id)}
                  />
                </RoleWrapper>
              </Col>
            </>
          );
        })}
      </Row>
    </>
  );
};

export default DetailsItem;
