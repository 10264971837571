import React, { useRef } from "react";
import { css } from "@emotion/react";
import { FullscreenIcon, Heading } from "evergreen-ui";
import { useDrawer } from "services/drawer/hooks";
import { createStyles } from "types/emotion-styles";
import DataDrawer from "components/molecules/ShowDataDetails/DataDrawer";
import themeOptions from "system/theme";

const styles = createStyles({
  container: {
    position: "relative",
    cursor: "pointer",
    "&:hover": {
      '[class*="-details-icon"]': {
        display: "block",
      },
    },
  },
  icon: {
    label: "details-icon",
    display: "none",
    position: "absolute",
    top: "2px",
    right: "2px",
    zIndex: 1,
    padding: "5px",
    background: themeOptions.colors.white,
    borderRadius: themeOptions.borders.xs,
    boxShadow: themeOptions.boxShadow.main,
    color: themeOptions.colors.gray700,
  },
});

const ShowDataDetails: React.FC<any> = ({
  data,
  title,
  sliceQuantity = 40,
  additionalClass,
  isShowVerticalDots = false,
}) => {
  const wrapRef: any = useRef(null);
  const previewRef: any = useRef(null);
  const { showDrawer } = useDrawer();
  const showDetails = () => {
    showDrawer({
      componentProps: {
        data: data,
      },
      component: DataDrawer,
      header: ({ title }: any) => <Heading size={700}>{title}</Heading>,
      headerProps: {
        title,
      },
      bodyWrapperProps: {
        hasSpacing: false,
      },
    });
  };
  const previewData =
    data?.length > sliceQuantity
      ? data?.split("").slice(0, sliceQuantity).join("") + " ..."
      : data;
  const isVerticalDots =
    isShowVerticalDots &&
    previewRef?.current?.offsetHeight > wrapRef?.current?.offsetHeight;
  return (
    <div
      ref={wrapRef}
      css={css(
        styles.container,
        additionalClass && additionalClass,
        isVerticalDots && {
          "&:after": {
            content: '"..."',
            display: "block",
            position: "absolute",
            top: "27px",
            fontWeight: "bold",
          },
        }
      )}
      onClick={showDetails}
    >
      <div ref={previewRef}>{previewData}</div>
      <FullscreenIcon size={22} css={css(styles.icon)} />
    </div>
  );
};

export default ShowDataDetails;
