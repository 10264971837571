import { arrayOptionsMap, parseFormData } from "system/helpers/helperFunctions";
import { UseModuleDataRes } from "modules/formTemplates/types";
import { useGetPaymentMethodsNameList } from "../payment/apiHooks";
import { useMemo } from "react";
import { useDrawer } from "services/drawer/hooks";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import JsonDrawer from "./components/JsonDrawer";
import FieldsOrder from "./components/FieldsOrder";
import { TransType } from "system/translations/types";

export const useModuleData = (): UseModuleDataRes => {
  const { data: paymentMethods = [] } = useGetPaymentMethodsNameList();
  const paymentMethodsOptions = arrayOptionsMap(Object.values(paymentMethods), {
    labelKey: "name",
    valueKey: "id",
  });
  return { paymentMethodsOptions };
};


export const useGetInitialProperties = ({
  data,
  isLoading,
  setFields,
  setStyles
} : {
  data: any,
  isLoading: boolean,
  setFields: (value: any) => void,
  setStyles: (value: any) => void,
}) => {
  const initialProperties = useMemo(() => {
    if (data && !isLoading) {
      const properties = JSON.parse(data?.properties);
      const newFields = properties?.fields.map((item: any) => {
        if (item.type === "files") {
          const files: any = [];
          data.formTemplateImages.map((file: any) => {
            if (file.name === item.name) {
              files.push(file.url);
            }
          });

          return { ...item, files: [...files] };
        }
        return item;
      });
      setFields(newFields);
      setStyles(properties?.styles);
      return { ...properties, fields: newFields };
    }
    return {};
  }, [data, isLoading]);

  return initialProperties
}

export const useShowFunctions = ({
  fieldsFormRef,
  stylesFormRef,
  getHeaderComponent,
  styles,
  fields,
  _t,
}: {
  fieldsFormRef: any,
  stylesFormRef: any,
  getHeaderComponent: ({ title }: any) => EmotionJSX.Element,
  styles: any,
  fields: any,
  _t: TransType, 
}) => {
  const { showDrawer } = useDrawer();
  const fieldFormName = "fields";
  const stylesFormName = "styles";

  const onOrder = (val: any) => {
    fieldsFormRef?.current?.formik?.setFieldValue(fieldFormName, val);
  };
  const showOrder = () => {
    const values = fieldsFormRef?.current?.formik?.values;
    showDrawer({
      header: getHeaderComponent,
      component: FieldsOrder,
      componentProps: {
        data: values?.[fieldFormName] || [],
        setOrder: onOrder,
      },
    });
  };
  const showFields = () => {
    showDrawer({
      componentProps: {
        json: JSON.stringify(fields, null, 4),
        isJson: true,
        onSubmit: (val: any) => {
          fieldsFormRef?.current?.formik.setFieldValue(fieldFormName, val);
        },
      },
      component: JsonDrawer,
      header: getHeaderComponent,
      headerProps: {
        title: _t("json_fields_preview"),
      },
      bodyWrapperProps: {
        hasSpacing: false,
      },
    });
  };
  const showStyles = () => {
    showDrawer({
      componentProps: {
        json: JSON.stringify(styles, null, 4),
        isJson: true,
        onSubmit: (val: any) => {
          stylesFormRef?.current?.formik.setFieldValue(stylesFormName, {
            ...val?.[stylesFormName],
          });
        },
      },
      component: JsonDrawer,
      header: getHeaderComponent,
      headerProps: {
        title: _t("json_styles_preview"),
      },
      bodyWrapperProps: {
        hasSpacing: false,
      },
    });
  };

  return { showOrder, showFields, showStyles }
}

export const useChangeHandlers = ({
  setFields,
  setStyles
} : {
  setFields: (value: any) => void,
  setStyles: (value: any) => void,
}) => {
  const onFieldsValuesChange = (values: any) => {
    setTimeout(() => {
      setFields([...values.fields]);
    }, 500);
  };
  const onStylesValuesChange = (values: any) => {
    setTimeout(() => {
      setStyles({ ...values });
    }, 500);
  };

  return { onFieldsValuesChange, onStylesValuesChange}
}

export const getSubmittedData = ({
  generalFormRef,
  data,
  styles,
  isUpdate = false,
  id, 
} : {
  generalFormRef : any,
  data: any,
  styles: any,
  isUpdate?: boolean,
  id?: any,
}) => {
  const images: any[] = [];
  const credentials: any[] = [];
  const fields = data?.fields;
  fields.forEach((item: any) => {
    if (item.hasOwnProperty("files")) {
      images.push({ name: item.name, files: item.files });
    }

    if (isUpdate && item?.type.includes("infoList") && item?.options) {
      item.options.forEach((option: any) => {
        credentials.push(option?.key);
      });
    }
  });

  const values = generalFormRef?.current?.formik?.values;

  if (isUpdate) {
    values.id = id;
    values.credentials = credentials;
  }
  const reqData = parseFormData({
    ...values,
    properties: JSON.stringify({ fields: data?.fields, styles: styles }),
    images: images,
  })

  return { reqData }
}