import React from "react";
import styles from "components/molecules/AssignSelect/styles";
import { css } from "@emotion/react";
import { CogIcon, ResetIcon, WarningSignIcon, Text } from "evergreen-ui";
import themeOptions from "system/theme";
import Loader from "components/atoms/Loader";

const AssignSelect: React.FC<any> = ({
  label,
  selected,
  onClick,
  isLoading,
}) => {
  return (
    <div
      css={css(styles.container, isLoading && styles.containerDisabled)}
      onClick={() => onClick(selected)}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div css={css(styles.label)}>
          {selected ? (
            <Text size={400} color={themeOptions.colors.gray800}>
              {label}
            </Text>
          ) : (
            <>
              <WarningSignIcon color={themeOptions.colors.yellow400} />
              <Text size={400} color={themeOptions.colors.gray800}>
                {label}
              </Text>
            </>
          )}
        </div>
      )}
      <div css={css(styles.icon)}>
        {selected ? (
          <ResetIcon size={12} color={themeOptions.colors.gray600} />
        ) : (
          <CogIcon size={12} color={themeOptions.colors.gray600} />
        )}
      </div>
    </div>
  );
};

export default AssignSelect;
