import { merge } from "lodash";
import { defaultTheme } from "evergreen-ui";
import themeOptions from "system/theme/index";
const evergreenTheme = merge(defaultTheme, {
  typography: {
    /*      fontFamilies: {
          display: "serif",
          ui: "serif",
          mono: "monospace",
        },*/
  },
  components: {
    Checkbox: {
      appearances: {
        checked: {
          background: themeOptions.colors.blue400,
        },
      },
    },
    Switch: {
      appearances: {
        checked: {
          background: themeOptions.colors.blue400,
        },
      },
    },
    Alert: {
      appearances: {
        notify: {
          background: "red",
          color: "red",
        },
      },
      intent: {
        notify: {
          background: "red",
          color: "red",
        },
      },
      notify: {
        background: "red",
        color: "red",
      },
      /*   baseStyle: {
        width: "500px",
      },*/
    },
    Toast: {
      appearances: {
        notify: {
          background: "red",
          color: "red",
        },
      },
      intent: {
        notify: {
          background: "red",
          color: "red",
        },
      },
      notify: {
        background: "red",
        color: "red",
      },
      /*   baseStyle: {
        width: "500px",
      },*/
    },
    Tab: {
      appearances: {
        primary: {
          fontWeight: 400,
          paddingBottom: "10px",
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
    DialogHeader: {
      baseStyle: {
        paddingTop: 24,
        paddingBottom: 16,
      },
    },
    DialogBody: {
      baseStyle: {
        minHeight: 0,
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    Button: {
      appearances: {
        secondary: {
          backgroundColor: defaultTheme.colors.green500,
          color: defaultTheme.colors.white,
          _hover: {
            backgroundColor: defaultTheme.colors.green600,
          },
          _active: {
            backgroundColor: defaultTheme.colors.green700,
          },
          _focus: {
            boxShadow: `0 0 0 2px ${defaultTheme.colors.green200}`,
            backgroundColor: defaultTheme.colors.green600,
          },
          _disabled: {
            backgroundColor: defaultTheme.colors.green200,
          },
        },
        danger: {
          backgroundColor: defaultTheme.colors.red500,
          color: defaultTheme.colors.white,
          _hover: {
            backgroundColor: defaultTheme.colors.red600,
          },
          _active: {
            backgroundColor: defaultTheme.colors.red700,
          },
          _focus: {
            boxShadow: `0 0 0 2px ${defaultTheme.colors.red100}`,
            backgroundColor: defaultTheme.colors.red600,
          },
          _disabled: {
            backgroundColor: defaultTheme.colors.red100,
          },
        },
        minimal: {
          _hover: {
            color: defaultTheme.colors.blue500,
            backgroundColor: defaultTheme.colors.white,
          },
          _active: {
            color: defaultTheme.colors.blue500,
          },
          _focus: {
            boxShadow: `0 0 0 2px ${defaultTheme.colors.blue200}`,
          },
        },
        tertiary: {
          backgroundColor: defaultTheme.colors.white,
          color: defaultTheme.colors.blue500,
          borderTopColor: defaultTheme.colors.blue500,
          borderBottomColor: defaultTheme.colors.blue500,
          borderLeftColor: defaultTheme.colors.blue500,
          borderRightColor: defaultTheme.colors.blue500,
          _hover: {
            color: defaultTheme.colors.blue500,
          },
          _active: {
            color: defaultTheme.colors.blue500,
            backgroundColor: defaultTheme.colors.gray50,
          },
          _focus: {
            boxShadow: `0 0 0 2px ${defaultTheme.colors.blue200}`,
          },
          _disabled: {
            color: defaultTheme.colors.gray500,
            borderTopColor: defaultTheme.colors.gray300,
            borderBottomColor: defaultTheme.colors.gray300,
            borderLeftColor: defaultTheme.colors.gray300,
            borderRightColor: defaultTheme.colors.gray300,
          },
        },
        custom: {
          boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.08)",
          backgroundColor: defaultTheme.colors.white,
          color: defaultTheme.colors.blue500,
          borderTopColor: defaultTheme.colors.blue100,
          borderBottomColor: defaultTheme.colors.blue100,
          borderLeftColor: defaultTheme.colors.blue100,
          borderRightColor: defaultTheme.colors.blue100,
          _hover: {
            color: defaultTheme.colors.blue500,
            borderTopColor: defaultTheme.colors.blue500,
            borderBottomColor: defaultTheme.colors.blue500,
            borderLeftColor: defaultTheme.colors.blue500,
            borderRightColor: defaultTheme.colors.blue500,
          },
          _active: {
            color: defaultTheme.colors.blue500,
            backgroundColor: defaultTheme.colors.blue100,
          },
          _focus: {
            boxShadow: `0 0 0 2px ${defaultTheme.colors.blue50}`,
          },
          _disabled: {
            color: defaultTheme.colors.gray500,
          },
        },
        linkBorderedBtn: {
          backgroundColor: "transparent",
          color: defaultTheme.colors.blue400,
          _hover: {
            backgroundColor: "transparent",
            color: defaultTheme.colors.blue500,
          },
          _active: {
            backgroundColor: "transparent",
            color: defaultTheme.colors.blue500,
          },
          _focus: {
            boxShadow: "none",
            backgroundColor: "transparent",
            color: defaultTheme.colors.blue500,
          },
          _disabled: {
            backgroundColor: "transparent",
            color: defaultTheme.colors.blue100,
          },
        },
      },
    },
  },
});

export default evergreenTheme;
