import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";
import searchIcon from "images/icons/search.svg";

const styles = createStyles({
  label: {
    color: themeOptions.colors.gray700,
    label: "select-label",
    fontSize: "12px",
    lineHeight: "16px",
  },
  singleLabel: {
    fontWeight: 500,
    color: themeOptions.colors.gray900,
    marginBottom: themeOptions.spacing.xs,
  },
  labelQuantity: {
    color: themeOptions.colors.gray700,
    marginLeft: themeOptions.spacing.xxs,
    label: "select-quantity",
  },
  labelAll: {
    marginRight: themeOptions.spacing.xxs,
    label: "select-labelAll",
    fontSize: "12px",
    lineHeight: "16px",
  },
  labelArrow: {
    svg: {
      fill: themeOptions.colors.gray700,
      height: "12px",
    },
    label: "select-labelArrow",
    lineHeight: "12px",
  },
  labelWrap: {
    display: "flex",
  },
  labelSingle: {
    marginLeft: "10px",
  },
  quantityValues: {
    background: themeOptions.colors.gray200,
    color: themeOptions.colors.blue900,
    padding: "4px 7px",
    borderRadius: "100%",
    marginRight: themeOptions.spacing.xxs,
    fontSize: "12px",
    label: "select-quantity-values",
  },
  selectWrap: {
    '.dropdown-container[aria-expanded="true"]': {
      '[class*="-select-labelArrow"]': {
        transform: "rotate(180deg)",
      },
      ".dropdown-heading": {
        display: "none",
      }
    },
    ".dropdown-container": {
      borderColor: "transparent",
      background: "transparent",
      "&:focus-within": {
        boxShadow: "none",
        borderColor: "transparent",
      },
      ".panel-content": {
        borderTop: `1px solid ${themeOptions.colors.gray300}`,
        boxShadow: "initial",
      },
    },
    ".dropdown-content": {
      paddingTop: "0",
      minWidth: "200px",
      zIndex: 2,
      width: "100vw",
      marginTop: "-25px",
      marginLeft: "-25px",
    },
    ".options": {
      width: "100%",
      maxHeight: "initial",
      "label.select-item": {
        borderBottom: `6px solid ${themeOptions.colors.gray300}`,
      },
      "li label.select-item": {
        borderBottom: `1px solid ${themeOptions.colors.gray300}`,
      },
    },
    ".options > label:first-child": {
      position: "relative",
      "&:before": {
        content: '"Select All"',
        fontSize: "12px",
        fontWeight: 500,
        color: themeOptions.colors.gray500,
      },
      "&:after": {
        content: '" - Clear All"',
        fontSize: "12px",
        fontWeight: 500,
        color: themeOptions.colors.gray500,
      },
      '&[aria-selected="true"]': {
        "&:after": {
          color: themeOptions.colors.blue400,
        },
      },
      '&[aria-selected="false"]': {
        "&:before": {
          color: themeOptions.colors.blue400,
        },
      },
      ".item-renderer": {
        display: "none",
      },
    },
    ".search": {
      borderBottom: `6px solid ${themeOptions.colors.gray300}`,
      position: "relative",
      "&:before": {
        content: '""',
        width: "15px",
        height: "15px",
        backgroundImage: `url(${searchIcon})`,
        display: "block",
        backgroundRepeat: "no-repeat",
        position: "absolute",
        top: "9px",
        left: "10px",
      },
      input: {
        background: themeOptions.colors.gray50,
        paddingLeft: "30px",
        fontSize: "12px",
        "&:focus": {
          background: themeOptions.colors.gray100,
        },
        height: "32px",
      },
    },
    ".item-renderer": {
      alignItems: "center",
      gap: themeOptions.spacing.xs,
      color: themeOptions.colors.gray800,
      input: {
        accentColor: themeOptions.colors.blue500,
      },
    },
    ".select-item.selected": {
      background: "transparent",
    },
  },
  isError: {
    cursor: "pointer",
  },
  hasSelected: {
    ".options > label:first-child": {
      position: "relative",
      "&:before": {
        content: '"Select All"',
        fontSize: "12px",
        fontWeight: 500,
        color: themeOptions.colors.blue400,
      },
      "&:after": {
        content: '" - Clear All"',
        fontSize: "12px",
        fontWeight: 500,
        color: themeOptions.colors.blue400,
      },
    },
  },
  menuFullWidth: {
    ".dropdown-content": {
      width: "fit-content",
    },
  },

});

export default styles;
