import React, { useEffect, useRef } from "react";
import SectionTitle from "components/atoms/SectionTitle";
import Card from "components/atoms/Card";
import { css } from "@emotion/react";
import themeOptions from "system/theme";
import FormBuilder from "services/formBuilder/FormBuilder";
import { Col, Row } from "react-flexbox-grid";
import {
  Heading,
  Pane,
  SidebarTab,
  StyleIcon,
  Tablist,
  ThListIcon,
} from "evergreen-ui";
import DrawerSettings from "modules/formTemplates/components/DrawerSettings";
import FieldsPreview from "modules/formTemplates/components/FieldsPreview";
import { useTrans } from "system/translations/hooks";
import { debounceHandler } from "system/helpers/helperFunctions";
import { isMobile } from "react-device-detect";

type FormViewProps = {
  sectionTitle: any;
  generalFormConfig: any;
  generalFormRef: any;
  generalFormInitialValues: any;
  generalFormValidationSchema: any;
  selectedTab: any;
  setSelectedTab: any;
  showOrder: any;
  showFields: any;
  fields: any;
  fieldsFormRef: any;
  fieldsFormConfig: any;
  showStyles: any;
  fieldsFormInitialValues: any;
  onFieldsValuesChange: any;
  fieldsFormCreateEditValidation: any;
  onSubmit: any;
  stylesFormConfig: any;
  stylesFormRef: any;
  onStylesValuesChange: any;
  stylesFormInitialValues: any;
  customStyles: any;
};

const FormView: React.FC<FormViewProps> = ({
  sectionTitle,
  generalFormConfig,
  generalFormRef,
  generalFormInitialValues,
  generalFormValidationSchema,
  selectedTab,
  setSelectedTab,
  showOrder,
  showFields,
  fields,
  fieldsFormRef,
  fieldsFormConfig,
  showStyles,
  fieldsFormInitialValues,
  onFieldsValuesChange,
  fieldsFormCreateEditValidation,
  onSubmit,
  stylesFormConfig,
  stylesFormRef,
  onStylesValuesChange,
  stylesFormInitialValues,
  customStyles,
}) => {
  const { _t } = useTrans();
  const fieldsPreviewRef = useRef<HTMLDivElement>(null);
  const scrollHandler = () => {
    debounceHandler(() => {
      if (fieldsPreviewRef && fieldsPreviewRef.current) {
        if (window.scrollY > 300) {
          fieldsPreviewRef.current.style.position = "absolute";
          fieldsPreviewRef.current.style.top = `${window.scrollY}px`;
          fieldsPreviewRef.current.style.transition = "all 0.3s";
        } else {
          fieldsPreviewRef.current.style.position = "static";
        }
      }
    });
  };
  /*  useEffect(() => {
    if (fieldsPreviewRef?.current && !isMobile) {
      document.addEventListener("scroll", scrollHandler);
    }
    return () => {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, [fieldsPreviewRef?.current]);*/

  return (
    <>
      <SectionTitle title={sectionTitle} />
      <Card
        wrapperClass={css({
          marginBottom: themeOptions.spacing.sm,
          padding: themeOptions.spacing.sm,
          paddingBottom: themeOptions.spacing.xs,
        })}
      >
        <FormBuilder
          showSubmit={false}
          formItemsConfig={generalFormConfig}
          ref={generalFormRef}
          formProps={{
            onSubmit: () => {},
            initialValues: generalFormInitialValues,
            validationSchema: generalFormValidationSchema,
            rowStyles: { alignItems: "flex-end" },
          }}
        />
      </Card>
      <Row>
        <Col lg={6} md={12}>
          <Pane
            css={css({
              padding: 0,
              display: "flex",
              flexDirection: "row",
              background: "#fff",
              borderRadius: themeOptions.borders.sm,
            })}
          >
            <Tablist
              padding={4}
              borderRight={`1px solid ${themeOptions.colors.gray300}`}
            >
              <SidebarTab
                padding={8}
                onSelect={() => setSelectedTab("fields")}
                key={"fields"}
                id={"fields"}
                aria-controls={`panel-fields`}
                isSelected={"fields" === selectedTab}
              >
                <ThListIcon />
              </SidebarTab>
              <SidebarTab
                padding={8}
                onSelect={() => setSelectedTab("styles")}
                key={"styles"}
                id={"styles"}
                aria-controls={`panel-styles`}
                isSelected={"styles" === selectedTab}
              >
                <StyleIcon />
              </SidebarTab>
            </Tablist>
            <Pane padding={themeOptions.spacing.md} width={"100%"}>
              <Pane
                key={"fields"}
                id={`panel-fields`}
                role="tabpanel"
                aria-labelledby={fields}
                display={"fields" === selectedTab ? "block" : "none"}
              >
                <Pane
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Heading>{_t("form_elements")}</Heading>
                  <DrawerSettings showOrder={showOrder} showJson={showFields} />
                </Pane>
                <FormBuilder
                  showSubmit={false}
                  formItemsConfig={fieldsFormConfig}
                  ref={fieldsFormRef}
                  formProps={{
                    onSubmit: onSubmit,
                    initialValues: fieldsFormInitialValues,
                    onFormValuesChange: onFieldsValuesChange,
                    validationSchema: fieldsFormCreateEditValidation,
                  }}
                />
              </Pane>
              <Pane
                key={"styles"}
                id={`panel-styles`}
                role="tabpanel"
                aria-labelledby={fields}
                display={"styles" === selectedTab ? "block" : "none"}
              >
                <Pane
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Heading>{_t("style_customization")}</Heading>
                  <DrawerSettings showJson={showStyles} />
                </Pane>
                <FormBuilder
                  showSubmit={false}
                  formItemsConfig={stylesFormConfig}
                  ref={stylesFormRef}
                  formProps={{
                    onSubmit: () => {},
                    onFormValuesChange: onStylesValuesChange,
                    initialValues: stylesFormInitialValues,
                    rowStyles: { alignItems: "flex-end" },
                  }}
                />
              </Pane>
            </Pane>
          </Pane>
        </Col>
        <Col lg={6} md={12}>
          <div ref={fieldsPreviewRef}>
            <FieldsPreview fields={fields} customStyles={customStyles} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default FormView;
