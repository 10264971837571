import React, { useState } from "react";
import { useTrans } from "system/translations/hooks";
import LoginForm from "./forms/loginForm";
import AuthWrapper from "modules/auth/components/wrappers/AuthWrapper";
import TfaProcessForm from "./forms/tfaProcessForm";
import { useLogin } from "modules/auth/apiHooks";
import { Heading } from "evergreen-ui";
import { css } from "@emotion/react";
import styles from "modules/auth/components/wrappers/authWrapperStyles";

const Login: React.FC = () => {
  const { _t } = useTrans();
  const { mutateAsync, isLoading, data: loginData, variables } = useLogin();
  const [step, setStep] = useState(1);
  const [payloadData, setPayloadData] = useState({ rememberMe: false });

  const backHandler = () => {
    setStep(1);
  };

  const onSuccess = (data: any) => {
    setPayloadData(data);
    setStep(2);
  };

  return (
    <>
      {step === 1 && (
        <AuthWrapper title={`${_t("log_in_to")}`}>
          <LoginForm
            isLoading={isLoading}
            onLoginAsync={mutateAsync}
            onSuccess={onSuccess}
          />
        </AuthWrapper>
      )}
      {step === 2 && (
        <AuthWrapper
          qrCode={loginData?.qrCode}
          title={_t("tfa_authentication")}
          backHandler={backHandler}
          subtitle={
            !loginData?.qrCode ? _t("tfa_authentication_help_text") : ""
          }
          footer={
            !loginData?.qrCode ? (
              <Heading
                css={css(styles.footerDescription)}
                dangerouslySetInnerHTML={{
                  __html: _t(`tfa_contact_support_via_mail_{link}`, {
                    link: (
                      <a target={"_blank"} href={"mailto:support@ozzypay.com"}>
                        support@ozzypay.com
                      </a>
                    ),
                  }),
                }}
              />
            ) : null
          }
        >
          <TfaProcessForm payloadData={variables} />
        </AuthWrapper>
      )}
    </>
  );
};

export default Login;
