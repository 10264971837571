import React from "react";
import { TextAreaProps } from "components/atoms/TextArea/types";
import { Textarea, Label, Pane } from "evergreen-ui";
import styles from "components/atoms/TextArea/styles";
import CopyButtonWrapper from "components/atoms/CopyButtonWrapper";
import { useTrans } from "system/translations/hooks";

const TextArea: React.FC<TextAreaProps> = ({
  id = "textAria",
  label,
  value,
  onChange,
  placeholder,

  ...rest
}) => {
  const { _t } = useTrans();
  return (
    <Pane css={styles.wrapper}>
      {!!label ? (
        <Label htmlFor="textarea-2" marginBottom={4} display="block">
          {label}
        </Label>
      ) : null}
      <Textarea
        {...rest}
        id={id}
        onChange={(e: any) => onChange(e?.target?.value)}
        value={value}
        placeholder={placeholder}
        resize={"vertical"}
      />
      <div css={styles.copyBtn}>
        <CopyButtonWrapper
          toastTitle={_t("copied_successfully")}
          copyValue={value}
        />
      </div>
    </Pane>
  );
};

export default TextArea;
