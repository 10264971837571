import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "services/formBuilder/types";
import { useDefaultFieldsConfig } from "services/formBuilder/hooks";
import DotsMenu from "components/atoms/DotsMenu";
import { CreateEditFormConfigParams } from "modules/translations/types";
import { TableColumnType } from "services/table/types";
import ShowDataDetails from "components/molecules/ShowDataDetails";

export const useTableColumns = (languages: any = []): TableColumnType[] => {
  const { _t } = useTrans();
  const cells = languages?.map((item: any) => {
    return {
      Header: item.toUpperCase(),
      id: item.toUpperCase(),
      Cell: (props: any) => {
        return !!props?.row?.original?.[item] ? (
          <ShowDataDetails data={props?.row?.original?.[item]} title={item} />
        ) : (
          "-"
        );
      },
    };
  });
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("key"),
        accessor: "key",
        id: "key",
        width: 350,
        isMobileTitle: true,
      },
      ...cells,
      {
        maxWidth: 65,
        Header: "",
        id: "UpdateDeleteMenu",
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.key,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    [languages]
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.key({
        columnParams: {
          md: 6,
          lg: 4,
        },
      }),
    ];
  }, []);
  return formConfig;
};

export const useCreateEditConfig = (
  params: CreateEditFormConfigParams
): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.key({
        hidden: params.isEdit,
        componentProps: {
          disabled: params.isEdit,
        },
        columnParams: {
          lg: 4,
          md: 4,
        },
      }),
      fields.general.divider({ hidden: params.isEdit }),
      fields.formFields.transKeys({
        name: [...params.languages],
        columnParams: {
          lg: 4,
          md: 4,
        },
      }),
      fields.general.divider(),
      fields.general.saveBtn({
        componentProps: {
          kind: params.isEdit ? "primary" : "secondary",
          label: params.isEdit ? _t("save_changes") : _t("create_key"),
        },
      }),
    ];
  }, [params.languages, params.isLoading]);
  return formConfig;
};
