import React, { useState } from "react";
import { SortMenuItem, SortMenuItemProps } from "./types";
import { Popover, Menu, Position, MoreIcon, CaretDownIcon, SortIcon } from "evergreen-ui";
import Btn from "components/atoms/Btn";
import themeOptions from "system/theme";
import { css } from "@emotion/react";
import { useTrans } from "system/translations/hooks";

const SortMenuMobile: React.FC<SortMenuItemProps> = ({ items }) => {
  const {_t} = useTrans()
  const [buttonProps, setButtonProps] = useState({
    icon: items[0].icon,
    text: items[0].title
  })
  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      statelessProps={{
        padding: 0,
      }}
      content={({close}) => 
        <Menu>
          <Menu.Group>
            {items.map(
              ({
                  onClick,
                  title,
                  icon,
                }: SortMenuItem,
                idx
              ) => {
                return (
                  <>
                      <Menu.Item
                        css={css({
                          padding: 0,
                          color: themeOptions.colors.gray800,
                        })}
                        onClick={() => {                          
                          onClick();
                          close();
                          setButtonProps({
                            icon,
                            text: title.includes('-') ? title.split(' ')[1] : title
                          })
                        }}
                      >
                        {title}
                      </Menu.Item>
                    {idx + 1 !== items.length && <Menu.Divider />}
                  </>
                )
              }
            )}
          </Menu.Group>
        </Menu>
      }
    >
      <div>
        <Btn
          kind={"minimal"}
          iconBefore={<buttonProps.icon color={themeOptions.colors.gray600} />}
          iconAfter={CaretDownIcon}
        >
          {_t("sort")}: {buttonProps.text}
        </Btn>
      </div>
    </Popover>
  );
};

export default SortMenuMobile;
