import React, { useMemo, useRef, useState } from "react";
import { useTrans } from "system/translations/hooks";
import { useCreateFormTemplate, useGetFormTemplate } from "../apiHooks";
import { generatePath, useHistory, useParams } from "react-router-dom";
import {
  createEditValidation,
  createEditGeneralValidation,
} from "../validation";
import {
  useCreateEditFieldsConfig,
  useCreateEditGeneralConfig,
  useCreateEditStylesConfig,
} from "../constantHooks";
import { Heading } from "evergreen-ui";
import Loader from "components/atoms/Loader";
import FormView from "modules/formTemplates/components/FormView";
import { APP_ROUTES } from "system/router/constants";
import { getSubmittedData, useChangeHandlers, useGetInitialProperties, useShowFunctions } from "../hooks";

const Duplicate: React.FC = () => {
  const history = useHistory();
  const routeParams: any = useParams();
  const { data, isLoading } = useGetFormTemplate(routeParams?.id);
  const { mutateAsync: onCreate } = useCreateFormTemplate();
  const isEdit = false;
  const generalFormRef: any = useRef(null);
  const fieldsFormRef: any = useRef(null);
  const stylesFormRef: any = useRef(null);
  const formGeneralConfig = useCreateEditGeneralConfig({ isEdit });
  const formStylesConfig = useCreateEditStylesConfig({ isEdit });
  const formFieldsConfig = useCreateEditFieldsConfig({ isEdit });
  const [fields, setFields] = useState<any>([]);
  const [styles, setStyles] = useState<any>({});
  const [selectedTab, setSelectedTab] = useState("fields");
  const { _t } = useTrans();

  if (data && data?.keys) {
    data.keys = []
  }  

  const onUpdate = ({ id }: any) => {
    const path = APP_ROUTES.paymentsFormTemplate.update;
    const link = generatePath(path, { id });
    history.replace(link);
  };
  const getHeaderComponent = ({ title = _t("fields_order") }: any) => <Heading size={700}>{title}</Heading>
  const initialProperties = useGetInitialProperties({
    data,
    isLoading,
    setFields,
    setStyles
  })
  const { showOrder, showFields, showStyles } = useShowFunctions({
    fieldsFormRef,
    stylesFormRef,
    getHeaderComponent,
    styles,
    fields,
    _t,
  })
  const { onFieldsValuesChange, onStylesValuesChange }  = useChangeHandlers({ setFields, setStyles })
  
  const onSubmit = async (data: any) => {
    generalFormRef?.current?.formik?.handleSubmit();
    if (generalFormRef?.current?.formik?.isValid) {
      const { reqData } = getSubmittedData({
        generalFormRef,
        data,
        styles,
      });

      const responseData = await onCreate(reqData);
      onUpdate(responseData)
    }
  };
  
  if (isLoading) {
    return <Loader formOverlay />;
  }
  return (
    <>
      <FormView
        sectionTitle={data?.name + '-' + _t("duplicate")}
        generalFormRef={generalFormRef}
        generalFormConfig={formGeneralConfig}
        generalFormInitialValues={{
          enabled: data?.enabled,
        }}
        generalFormValidationSchema={createEditGeneralValidation(_t, {
          isEdit,
        })}
        fields={fields}
        fieldsFormRef={fieldsFormRef}
        fieldsFormConfig={formFieldsConfig}
        fieldsFormInitialValues={{ fields: initialProperties?.fields }}
        fieldsFormCreateEditValidation={createEditValidation(_t, {
          isEdit,
        })}
        onFieldsValuesChange={onFieldsValuesChange}
        stylesFormRef={stylesFormRef}
        stylesFormConfig={formStylesConfig}
        stylesFormInitialValues={initialProperties?.styles}
        onStylesValuesChange={onStylesValuesChange}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        showOrder={showOrder}
        showFields={showFields}
        showStyles={showStyles}
        customStyles={styles?.styles}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default Duplicate;
