import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { Pane } from "evergreen-ui";
import { useTrans } from "system/translations/hooks";
import themeOptions from "system/theme";
import TextArea from "components/atoms/TextArea";

const Details = (props: any) => {
  const { _t } = useTrans();
  const formatValue = (value: string) => {
    let formattedValue = "";
    if (value) {
      try {
        const val = JSON.parse(value);
        formattedValue = JSON.stringify(val, null, 2);
      } catch (e) {
        formattedValue = value;
      }
    }
    return formattedValue;
  };
  return (
    <Pane height={300} padding={themeOptions.spacing.md}>
      <Row style={{ gap: "10px 0" }}>
        <Col md={12}>
          <TextArea
            height={180}
            id={"request_params"}
            label={_t("request_params")}
            onChange={() => {}}
            value={formatValue(props?.rowData?.requestParams)}
          />
        </Col>
        <Col md={6}>
          <TextArea
            height={250}
            id={"request_header"}
            label={_t("request_header")}
            onChange={() => {}}
            value={formatValue(props?.rowData?.requestHeader)}
          />
        </Col>
        <Col md={6}>
          <TextArea
            height={250}
            id={"response_header"}
            label={_t("response_header")}
            onChange={() => {}}
            value={formatValue(props?.rowData?.responseHeader)}
          />
        </Col>
        <Col md={6}>
          <TextArea
            height={250}
            id={"request_data"}
            label={_t("request_data")}
            onChange={() => {}}
            value={formatValue(props?.rowData?.requestData)}
          />
        </Col>
        <Col md={6}>
          <TextArea
            height={250}
            id={"response_data"}
            label={_t("response_data")}
            onChange={() => {}}
            value={formatValue(props?.rowData?.responseData)}
          />
        </Col>
      </Row>
    </Pane>
  );
};

export default Details;
