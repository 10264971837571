import React from "react";
import { InfoTagProps } from "components/atoms/InfoTag/types";
import classNames from "classnames";
import {
  Badge,
  Pane,
  TickCircleIcon,
  TimeIcon,
  BanCircleIcon,
  InfoSignIcon,
  ErrorIcon,
} from "evergreen-ui";
import themeOptions from "system/theme";

const InfoTag: React.FC<InfoTagProps> = ({ label, status, type }) => {
  const statusInfo: any = {
    approved: {
      color: "green",
      icon: <TickCircleIcon size={14} />,
    },
    pending: {
      color: "yellow",
      icon: <TimeIcon size={14} />,
    },
    canceled: {
      color: "neutral",
      icon: <InfoSignIcon size={14} />,
    },
    declined: {
      color: "red",
      icon: <ErrorIcon size={14} />,
    },
    error: {
      color: "red",
      icon: <BanCircleIcon size={14} />,
    },
    waiting: {
      color: themeOptions.colors.yellowGreen100,
      icon: (
        <InfoSignIcon color={themeOptions.colors.yellowGreen400} size={14} />
      ),
    },
  };
  return type == "secondary" ? (
    <div className={classNames(`info-tag-${type}-wrap`)}>
      <div
        className={classNames(
          `info-${type}-tag`,
          `info-${type}-tag--${status?.toLowerCase()}`
        )}
      />
      <span className={`info-${type}-tag-label`}>{label}</span>
    </div>
  ) : (
    <Badge
      height={"24px"}
      width={"fit-content"}
      display={"flex"}
      alignItems={"center"}
      gap={5}
      borderRadius={themeOptions.borders.sm}
      color={statusInfo?.[status]?.color}
    >
      {statusInfo?.[status]?.icon}
      {label}
    </Badge>
  );
};

export default InfoTag;
