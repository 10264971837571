import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath, useHistory } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  LOGIN_TFA_URL,
  LOGIN_URL,
  RESET_PASSWORD_URL,
  API_GET_CURRENT_ADMIN,
  LOGOUT_URL,
} from "system/api/apiUrls";
import { APP_ROUTES } from "system/router/constants";
import {
  generateUrlQuery,
  getToken,
  removeToken,
  setToken,
} from "system/helpers/helperFunctions";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useTrans } from "system/translations/hooks";
import { useToast } from "services/toast/hooks";
import { AuthDataType, LoginDataType } from "./types";
import { ErrorType } from "system/helpers/types";
import { CurrentUserType } from "modules/auth/types";

export const getCurrentAdmin = "getCurrentAdmin";

export const useLogin = () => {
  const [apiRequest] = useApiRequest();
  const history = useHistory();
  const queryClient = useQueryClient();
  const toastHandler = useToast();
  const { _t } = useTrans();
  return useMutation(
    (data: any): Promise<LoginDataType> =>
      apiRequest({
        url: LOGIN_URL,
        method: "post",
        data: data,
      }),
    {
      onSuccess: async (responseData: LoginDataType) => {
        if (!responseData?.tfaenabled && responseData.confirmed) {
          setToken(responseData?.token);
          await queryClient.refetchQueries(getCurrentAdmin);
          await history.push(APP_ROUTES.transactions.index);
        } else if (
          typeof responseData?.confirmed === "boolean" &&
          !responseData.confirmed
        ) {
          toastHandler.addToast({
            title: _t("user_not_confirmed"),
            kind: "danger",
          });
        }
      },
      onError: (err: any) => {
        toastHandler.addToast({
          title: err.errorData.message,
          kind: "danger",
        });
      },
    }
  );
};

export const useLoginTfa = () => {
  const [apiRequest] = useApiRequest();
  const history = useHistory();
  const { apiErrors } = useApiErrors();
  const toastHandler = useToast();
  const { _t } = useTrans();
  const queryClient = useQueryClient();

  return useMutation(
    (data: any): Promise<LoginDataType> =>
      apiRequest({
        url: LOGIN_TFA_URL,
        method: "post",
        data: data,
      }),
    {
      onSuccess: async (responseData: LoginDataType) => {
        if (
          typeof responseData?.confirmed === "boolean" &&
          responseData?.confirmed
        ) {
          setToken(responseData?.token);
          await queryClient.refetchQueries(getCurrentAdmin);
          await history.push(APP_ROUTES.transactions.index);
        } else {
          toastHandler.addToast({
            title: _t("user_not_confirmed"),
            kind: "danger",
          });
        }
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useForgotPassword = () => {
  const [apiRequest] = useApiRequest();
  const history = useHistory();
  const { apiErrors } = useApiErrors();
  const toastHandler = useToast();
  const { _t } = useTrans();

  return useMutation(
    (data: any): Promise<AuthDataType> =>
      apiRequest({
        url: "/api/reset-password",
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        toastHandler.addToast({
          title: _t("forgot_password_email_sent"),
          kind: "success",
        });
        history.push(APP_ROUTES.login);
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useResetPassword = () => {
  const [apiRequest] = useApiRequest();
  const { apiErrors } = useApiErrors();

  return useMutation(
    (data: any): Promise<AuthDataType> =>
      apiRequest({
        url: generateUrlQuery(RESET_PASSWORD_URL, { token: data.token }),
        method: "put",
        data: {
          password: data.password,
        },
      }),
    {
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useLogout = () => {
  const [apiRequest] = useApiRequest();
  const { apiErrors } = useApiErrors();
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation(
    (data: any): Promise<AuthDataType> =>
      apiRequest({
        url: LOGOUT_URL,
        method: "get",
        data: {},
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(getCurrentAdmin);
        removeToken();
        history.push(APP_ROUTES.login);
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useGetCurrentAdmin = () => {
  const token = getToken();
  const [apiRequest] = useApiRequest();
  return useQuery<CurrentUserType>(
    [getCurrentAdmin],
    () =>
      apiRequest({
        url: generatePath(API_GET_CURRENT_ADMIN),
        method: "get",
      }),
    {
      enabled: !!token,
      staleTime: window?.Infinity,
    }
  );
};
