import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  API_UPDATE_TRANSLATIONS,
  API_CREATE_TRANSLATIONS,
  API_GET_TRANSLATIONS,
  API_GET_TRANSLATION,
  API_DELETE_TRANSLATIONS,
  API_GET_TRANSLATIONS_LANGUAGES,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  TranslationsDataType,
  TranslationsLanguagesDataType,
  TranslationDataType,
} from "modules/translations/types";

const getTranslationsKey = "getTranslationsKey";
const getTranslationKey = "getTranslationKey";
const getTranslationsLanguagesKey = "getTranslationsLanguagesKey";
export const useGetTranslations = (filters: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getTranslationsKey, filters],
    (): Promise<TranslationsDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_TRANSLATIONS, filters),
        method: "get",
      })
  );
};
export const useGetTranslation = (id: string) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getTranslationKey, id],
    (): Promise<TranslationDataType> =>
      apiRequest({
        url: generatePath(API_GET_TRANSLATION, { id }),
        method: "get",
      })
  );
};
export const useGetTranslationsLanguages = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    getTranslationsLanguagesKey,
    (): Promise<TranslationsLanguagesDataType> =>
      apiRequest({
        url: API_GET_TRANSLATIONS_LANGUAGES,
        method: "get",
      })
  );
};

export const useCreateTranslations = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_CREATE_TRANSLATIONS, {
          id: data.id,
        }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getTranslationsKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useDeleteTranslations = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_TRANSLATIONS, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getTranslationsKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useUpdateTranslations = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_TRANSLATIONS, {
          id: data.id,
        }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getTranslationsKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
