import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_MERCHANTS,
  API_GET_MERCHANT,
  API_GET_MERCHANTS_NAME_LIST,
  API_DELETE_MERCHANT,
  API_CREATE_MERCHANT,
  API_UPDATE_MERCHANT,
  API_MERCHANT_PAYMENT_METHODS,
  API_GET_SUB_MERCHANTS,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { useToast } from "services/toast/hooks";
import { useTrans } from "system/translations/hooks";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  MerchantDataType,
  MerchantNameListDataType,
  MerchantsDataType,
} from "modules/merchants/types";

const getMerchantsKey = "getMerchantsKey";
const getSubMerchantsKey = "getSubMerchantsKey";
const getMerchantKey = "getMerchantKey";
const getMerchantsNameListKey = "getMerchantsNameListKey";
const getMerchantPaymentMethods = "getMerchantPaymentMethods";
export const useGetMerchants = (filter = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantsKey, filter],
    (): Promise<MerchantsDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_MERCHANTS, filter),
        method: "get",
      })
  );
};
export const useGetSubMerchants = (
  filter = {},
  merchantId: number = 0,
  enabled = true
) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getSubMerchantsKey, filter, merchantId],
    (): Promise<MerchantsDataType> =>
      apiRequest({
        url: generateUrlQuery(
          generatePath(API_GET_SUB_MERCHANTS, { merchantId }),
          filter
        ),
        method: "get",
      }),
    { enabled: enabled }
  );
};
export const useGetMerchant = (id: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantKey, id],
    (): Promise<MerchantDataType> =>
      apiRequest({
        url: generatePath(API_GET_MERCHANT, { id }),
        method: "get",
      })
  );
};
export const useGetMerchantsNameList = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantsNameListKey],
    (): Promise<MerchantNameListDataType> =>
      apiRequest({
        url: API_GET_MERCHANTS_NAME_LIST,
        method: "get",
      })
  );
};

export const useCreateMerchant = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_MERCHANT,
        method: "put",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantsKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useDeleteMerchant = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_MERCHANT, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantsKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useUpdateMerchant = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_UPDATE_MERCHANT,
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantsKey);
        queryClient.invalidateQueries(getMerchantKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
