import { SortAlphabeticalDescIcon, SortAlphabeticalIcon, SortIcon } from "evergreen-ui";
import { useTrans } from "system/translations/hooks";
import { ArrayOptionsMapRes } from "./types";

export const getNestedValue = (obj: any = {}, path: any, divider = ".") => {
  let properties = Array.isArray(path) ? path : path.split(divider);
  return properties.reduce((p: any, c: any) => p && p[c], obj);
};
export const getValueByKey = (data: any = {}, keys: string[] = []) => {
  let resData: any = {};
  keys.forEach((key) => {
    resData[key] = data?.[key] || undefined;
  });
  return resData;
};

export const getToken = (fieldName = "token") =>
  localStorage.getItem(fieldName);
export const setToken = (val: string, fieldName = "token") =>
  localStorage.setItem(fieldName, val);
export const removeToken = (fieldName = "token") =>
  localStorage.removeItem(fieldName);
export const getLocalStorageItem = (item: string) =>
  JSON.parse(<string>localStorage.getItem(item));
export const setLocalStorageItem = (itemName: string, val: any) =>
  localStorage.setItem(itemName, JSON.stringify(val));
export const removeLocalStorageItem = (item: string) =>
  localStorage.removeItem(item);

export const arrayOptionsMap = (
  data: any[],
  { labelKey, valueKey }: { labelKey: string; valueKey: string },
  allFields = false
): ArrayOptionsMapRes => {
  return data?.map((item, idx) => {
    let additionalData = {};
    if (allFields) {
      additionalData = item;
    }
    return {
      label: typeof item !== "object" ? item : item[labelKey],
      value: typeof item !== "object" ? item : item[valueKey],
      ...additionalData,
    };
  });
};

export const generateUrlQuery = (
  url: string,
  params: { [key: string]: any } = {}
) => {
  const preparedUrl = new URL(url, window.location.href);
  for (let key in params) {
    !!params[key] && preparedUrl.searchParams.append(key, params[key]);
  }
  //decodeURIComponent(preparedUrl.search)
  return preparedUrl.pathname + preparedUrl.search;
};

export const checkObjectType = (val: any) =>
  typeof val === "object" &&
  !Array.isArray(val) &&
  Object.prototype.toString.call(val) !== "[object File]";

export const checkStringType = (val: any) => typeof val === "string";
export const checkNumberType = (val: any) => typeof val === "number";
export const checkBooleanType = (val: any) => typeof val === "boolean";
export const checkArrayType = (val: any) => Array.isArray(val);

export const parseNestedData = (values: any) => {
  const resultData: any = {};
  let startKey = "";
  var parseQ = (values: any, parsDataKey: any) => {
    for (let key in values) {
      let resKey = ["", "searchWithFieldSelect"].includes(parsDataKey)
        ? key
        : parsDataKey + `[${key}]`;
      if (checkObjectType(values[key])) {
        parseQ(values[key], resKey);
      } else {
        resultData[resKey] = values[key];
      }
    }
  };

  parseQ(values, startKey);
  return resultData;
};

const checkIfFiltersEmpty = (filters: any) => {
  if (
    !Object.values(filters).filter(
      (filterItem: any) => filterItem && filterItem.length
    ).length
  ) {
    return true;
  }
  return false;
};

export const filterArrayByValues = (data = [], filters: any) => {
  const flatFilters = parseNestedData(filters);
  if (checkIfFiltersEmpty(flatFilters)) {
    return data;
  }
  const filteredData = data?.filter((item: any) => {
    const flatDataItem = parseNestedData(item);
    let res = true;
    for (let key in flatFilters) {
      let prevRes: boolean = res;
      if (
        flatDataItem.hasOwnProperty(key) &&
        checkStringType(flatDataItem[key]) &&
        checkStringType(flatFilters[key]) &&
        flatFilters[key].length
      ) {
        const val = flatDataItem[key].toLowerCase();
        const searchVal = flatFilters[key].toLowerCase();
        res = prevRes && val.includes(searchVal);
      } else if (
        flatDataItem.hasOwnProperty(key) &&
        Array.isArray(flatDataItem[key]) &&
        Array.isArray(flatFilters[key]) &&
        flatFilters[key].length
      ) {
        let containInArray = false;
        flatFilters[key].forEach((item: any) => {
          if (flatDataItem[key].includes(item)) {
            containInArray = true;
          }
        });
        res = prevRes && containInArray;
      } else if (
        flatDataItem.hasOwnProperty(key) &&
        Array.isArray(flatFilters[key]) &&
        flatFilters[key].length &&
        !checkArrayType(flatDataItem[key]) &&
        !checkObjectType(flatDataItem[key])
      ) {
        res = prevRes && flatFilters[key].includes(flatDataItem[key]);
      }
    }
    return res;
  });
  return filteredData;
};

export const make4SpacesInText = (text: string) =>
  text?.replace(/(.{4})/g, "$1 ").trim();

export const toFixedStrict = (num: any, fixed = 2) => {
  return parseFloat(`${num}`).toFixed(2);
};

export const getDividedAmount = (amount: any) => amount / 100;

export const convertAmount = (amount: any) =>
  toFixedStrict(getDividedAmount(amount));

let timer: number;
export const debounceHandler = (callback: () => any) => {
  clearTimeout(timer);
  timer = window.setTimeout(function () {
    callback();
  }, 200);
};

export const parseFormData = (values: any) => {
  const resultData = new FormData();
  let startKey = "";

  var parseQ = (values: any, parsDataKey: any) => {
    for (let key in values) {
      let resKey = parsDataKey === "" ? key : parsDataKey + `.${key}`;
      console.log(key, values[key]);
      if (
        typeof values[key] === "object" &&
        !Array.isArray(values[key]) &&
        Object.prototype.toString.call(values[key]) !== "[object File]"
      ) {
        parseQ(values[key], resKey);
      } else if (typeof values[key] === "boolean") {
        if (!!values[key]) {
          resultData.append(resKey, "1");
        } else {
          resultData.append(resKey, "0");
        }
      } else if (Array.isArray(values[key])) {
        values[key].forEach((item: any, idx: any) => {
          if (
            typeof item === "object" &&
            !Array.isArray(item) &&
            Object.prototype.toString.call(item) !== "[object File]"
          ) {
            parseQ(item, resKey + `[${idx}]` + parsDataKey);
          } else {
            resultData.append(resKey + `[${idx}]`, item);
          }
        });
      } else {
        resultData.append(resKey, values[key]);
      }
    }
  };

  parseQ(values, startKey);

  return resultData;
};

export const getValues = (values: object, specialKeys: string[]): object => Object.entries(values).reduce(
  (a, [key, value]) => specialKeys.includes(key) ? { ...a, [key]: value } : { ...a }, {})

  export const setMobileSort = (
    fieldName: string,
    setSort: (arg: any) => any, 
    type: string = 'default'
  ) => {
    switch (type) {
      case "A-Z":
        setSort([{ id: fieldName, desc: false }])
        break;
      case "Z-A":
        setSort([{ id: fieldName, desc: true }])
        break;
      default:
        setSort({})
    }
  }

