import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "services/formBuilder/types";
import { useDefaultFieldsConfig } from "services/formBuilder/hooks";
import DotsMenu from "components/atoms/DotsMenu";
import { CreateEditFormConfigParams } from "modules/currencies/types";
import InfoTag from "components/atoms/InfoTag";
import { statusOptions } from "modules/currencies/constants";
import { TableColumnType } from "services/table/types";
import globalStyles, { isMobile } from "system/theme/globalStyles";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("code"),
        accessor: "codeLiteral",
        id: "codeLiteral",
        isMobileTitle: true,
      },
      {
        Header: _t("currency_name"),
        accessor: "description",
        id: "description",
      },
      {
        Header: _t("status"),
        Cell: (props: any) => {
          const status = props?.row?.original?.enabled
            ? "active"
            : "deactivated";
          const label = props?.row?.original?.enabled ? "Enabled" : "Disabled";
          return (
            <>
              <InfoTag
                type={"secondary"}
                size={"sm"}
                status={status}
                label={label}
              />
            </>
          );
        },
      },
      {
        maxWidth: 65,
        Header: "",
        id: "UpdateDeleteMenu",
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const options = statusOptions(_t);
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.filterValue({
        componentProps: { showSearch: true, label: _t("search") },
        columnParams: {
          md: 4,
          lg: 4,
        },
        openDrawer: true,
      }),
      fields.filters.status({
        componentProps: { options },
      }),
      fields.general.submitBtn({
        componentProps: {
          css: isMobile && globalStyles.submitBtnForMobileFilters
        }
      }),
    ];
  }, []);
  return formConfig;
};

export const useCreateEditConfig = (
  params: CreateEditFormConfigParams
): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.codeLiteral({
        componentProps: {
          disabled: params.isEdit,
        },
      }),
      fields.formFields.currencyName(),
      fields.formFields.enabled(),
      fields.general.divider(),
      fields.general.saveBtn({
        componentProps: {
          kind: params.isEdit ? "primary" : "secondary",
          label: params.isEdit ? _t("save_changes") : _t("create_currency"),
        },
      }),
    ];
  }, []);
  return formConfig;
};
