import React from "react";
import { useTrans } from "system/translations/hooks";
import { useGetRejectionReasons } from "modules/rejectionReasons/apiHooks";
import SectionTitle from "components/atoms/SectionTitle";

const RejectionReasons = () => {
  const { _t } = useTrans();
  const { data, isLoading } = useGetRejectionReasons();
  return (
    <>
      <SectionTitle title={_t("rejection_reasons")} />
      {JSON.stringify(data)}
    </>
  );
};

export default RejectionReasons;
