import React from "react";
import * as Yup from "yup";
import FormBuilder from "services/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useForgotPassword } from "modules/auth/apiHooks";
import { isValidEmail } from "system/helpers/validationHelper";
import { useForgotPasswordFormConfig } from "modules/auth/constantHooks";
import Loader from "components/atoms/Loader";

const ForgotPasswordForm: React.FC = () => {
  const { mutateAsync, isLoading } = useForgotPassword();
  const { _t } = useTrans();
  const formConfig = useForgotPasswordFormConfig();
  const onSubmit = async (data: any) => {
    await mutateAsync(data);
  };

  const ForgotPasswordSchema = Yup.object().shape({
    email: isValidEmail(_t).required(),
  });

  return (
    <>
      {isLoading && <Loader formOverlay />}
      <FormBuilder
        formItemsConfig={formConfig}
        formProps={{
          onSubmit: onSubmit,
          initialValues: { email: "" },
          validationSchema: ForgotPasswordSchema,
        }}
      />
    </>
  );
};

export default ForgotPasswordForm;
