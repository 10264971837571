import React, { forwardRef, useImperativeHandle, useMemo } from "react";
import { format } from "date-fns";
import FormBuilder from "services/formBuilder/FormBuilder";
import Table from "services/table";
import { useModal } from "services/modal/hooks";
import { useTrans } from "system/translations/hooks";
import ExpandedRow from "components/molecules/ExpandedRow";
import {
  useGetSortMenuMobileItems,
  useListPagination,
  useListSort,
  useMobileFiltersDrawer,
  usePrepareForm,
} from "system/helpers/hooks";
import {
  useGetTransactions,
  useGetTransactionsExport,
  useUpdateTransaction,
} from "modules/transactions/apiHooks";
import { expandedItems, filterOptions } from "modules/transactions/constants";
import {
  useTableColumns,
  useSearchFormConfig,
} from "modules/transactions/constantHooks";
import config from "system/config";
import { ArrayOptionsMapRes } from "system/helpers/types";
import { useConfirmModal } from "services/modal/predefinedModals";
import { useDrawer } from "services/drawer/hooks";
import TransactionDetails from "modules/transactions/components/TransactionDetails";
import TransactionDetailsHeader from "modules/transactions/components/TransactionDetailsHeader";
import {
  TransactionItemType,
  TransactionsProps,
} from "modules/transactions/types";
import DeclineForm from "modules/transactions/components/DeclineForm";
import ClientDetailsHeader from "modules/transactions/components/ClientDetailsHeader";
import ClientDetails from "modules/transactions/components/ClientDetails";
import styles from "../styles";
import { isMobile } from "system/theme/globalStyles";
import FilterMobileButtons from "components/molecules/FilterMobileButtons";
import HistoryLog from "modules/historyLog";

const Transactions: React.FC<TransactionsProps> = forwardRef(
  ({ initialFilters, tableName, transactionStatus }, ref) => {
    const { showDrawer, hideDrawer, updateDrawer } = useDrawer();
    const { showModal } = useModal();
    const { _t } = useTrans();
    const { onConfirm } = useConfirmModal();
    const filterByOptions: ArrayOptionsMapRes = useMemo(
      () => filterOptions(_t),
      [_t]
    );
    const filterInitialValues = useMemo(
      () => ({
        filterField: filterByOptions[2].value,
        fromDate: format(new Date(), config.dateFormat),
        toDate: format(new Date(), config.dateFormat),
        ...initialFilters,
      }),
      [initialFilters]
    );
    const isPendingTransactions = tableName === "pending";
    const searchFormConfig = useSearchFormConfig({ isPendingTransactions });
    const normalizeReqData = (data: any, initialValues: any = {}) => {
      const normalizeDate = {
        ...data,
        updatedDate: `${data.searchFrom !== "created"}`,
        status: data.status ? data.status : initialValues.status,
      };
      if (data?.date) {
        normalizeDate.fromDate = data?.date?.fromDate;
        normalizeDate.toDate = data?.date?.toDate;
      }
      !isMobile && delete normalizeDate.date;
      delete normalizeDate.searchFrom;
      return normalizeDate;
    };
    const { mobileSearchFormConfig, filterParams, filters, setFilters } =
      usePrepareForm(searchFormConfig, filterInitialValues, normalizeReqData);
    const { showFilters, filtersMobile, setFiltersMobile } =
      useMobileFiltersDrawer(filterParams);
    useImperativeHandle(
      ref,
      () => ({
        getFilters: () => filters,
      }),
      [filters]
    );
    const { sort, setSort } = useListSort({});
    const { pagination, setPagination } = useListPagination(
      config.defaultInitialPagination
    );
    const { sortMenuItems } = useGetSortMenuMobileItems(
      "Last updated",
      "merchant.name",
      setSort
    );
    const requestParams = useMemo(
      () => ({ ...filters, ...pagination, ...sort }),
      [filters, pagination, sort]
    );
    const { data, isLoading } = useGetTransactions(
      requestParams,
      transactionStatus
    );
    const { mutateAsync, isLoading: isUpdating } = useUpdateTransaction();
    const columns = useTableColumns({
      paymentDetailsKeys: data?.paymentDetailsKeys,
      isPendingTransactions,
    });
    const forceHiddenColumns = isPendingTransactions
      ? ["comment", "rejectionReason", "transaction_details", "processedAmount"]
      : ["approve_decline"];
    const showTransactionDetails = (transaction: TransactionItemType) => {
      showDrawer({
        componentProps: {
          transactionId: transaction.transactionUUID,
        },
        component: TransactionDetails,
        header: TransactionDetailsHeader,
        headerProps: {
          transactionId: transaction.transactionUUID,
          transactionStatus: transaction.transactionStatus,
        },
        bodyWrapperProps: {
          hasSpacing: false,
        },
      });
    };
    const showHistory = (id: string) => {
      showModal({
        title: _t("history_log"),
        width: "90%",
        componentProps: {
          transactionId: id,
          showOnlyTable: true,
        },
        component: HistoryLog,
      });
    };
    const showClientDetails = (transaction: TransactionItemType) => {
      showDrawer({
        componentProps: {
          transactionId: transaction.transactionUUID,
        },
        component: ClientDetails,
        header: ClientDetailsHeader,
        headerProps: {
          title: `${transaction.customer.lastName} ${transaction.customer.firstName}`,
          transactionStatus: transaction.transactionStatus,
        },
        bodyWrapperProps: {
          hasSpacing: false,
        },
      });
    };
    const expandedRowItems = useMemo(
      () => data && expandedItems(_t),
      [_t, data]
    );
    const onFilterSubmit = async (data: any) => {
      const normalizeDate = normalizeReqData(data);
      setPagination(config.defaultInitialPagination);
      const status =
        Array.isArray(normalizeDate.status) && !!normalizeDate.status.length
          ? normalizeDate.status
          : initialFilters.status;
      isMobile
        ? setFilters({
            ...normalizeDate,
            status,
            sendDate: new Date().getTime(),
            ...filtersMobile,
          })
        : setFilters({
            ...normalizeDate,
            status,
            sendDate: new Date().getTime(),
          });
      hideDrawer("filters");
    };

    const onReloadData = () => {
      setFilters({ ...filters, sendDate: new Date().getTime() });
    };
    const onDeclineWithConfirm = ({ id }: any) => {
      showModal({
        title: _t("decline_transaction"),
        componentProps: {
          transactionId: id,
        },
        component: DeclineForm,
      });
    };
    const onApprove = ({ id }: any) => {
      mutateAsync({ data: { status: "APPROVED" }, id: id.trim() });
    };
    const onApproveWithConfirm = ({ id }: any) => {
      onConfirm({
        onOk: () => onApprove({ id }),
        title: `${_t("approve_transaction")}?`,
        bodyText: _t("approve_transaction_descriptions"),
        onOkKind: "primary",
        onOkText: _t("approve"),
      });
    };

    return (
      <>
        <FormBuilder
          showSubmit={false}
          formItemsConfig={isMobile ? mobileSearchFormConfig : searchFormConfig}
          formProps={{
            submitBtnLabel: _t("apply_filters"),
            onSubmit: onFilterSubmit,
            initialValues: filterInitialValues,
            resultQuantity: data?.totalElements,
            rowStyles: isMobile
              ? {
                  display: "grid",
                  gridTemplateColumns: "0 2fr 1fr auto",
                }
              : {},
          }}
        />
        {isMobile && (
          <div css={styles.mobileButtonsContainer}>
            <FilterMobileButtons
              showFilters={showFilters}
              sortMenuItems={sortMenuItems}
              setFiltersMobile={setFiltersMobile}
              setFilters={setFilters}
              filters={filtersMobile}
              totalElements={data?.totalElements || 0}
              filterInitialValues={filterInitialValues}
            />
          </div>
        )}
        <div css={styles.mobileTableBackground}>
          <Table
            tableName={"transaction-table" + tableName}
            totalRecords={data?.totalElements}
            onPagination={setPagination}
            columns={columns}
            isLoading={isLoading}
            data={data?.content || []}
            isPagination
            onSort={setSort}
            pageIndex={pagination.page}
            pageSize={pagination.perPage}
            forceHiddenColumns={forceHiddenColumns}
            cellProps={{
              showTransactionDetails,
              showClientDetails,
              onDeclineWithConfirm,
              onApproveWithConfirm,
              showHistory,
              expandedRow: <ExpandedRow items={expandedRowItems} />,
            }}
          />
        </div>
      </>
    );
  }
);

export default Transactions;
