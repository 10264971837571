import React from "react";
import { DragItemWrapProps } from "components/atoms/DragItemWrap/types";
import { css } from "@emotion/react";
import styles from "components/atoms/DragItemWrap/styles";
import { DragHandleVerticalIcon } from "evergreen-ui";

const DragItemWrap: React.FC<DragItemWrapProps> = ({ children, wrapClass }) => {
  return (
    <div css={css(styles.wrapper, wrapClass && wrapClass)}>
      <div>{children}</div>
      <div css={css(styles.icon)}>
        <DragHandleVerticalIcon />
      </div>
    </div>
  );
};

export default DragItemWrap;
