import React from "react";
import { Route, Switch } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ActionLog from "pages/actionLog";
import Login from "pages/auth/login";
import VerifiedPage from "pages/auth/verified";
import ForgotPassword from "pages/auth/forgotPassword";
import Merchants from "pages/merchants";
import PageNotFound from "pages/pageNotFound";
import Roles from "pages/roles";
import Statistics from "pages/statistics";
import Transactions from "pages/transactions";
import Users from "pages/users";
import PrivateRoute from "./PrivateRoute";
import UsersCreatePage from "pages/users/create";
import UsersUpdatePage from "pages/users/update";
import Translations from "pages/translations";
import TranslationsCreatePage from "pages/translations/create";
import TranslationsUpdatePage from "pages/translations/update";
import RolesCreatePage from "pages/roles/create";
import RolesUpdatePage from "pages/roles/update";
import MerchantCreatePage from "pages/merchants/create";
import MerchantUpdatePage from "pages/merchants/update";
import PaymentsPage from "pages/payments";
import PaymentsCreatePage from "pages/payments/create";
import PaymentsUpdatePage from "pages/payments/update";
import MerchantBlackListPage from "pages/merchantBlackList";
import MerchantWhiteListPage from "pages/merchantWhiteList";
import MerchantPaymentMethodsPage from "pages/merchantPaymentMethods";
import MerchantPaymentMethodsCreatePage from "pages/merchantPaymentMethods/create";
import MerchantPaymentMethodsUpdatePage from "pages/merchantPaymentMethods/update";
import MerchantPaymentMethodsDetailsPage from "pages/merchantPaymentMethods/details";
import ResetPassword from "pages/auth/resetPassword";
import CurrenciesPage from "pages/currencies";
import CurrenciesCreatePage from "pages/currencies/create";
import CurrenciesUpdatePage from "pages/currencies/update";
import CountriesPage from "pages/countries";
import CountriesCreatePage from "pages/countries/create";
import CountriesUpdatePage from "pages/countries/update";
import AtmFeesPage from "pages/atmFees";
import AtmFeesCreatePage from "pages/atmFees/create";
import AtmFeesUpdatePage from "pages/atmFees/update";
import AssignmentRulesPage from "pages/assignmentRules";
import AssignmentRulesCreatePage from "pages/assignmentRules/create";
import AssignmentRulesUpdatePage from "pages/assignmentRules/update";
import FormTemplatesPage from "pages/formTemplates";
import FormTemplatesCreatePage from "pages/formTemplates/create";
import FormTemplatesUpdatePage from "pages/formTemplates/update";
import RejectionReasonsPage from "pages/rejectionReasons";
import HistoryLogPage from "pages/historyLog";
import UIPage from "pages/ui";
import DashboardPage from "pages/dashboard";
import { AccessToType } from "system/helpers/types";
import FormTemplatesDuplicatePage from "pages/formTemplates/duplicate";

type RouteType = {
  path: string;
  component: any;
  exact?: boolean;
  privateRoute?: boolean;
  accessTo?: AccessToType;
};

const routes: RouteType[] = [
  {
    path: APP_ROUTES.login,
    component: Login,
    privateRoute: false,
    accessTo: "",
  },
  {
    path: APP_ROUTES.verified,
    component: VerifiedPage,
    privateRoute: false,
    accessTo: "",
  },
  {
    path: APP_ROUTES.forgotPassword,
    component: ForgotPassword,
    privateRoute: false,
    accessTo: "",
  },
  {
    path: APP_ROUTES.resetPassword,
    component: ResetPassword,
    privateRoute: false,
    accessTo: "",
  },
  {
    path: APP_ROUTES.dashboard.index,
    component: DashboardPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_VIEW_DASHBOARD",
  },
  {
    path: APP_ROUTES.actionLog,
    component: ActionLog,
    privateRoute: true,
    accessTo: "ROLE_VIEW_ACTION_LOG",
  },
  //merchants
  {
    path: APP_ROUTES.merchants.index,
    component: Merchants,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_VIEW_MERCHANTS",
  },
  {
    path: APP_ROUTES.merchants.create,
    component: MerchantCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_CREATE_MERCHANTS",
  },
  {
    path: APP_ROUTES.merchants.update,
    component: MerchantUpdatePage,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_MERCHANTS", "ROLE_VIEW_MERCHANT_INFORMATION"],
  },
  {
    path: APP_ROUTES.payments.index,
    component: PaymentsPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_PAYMENT_METHODS",
  },
  {
    path: APP_ROUTES.payments.create,
    component: PaymentsCreatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_PAYMENT_METHODS",
  },
  {
    path: APP_ROUTES.payments.update,
    component: PaymentsUpdatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_PAYMENT_METHODS",
  },
  {
    path: APP_ROUTES.merchants.blacklist.index,
    component: MerchantBlackListPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_BLACKLIST",
  },
  {
    path: APP_ROUTES.merchants.whitelist.index,
    component: MerchantWhiteListPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_WHITELIST",
  },
  {
    path: APP_ROUTES.merchants.payment_methods.index,
    component: MerchantPaymentMethodsPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_VIEW_MERCHANT_PM",
  },
  {
    path: APP_ROUTES.merchants.payment_methods.create,
    component: MerchantPaymentMethodsCreatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_PM",
  },
  {
    path: APP_ROUTES.merchants.payment_methods.update,
    component: MerchantPaymentMethodsUpdatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_PM",
  },
  {
    path: APP_ROUTES.merchants.payment_methods.payment_details,
    component: MerchantPaymentMethodsDetailsPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_METHOD_DETAILS",
  },
  //end merchants
  {
    path: APP_ROUTES.roles.index,
    component: Roles,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_ROLES"],
  },
  {
    path: APP_ROUTES.roles.create,
    component: RolesCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_ROLES"],
  },
  {
    path: APP_ROUTES.roles.update,
    component: RolesUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_ROLES"],
  },
  {
    path: APP_ROUTES.statistics,
    component: Statistics,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_VIEW_STATISTICS",
  },
  {
    path: APP_ROUTES.transactions.index,
    component: Transactions,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_VIEW_TRANSACTIONS",
  },
  {
    path: APP_ROUTES.translations.index,
    component: Translations,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_SETTINGS"],
  },
  {
    path: APP_ROUTES.translations.create,
    component: TranslationsCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_SETTINGS"],
  },
  {
    path: APP_ROUTES.translations.update,
    component: TranslationsUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_SETTINGS"],
  },
  {
    path: APP_ROUTES.users.index,
    component: Users,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_USERS"],
  },
  {
    path: APP_ROUTES.users.create,
    component: UsersCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_USERS"],
  },
  {
    path: APP_ROUTES.users.update,
    component: UsersUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_USERS"],
  },
  {
    path: APP_ROUTES.currencies.index,
    component: CurrenciesPage,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_SETTINGS"],
  },
  {
    path: APP_ROUTES.currencies.create,
    component: CurrenciesCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_SETTINGS"],
  },
  {
    path: APP_ROUTES.currencies.update,
    component: CurrenciesUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_SETTINGS"],
  },
  {
    path: APP_ROUTES.countries.index,
    component: CountriesPage,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_SETTINGS"],
  },
  {
    path: APP_ROUTES.countries.create,
    component: CountriesCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_SETTINGS"],
  },
  {
    path: APP_ROUTES.countries.update,
    component: CountriesUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_SETTINGS"],
  },
  {
    path: APP_ROUTES.atmFees.index,
    component: AtmFeesPage,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_SETTINGS"],
  },
  {
    path: APP_ROUTES.atmFees.create,
    component: AtmFeesCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_SETTINGS"],
  },
  {
    path: APP_ROUTES.atmFees.update,
    component: AtmFeesUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_SETTINGS"],
  },
  {
    path: APP_ROUTES.assignmentRules.index,
    component: AssignmentRulesPage,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_SETTINGS"],
  },
  {
    path: APP_ROUTES.assignmentRules.create,
    component: AssignmentRulesCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_SETTINGS"],
  },
  {
    path: APP_ROUTES.assignmentRules.update,
    component: AssignmentRulesUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_SETTINGS"],
  },
  {
    path: APP_ROUTES.rejectionReasons.index,
    component: RejectionReasonsPage,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_SETTINGS"],
  },

  {
    path: APP_ROUTES.paymentsFormTemplate.index,
    component: FormTemplatesPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_FORM_TEMPLATES",
  },
  {
    path: APP_ROUTES.paymentsFormTemplate.create,
    component: FormTemplatesCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_FORM_TEMPLATES",
  },
  {
    path: APP_ROUTES.paymentsFormTemplate.update,
    component: FormTemplatesUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_FORM_TEMPLATES",
  },
  {
    path: APP_ROUTES.paymentsFormTemplate.duplicate,
    component: FormTemplatesDuplicatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_FORM_TEMPLATES",
  },
  {
    path: APP_ROUTES.historyLog,
    component: HistoryLogPage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_VIEW_HISTORY_LOG",
  },
  {
    path: "/ui",
    component: UIPage,
    exact: false,
    privateRoute: false,
  },
];

export default (
  <Switch>
    {routes.map((route) => {
      const { privateRoute, ...rest } = route;
      const RouteComponent = !!privateRoute ? PrivateRoute : Route;
      return <RouteComponent key={route.path} {...rest} />;
    })}
    <Route component={PageNotFound} />
  </Switch>
);
