import React from "react";
import { TableHeadAddButtonProps } from "components/atoms/TableHeadAddButton/types";
import { css } from "@emotion/react";
import styles from "components/atoms/TableHeadAddButton/styles";
import { PlusIcon } from "evergreen-ui";

const TableHeadAddButton: React.FC<TableHeadAddButtonProps> = ({
  onClick,
  text,
}) => {
  return (
    <div css={css(styles.wrapper)} onClick={onClick}>
      <div css={css(styles.container)}>
        <PlusIcon />
        <div>{text}</div>
      </div>
    </div>
  );
};

export default TableHeadAddButton;
