import * as Yup from "yup";
import { TransType } from "system/translations/types";

export const createEditValidation = (_t: TransType, params: any) => {
  return Yup.object().shape(
    {
      maxAmount: Yup.number()
        .positive()
        .required(_t("required"))
        .when("minAmount", (minAmount) => {
          return minAmount
            ? Yup.number().min(minAmount, _t("must_be_greater_than_min"))
            : Yup.number().min(0, _t("must_be_greater"));
        }),
      minAmount: Yup.number()
        .positive()
        .required(_t("required"))
        .when("maxAmount", (maxAmount) => {
          return maxAmount
            ? Yup.number()
                .min(0, _t("must_be_greater"))
                .max(maxAmount, _t("must_be_lower_than_max"))
            : Yup.number().min(0, _t("must_be_greater"));
        }),
    },
    [["maxAmount", "minAmount"]]
  );
};
