import React, { useRef } from "react";
import Drawer from "services/drawer/index";

const Context: any = React.createContext(null);

const DrawerContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const childRef: any = useRef(null);
  const showDrawer = (params = {}, name: string = "default") => {
    childRef.current.drawerOpen(name, params);
  };
  const updateDrawer = (params = {}, name: string) => {
    childRef.current.drawerUpdate(name, params);
  };
  const hideDrawer = (name: string) => {
    childRef.current.drawerClose(name);
  };
  const hideAllDrawer = () => {
    childRef.current.drawerCloseAll();
  };

  return (
    <Context.Provider
      value={{ showDrawer, hideDrawer, updateDrawer, hideAllDrawer }}
    >
      {children}
      <Drawer ref={childRef} hideDrawer={hideDrawer} />
    </Context.Provider>
  );
};

export default Context;
export { DrawerContextProvider };
