import React, { useState } from "react";
import { useTrans } from "system/translations/hooks";
import AuthWrapper from "modules/auth/components/wrappers/AuthWrapper";
import { APP_ROUTES } from "system/router/constants";
import ResetPasswordForm from "./forms/resetPasswordForm";
import { useHistory } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "images/icons/success_with_bg.svg";

const ResetPassword: React.FC = () => {
  const { _t } = useTrans();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const onSuccess = () => setStep(2);
  const backHandler = () => {
    history.push(APP_ROUTES.login);
  };

  return (
    <>
      {step === 1 && (
        <AuthWrapper
          title={_t("reset_password")}
          subtitle={_t("reset_password_help_text")}
          backHandler={backHandler}
        >
          <ResetPasswordForm onSuccess={onSuccess} />
        </AuthWrapper>
      )}
      {step === 2 && (
        <AuthWrapper
          title={_t("password_changed")}
          subtitle={_t("password_changed_message")}
          actionLink={APP_ROUTES.login}
          actionText={_t("back_to_login")}
          classname={"reset-password--success"}
          statusIcon={<SuccessIcon />}
        />
      )}
    </>
  );
};

export default ResetPassword;
