import React, { useMemo } from "react";
import FormBuilder from "services/formBuilder/FormBuilder";
import Table from "services/table";
import { useModal } from "services/modal/hooks";
import { useTrans } from "system/translations/hooks";
import {
  useGetSortMenuMobileItems,
  useListPagination,
  useListSort,
  useMobileFiltersDrawer,
  usePrepareForm,
} from "system/helpers/hooks";
import {
  useGetFormTemplates,
  useDeleteFormTemplate,
} from "modules/formTemplates/apiHooks";
import {
  useTableColumns,
  useSearchFormConfig,
} from "modules/formTemplates/constantHooks";
import config from "../../system/config";
import Info from "modules/formTemplates/components/Info";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ButtonLink from "components/atoms/ButtonLink";
import { DotsMenuItem } from "components/atoms/DotsMenu/types";
import { DeleteIcon, DuplicateIcon, EditIcon, PlusIcon } from "evergreen-ui";
import SectionTitle from "components/atoms/SectionTitle";
import { useConfirmModal } from "services/modal/predefinedModals";
import { useDrawer } from "services/drawer/hooks";
import globalStyles, { isMobile } from "system/theme/globalStyles";
import themeOptions from "system/theme";
import FilterMobileButtons from "components/molecules/FilterMobileButtons";

const FormTemplates: React.FC = () => {
  const history = useHistory();
  const { _t } = useTrans();
  const { onConfirm } = useConfirmModal();
  const { showModal } = useModal();
  const { showDrawer, hideDrawer } = useDrawer();
  const filterInitialValues = {};
  const searchFormConfig = useSearchFormConfig();
  const { mobileSearchFormConfig, filterParams, filters, setFilters } =
    usePrepareForm(searchFormConfig, filterInitialValues);
  const { showFilters, filtersMobile, setFiltersMobile } =
    useMobileFiltersDrawer(filterParams);
  const { sort, setSort } = useListSort({});
  const { pagination, setPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const { sortMenuItems } = useGetSortMenuMobileItems(
    "Last created",
    "name",
    setSort
  );
  const requestParams = useMemo(
    () => ({ ...filters, ...pagination, ...sort }),
    [filters, pagination, sort]
  );
  const { data, isLoading } = useGetFormTemplates(requestParams);
  const { mutate: onDelete } = useDeleteFormTemplate();
  const columns = useTableColumns();
  const showDetails = (id: string) => {
    showModal({
      componentProps: {
        id: id,
      },
      component: Info,
    });
  };
  const onFilterSubmit = async (data: any) => {
    setPagination(config.defaultInitialPagination);
    isMobile ? setFilters({ ...data, ...filtersMobile }) : setFilters(data);
    hideDrawer("filters");
  };
  const onUpdate = ({ id }: any) => {
    const path = APP_ROUTES.paymentsFormTemplate.update;
    const link = generatePath(path, { id });
    history.push(link, history.location);
  };
  const onDuplicate = ({ id }: any) => {
    const path = APP_ROUTES.paymentsFormTemplate.duplicate;
    const link = generatePath(path, { id });
    history.push(link, history.location);
  };
  const onDeactivate = ({ id, allData }: any) => {
    onConfirm({
      onOk: () => onDelete({ id }),
      title: `${_t("delete")} ${allData?.code}?`,
      bodyText: _t("action_cannot_be_undone"),
      onOkKind: "danger",
      onOkText: _t("delete"),
    });
  };

  let actionMenuItems: DotsMenuItem[] = [
    {
      title: _t("edit"),
      onClick: onUpdate,
      icon: <EditIcon size={12} />,
      accessTo: "ROLE_MODIFY_FORM_TEMPLATES",
    },
    {
      title: _t("duplicate"),
      onClick: onDuplicate,
      icon: <DuplicateIcon size={12} />,
      accessTo: "ROLE_MODIFY_FORM_TEMPLATES",
    },
    {
      title: _t("delete"),
      onClick: onDeactivate,
      icon: <DeleteIcon size={12} />,
      intent: "danger",
      accessTo: "ROLE_MODIFY_FORM_TEMPLATES",
    },
  ];
  return (
    <>
      <div css={globalStyles.mobileHeader}>
        <SectionTitle
          title={_t("form_template")}
          extra={
            <>
              <ButtonLink
                kind={"secondary"}
                iconBefore={PlusIcon}
                linkTo={APP_ROUTES.paymentsFormTemplate.create}
                label={_t("create_template")}
              />
            </>
          }
        />
        <FormBuilder
          formItemsConfig={isMobile ? mobileSearchFormConfig : searchFormConfig}
          showSubmit={false}
          formProps={{
            submitBtnLabel: _t("apply_filters"),
            onSubmit: onFilterSubmit,
            initialValues: {},
          }}
        />
        {isMobile && (
          <FilterMobileButtons
            showFilters={showFilters}
            sortMenuItems={sortMenuItems}
            setFiltersMobile={setFiltersMobile}
            setFilters={setFilters}
            filters={filtersMobile}
            totalElements={data?.totalElements || 0}
            filterInitialValues={filterInitialValues}
          />
        )}
      </div>
      <Table
        totalRecords={data?.totalElements}
        columns={columns}
        pageIndex={pagination.page}
        pageSize={pagination.perPage}
        data={data?.content || []}
        isLoading={isLoading}
        isPagination
        onPagination={setPagination}
        onSort={setSort}
        cellProps={{ showDetails, actionMenuItems }}
      />
    </>
  );
};

export default FormTemplates;
