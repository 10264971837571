import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  wrapper: {
    background: themeOptions.colors.white,
    padding: themeOptions.spacing.md,
    borderTop: `1px solid ${themeOptions.colors.gray300}`,
    borderBottomLeftRadius: themeOptions.borders.sm,
    borderBottomRightRadius: themeOptions.borders.sm,
    boxShadow: themeOptions.boxShadow.main,
  },
  paginationContainer: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    [themeOptions.maxMedia.md]: {
      gridTemplateColumns: "1fr",
    },
    alignItems: "center",
    gap: themeOptions.spacing.sm,
    nav: {
      li: {
        button: {
          height: "24px",
          width: "24px",
          minWidth: "24px",
          background: themeOptions.colors.gray100,
          "&:hover": {
            backgroundColor: `${themeOptions.colors.blue400} !important`,
            color: `${themeOptions.colors.white} !important`,
            svg: {
              fill: `${themeOptions.colors.white} !important`,
            },
          },
          '&[aria-current="true"]': {
            backgroundColor: `${themeOptions.colors.blue400} !important`,
            color: `${themeOptions.colors.white} !important`,
            svg: {
              fill: `${themeOptions.colors.white} !important`,
            },
          },
        },
      },
    },
  },
  pageSizeContainer: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    justifyItems: "flex-end",
    alignItems: "center",
  },
  paginationTotalRecords: {
    color: themeOptions.colors.gray600,
  },
});

export default styles;
