import React from "react";
import Update from "modules/payments/components/Update";

const PaymentUpdatePage = () => {
  return (
    <div>
      <Update />
    </div>
  );
};

export default PaymentUpdatePage;
