import { useGetUsersNameList } from "modules/users/apiHooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { UsePaymentsModuleDataRes } from "modules/payments/types";

export const usePaymentsModuleData = (): UsePaymentsModuleDataRes => {
  const { data: usersList = [] } = useGetUsersNameList();
  const usersOptions = arrayOptionsMap(usersList, {
    labelKey: "name",
    valueKey: "id",
  });
  return { usersOptions };
};
