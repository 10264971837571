import * as Yup from "yup";
import { TransType } from "system/translations/types";
import { isValidPassword } from "system/helpers/validationHelper";

export const changePasswordValidation = (_t: TransType) => {
  return Yup.object().shape({
    oldPassword: isValidPassword(_t).required(_t("required")),
    password: isValidPassword(_t).required(_t("required")),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], _t("passwords_must_match"))
      .required(_t("required")),
  });
};
