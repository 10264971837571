import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  container: {},
  iconOnly: {
    padding: themeOptions.spacing.xs,
    borderRadius: themeOptions.borders.sm,
    div: {
      margin: 0,
    },
  },
});

export default styles;
