import React from "react";
import Btn from "components/atoms/Btn";
import Search from "components/atoms/Search";
import { Pane, InfoSignIcon } from "evergreen-ui";
import MultiSelect from "components/atoms/MultiSelect";

const UIPage = () => {
  return (
    <div>
      <Pane
        display="flex"
        backgroundColor={"#fff"}
        padding={"10px"}
        flexDirection="row"
        gap={"10px"}
        alignItems="flex-start"
      >
        <Btn kind="default" iconBefore={InfoSignIcon}>
          Default
        </Btn>
        <Btn kind="primary" iconBefore={InfoSignIcon}>
          Primary
        </Btn>
        <Btn kind="danger" iconBefore={InfoSignIcon}>
          Danger
        </Btn>
        <Btn kind="secondary" iconBefore={InfoSignIcon}>
          Secondary
        </Btn>
        <Btn kind="tertiary" iconBefore={InfoSignIcon}>
          Tertiary
        </Btn>
        <Btn kind="minimal" iconBefore={InfoSignIcon}>
          Minimal
        </Btn>
        <Btn kind="custom" iconBefore={InfoSignIcon}>
          Custom
        </Btn>
      </Pane>
      <Pane
        display="flex"
        backgroundColor={"#fff"}
        padding={"10px"}
        flexDirection="row"
        gap={"10px"}
        alignItems="flex-start"
      >
        <Search
          placeholder={"placeholder"}
          value={""}
          onChange={() => {}}
          showSearch
        />
        <Search
          placeholder={"placeholder"}
          value={"text"}
          onChange={() => {}}
        />
        <Search
          placeholder={"placeholder"}
          value={""}
          onChange={() => {}}
          showSearch
        />
      </Pane>
      <Pane
        display="flex"
        backgroundColor={"#fff"}
        padding={"10px"}
        flexDirection="row"
        gap={"10px"}
        alignItems="flex-start"
      >
        <MultiSelect
          value={null}
          onChange={() => {}}
          options={[
            { label: "1rfejnrekjfdnerkfdsn", value: "1" },
            { label: "2", value: "2" },
          ]}
          titleText={"label"}
          label={"label"}
        />
        <MultiSelect
          value={"1"}
          onChange={() => {}}
          options={[
            { label: "teststsstst test", value: "1" },
            { label: "2 test", value: "2" },
          ]}
          titleText={"label"}
          label={"label"}
        />
      </Pane>
    </div>
  );
};

export default UIPage;
