import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "services/formBuilder/FormBuilder";
import {
  useCreateTranslations,
  useGetTranslationsLanguages,
} from "modules/translations/apiHooks";
import { createEditValidation } from "modules/translations/validation";
import { useCreateEditConfig } from "modules/translations/constantHooks";
import SectionTitle from "components/atoms/SectionTitle";
import { useHistory } from "react-router-dom";

const Create: React.FC = () => {
  const { _t } = useTrans();
  const history = useHistory();
  const { mutateAsync: onCreate, isLoading } = useCreateTranslations();
  const { data: languages } = useGetTranslationsLanguages();
  const languagesData = useMemo(
    () => languages?.map((item) => item.isoCode) || [],
    [languages]
  );
  const formConfig = useCreateEditConfig({
    languages: languagesData,
    isEdit: false,
    isLoading,
  });
  const onSubmit = async (data: any) => {
    const { key, ...rest } = data;
    await onCreate({ data: rest, id: key });
    history.goBack();
  };

  return (
    <>
      <SectionTitle title={_t("create_translation")} />
      <Card>
        <FormBuilder
          formItemsConfig={formConfig}
          showSubmit={false}
          formProps={{
            onSubmit: onSubmit,
            initialValues: {},
            validationSchema: createEditValidation(_t, {
              isEdit: false,
            }),
          }}
        />
      </Card>
    </>
  );
};

export default Create;
