import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  API_UPDATE_REJECTION_REASONS,
  API_CREATE_REJECTION_REASONS,
  API_GET_REJECTION_REASONS,
  API_GET_REJECTION_REASON,
  API_DELETE_REJECTION_REASONS,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  RejectionReasonsDataType,
  RejectionReasonDataType,
} from "modules/rejectionReasons/types";

const getRejectionReasonsKey = "getRejectionReasonsKey";
const getCurrencyKey = "getCurrencyKey";
export const useGetRejectionReasons = (filters: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getRejectionReasonsKey, filters],
    (): Promise<RejectionReasonsDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_REJECTION_REASONS, filters),
        method: "get",
      })
  );
};
export const useGetRejectionReason = (id: string) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getCurrencyKey, id],
    (): Promise<RejectionReasonDataType> =>
      apiRequest({
        url: generatePath(API_GET_REJECTION_REASON, { id }),
        method: "get",
      })
  );
};

export const useCreateRejectionReasons = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_CREATE_REJECTION_REASONS, {
          id: data.id,
        }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getRejectionReasonsKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useDeleteRejectionReasons = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_REJECTION_REASONS, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getRejectionReasonsKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useUpdateRejectionReasons = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_REJECTION_REASONS, {
          id: data.id,
        }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getRejectionReasonsKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
