import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "services/formBuilder/FormBuilder";
import {
  useGetRole,
  useGetRolesAuthorities,
  useUpdateRole,
} from "modules/roles/apiHooks";
import { createEditValidation } from "modules/roles/validation";
import { useRoleInitialData } from "modules/roles/hooks";
import { modifyCreateUpdateReqData } from "modules/roles/helper";
import Loader from "components/atoms/Loader";
import { useCreateEditConfig } from "modules/roles/constantHooks";
import { useDeleteWithConfirm } from "modules/roles/hooks";
import Btn from "components/atoms/Btn";
import SectionTitle from "components/atoms/SectionTitle";
import { DeleteIcon } from "evergreen-ui";

const Update: React.FC = () => {
  const isEdit = true;
  const { _t } = useTrans();
  const history = useHistory();
  const routeParams: any = useParams();
  const { data: rolesAuthorities = [] } = useGetRolesAuthorities();
  const { data: roleData, isLoading } = useGetRole(routeParams?.id);
  const { mutateAsync: onUpdate } = useUpdateRole();
  const formConfig = useCreateEditConfig({
    rolesAuthorities,
    isEdit,
  });
  const { initialAuthorities } = useRoleInitialData(rolesAuthorities, roleData);
  const onSubmit = async (data: any) => {
    const reqData = modifyCreateUpdateReqData(data);
    await onUpdate({ data: reqData, id: routeParams?.id });
    history.goBack();
  };

  const onDeleteWithConfirm = useDeleteWithConfirm(true);

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <>
      <SectionTitle
        title={_t("edit_role")}
        extra={
          <Btn
            kind={"default"}
            iconBefore={DeleteIcon}
            onClick={() => onDeleteWithConfirm({ id: roleData?.id })}
          >
            {_t("delete_role")}
          </Btn>
        }
      />
      <Card>
        <FormBuilder
          formItemsConfig={formConfig}
          showSubmit={false}
          formProps={{
            onSubmit: onSubmit,
            initialValues: {
              name: roleData?.name,
              description: roleData?.description,
              authorities: initialAuthorities,
            },
            validationSchema: createEditValidation(_t, {
              isEdit,
            }),
          }}
        />
      </Card>
    </>
  );
};

export default Update;
