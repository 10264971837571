import React from "react";
import { useTrans } from "../../system/translations/hooks";
import { includesBackgroundRoutes } from "../../modules/auth/constants";
import { useRouteMatch } from "react-router-dom";
import { css } from "@emotion/react";
import { history } from "App";
import { APP_ROUTES } from "system/router/constants";
import { getToken } from "system/helpers/helperFunctions";

const NotLoggedTemplate = ({ children }: { children?: React.ReactNode }) => {
  const token = getToken();
  if (token) {
    history.push(APP_ROUTES.dashboard.index);
  }
  return <div css={css(`height: 100%`)}>{children}</div>;
};

export default NotLoggedTemplate;
