import React, { useMemo } from "react";
import { useDeleteUser, useGetUsers, useResetUserPassword } from "./apiHooks";
import Table from "services/table";
import { useTrans } from "system/translations/hooks";
import FormBuilder from "services/formBuilder/FormBuilder";
import {
  useFilters,
  useGetSortMenuMobileItems,
  useListPagination,
  useListSort,
  useMobileFiltersDrawer,
  usePrepareForm,
} from "system/helpers/hooks";
import { useHistory, generatePath } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ButtonLink from "components/atoms/ButtonLink";
import { useConfirmModal } from "services/modal/predefinedModals";
import { useSearchFormConfig } from "modules/users/constantHooks";
import { useTableColumns } from "modules/users/constantHooks";
import { useUpdateUser } from "./apiHooks";
import config from "system/config";
import { DeleteIcon, EditIcon, KeyIcon, PlusIcon } from "evergreen-ui";
import SectionTitle from "components/atoms/SectionTitle";
import { selectFieldsForSearch } from "modules/users/constants";
import { useDrawer } from "services/drawer/hooks";
import globalStyles, { isMobile } from "system/theme/globalStyles";
import themeOptions from "system/theme";
import FilterMobileButtons from "components/molecules/FilterMobileButtons";

const Users = () => {
  const filterInitialValues = {
    filterField: selectFieldsForSearch[0].value,
  }
  const searchFormConfig = useSearchFormConfig();
  const {
    mobileSearchFormConfig,
    filterParams,
    filters,
    setFilters
  } = usePrepareForm(searchFormConfig, filterInitialValues)
  const { showFilters, filtersMobile, setFiltersMobile } = useMobileFiltersDrawer(filterParams);
  const { sort, setSort } = useListSort({});
  const { pagination, setPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const { showDrawer, hideDrawer } = useDrawer();
  const { sortMenuItems } =  useGetSortMenuMobileItems('Last created','name', setSort);
  const requestParams = useMemo(
    () => ({ ...filters, ...pagination, ...sort }),
    [filters, pagination, sort]
  );
  const { data, isLoading } = useGetUsers(requestParams);
  const { onConfirm } = useConfirmModal();
  const { mutate: onUserPasswordReset } = useResetUserPassword();
  const { mutate: onDelete } = useDeleteUser();
  const history = useHistory();
  const { _t } = useTrans();
  const columns = useTableColumns();
  const onUpdate = ({ id }: any) => {
    const link = generatePath(APP_ROUTES.users.update, { id });
    history.push(link, history.location);
  };
  const onDeleteWithConfirm = ({ id, allData }: any) => {
    const userName = allData.name;
    onConfirm({
      onOk: () => onDelete({ id }),
      title: `${_t("deactivate_user")}?`,
      bodyText: (
        <span
          className="form__content"
          dangerouslySetInnerHTML={{
            __html: _t("user_deactivation_description_{userName}", {
              userName: <b>{userName}</b>,
            }),
          }}
        />
      ),
      onOkKind: "danger",
      onOkText: _t("deactivate"),
    });
  };
  const onPasswordReset = ({ allData }: any) => {
    onUserPasswordReset({ email: allData.email });
  };
  const onFilterSubmit = async (data: any) => {
    isMobile
      ? setFilters({ ...data, ...filtersMobile })
      : setFilters(data);
    hideDrawer('filters');
  };

  const { mutate: onUpdateUser, isLoading: onUpdateUserLoading } =
    useUpdateUser();

  const actionMenuItems = [
    {
      title: _t("edit"),
      onClick: onUpdate,
      icon: <EditIcon size={12} />,
    },
    {
      title: _t("reset_password"),
      onClick: onPasswordReset,
      icon: <KeyIcon size={12} />,
    },
    {
      title: _t("deactivate"),
      onClick: onDeleteWithConfirm,
      icon: <DeleteIcon size={12} />,
      intent: "danger",
    },
  ];

  const onActionUpdate = (
    { id }: any,
    updateToggleValueState: (value: boolean) => any
  ) => {
    let updatedUser: any = data?.content.find((user: any) => user.id === id);
    !onUpdateUserLoading &&
      (updatedUser.activated
        ? onConfirm({
          onOk: () => {
            onUpdateUser({
              data: { ...updatedUser, activated: false },
              id: id,
            });
            updateToggleValueState(false);
          },
          title: _t("deactivating_of_{user}_header_text", {
            user: updatedUser.name,
          }),
          bodyText: _t("deactivating_of_user_body_text"),
          onOkText: _t("deactivate"),
          onOkKind: "danger",
          iconBefore: DeleteIcon,
          isShowScale: false,
        })
        : (() => {
          onUpdateUser({
            data: { ...updatedUser, activated: true },
            id: id,
          });
          updateToggleValueState(true);
        })());
  };
  return (
    <>
      <div css={globalStyles.mobileHeader}>
        <SectionTitle
          title={_t("user_management")}
          extra={
            <>
              <ButtonLink
                kind={"secondary"}
                iconBefore={PlusIcon}
                linkTo={APP_ROUTES.users.create}
                label={_t("create_user")}
              />
            </>
          }
        />
        <FormBuilder
          formItemsConfig={isMobile ? mobileSearchFormConfig : searchFormConfig}
          showSubmit={false}
          formProps={{
            submitBtnLabel: _t("apply_filters"),
            onSubmit: onFilterSubmit,
            initialValues: filterInitialValues,
            btnColumnParams: {
              lg: { span: 8 },
            },
          }}
        />
        {isMobile &&
          <FilterMobileButtons
            showFilters={showFilters}
            sortMenuItems={sortMenuItems}
            setFiltersMobile={setFiltersMobile}
            setFilters={setFilters}
            filters={filtersMobile}
            totalElements={data?.totalElements || 0}
            filterInitialValues={filterInitialValues}
          />
        }
      </div>
      <Table
        totalRecords={data?.totalElements}
        columns={columns}
        pageIndex={pagination.page}
        pageSize={pagination.perPage}
        data={data?.content || []}
        isLoading={isLoading}
        isPagination
        onPagination={setPagination}
        onSort={setSort}
        cellProps={{ actionMenuItems, onActionUpdate }}
      />
    </>
  );
};

export default Users;
