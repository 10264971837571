import { useQuery } from "react-query";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_HISTORY_LOG,
  API_GET_TRANSACTION_HISTORY,
} from "system/api/apiUrls";
import { generatePath } from "react-router-dom";
import { HistoryLogDataType } from "modules/historyLog/types";

const getHistoryLogKey = "getHistoryLogKey";
const getTransactionHistoryKey = "getTransactionHistoryKey";

export const useGetHistoryLog = (filter: any = {}) => {
  const [apiRequest] = useApiRequest();
  const { transactionId = "" } = filter;
  return useQuery(
    [getHistoryLogKey, filter],
    (): Promise<HistoryLogDataType> =>
      apiRequest({
        url: generatePath(API_GET_HISTORY_LOG, {
          transactionId,
        }),
        method: "get",
      }),
    {
      enabled: !!transactionId,
    }
  );
};
export const useGetTransactionHistory = (transactionId: string = "") => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getTransactionHistoryKey, transactionId],
    (): Promise<HistoryLogDataType> =>
      apiRequest({
        url: generatePath(API_GET_TRANSACTION_HISTORY, { transactionId }),
        method: "get",
      })
  );
};
