import * as Yup from "yup";
import { TransType } from "system/translations/types";
import { CreateEditValidationParams } from "modules/currencies/types";

export const createEditValidation = (
  _t: TransType,
  params: CreateEditValidationParams
) => {
  return Yup.object().shape({
    codeLiteral: Yup.string()
      .matches(/[A-Z]{3}/, _t("currency_case_error"))
      .length(3, _t("currency_length_error"))
      .required(_t("required")),
    description: Yup.string().required(_t("required")),
  });
};
