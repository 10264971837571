import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { css } from "@emotion/react";
import styles from "components/atoms/Link/styles";

const TextLink: React.FC<LinkProps & { label: string }> = ({
  label,
  ...rest
}) => {
  return (
    <Link css={css(styles.link)} {...rest}>
      {label}
    </Link>
  );
};

export default TextLink;
