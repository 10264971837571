import { useMutation, useQuery, useQueryClient } from "react-query";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_TRANSACTIONS,
  API_GET_TRANSACTION,
  API_GET_TRANSACTIONS_EXPORT,
  API_UPDATE_RESEND_IPN,
  API_UPDATE_TRANSACTION,
  API_GET_CLIENT,
  API_GET_REJECTION_REASONS,
  API_ASSIGN_TRANSACTION,
  API_RESET_ASSIGN_TRANSACTION,
  API_RESEND_IPN,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { generatePath } from "react-router-dom";
import { useToast } from "services/toast/hooks";
import { useTrans } from "system/translations/hooks";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  ClientDataType,
  TransactionByIdDataType,
  TransactionDataType,
} from "modules/transactions/types";

const getTransactionsKey = "getTransactionsKey";
const getTransactionKey = "getTransactionKey";
const getClientKey = "getClientKey";
const getRejectionReasons = "getRejectionReasons";
export const useGetTransactions = (filters: any = {}, name?: string) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [name, filters],
    (): Promise<TransactionDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_TRANSACTIONS, filters),
        method: "get",
      }).then(
        (data: TransactionDataType) =>
          new Promise((resolve) => {
            let paymentDetailsKeys = {};
            data?.content?.forEach((item) => {
              if (
                item?.paymentDetails &&
                Object.keys(item?.paymentDetails?.properties).length
              ) {
                paymentDetailsKeys = {
                  ...paymentDetailsKeys,
                  ...item?.paymentDetails?.properties,
                };
              }
            });

            resolve({ ...data, paymentDetailsKeys: paymentDetailsKeys });
          })
      ),
    {
      staleTime: 0,
    }
  );
};
export const useGetTransaction = (id: number | string) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getTransactionKey, id],
    (): Promise<TransactionByIdDataType> =>
      apiRequest({
        url: generatePath(API_GET_TRANSACTION, { id }),
        method: "get",
      })
  );
};
export const useGetTransactionsExport = () => {
  const [apiRequest] = useApiRequest();
  const toastHandler = useToast();
  const { _t } = useTrans();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generateUrlQuery(API_GET_TRANSACTIONS_EXPORT, data),
        method: "get",
      }),
    {
      onSuccess: () => {
        toastHandler.addToast({
          title: _t("export_csv_notification"),
          kind: "success",
        });
      },
    }
  );
};

export const useGetClient = (id: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getClientKey, id],
    (): Promise<ClientDataType> =>
      apiRequest({
        url: generatePath(API_GET_CLIENT, { id }),
        method: "get",
      })
  );
};
export const useGetRejectionReasons = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    getRejectionReasons,
    (): Promise<any> =>
      apiRequest({
        url: API_GET_REJECTION_REASONS,
        method: "get",
      })
  );
};

export const useUpdateTransaction = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_TRANSACTION, {
          id: data.id,
        }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("pending_transactions");
        queryClient.invalidateQueries("history_transactions");
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useAssignTransaction = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_ASSIGN_TRANSACTION, {
          id: data.id,
        }),
        method: "post",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("pending_transactions");
        queryClient.invalidateQueries("history_transactions");
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useResetAssignTransaction = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_RESET_ASSIGN_TRANSACTION, {
          id: data.id,
        }),
        method: "post",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("pending_transactions");
        queryClient.invalidateQueries("history_transactions");
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useResendIpn = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (id: string) =>
      apiRequest({
        url: generatePath(API_RESEND_IPN, {
          id,
        }),
        method: "post",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("pending_transactions");
        queryClient.invalidateQueries("history_transactions");
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
