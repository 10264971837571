import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  mobileHeaderBackground: {
    [themeOptions.maxMedia.md]: {
      backgroundColor: themeOptions.colors.white,
      margin: "-23px -20px 0",
      padding: "20px 20px 0",
    }
  },
  mobileTableBackground: {
    [themeOptions.maxMedia.md]: {
      backgroundColor: themeOptions.colors.c1,
      margin: "0 -20px 0",
      padding: "6px 20px 0"
    }
  },
  mobileButtonsContainer: {
    marginBottom: "24px",
  }
});

export default styles;
