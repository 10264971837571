import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";
import { Pane } from "evergreen-ui";
import React from "react";

const styles = createStyles({
  container: {
    display: "flex",
    paddingLeft: 4,
    paddingRight: 20,
    height: "47px",
    background: themeOptions.colors.c3,
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    zIndex: 3,
  },
  logoWrap: {
    flex: 1,
    paddingLeft: themeOptions.spacing.sm,
    alignItems: "center",
    display: "flex",
    gap: themeOptions.spacing.sm,
  },
  userWrap: {
    display: "flex",
    justifyContent: "flex-end",
    flex: "100%",
  },
  userName: {
    display: "flex",
    alignItems: "center",
    height: "47px",
    color: themeOptions.colors.white,
    cursor: "pointer",
  },
  popoverWrapper: {
    marginTop: "-5px",
    [themeOptions.maxMedia.md]: {
      width: "100%",
      left: 0,
    },
    [themeOptions.minMedia.md]: {
      transform: "scale(1) translateX(20px) !important",
    },
  },
  menuButton: {
    marginRight: themeOptions.spacing.xs,
    display: "block",
  },
});

export default styles;
