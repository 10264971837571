import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  container: {
    display: "flex",
    gap: themeOptions.spacing.xs,
    alignItems: "center",
    cursor: "pointer",
    border: "1px solid",
    borderColor: "transparent",
    padding: `${themeOptions.spacing.xxs} ${themeOptions.spacing.xs}`,
    borderRadius: themeOptions.borders.sm,
    width: "max-content",
    "&:hover": {
      borderColor: themeOptions.colors.gray400,
      '[class*="-select-icon"]': {
        opacity: 1,
      },
    },
  },
  containerDisabled: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  label: {
    display: "flex",
    gap: themeOptions.spacing.xs,
    alignItems: "center",
  },
  icon: {
    label: "select-icon",
    opacity: 0,
    background: themeOptions.colors.gray200,
    padding: themeOptions.spacing.xxs,
    borderRadius: themeOptions.borders.xs,
    lineHeight: 0,
  },
});

export default styles;
