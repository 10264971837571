import React from "react";
import Card from "../../../components/atoms/Card";
import { css } from "@emotion/react";
import Input from "components/atoms/Input";
import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";
import { getProperty } from "dot-prop";
import ColorPicker from "components/atoms/ColorPicker";
import { Col, Row } from "react-flexbox-grid";
import Divider from "components/atoms/Divider";
import { useTrans } from "system/translations/hooks";
/*    primaryColor: "#000",
  secondaryColor: "#000",
  headlineColor: "#000",
  textColor: "#ddd",
  errorColor: "#ff0000",
  mainBgColor: "#fff",
  fieldsBorderColor: "#000",
  verticalInnerSpace: "4",
  horizontalInnerSpace: "4",
  fieldsSpace: "4",
  bgBorderRadius: "4",
  fieldsBorderRadius: "4",*/
const styles = ({
  primaryColor,
  secondaryColor,
  headlineColor,
  textColor,
  errorColor,
  mainBgColor,
  fieldsBorderColor,
  verticalInnerSpace = 0,
  horizontalInnerSpace = 0,
  fieldsSpace = 0,
  bgBorderRadius,
  fieldsBorderRadius,
}: any) =>
  createStyles({
    customWrap: {
      display: "grid",
      alignItems: "center",
      marginBottom: "10px",
    },
    /*    fieldsWrap: {
      padding: `${verticalInnerSpace}px ${horizontalInnerSpace}px`,
      background: mainBgColor || themeOptions.colors.white,
      borderRadius: bgBorderRadius,
    },
    fieldItem: {
      marginBottom: fieldsSpace,
    },
    inputStyles: {
      borderRadius: fieldsBorderRadius,
      borderColor: fieldsBorderColor,
    },
    fieldsTitle: {
      textAlign: "center",
      marginBottom: "20px",
    },*/
  });

const FieldsStyles: React.FC<any> = ({ name, onChange, onBlur, values }) => {
  const { _t } = useTrans();
  const generalStyles = styles({
    /*    primaryColor: values?.styles?.primaryColor,
    secondaryColor: values?.styles?.secondaryColor,
    headlineColor: values?.styles?.headlineColor,
    textColor: values?.styles?.textColor,
    errorColor: values?.styles?.errorColor,
    mainBgColor: values?.styles?.mainBgColor,
    fieldsBorderColor: values?.styles?.fieldsBorderColor,
    verticalInnerSpace: values?.styles?.verticalInnerSpace,
    horizontalInnerSpace: values?.styles?.horizontalInnerSpace,
    fieldsSpace: values?.styles?.fieldsSpace,
    bgBorderRadius: values?.styles?.bgBorderRadius,
    fieldsBorderRadius: values?.styles?.fieldsBorderRadius,*/
  });
  return (
    <Card wrapperClass={css({ position: "relative", marginTop: "8px" })}>
      <Divider text={_t("general_colors")} />
      <Row>
        <Col lg={6} md={12}>
          <div css={css(generalStyles.customWrap)}>
            <ColorPicker
              label={"Primary color"}
              name={`${name}.primaryColor`}
              value={getProperty(values, `${name}.primaryColor`)}
              onChange={(val: any) => {
                const fieldName: string = `${name}.primaryColor`;
                onChange(val, fieldName);
              }}
            />
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div css={css(generalStyles.customWrap)}>
            <ColorPicker
              label={"Secondary color"}
              name={`${name}.secondaryColor`}
              value={getProperty(values, `${name}.secondaryColor`)}
              onChange={(val: any) => {
                const fieldName: string = `${name}.secondaryColor`;
                onChange(val, fieldName);
              }}
            />
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div css={css(generalStyles.customWrap)}>
            <ColorPicker
              label={"Background color"}
              name={`${name}.mainBgColor`}
              value={getProperty(values, `${name}.mainBgColor`)}
              onChange={(val: any) => {
                const fieldName: string = `${name}.mainBgColor`;
                onChange(val, fieldName);
              }}
            />
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div css={css(generalStyles.customWrap)}>
            <ColorPicker
              label={"Fields border color"}
              name={`${name}.fieldsBorderColor`}
              value={getProperty(values, `${name}.fieldsBorderColor`)}
              onChange={(val: any) => {
                const fieldName: string = `${name}.fieldsBorderColor`;
                onChange(val, fieldName);
              }}
            />
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div css={css(generalStyles.customWrap)}>
            <ColorPicker
              label={"Reset button active color"}
              name={`${name}.resetButtonActiveColor`}
              value={getProperty(values, `${name}.resetButtonActiveColor`)}
              onChange={(val: any) => {
                const fieldName: string = `${name}.resetButtonActiveColor`;
                onChange(val, fieldName);
              }}
            />
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div css={css(generalStyles.customWrap)}>
            <ColorPicker
              label={"Submit button active color"}
              name={`${name}.submitButtonActiveColor`}
              value={getProperty(values, `${name}.submitButtonActiveColor`)}
              onChange={(val: any) => {
                const fieldName: string = `${name}.submitButtonActiveColor`;
                onChange(val, fieldName);
              }}
            />
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div css={css(generalStyles.customWrap)}>
            <ColorPicker
              label={"Action button color"}
              name={`${name}.actionBtnColor`}
              value={getProperty(values, `${name}.actionBtnColor`)}
              onChange={(val: any) => {
                const fieldName: string = `${name}.actionBtnColor`;
                onChange(val, fieldName);
              }}
            />
          </div>
        </Col>{" "}
        <Col lg={6} md={12}>
          <div css={css(generalStyles.customWrap)}>
            <ColorPicker
              label={"Action button active color"}
              name={`${name}.actionBtnActiveColor`}
              value={getProperty(values, `${name}.actionBtnActiveColor`)}
              onChange={(val: any) => {
                const fieldName: string = `${name}.actionBtnActiveColor`;
                onChange(val, fieldName);
              }}
            />
          </div>
        </Col>
      </Row>
      <Divider text={_t("font_colors")} />
      <Row>
        <Col lg={6} md={12}>
          <div css={css(generalStyles.customWrap)}>
            <ColorPicker
              label={"Headline color"}
              name={`${name}.headlineColor`}
              value={getProperty(values, `${name}.headlineColor`)}
              onChange={(val: any) => {
                const fieldName: string = `${name}.headlineColor`;
                onChange(val, fieldName);
              }}
            />
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div css={css(generalStyles.customWrap)}>
            <ColorPicker
              label={"Paragraph color"}
              name={`${name}.paragraphColor`}
              value={getProperty(values, `${name}.paragraphColor`)}
              onChange={(val: any) => {
                const fieldName: string = `${name}.paragraphColor`;
                onChange(val, fieldName);
              }}
            />
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div css={css(generalStyles.customWrap)}>
            <ColorPicker
              label={"Error color"}
              name={`${name}.errorColor`}
              value={getProperty(values, `${name}.errorColor`)}
              onChange={(val: any) => {
                const fieldName: string = `${name}.errorColor`;
                onChange(val, fieldName);
              }}
            />
          </div>
        </Col>
      </Row>
      <Divider text={_t("space")} />
      <Row>
        <Col lg={6} md={12}>
          <div css={css(generalStyles.customWrap)}>
            <Input
              id={"verticalInnerSpace"}
              name={`${name}.verticalInnerSpace`}
              label={"Vertical inner space"}
              value={getProperty(values, `${name}.verticalInnerSpace`)}
              onBlur={() => onBlur(`${name}.verticalInnerSpace`)}
              onChange={(value: any) => {
                const fieldName: string = `${name}.verticalInnerSpace`;
                onChange(value, fieldName);
              }}
            />
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div css={css(generalStyles.customWrap)}>
            <Input
              id={"horizontalInnerSpace"}
              name={`${name}.horizontalInnerSpace`}
              label={"Horizontal inner space"}
              value={getProperty(values, `${name}.horizontalInnerSpace`)}
              onBlur={() => onBlur(`${name}.horizontalInnerSpace`)}
              onChange={(value: any) => {
                const fieldName: string = `${name}.horizontalInnerSpace`;
                onChange(value, fieldName);
              }}
            />
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div css={css(generalStyles.customWrap)}>
            <Input
              id={"fieldsSpace"}
              name={`${name}.fieldsSpace`}
              label={"Gap between fields"}
              value={getProperty(values, `${name}.fieldsSpace`)}
              onBlur={() => onBlur(`${name}.fieldsSpace`)}
              onChange={(value: any) => {
                const fieldName: string = `${name}.fieldsSpace`;
                onChange(value, fieldName);
              }}
            />
          </div>
        </Col>
      </Row>
      <Divider text={_t("other")} />
      <Row>
        <Col lg={6} md={12}>
          <div css={css(generalStyles.customWrap)}>
            <Input
              id={"bgBorderRadius"}
              name={`${name}.bgBorderRadius`}
              label={"Background border radius"}
              value={getProperty(values, `${name}.bgBorderRadius`)}
              onBlur={() => onBlur(`${name}.bgBorderRadius`)}
              onChange={(value: any) => {
                const fieldName: string = `${name}.bgBorderRadius`;
                onChange(value, fieldName);
              }}
            />
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div css={css(generalStyles.customWrap)}>
            <Input
              id={"fieldsBorderRadius"}
              name={`${name}.fieldsBorderRadius`}
              label={"Fields border radius"}
              value={getProperty(values, `${name}.fieldsBorderRadius`)}
              onBlur={() => onBlur(`${name}.fieldsBorderRadius`)}
              onChange={(value: any) => {
                const fieldName: string = `${name}.fieldsBorderRadius`;
                onChange(value, fieldName);
              }}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default FieldsStyles;
