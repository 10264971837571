import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "services/formBuilder/FormBuilder";
import {
  useDeleteCurrencies,
  useGetCurrency,
  useUpdateCurrencies,
} from "modules/currencies/apiHooks";
import { createEditValidation } from "modules/currencies/validation";
import Loader from "components/atoms/Loader";
import { useCreateEditConfig } from "modules/currencies/constantHooks";
import SectionTitle from "components/atoms/SectionTitle";
import { DeleteIcon } from "evergreen-ui";
import Btn from "components/atoms/Btn";
import { useConfirmModal } from "services/modal/predefinedModals";

const Update: React.FC = () => {
  const isEdit = true;
  const { _t } = useTrans();
  const history = useHistory();
  const routeParams: any = useParams();
  const { data: currenciesData, isLoading } = useGetCurrency(routeParams.id);
  const { mutateAsync: onUpdate } = useUpdateCurrencies();
  const { onConfirm } = useConfirmModal();
  const formConfig = useCreateEditConfig({
    isEdit: true,
  });
  const { mutate: onDelete } = useDeleteCurrencies();
  const onDeleteWithConfirm = () => {
    onConfirm({
      onOk: () => onDelete({ id: currenciesData?.id }),
      title: `${_t("deactivate")} ${currenciesData?.codeLiteral}?`,
      bodyText: _t("are_you_sure"),
      onOkKind: "danger",
      onOkText: _t("deactivate"),
    });
  };
  const onSubmit = async (data: any) => {
    const { key, ...rest } = data;
    await onUpdate({ data: rest, id: key });
    history.goBack();
  };

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <>
      <SectionTitle
        title={_t("edit_currency")}
        extra={
          <Btn
            kind={"default"}
            iconBefore={DeleteIcon}
            onClick={onDeleteWithConfirm}
          >
            {_t("delete_currency")}
          </Btn>
        }
      />
      <Card title={_t("edit_translation")}>
        <FormBuilder
          formItemsConfig={formConfig}
          showSubmit={false}
          formProps={{
            onSubmit: onSubmit,
            initialValues: { ...currenciesData },
            validationSchema: createEditValidation(_t, {
              isEdit,
            }),
          }}
        />
      </Card>
    </>
  );
};

export default Update;
