import { useGetRoles } from "modules/roles/apiHooks";
import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { useConstants } from "system/helpers/hooks";
import { UseUserModuleDataRes } from "modules/users/types";
import { useGetPaymentMethodsNameList } from "modules/payment/apiHooks";

export const useUserModuleData = (): UseUserModuleDataRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentMethods = [] } = useGetPaymentMethodsNameList();
  const merchantsOptions = arrayOptionsMap(merchantsList, {
    labelKey: "name",
    valueKey: "id",
  });
  const paymentMethodsOptions = arrayOptionsMap(Object.values(paymentMethods), {
    labelKey: "name",
    valueKey: "id",
  });
  const { data: rolesData = [] } = useGetRoles();
  const rolesOptions = arrayOptionsMap(rolesData, {
    labelKey: "name",
    valueKey: "id",
  });

  return { rolesOptions, merchantsOptions, paymentMethodsOptions };
};
