import React, { useEffect } from "react";
import FormBuilder from "services/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useCreateUser } from "../apiHooks";
import { createEditUserValidation } from "../validation";
import { useCreateEditConfig } from "modules/users/constantHooks";
import SectionTitle from "components/atoms/SectionTitle";
import Card from "components/atoms/Card";

const Create: React.FC = () => {
  const { _t } = useTrans();
  const { mutate: onCreate } = useCreateUser();
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 30);
  }, []);
  const formConfig = useCreateEditConfig({
    isEdit: false,
  });
  const onSubmit = async (data: any) => {
    onCreate(data);
  };

  return (
    <>
      <SectionTitle title={_t("create_user")} />
      <Card>
        <FormBuilder
          formItemsConfig={formConfig}
          formProps={{
            onSubmit: onSubmit,
            initialValues: {
              admin: true,
            },
            validationSchema: createEditUserValidation(_t, {
              isEdit: false,
            }),
          }}
        />
      </Card>
    </>
  );
};

export default Create;
