import React from "react";
import { useTrans } from "system/translations/hooks";
import { ApproveDeclineProps } from "components/atoms/ApproveDecline/types";
import { css } from "@emotion/react";
import styles from "components/atoms/ApproveDecline/styles";
import { TickIcon, CrossIcon } from "evergreen-ui";
import themeOptions from "system/theme";

const ApproveDecline: React.FC<ApproveDeclineProps> = ({ onChange, value }) => {
  const { _t } = useTrans();
  return (
    <div css={css(styles.wrapper)}>
      <div
        onClick={() => onChange("APPROVED")}
        css={css(styles.button, value === "APPROVED" && styles.approve)}
      >
        <TickIcon size={21} color={themeOptions.colors.green500} />
        <div>{_t("approve")}</div>
      </div>
      <div
        onClick={() => onChange("DECLINED")}
        css={css(styles.button, value === "DECLINED" && styles.decline)}
      >
        <CrossIcon size={21} color={themeOptions.colors.red500} />
        <div>{_t("decline")}</div>
      </div>
    </div>
  );
};

export default ApproveDecline;
