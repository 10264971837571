import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "services/formBuilder/FormBuilder";
import { useGetCountry, useUpdateCountries } from "modules/countries/apiHooks";
import { createEditValidation } from "modules/countries/validation";
import Loader from "components/atoms/Loader";
import { useCreateEditConfig } from "modules/countries/constantHooks";
import SectionTitle from "components/atoms/SectionTitle";
import { DeleteIcon } from "evergreen-ui";
import Btn from "components/atoms/Btn";

const Update: React.FC = () => {
  const isEdit = true;
  const { _t } = useTrans();
  const history = useHistory();
  const routeParams: any = useParams();
  const { data: countriesData = {}, isLoading } = useGetCountry(routeParams.id);
  const { mutateAsync: onUpdate } = useUpdateCountries();
  const formConfig = useCreateEditConfig({
    isEdit: true,
  });
  const onSubmit = async (data: any) => {
    const { key, ...rest } = data;
    await onUpdate({ data: rest, id: key });
    history.goBack();
  };

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <>
      <SectionTitle
        title={_t("edit_country")}
        extra={
          <Btn
            kind={"default"}
            iconBefore={DeleteIcon}
            onClick={() => alert("in progress")}
          >
            {_t("delete_country")}
          </Btn>
        }
      />
      <Card title={_t("edit_translation")}>
        <FormBuilder
          formItemsConfig={formConfig}
          showSubmit={false}
          formProps={{
            onSubmit: onSubmit,
            initialValues: { ...countriesData },
            validationSchema: createEditValidation(_t, {
              isEdit,
            }),
          }}
        />
      </Card>
    </>
  );
};

export default Update;
