import React from "react";
import { ColorPickerProps } from "components/atoms/ColorPicker/types";
import { TextInputField } from "evergreen-ui";
import styles from "components/atoms/ColorPicker/styles";
import { css } from "@emotion/react";

const ColorPicker: React.FC<ColorPickerProps> = ({
  id = "colorPicker",
  label,
  value,
  onChange,
  placeholder,
  name,
}) => {
  return (
    <div css={css(styles.container)}>
      <TextInputField
        css={css(styles.input)}
        placeholder={placeholder}
        marginBottom={0}
        id={id}
        onChange={(e: any) => onChange && onChange(e?.target?.value)}
        value={value}
        label={label}
      />
      <input
        css={css(styles.picker)}
        type={"color"}
        id={id}
        name={name}
        value={value}
        onChange={(e: any) => onChange && onChange(e?.target?.value)}
      />
    </div>
  );
};

export default ColorPicker;
