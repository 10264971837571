import React from "react";
import { useTrans } from "system/translations/hooks";
import themeOptions from "system/theme";
import { createStyles } from "types/emotion-styles";
import { css } from "@emotion/react";
import { useDrawer } from "services/drawer/hooks";
import Btn from "components/atoms/Btn";
import TextArea from "components/atoms/TextArea";

const styles = createStyles({
  wrapper: {
    display: "grid",
    height: "100%",
    gridTemplateRows: "1fr 72px",
  },
  container: {
    padding: themeOptions.spacing.md,
    color: themeOptions.colors.gray700,
  },
  buttonsWrapper: {
    padding: themeOptions.spacing.md,
    borderTop: `1px solid ${themeOptions.colors.gray300}`,
    display: "grid",
    justifyContent: "flex-end",
  },
});

const DataDrawer: React.FC<{ data: any; isJson?: boolean }> = ({
  data,
  isJson = false,
}) => {
  const { _t } = useTrans();
  const { hideDrawer } = useDrawer();

  return (
    <div css={css(styles.wrapper)}>
      <div css={styles.container}>
        {isJson ? (
          <TextArea height={500} onChange={() => {}} value={data} />
        ) : (
          data
        )}
      </div>
      <div css={styles.buttonsWrapper}>
        <Btn kind={"custom"} onClick={() => hideDrawer()}>
          {_t("close")}
        </Btn>
      </div>
    </div>
  );
};

export default DataDrawer;
