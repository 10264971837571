import React from "react";
import {
  ManuallyEnteredDataIcon,
  SortIcon,
  StyleIcon,
  Tooltip,
  Text,
  CodeIcon,
} from "evergreen-ui";
import themeOptions from "system/theme";
import { useTrans } from "system/translations/hooks";
import { createStyles } from "types/emotion-styles";
import { css } from "@emotion/react";
import Btn from "components/atoms/Btn";

const styles = createStyles({
  container: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  text: {
    color: themeOptions.colors.blue400,
    cursor: "pointer",
    fontWeight: 600,
  },
  /*  icon: {
    display: "flex",
    gap: "5px",
    cursor: "pointer",
    color: themeOptions.colors.gray600,
    "&:hover": {
      color: themeOptions.colors.gray800,
    },
  },*/
});

const DrawerSettings: React.FC<any> = ({
  showOrder,
  showFields,
  showStyles,
  showJson,
}) => {
  const { _t } = useTrans();
  return (
    <div css={css(styles.container)}>
      <Btn iconBefore={CodeIcon} onClick={showJson}>
        {_t("json_settings")}
      </Btn>
      {showOrder ? (
        <Text size={300} css={css(styles.text)} onClick={showOrder}>
          {_t("edit_order")}
        </Text>
      ) : null}

      {/*      <Tooltip content={_t("fields_order")}>
        <SortIcon css={css(styles.icon)} onClick={showOrder} />
      </Tooltip>*/}
      {/*      <Tooltip content={_t("fields_json")}>
        <ManuallyEnteredDataIcon css={css(styles.icon)} onClick={showFields} />
      </Tooltip>
      <Tooltip content={_t("fields_styles_json")}>
        <StyleIcon css={css(styles.icon)} onClick={showStyles} />
      </Tooltip>*/}
    </div>
  );
};

export default DrawerSettings;
