import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "services/formBuilder/FormBuilder";
import {
  useGetTranslation,
  useGetTranslationsLanguages,
  useUpdateTranslations,
} from "modules/translations/apiHooks";
import { createEditValidation } from "modules/translations/validation";
import Loader from "components/atoms/Loader";
import { useCreateEditConfig } from "modules/translations/constantHooks";
import SectionTitle from "components/atoms/SectionTitle";

const Update: React.FC = () => {
  const isEdit = true;
  const { _t } = useTrans();
  const history = useHistory();
  const routeParams: any = useParams();
  const keyName = routeParams.id;
  const { data: translationsData = {}, isLoading } = useGetTranslation(keyName);
  const transKey: string = Object.keys(translationsData)?.[0] || "";
  const transValues: any = Object.values(translationsData)?.[0] || {};
  const { data: languages } = useGetTranslationsLanguages();
  const { mutateAsync: onUpdate, isLoading: isUpdating } =
    useUpdateTranslations();
  const languagesData = useMemo(
    () => languages?.map((item) => item.isoCode) || [],
    [languages]
  );
  const formConfig = useCreateEditConfig({
    languages: languagesData,
    isEdit: true,
    isLoading: isUpdating,
  });
  const onSubmit = async (data: any) => {
    const { key, ...rest } = data;
    await onUpdate({ data: rest, id: key });
    history.goBack();
  };

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <>
      <SectionTitle title={keyName || _t("edit_translation")} />
      <Card>
        <FormBuilder
          formItemsConfig={formConfig}
          showSubmit={false}
          formProps={{
            onSubmit: onSubmit,
            initialValues: { key: transKey, ...transValues },
            validationSchema: createEditValidation(_t, {
              isEdit,
            }),
          }}
        />
      </Card>
    </>
  );
};

export default Update;
