import React, { useEffect, useState } from "react";
import Select from "../Select";
import { SearchWithFieldSelectProps } from "./types";
import "../../../styles/atoms/searchWithFieldSelect.scss";
import {
  IconButton,
  Pane,
  SearchIcon,
  SearchInput,
  TextInput,
} from "evergreen-ui";

const SearchWithFieldSelect: React.FC<SearchWithFieldSelectProps> = ({
  id = "inputWithSelect",
  labelText,
  placeholder,
  inputValue,
  selectValue,
  onChange,
  showSearch = false,
  disabled = false,
  searchIconDescription,
  formOnSubmit,
  size = "xl",
  selectItems,
  inputName,
  selectName,
  ...rest
}) => {
  const [inputValueState, setInputValueState] = useState(inputValue);
  const [selectValueState, setSelectValueState] = useState<any>(selectValue);
  const handleSubmit = () => {
    selectValueState &&
      typeof inputValueState === "string" &&
      onChange({ [selectValueState]: inputValueState });
  };

  useEffect(() => {
    handleSubmit();
  }, [inputValueState, selectValueState]);

  return (
    <div className="search-with-field-select__container">
      <TextInput
        id={id}
        onChange={(e: any) => {
          setInputValueState(e?.target?.value);
          onChange(e?.target?.value, inputName);
        }}
        value={inputValueState}
        placeholder={placeholder}
        disabled={disabled}
        width={"100%"}
      />
      <Select
        id="Select"
        onChange={(val) => {
          setSelectValueState(val);
          onChange(val, selectName);
        }}
        options={selectItems}
        value={selectValueState}
        hideLabel={true}
        disabled={disabled}
      />
      {showSearch && (
        <IconButton
          type={"submit"}
          icon={SearchIcon}
          disabled={disabled}
          onClick={() => handleSubmit()}
        />
      )}
    </div>
  );
};

export default SearchWithFieldSelect;
