import React, { useEffect, useMemo, useRef, useState } from "react";
import { MultiSelect as MultiSelectCustom } from "react-multi-select-component";
import { useOutsideClick } from "system/helpers/hooks";
import { useTrans } from "system/translations/hooks";
import { css } from "@emotion/react";
import { CaretDownIcon, Text, CrossIcon, CaretRightIcon } from "evergreen-ui";
import themeOptions from "system/theme";
import { useDrawer } from "services/drawer/hooks";
import styles from "./styles";

const MultiSelectMobile: React.FC<{
  value: any;
  onChange: ({ selectedItems }: { selectedItems: [] }) => void;
  options: any;
  titleText: string;
  labelWithAll?: boolean;
  wrapClassPrefix?: string;
  label?: React.ReactNode | undefined;
  isOpen?: boolean;
  invalidText?: string;
  whiteBackground?: boolean;
  withSelectAll?: boolean;
  singleSelect?: boolean;
  twoColumns?: boolean;
  menuFullWidth?: boolean;
}> = ({
  onChange,
  options = [],
  label,
  titleText,
  value,
  labelWithAll = true,
  wrapClassPrefix,
  isOpen,
  invalidText,
  whiteBackground = false,
  withSelectAll = true,
  singleSelect = false,
  twoColumns = false,
  menuFullWidth = true,
  ...rest
}) => {
  const selected = useMemo(() => {
    return options?.filter((item: any) => {
      return Array.isArray(value) && value?.includes(item?.value) && item;
    });
  }, [value, options]);
  return (
    <div>
      <div css={css(styles.selectWrap)}>
        <MultiSelectCustom
          options={options}
          value={selected}
          isOpen={true}
          ClearSelectedIcon={null}
          valueRenderer={() => ( <div />)}
          ArrowRenderer={() => (
            <div css={css(styles.labelArrow)}>
              <CaretDownIcon />
            </div>
          )}
          onChange={(data: any) => {
            const filteredData = data?.map(
              (item: any) => item.value
            );
            const result: any = singleSelect
              ? filteredData?.length
                ? [filteredData[filteredData.length - 1]]
                : []
              : filteredData;
            return onChange(result);
          }}
          labelledBy={titleText}
          hasSelectAll={withSelectAll}
        />
      </div>
      {invalidText && (
        <div className="multi-select__required-error__text">{invalidText}</div>
      )}
    </div>
  );
};

export default MultiSelectMobile;
