import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../../services/formBuilder/types";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { entityTypes, actionTypes } from "modules/actionLog/constants";
import { useDefaultFieldsConfig } from "services/formBuilder/hooks";
import ShowDataDetails from "components/molecules/ShowDataDetails";
import globalStyles, { isMobile } from "system/theme/globalStyles";
import { TableColumnType } from "services/table/types";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("user"),
        id: "userEmail",
        accessor: "userEmail",
        width: 200,
      },
      {
        Header: _t("entity_name"),
        id: "entityName",
        accessor: "entityName",
      },
      {
        Header: _t("action_type"),
        id: "actionType",
        accessor: "actionType",
      },
      {
        Header: _t("entity_type"),
        id: "entityType",
        accessor: "entityType",
        isMobileTitle: true,
      },
      {
        Header: _t("date"),
        id: "date",
        accessor: "date",
      },
      {
        Header: _t("user_ip"),
        id: "userIp",
        accessor: "userIp",
      },
      {
        Header: _t("action"),
        id: "action1",
        Cell: (props: any) => {
          return (
            <ShowDataDetails
              data={props?.row?.original?.action}
              title={_t("action")}
              sliceQuantity={120}
            />
          );
        },
        width: 400,
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const entityTypesOptions = arrayOptionsMap(entityTypes, {
    labelKey: "name",
    valueKey: "id",
  });
  const actionTypesOptions = arrayOptionsMap(actionTypes, {
    labelKey: "id",
    valueKey: "id",
  });
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.userEmail({
        columnParams: {
          md: 4,
          lg: 3,
        },
        componentProps: {
          showSearch: true,
        },
        openDrawer: true,
      }),
      fields.filters.date({
        componentProps: {
          showPeriodSwitcher: false,
        },
      }),
      fields.filters.entityType({
        componentProps: {
          options: entityTypesOptions,
        },
      }),
      fields.filters.actionType({
        componentProps: {
          options: actionTypesOptions,
        },
      }),
      fields.general.submitBtn({
        componentProps: {
          css: isMobile && globalStyles.submitBtnForMobileFilters,
        },
      }),
    ];
  }, []);
  return formConfig;
};
