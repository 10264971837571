import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  container: {
    padding: themeOptions.spacing.lg,
    borderRadius: themeOptions.borders.sm,
    background: themeOptions.colors.white,
  },
  header: {
    height: "20px",
    display: "grid",
    gridTemplateColumns: "1fr 70px",
    marginBottom: themeOptions.spacing.md,
  },
  headerCollapsed: {
    marginBottom: 0,
  },
  title: {},
  extra: {
    justifySelf: "flex-end",
  },
  body: {
    display: "block",
  },
  collapsed: {
    display: "none",
  },
});

export default styles;
