import * as Yup from "yup";
import { TransType } from "system/translations/types";

export const createEditValidation = (_t: TransType, params: any) => {
  return Yup.object().shape({
    /*    fields: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(_t("required")),
        type: Yup.string().required(_t("required")),
      })
    ),*/
  });
};
export const createEditGeneralValidation = (_t: TransType, params: any) => {
  return Yup.object().shape({});
};
