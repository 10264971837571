import React, { useState } from "react";
import DragItemWrap from "components/atoms/DragItemWrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Checkbox from "components/atoms/Checkbox";
import { Pane } from "evergreen-ui";

const ColumnsSettings = ({ columns, setColumnOrder, columnHider }: any) => {
  const [columnsList, setColumnsList] = useState([...columns]);
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result: any[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const getListStyle = (isDraggingOver: any) => ({
    width: "100%",
  });

  const getItemStyle = (isDragging: any, draggableStyle: any) => {
    let styles = {
      ...draggableStyle,
    };
    if (isDragging) {
      styles.background = "lightgrey";
      styles.left = 24;
    }
    return styles;
  };

  const onDragEndHandler = (result: any) => {
    const newList = reorder(
      columnsList,
      result.source.index,
      result.destination.index
    );
    try {
      const listId = newList.map((item: any) => item.id);
      setColumnsList(newList);
      setTimeout(() => {
        setColumnOrder(listId);
      }, 400);
    } catch (e) {}
  };
  const columnHiderHandler = (id: any, e: any) => {
    const list = columnsList.map((item) => {
      if (item.id === id) {
        return { ...item, isVisible: e };
      }
      return item;
    });
    setColumnsList(list);
    setTimeout(() => {
      columnHider(id, e);
    }, 300);
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEndHandler}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {columnsList?.map((item: any, idx: any) => {
                if (!item.Header) {
                  return false;
                }
                return (
                  <Draggable
                    draggableId={item.id}
                    key={item.id + idx}
                    index={idx}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <DragItemWrap>
                          <Pane
                            display={"flex"}
                            alignItems={"center"}
                            gap={"10px"}
                          >
                            <Checkbox
                              id={item.id}
                              value={item.isVisible}
                              onChange={(e) => columnHiderHandler(item.id, e)}
                              /*    label={item.Header}*/
                            />
                            {item.Header}
                          </Pane>
                        </DragItemWrap>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ColumnsSettings;
