import InfoTag from "components/atoms/InfoTag";
import React, { useMemo, useState } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../../services/formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useUserModuleData } from "modules/users/hooks";
import { useDefaultFieldsConfig } from "services/formBuilder/hooks";
import { CreateEditFormConfigParams } from "modules/users/types";
import { selectFieldsForSearch } from "./constants";
import Toggle from "components/atoms/Toggle";
import { TableColumnType } from "services/table/types";
import globalStyles, { isMobile } from "system/theme/globalStyles";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();

  const activeStatusColumn = [
    {
      maxWidth: 140,
      Header: _t("status"),
      Cell: (props: any) => {
        const [activationStatus, setAtivationStatus] = useState(
          props?.row?.original?.activated
        );
        return (
          <Toggle
            key={"Toggle" + props?.row?.original?.id}
            id={"Toggle" + props?.row?.original?.id}
            value={activationStatus}
            onChange={(e) => {
              props.onActionUpdate(props?.row?.original, setAtivationStatus);
            }}
            /*     labelA={_t("inactive")}
            labelB={_t("active")}
            withControlledValue={true}*/
          />
        );
      },
    },
  ];

  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("name"),
        accessor: "name",
        id: "name",
        isMobileTitle: true,
      },
      {
        Header: _t("email"),
        accessor: "email",
        id: "email",
        width: 230,
      },
      {
        Header: _t("role"),
        accessor: "securityGroup.name",
        id: "securityGroup",
      },
      {
        Header: _t("status"),
        accessor: "status",
        id: "status",
        width: 120,
        Cell: (props: any) => {
          const status = props.row.original.activated
            ? "active"
            : "deactivated";
          const label = props.row.original.activated
            ? "activated"
            : "deactivated";
          return (
            <>
              <InfoTag type={"secondary"} status={status} label={label} />
            </>
          );
        },
      },
      {
        Header: _t("tfa"),
        accessor: "tfa",
        id: "tfa1",
        width: 120,
        Cell: (props: any) => {
          const status = props.row.original.tfaEnabled
            ? "active"
            : "deactivated";
          const label = props.row.original.tfaEnabled
            ? "activated"
            : "deactivated";
          return (
            <>
              <InfoTag type={"secondary"} status={status} label={label} />
            </>
          );
        },
      },
      {
        Header: _t("last_login"),
        accessor: "lastSeen",
        id: "lastLogIn",
        width: 130,
      },
      {
        Header: _t("last_ip_session"),
        accessor: "ip",
        id: "lastIpSession",
        width: 130,
      },
      {
        maxWidth: 65,
        Header: "",
        id: "UpdateDeleteMenu",
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const { rolesOptions, merchantsOptions, paymentMethodsOptions } =
    useUserModuleData();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.SearchInputWithSelect({
        componentProps: {
          selectName: "filterField",
          inputName: "filterValue",
          showSearch: true,
          searchIconDescription: _t("search"),
          selectItems: selectFieldsForSearch,
          selectValue: selectFieldsForSearch[0].value,
        },
        openDrawer: true,
      }),
      fields.filters.merchants({
        valuePreview: true,
        componentProps: {
          options: merchantsOptions,
        },
      }),
      fields.filters.paymentMethod({
        componentProps: {
          options: paymentMethodsOptions,
        },
      }),
      fields.filters.securityGroupId({
        valuePreview: true,
        componentProps: {
          options: rolesOptions,
        },
      }),
      fields.general.submitBtn({
        emptySpace: {},
        componentProps: {
          css: isMobile && globalStyles.submitBtnForMobileFilters,
        },
      }),
    ];
  }, [rolesOptions, merchantsOptions, paymentMethodsOptions]);
  return formConfig;
};

export const useCreateEditConfig = (
  params: CreateEditFormConfigParams
): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const { rolesOptions, merchantsOptions, paymentMethodsOptions } =
    useUserModuleData();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.name({
        columnParams: {
          md: params?.isEdit ? 6 : 4,
          lg: params?.isEdit ? 4 : 6,
        },
        emptySpace: {
          md: params?.isEdit ? 0 : 12,
          lg: params?.isEdit ? 0 : 12,
        },
      }),
      fields.formFields.password({
        hidden: params.isEdit,
        columnParams: {
          md: params?.isEdit ? 4 : 6,
          lg: params?.isEdit ? 4 : 6,
        },
        emptySpace: {
          md: params?.isEdit ? 0 : 12,
          lg: params?.isEdit ? 0 : 12,
        },
      }),
      fields.formFields.email({
        columnParams: {
          md: params?.isEdit ? 4 : 6,
          lg: params?.isEdit ? 4 : 6,
        },
        emptySpace: {
          md: params?.isEdit ? 0 : 12,
          lg: params?.isEdit ? 0 : 12,
        },
      }),
      fields.formFields.securityGroupId({
        componentProps: {
          options: rolesOptions,
        },
        columnParams: {
          md: params?.isEdit ? 4 : 6,
          lg: params?.isEdit ? 4 : 6,
        },
        emptySpace: {
          md: params?.isEdit ? 0 : 12,
          lg: params?.isEdit ? 0 : 12,
        },
      }),
      fields.formFields.merchants({
        componentProps: {
          isOpen: !isMobile,
          twoColumns: !isMobile,
          options: merchantsOptions,
          isMobileCreateEdit: isMobile,
        },
        columnParams: {
          md: 6,
          lg: 6,
        },
        emptySpace: {
          md: params?.isEdit ? 0 : 12,
          lg: params?.isEdit ? 0 : 12,
        },
      }),
      fields.formFields.paymentMethods({
        componentProps: {
          isOpen: !isMobile,
          twoColumns: !isMobile,
          options: paymentMethodsOptions,
          isMobileCreateEdit: isMobile,
        },
        columnParams: {
          md: 6,
          lg: 6,
        },
        emptySpace: {
          md: params?.isEdit ? 0 : 12,
          lg: params?.isEdit ? 0 : 12,
        },
      }),
      fields.general.divider(),
      fields.general.saveBtn({
        componentProps: {
          kind: params.isEdit ? "primary" : "secondary",
          label: params.isEdit ? _t("save_changes") : _t("create_user"),
        },
      }),
    ];
  }, [rolesOptions, merchantsOptions]);
  return formConfig;
};
