import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "services/formBuilder/FormBuilder";
import { useCreateMerchantPaymentMethods } from "modules/merchantPaymentMethods/apiHooks";
import { createEditValidation } from "modules/merchantPaymentMethods/validation";
import { useCreateEditConfig } from "modules/merchantPaymentMethods/constantHooks";
import SectionTitle from "components/atoms/SectionTitle";

const Create: React.FC = () => {
  const isEdit = false;
  const history = useHistory();
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { mutateAsync: onCreate } = useCreateMerchantPaymentMethods();
  const formConfig = useCreateEditConfig({ isEdit });
  const onSubmit = async (data: any) => {
    await onCreate(data);
    history.goBack();
  };

  return (
    <>
      <SectionTitle title={_t("add_method")} />
      <Card>
        <FormBuilder
          showSubmit={false}
          formItemsConfig={formConfig}
          formProps={{
            submitBtnLabel: _t("create"),
            onSubmit: onSubmit,
            initialValues: {
              merchantId: routeParams?.merchantId,
              maskSensitiveData: false,
              enabled: false,
            },
            validationSchema: createEditValidation(_t, {
              isEdit: false,
            }),
          }}
        />
      </Card>
    </>
  );
};

export default Create;
