import React, { useMemo } from "react";
import { SelectProps } from "./types";
import {
  Button,
  CaretDownIcon,
  EditIcon,
  SelectMenu,
  Text,
  WarningSignIcon,
} from "evergreen-ui";
import { css } from "@emotion/react";
import styles from "components/atoms/Select/styles";

const Select: React.FC<SelectProps> = ({
  id = "Select",
  onChange,
  options = [],
  label,
  placeholder = "Select",
  value,
  title,
  hideLabel = false,
  hasFilter = false,
  disabled,
  size = "xl",
  isLoading = false,
  preViewType = "default",
  previewSuffix = "",
  width = 240,
  validationMessage,
  isInvalid,
  additionalStyles,
  ...rest
}) => {
  const selectedLabel = useMemo(() => {
    if (value && options) {
      return options.find((item: any) => item.value === value)?.label;
    }
    return value;
  }, [value, options]);
  const renderPreView = () => {
    let res;
    switch (preViewType) {
      case "text":
        res = (
          <div css={css(styles.labelContainer)}>
            <span css={css(styles.labelValue)}>
              {value || placeholder} {previewSuffix && previewSuffix}
            </span>
            <CaretDownIcon css={css(styles.labelArrow)} />
          </div>
        );
        break;
      case "edit":
        res = (
          <div
            css={css(
              styles.labelContainer,
              styles.editLabelContainer,
              !selectedLabel && styles.notAssignedLabel
            )}
          >
            <span css={css(styles.labelValue)}>
              {selectedLabel ? (
                selectedLabel
              ) : (
                <span>
                  <WarningSignIcon
                    verticalAlign={"middle"}
                    color="warning"
                    marginRight={8}
                  />
                  Not assigned
                </span>
              )}
            </span>
            <EditIcon size={20} css={css(styles.labelEditIcon)} />
          </div>
        );
        break;
      default:
        res = (
          <div css={css(disabled && styles.disabledLabel)}>
            {label && <Text css={css(styles.selectLabel)}>{label}</Text>}
            <Button
              width={"100%"}
              css={css(
                styles.buttonContainer,
                isInvalid && styles.errorSelect,
                additionalStyles && additionalStyles
              )}
              type={"button"}
              justifyContent={"space-between"}
              iconAfter={CaretDownIcon}
              disabled={disabled}
            >
              {selectedLabel || value || placeholder}
            </Button>
            {validationMessage && (
              <Text size={300} css={css(styles.errorLabel)}>
                {validationMessage}
              </Text>
            )}
          </div>
        );
    }
    return res;
  };
  const height = options.length > 10 ? 250 : options.length * 33;

  return (
    <SelectMenu
      closeOnSelect
      height={height}
      width={width}
      title={title}
      options={options}
      selected={value}
      hasFilter={hasFilter}
      hasTitle={!!title}
      onSelect={(item) => {
        onChange(item.value);
      }}
    >
      {renderPreView()}
    </SelectMenu>
  );
};

export default Select;
