import { Link } from "carbon-components-react";
import InfoTag from "components/atoms/InfoTag";
import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "services/formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useDefaultFieldsConfig } from "services/formBuilder/hooks";
import {
  paymentTypeOptions,
  methodStatusOptions,
} from "modules/formTemplates/constants";
import { generatePath, useHistory } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import FieldItem from "modules/formTemplates/components/FieldItem";
import { useModuleData } from "modules/formTemplates/hooks";
import FieldsStyles from "modules/formTemplates/components/FieldsStyles";
import { TableColumnType } from "services/table/types";
import globalStyles, { isMobile } from "system/theme/globalStyles";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const history = useHistory();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("template_name"),
        id: "code",
        isMobileTitle: true,
        Cell: (props: any) => {
          return (
            <Link
              onClick={() =>
                history.push(
                  generatePath(APP_ROUTES.paymentsFormTemplate.update, {
                    id: props?.row?.original?.id,
                  }),
                  history.location
                )
              }
            >
              {props?.row?.original?.name}
            </Link>
          );
        },
      },
      {
        Header: _t("method"),
        id: "method",
        accessor: "paymentMethod.code",
      },
      {
        Header: _t("status"),
        Cell: (props: any) => {
          const status = props?.row?.original?.enabled
            ? "active"
            : "deactivated";
          return (
            <>
              <InfoTag
                type={"secondary"}
                size={"sm"}
                status={status}
                label={status}
              />
            </>
          );
        },
      },
      {
        width: 40,
        Header: "",
        id: "actionMenu",
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const statusOptions = methodStatusOptions(_t);
  const typeOptions = paymentTypeOptions(_t);
  const { paymentMethodsOptions } = useModuleData();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.filterValue({
        componentProps: {
          showSearch: true,
          SearchIconDescription: _t("search"),
        },
        columnParams: {
          md: 4,
          lg: 4,
        },
        openDrawer: true,
        name: "name",
      }),
      fields.filters.paymentMethod({
        componentProps: {
          options: paymentMethodsOptions,
        },
      }),
      fields.filters.status({
        componentProps: {
          options: statusOptions,
        },
      }),
      fields.filters.type({
        componentProps: {
          options: typeOptions,
        },
      }),
      fields.general.submitBtn({
        componentProps: {
          css: isMobile && globalStyles.submitBtnForMobileFilters,
        },
      }),
    ];
  }, [paymentMethodsOptions]);
  return formConfig;
};

export const useCreateEditGeneralConfig = ({
  isEdit,
  isLoading,
}: {
  isEdit: boolean;
  isLoading?: boolean;
}): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const typeOptions = paymentTypeOptions(_t);
  const { paymentMethodsOptions } = useModuleData();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.type({
        name: "formTemplateType",
        componentProps: {
          options: typeOptions,
        },
        columnParams: {
          md: 6,
          lg: 4,
        },
      }),
      fields.formFields.paymentMethodId({
        componentProps: {
          options: paymentMethodsOptions,
        },
        columnParams: {
          md: 6,
          lg: 4,
        },
      }),
      fields.formFields.enabled({
        columnParams: {
          md: 3,
          lg: 2,
        },
      }),
    ];
  }, [isLoading, paymentMethodsOptions]);
  return formConfig;
};

export const useCreateEditStylesConfig = ({
  isEdit,
  isLoading,
}: {
  isEdit: boolean;
  isLoading?: boolean;
}): FormConfigType => {
  const { _t } = useTrans();
  const formConfig = useMemo((): FormConfigType => {
    return [
      {
        component: FieldsStyles,
        name: "styles",
        hasValues: true,
        componentProps: {},
        columnParams: {
          md: 12,
          lg: 12,
          style: {
            padding: 0,
          },
        },
      },
    ];
  }, [isLoading]);
  return formConfig;
};

export const useCreateEditFieldsConfig = ({
  isEdit,
  isLoading,
}: {
  isEdit: boolean;
  isLoading?: boolean;
}): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      {
        component: null,
        type: "array",
        name: "fields",
        hasValues: true,
        components: [
          {
            component: FieldItem,
            name: "",
            componentProps: {},
            columnParams: {
              lg: 12,
              md: 12,
              style: {
                padding: 0,
              },
            },
          },
        ],
      },
      fields.general.divider(),
      fields.general.saveBtn({
        componentProps: {
          kind: isEdit ? "primary" : "secondary",
          label: isEdit ? _t("save_changes") : _t("create_template"),
          isLoading: isLoading,
        },
      }),
    ];
  }, [isLoading]);
  return formConfig;
};
