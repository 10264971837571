import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import {
  UseModuleDataRes,
  UseTransactionModuleDataRes,
} from "modules/transactions/types";
import {
  useGetPaymentGateways,
  useGetPaymentMethodsNameList,
} from "../payment/apiHooks";
import { useGetRejectionReasons } from "modules/transactions/apiHooks";

export const useModuleData = (): UseModuleDataRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentMethods = [] } = useGetPaymentMethodsNameList();
  const merchantsOptions = arrayOptionsMap(merchantsList, {
    labelKey: "name",
    valueKey: "id",
  });
  const paymentMethodsOptions = arrayOptionsMap(Object.values(paymentMethods), {
    labelKey: "name",
    valueKey: "id",
  });
  return { merchantsOptions, paymentMethodsOptions };
};
export const useTransactionModuleData = (): UseTransactionModuleDataRes => {
  const { data: rejectionReasons = [] } = useGetRejectionReasons();
  const rejectionReasonsOptions = arrayOptionsMap(rejectionReasons, {
    labelKey: "name",
    valueKey: "id",
  });

  return { rejectionReasonsOptions };
};
