import React, {
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useFormik } from "formik";
import { useFormikHelper } from "system/helpers/hooks";
import { FormBuilderProps } from "services/formBuilder/types";
import { getNestedValue } from "system/helpers/helperFunctions";
import { useTrans } from "system/translations/hooks";
import FieldsArray from "services/formBuilder/FieldsArray";
import FieldItem from "services/formBuilder/FieldItem";
import { Grid, Row } from "react-flexbox-grid";
import FiltersSummary from "components/atoms/FiltersSummary";
import { css } from "@emotion/react";
import styles from "services/formBuilder/styles";

const FormBuilder: React.FC<FormBuilderProps> = forwardRef(
  ({ formProps, formItemsConfig, showSubmit = false, showFiltersDrawer = false }, ref) => {
    const { initialValues, ...restFormProps } = formProps;
    const { _t } = useTrans();
    const formInitialValues = useMemo(() => {
      let values = formProps.initialValues || {};
      formItemsConfig.forEach(({ name }: any) => {
        if (
          !!name &&
          !Array.isArray(name) &&
          !values.hasOwnProperty(name) &&
          !name.includes(".")
        ) {
          values[name] = undefined;
        }
      });
      return values;
    }, [formItemsConfig]);
    const formik: any = useFormik({
      /*    validateOnChange: true,
    validateOnMount: false,*/

      ...restFormProps,
      // initialValues: formInitialValues,
      initialValues: showFiltersDrawer ? formProps.values : formInitialValues,
      values: showFiltersDrawer ? formProps.values || {} : undefined,
    });    
    useEffect(() => {
      formProps.onFormValuesChange &&
        formProps.onFormValuesChange(formik.values, formik);
    }, [formik.values]);
    useImperativeHandle(ref, () => ({
      formik,
    }));
    const resetField = (fieldName: any) => {
      const initialValue = initialValues?.[fieldName];
      formik.setFieldValue(fieldName, initialValue);
    };
    const onReset = () => {
      formProps?.onReset && formProps?.onReset();
    };
    const { filterValuesToPreview } = useMemo(() => {
      let selectedValuePreview: any = {};
      let filterValuesToPreview = 0;
      formItemsConfig.forEach((item: any) => {
        const value = formik.values?.[item.name];
        if (item.valuePreview && !!value)
          if ((Array.isArray(value) && value.length) || !Array.isArray(value)) {
            filterValuesToPreview = filterValuesToPreview + 1;
          }
      });
      return { selectedValuePreview, filterValuesToPreview };
    }, [formItemsConfig, formik.values]);
    const { error, onBlur, onChange } = useFormikHelper(formik);
    return (
      <form noValidate className={"form"} onSubmit={formik.handleSubmit}>
        <Grid css={css(styles.gridItem)}>
          <Row css={css(styles.rowItem)} style={formProps.rowStyles}>
            {formItemsConfig?.map((item: any) => {
              let {
                component: Component,
                componentProps: formItemProps,
                name,
                columnParams = {},
                emptySpace,
                type,
                hidden,
                components,
                hasValues,
                showAddBtn,
                elementType = "formElement",
              } = item;
              if (hidden) {
                return null;
              }
              const componentProps =
                typeof formItemProps === "function"
                  ? formItemProps(formik)
                  : formItemProps;
              const additionalProps: any = {};
              if (componentProps?.type === "submit") {
                additionalProps.formOnSubmit = formik.handleSubmit;
              }
              if (!!hasValues) {
                additionalProps.values = formik.values;
              }
              if (Array.isArray(name)) {
                const label = (param: any) =>
                  typeof componentProps.label === "function"
                    ? componentProps.label(param)
                    : componentProps.label;
                return name.map((nameKey) => (
                  <FieldItem
                    emptySpace={emptySpace}
                    columnParams={columnParams}
                    Component={Component}
                    additionalProps={additionalProps}
                    label={label({ fieldName: nameKey })}
                    name={nameKey}
                    componentProps={componentProps}
                    error={error(nameKey)}
                    onBlur={onBlur}
                    onChange={onChange}
                    formik={formik}
                    formProps={formProps}
                    getNestedValue={getNestedValue}
                    showFiltersDrawer={showFiltersDrawer}
                  />
                ));
              }
              if (type === "array" && typeof name === "string") {
                const onFormChange = (name: any, value: any) =>
                  formik.setFieldValue(name, value);

                return (
                  <FieldsArray
                    name={name}
                    values={formik?.values?.[name]}
                    onFormChange={(name: any, value: any) =>
                      onFormChange(name, value)
                    }
                    showAddBtn={showAddBtn}
                  >
                    <Grid css={css(styles.gridItem)}>
                      <Row css={css(styles.rowItem)}>
                        {formik?.values?.[name] &&
                          formik?.values?.[name].map(
                            (item: any, arrayIndex: number) => {
                              return (
                                <>
                                  {components?.map((item: any) => {
                                    let {
                                      component: Component,
                                      componentProps: formItemProps,
                                      name: componentName,
                                      columnParams = {},
                                      emptySpace,
                                    } = item;
                                    let fullName = !!componentName
                                      ? `${name}[${arrayIndex}].${componentName}`
                                      : `${name}[${arrayIndex}]`;
                                    const componentProps =
                                      typeof formItemProps === "function"
                                        ? formItemProps(formik)
                                        : formItemProps;
                                    const additionalProps: any = {
                                      parentName: name,
                                    };
                                    if (!!hasValues) {
                                      additionalProps.values = formik.values;
                                    }

                                    return (
                                      <FieldItem
                                        key={fullName}
                                        emptySpace={emptySpace}
                                        columnParams={columnParams}
                                        Component={Component}
                                        additionalProps={additionalProps}
                                        name={fullName}
                                        parentName={name}
                                        componentProps={componentProps}
                                        error={
                                          error(name as string)?.[arrayIndex]
                                        }
                                        index={arrayIndex}
                                        onFormChange={(name: any, value: any) =>
                                          onFormChange(name, value)
                                        }
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        formik={formik}
                                        formProps={formProps}
                                        getNestedValue={getNestedValue}
                                      />
                                    );
                                  })}
                                </>
                              );
                            }
                          )}
                      </Row>
                    </Grid>
                  </FieldsArray>
                );
              }
              if (elementType === "additionalComponent") {
                return (
                  <FieldItem
                    emptySpace={emptySpace}
                    columnParams={columnParams}
                    Component={Component}
                    componentProps={componentProps}
                  />
                );
              }
              if (!!hasValues) {
                additionalProps.values = formik.values;
              }
              return (
                <FieldItem
                  emptySpace={emptySpace}
                  columnParams={columnParams}
                  Component={Component}
                  additionalProps={additionalProps}
                  name={name}
                  componentProps={componentProps}
                  error={error(name)}
                  onBlur={onBlur}
                  onChange={onChange}
                  formik={formik}
                  onReset={onReset}
                  formProps={formProps}
                  getNestedValue={getNestedValue}
                  showFiltersDrawer={showFiltersDrawer}
                />
              );
            })}
          </Row>
        </Grid>
        <div>
          {filterValuesToPreview &&
          formik.submitCount &&
          !formik.isSubmitting ? (
            <FiltersSummary
              onClear={() => {
                formik.handleReset();
                formik.handleSubmit();
              }}
              filterQuantity={filterValuesToPreview}
              resultQuantity={formProps.resultQuantity}
            />
          ) : null}
        </div>
      </form>
    );
  }
);

export default FormBuilder;
