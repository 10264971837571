import React from "react";
import Card from "components/atoms/Card";
import Table from "services/table";
import { useTrans } from "system/translations/hooks";
import { useTableColumns } from "modules/merchantBlackList/constantHooks";
import { useParams } from "react-router-dom";
import {
  useGetMerchantBlackList,
  useDeleteMerchantBlackList,
} from "modules/merchantBlackList/apiHooks";
import { useListPagination } from "system/helpers/hooks";
import config from "system/config";
import { useConfirmModal } from "services/modal/predefinedModals";
import { EditIcon, PlusIcon, TrashIcon } from "evergreen-ui";
import TableHeadAddButton from "components/atoms/TableHeadAddButton";
import { useDrawer } from "services/drawer/hooks";
import TransactionDetails from "modules/transactions/components/TransactionDetails";
import TransactionDetailsHeader from "modules/transactions/components/TransactionDetailsHeader";
import DrawerHeader from "modules/merchantBlackList/components/DrawerHeader";
import Create from "modules/merchantBlackList/components/Create";
import Update from "modules/merchantBlackList/components/Update";

const MerchantBlackList = () => {
  const { _t } = useTrans();
  const { showDrawer } = useDrawer();
  const routeParams: any = useParams();
  const { onConfirm } = useConfirmModal();
  const { pagination, setPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const { data, isLoading } = useGetMerchantBlackList(
    routeParams?.id,
    pagination
  );
  const { mutate: onDelete } = useDeleteMerchantBlackList(routeParams?.id);

  const columns = useTableColumns();
  const onUpdate = ({ id, allData }: any) => {
    showDrawer({
      component: Update,
      componentProps: {
        merchantId: routeParams?.id,
        id: id,
      },
      header: DrawerHeader,
      headerProps: {
        text: allData?.email,
      },
      bodyWrapperProps: {
        hasSpacing: false,
      },
    });
  };
  const onDeleteWithConfirm = ({ id, allData }: any) => {
    onConfirm({
      onOk: () => onDelete({ id }),
      title: `${_t("delete_email")} ${allData?.email}?`,
      bodyText: _t("action_cannot_be_undone"),
      onOkKind: "danger",
      onOkText: _t("deactivate"),
    });
  };

  const actionMenuItems = [
    { title: _t("edit"), onClick: onUpdate, icon: <EditIcon size={12} /> },
    {
      title: _t("delete"),
      onClick: onDeleteWithConfirm,
      icon: <TrashIcon size={12} />,
      intent: "danger",
    },
  ];
  const onCreate = () => {
    showDrawer({
      component: Create,
      componentProps: {
        merchantId: routeParams?.id,
      },
      header: DrawerHeader,
      headerProps: {
        text: _t("add_email_address"),
      },
      bodyWrapperProps: {
        hasSpacing: false,
      },
    });
  };

  return (
    <>
      <Table
        totalRecords={data?.totalElements}
        onPagination={setPagination}
        columns={columns}
        isLoading={isLoading}
        data={data?.content || []}
        isPagination
        afterHeadComponent={
          <TableHeadAddButton
            onClick={onCreate}
            text={_t("add_email_address")}
          />
        }
        showTotalNoData={false}
        showSettingsIcon={false}
        pageIndex={pagination.page}
        pageSize={pagination.perPage}
        cellProps={{
          actionMenuItems,
        }}
      />
    </>
  );
};

export default MerchantBlackList;
