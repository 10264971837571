import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  wrapper: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
    zIndex: 5,
  },
  formOverlay: (bgOpacity) => ({
    position: "absolute",
    top: "1px",
    bottom: "1px",
    left: "1px",
    right: "1px",
    justifyContent: "center",
    background: `rgba(255 255 255 / ${bgOpacity}%)}`,
  }),
  spinner: {
    circle: {
      stroke: themeOptions.colors.blue500,
    },
  },
  /*  spinner1: (opacity) => ({
    circle: {
      stroke: themeOptions.colors.blue500
    }
  }),*/
});

export default styles;
