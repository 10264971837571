import React, { useMemo } from "react";
import { Link } from "carbon-components-react";
import { useTrans } from "system/translations/hooks";
import Toggle from "components/atoms/Toggle";
import {
  useEnableUserTFa,
  useResendConfirmationLink,
  useResetTFA,
  useResetUserPassword,
} from "modules/users/apiHooks";
import { UserItemType, UserSettingsProps } from "modules/users/types";
import DescriptionList from "components/molecules/DescriptionList";
import Card from "components/atoms/Card";
import { Col, Row } from "react-flexbox-grid";
import { Pane, Text } from "evergreen-ui";
import { ReactComponent as InboxIcon } from "images/icons/inbox_outline.svg";
import { ReactComponent as KeyIcon } from "images/icons/key_outline.svg";
import { ReactComponent as ShieldIcon } from "images/icons/shield_outline.svg";
import themeOptions from "system/theme";
import { createStyles } from "types/emotion-styles";
import { useConfirmModal } from "services/modal/predefinedModals";
const styles = createStyles({
  spacing: {
    margin: `${themeOptions.spacing.sm} 0`,
  },
});
const Settings: React.FC<UserSettingsProps> = ({ userData }) => {
  const { _t } = useTrans();
  const { onConfirm } = useConfirmModal();
  const { mutate: onResend } = useResendConfirmationLink();
  const { mutate: onTFa } = useEnableUserTFa();
  const { mutate: onResetTFa } = useResetTFA();
  const onResendConfirmationLink = (e: any) => {
    e.preventDefault();
    onResend(userData.id);
  };
  const { mutate: onUserPasswordReset } = useResetUserPassword();
  const onPasswordReset = (e: any) => {
    e.preventDefault();
    onUserPasswordReset({ email: userData.email });
  };
  const onUserResetTFa = (e: any) => {
    e.preventDefault();
    onConfirm({
      onOk: () => onResetTFa(userData.id),
      title: `${_t("reset_tfa")}?`,
      bodyText: (
        <span
          className="form__content"
          dangerouslySetInnerHTML={{
            __html: _t("reset_tfa_description", {
              userName: <b>{userData?.name}</b>,
            }),
          }}
        />
      ),
      onOkKind: "primary",
      onOkText: _t("reset"),
    });
  };
  const listData: any = useMemo(() => {
    const res = [];
    let key: keyof UserItemType;
    for (key in userData) {
      const val = typeof userData[key] ? `${userData[key]}` : userData[key];
      res.push({ title: key, value: val });
    }
    return res;
  }, [userData]);

  const enableTFa = (val: boolean) => {
    const data = {
      id: userData.id,
      tfaState: val,
    };
    onTFa(data);
  };

  return (
    <div css={styles.spacing}>
      <Text display={"block"} textAlign={"left"} size={300}>
        {_t("security_settings")}
      </Text>
      <Row>
        <Col md={6}>
          <Card wrapperClass={styles.spacing}>
            <Pane
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
              marginBottom={themeOptions.spacing.md}
            >
              <ShieldIcon style={{ marginBottom: themeOptions.spacing.md }} />
              <Toggle
                onChange={enableTFa}
                value={userData.tfaEnabled}
                label={_t("auth_tfa")}
              />
              <Text
                textAlign={"center"}
                color={themeOptions.colors.gray700}
                marginBottom={themeOptions.spacing.xs}
                marginTop={themeOptions.spacing.xs}
                size={300}
              >
                {_t("auth_tfa_description")}
              </Text>
              <Link onClick={onUserResetTFa} href="javascript:void(0)">
                {_t("reset_tfa")}
              </Link>
            </Pane>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Card>
            <Pane
              display={"grid"}
              gridTemplateColumns={"40px 1fr"}
              gap={"10px"}
              alignItems={"center"}
            >
              <InboxIcon />
              <Pane>
                <b>{_t("reset_password")}</b>
                <Text
                  display={"block"}
                  textAlign={"left"}
                  color={themeOptions.colors.gray700}
                  marginBottom={themeOptions.spacing.xs}
                  marginTop={themeOptions.spacing.xs}
                  size={300}
                >
                  {_t("reset_password_description")}
                </Text>
                <Link onClick={onPasswordReset}>{_t("send_email_link")}</Link>
              </Pane>
            </Pane>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Pane
              display={"grid"}
              gridTemplateColumns={"40px 1fr"}
              gap={"10px"}
              alignItems={"center"}
            >
              <KeyIcon />
              <Pane>
                <b>{_t("email_confirmation")}</b>
                <Text
                  display={"block"}
                  textAlign={"left"}
                  color={themeOptions.colors.gray700}
                  marginBottom={themeOptions.spacing.xs}
                  marginTop={themeOptions.spacing.xs}
                  size={300}
                >
                  {_t("email_confirmation_description")}
                </Text>
                <Link onClick={onResendConfirmationLink}>
                  {_t("resend_confirmation_link")}
                </Link>
              </Pane>
            </Pane>
          </Card>
        </Col>
      </Row>
      <Text
        display={"block"}
        textAlign={"left"}
        marginBottom={themeOptions.spacing.xs}
        marginTop={themeOptions.spacing.xs}
        size={300}
      >
        {_t("other_details")}
      </Text>
      <Card>
        <DescriptionList list={listData} />
      </Card>
    </div>
  );
};

export default Settings;
