import { createStyles } from "types/emotion-styles";
import Bg from "images/auth/bg.jpg";
import themeOptions from "system/theme";

const styles = createStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "612px 1fr",
    height: "100%",
    background: themeOptions.colors.c1,
    [themeOptions.maxMedia.lg]: {
      gridTemplateColumns: "auto",
      background: themeOptions.colors.white,
    },
  },
  img: {
    backgroundImage: `url(${Bg})`,
    backgroundSize: "cover",
    [themeOptions.maxMedia.lg]: {
      display: "none",
    },
  },
  logo: {
    alignSelf: "end",
    justifySelf: "center",
  },
  contentWrap: {
    justifyContent: "center",
    display: "grid",
    padding: themeOptions.spacing.md,
    gridTemplateRows: "150px 1fr",
    gridGap: "40px",
    position: "relative",
  },
  content: {
    background: themeOptions.colors.white,
    padding: `${themeOptions.spacing.xlg} ${themeOptions.spacing.xxlg}`,
    height: "fit-content",
    borderRadius: themeOptions.borders.sm,
    boxShadow: themeOptions.boxShadow.main,
    maxWidth: "454px",
    position: "relative",
    [themeOptions.maxMedia.lg]: {
      boxShadow: "none",
      maxWidth: "initial",
      padding: themeOptions.spacing.xs,
    },
  },
  title: {
    textAlign: "center",
    marginBottom: themeOptions.spacing.xs,
  },
  description: {
    textAlign: "left",
    marginBottom: themeOptions.spacing.md,
  },
  qrWrap: {
    display: "grid",
    gridTemplateColumns: "130px 1fr",
    gridGap: themeOptions.spacing.md,
    marginTop: themeOptions.spacing.sm,
  },
  statusIconWrap: {
    textAlign: "center",
    marginBottom: themeOptions.spacing.sm,
  },
  subtitle: {
    textAlign: "center",
    marginBottom: themeOptions.spacing.md,
    color: themeOptions.colors.gray600,
    fontWeight: "400",
  },
  footerDescription: {
    marginBottom: themeOptions.spacing.md,
    marginTop: themeOptions.spacing.xs,
    color: themeOptions.colors.gray600,
    fontWeight: "400",
    fontSize: "12px",
  },
  titleWrap: {
    position: "relative",
  },
  footerText: {
    alignSelf: "center",
    justifySelf: "center",
    color: themeOptions.colors.gray600,
    position: "absolute",
    bottom: themeOptions.spacing.sm,
  },
  backBtn: {
    color: themeOptions.colors.gray600,
    position: "absolute",
    top: "6px",
    cursor: "pointer",
  },
});

export default styles;
