import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../../services/formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useDefaultFieldsConfig } from "services/formBuilder/hooks";
import { useModuleData } from "modules/merchantPaymentMethods/hooks";
import { statusOptions } from "modules/merchants/constants";
import { paymentTypeOptions } from "modules/merchantPaymentMethods/constants";
import InfoTag from "components/atoms/InfoTag";
import Select from "components/atoms/Select";
import { TableColumnType } from "services/table/types";
import DetailsItem from "modules/merchantPaymentMethods/components/DetailsItem";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("method_name"),
        accessor: "paymentMethod.code",
        id: "paymentMethod",
        isMobileTitle: true,
      },
      /*      {
        Header: _t("mask_sensitive"),
        accessor: "maskSensitiveData",
        id: "maskSensitiveData",
        Cell: (props: any): any => `${props.row.original.enabled}`,
      },*/
      {
        Header: _t("commission"),
        accessor: "commission",
        id: "commission",
      },
      {
        Header: _t("status"),
        accessor: "enabled",
        id: "enabled",
        width: 120,
        Cell: (props: any) => {
          const status = props.row.original.enabled ? "active" : "deactivated";
          const label = props.row.original.enabled
            ? "activated"
            : "deactivated";
          return (
            <>
              <InfoTag type={"secondary"} status={status} label={label} />
            </>
          );
        },
      },
      {
        maxWidth: 40,
        Header: "",
        id: "actionMenu",
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useActionLogTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("date"),
        id: "createdDate",
        accessor: "createdDate",
        width: 150,
      },
      {
        Header: _t("user"),
        id: "userEmail",
        accessor: "userEmail",
      },
      {
        Header: _t("key"),
        id: "key",
        accessor: "key",
      },
      {
        Header: _t("old_value"),
        id: "oldValue",
        accessor: "oldValue",
      },
      {
        Header: _t("changed_value"),
        id: "changedValue",
        accessor: "changedValue",
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const statusOptionsList = statusOptions(_t);
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.filterValue({ name: "name" }),
      fields.filters.status({
        componentProps: {
          options: statusOptionsList,
        },
      }),
      fields.general.submitBtn(),
    ];
  }, []);
  return formConfig;
};

export const useActionLogSearchFormConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.filterValue({
        name: "searchField",
        columnParams: {
          md: 4,
          lg: 3,
        },
        componentProps: {
          showSearch: true,
        },
      }),
      fields.filters.date({
        valuePreview: false,
        componentProps: {
          showPeriodSwitcher: false,
        },
      }),
      fields.general.submitBtn(),
    ];
  }, []);
  return formConfig;
};

export const useCreateEditConfig = (params: {
  isEdit: boolean;
}): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const { _t } = useTrans();
  const { methodsOptions } = useModuleData();
  const paymentOptions = paymentTypeOptions(_t);
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.type({
        componentProps: {
          options: paymentOptions,
          disabled: params.isEdit,
          onFieldChange: (value: any, values: any, form: any) => {
            form.setFieldValue("paymentMethodId", undefined);
          },
        },
        columnParams: {
          md: 6,
          lg: 6,
        },
        emptySpace: {
          md: 12,
          lg: 12,
        },
      }),
      {
        component: Select,
        name: "paymentMethodId",
        componentProps: (form: any) => {
          const options = methodsOptions.filter((item) => {
            if (item?.type == form?.values?.type || item?.type === "ALL") {
              return item;
            }
          });
          return {
            options: options,
            hasFilter: options.length > 5,
            disabled: params.isEdit,
            label: _t("payment_method"),
            placeholder: _t("payment_method"),
          };
        },
        columnParams: {
          md: 6,
          lg: 6,
        },
        emptySpace: {
          md: 12,
          lg: 12,
        },
      },
      fields.formFields.commission({
        columnParams: {
          md: 6,
          lg: 6,
        },
        emptySpace: {
          md: 12,
          lg: 12,
        },
      }),
      fields.formFields.enabled(),
      fields.formFields.redirectEnabled(),
      /*     fields.formFields.maskSensitiveData(),*/
      fields.general.divider(),
      fields.general.saveBtn({
        componentProps: {
          kind: params.isEdit ? "primary" : "secondary",
          label: params.isEdit ? _t("save_changes") : _t("add_method"),
        },
      }),
    ];
  }, [methodsOptions]);
  return formConfig;
};

export const useEditDetailsConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const { _t } = useTrans();
  const formConfig = useMemo((): FormConfigType => {
    return [
      {
        component: DetailsItem,
        name: "fields",
        componentProps: {},
        columnParams: {
          lg: 12,
          md: 12,
        },
      },
      fields.general.divider(),
      fields.general.saveBtn({
        componentProps: {
          kind: "primary",
          label: _t("save_changes"),
        },
      }),
    ];
  }, []);
  return formConfig;
};
