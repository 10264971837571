import React, { useMemo } from "react";
import Table from "services/table";
import { useTrans } from "system/translations/hooks";
import {
  useSearchFormConfig,
  useTableColumns,
} from "modules/merchantPaymentMethods/constantHooks";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import {
  useGetMerchantPaymentMethods,
  useDeleteMerchantPaymentMethods,
} from "modules/merchantPaymentMethods/apiHooks";
import { useFilters, useListPagination } from "system/helpers/hooks";
import config from "system/config";
import { useConfirmModal } from "services/modal/predefinedModals";
import { EditIcon, PlusIcon, TrashIcon } from "evergreen-ui";
import FormBuilder from "services/formBuilder/FormBuilder";

const Methods: React.FC<{ tableName: string }> = ({ tableName }) => {
  const history = useHistory();
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const searchFormConfig = useSearchFormConfig();
  const filterInitialValues = {
    merchantId: routeParams?.merchantId,
    type: tableName,
  };
  const { filters, setFilters } = useFilters(filterInitialValues);
  const { onConfirm } = useConfirmModal();
  const { pagination, setPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const requestParams = useMemo(
    () => ({ ...filters, ...pagination }),
    [filters, pagination]
  );
  const { data, isLoading } = useGetMerchantPaymentMethods(requestParams);
  const { mutate: onDelete } = useDeleteMerchantPaymentMethods(routeParams?.id);
  const columns = useTableColumns();
  const onUpdate = ({ id }: any) => {
    const link = generatePath(APP_ROUTES.merchants.payment_methods.update, {
      merchantId: routeParams?.merchantId,
      id,
    });
    history.push(link, history.location);
  };
  const onAddDetails = ({ id }: any) => {
    const link = generatePath(
      APP_ROUTES.merchants.payment_methods.payment_details,
      {
        merchantId: routeParams?.merchantId,
        id,
      }
    );
    history.push(link, history.location);
  };
  const onDeleteWithConfirm = ({ id }: any) => {
    onConfirm({
      onOk: () => onDelete({ id }),
      onOkKind: "danger",
      onOkText: _t("delete"),
      bodyText: _t("action_cannot_undone"),
      title: <>{_t("delete_method")}?</>,
    });
  };
  const onFilterSubmit = async (data: any) => {
    setPagination(config.defaultInitialPagination);
    setFilters(data);
  };

  const actionMenuItems = [
    {
      title: _t("edit"),
      onClick: onUpdate,
      icon: <EditIcon size={12} />,
      accessTo: "ROLE_MODIFY_MERCHANT_PM",
    },
    {
      title: _t("add_payment_details"),
      onClick: onAddDetails,
      icon: <PlusIcon size={12} />,
      accessTo: "ROLE_MODIFY_MERCHANT_METHOD_DETAILS",
    },
    {
      title: _t("delete"),
      onClick: onDeleteWithConfirm,
      icon: <TrashIcon size={12} />,
      intent: "danger",
      accessTo: "ROLE_MODIFY_MERCHANT_PM",
    },
  ];

  return (
    <>
      <FormBuilder
        showSubmit={false}
        formItemsConfig={searchFormConfig}
        formProps={{
          submitBtnLabel: _t("apply_filters"),
          onSubmit: onFilterSubmit,
          initialValues: filterInitialValues,
          resultQuantity: data?.totalElements,
        }}
      />
      <Table
        totalRecords={data?.totalElements}
        onPagination={setPagination}
        columns={columns}
        isLoading={isLoading}
        data={data?.content || []}
        isPagination
        pageIndex={pagination.page}
        pageSize={pagination.perPage}
        cellProps={{
          actionMenuItems,
        }}
      />
    </>
  );
};

export default Methods;
