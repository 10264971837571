import React, { useState, useRef } from "react";
import { useTrans } from "system/translations/hooks";
import Btn from "components/atoms/Btn";
import { Pane, ExportIcon } from "evergreen-ui";
import SectionTitle from "components/atoms/SectionTitle";
import Transactions from "modules/transactions/components/Transactions";
import Tabs from "components/atoms/Tabs";
import styles from "./styles";
import { useGetTransactionsExport } from "modules/transactions/apiHooks";

const TransactionsTabs = () => {
  const { _t } = useTrans();
  const pendingTransactionRef: any = useRef(null);
  const allTransactionRef: any = useRef(null);
  const [selectedTab, setSelected] = useState("pending");
  const { mutate: onGetExport, isLoading: isLoadingExport } =
    useGetTransactionsExport();
  const tabs = {
    pending: {
      name: "pending",
      header: _t("tab_pending"),
      headerProps: {},
      body: Transactions,
      bodyProps: {
        tableName: "pending",
        transactionStatus: "pending_transactions",
        ref: pendingTransactionRef,
        initialFilters: {
          status: "PENDING",
        },
      },
    },
    transaction_history: {
      name: "transaction_history",
      header: _t("all_transactions"),
      headerProps: {},
      body: Transactions,
      bodyProps: {
        tableName: "transaction_history",
        transactionStatus: "history_transactions",
        ref: allTransactionRef,
        initialFilters: {
          status: "APPROVED,WAITING,CANCELED,DECLINED,ERROR",
        },
      },
    },
  };
  const onExport = () => {
    const tabRef: any =
      selectedTab === "pending" ? pendingTransactionRef : allTransactionRef;
    if (tabRef?.current) {
      const filters = tabRef?.current?.getFilters();
      onGetExport(filters);
    }
  };

  return (
    <div css={styles.mobileHeaderBackground}>
      <SectionTitle
        title={_t("transactions")}
        extra={
          <Pane display="flex" flexDirection="row" gap={"10px"}>
            <Btn
              kind="default"
              onClick={onExport}
              iconBefore={ExportIcon}
              isLoading={isLoadingExport}
              disabled={isLoadingExport}
            >
              {_t("export_CSV")}
            </Btn>
          </Pane>
        }
      />
      <Tabs tabs={tabs} onChange={setSelected} selected={selectedTab} />
    </div>
  );
};

export default TransactionsTabs;
