import React from "react";
import { FiltersSummaryProps } from "./types";
import { css } from "@emotion/react";
import styles from "components/atoms/FiltersSummary/styles";
import { FilterIcon } from "evergreen-ui";
import { useTrans } from "system/translations/hooks";

const FiltersSummary: React.FC<FiltersSummaryProps> = ({
  filterQuantity,
  resultQuantity,
  onClear,
}) => {
  const { _t } = useTrans();
  if (!filterQuantity) {
    return null;
  }
  return (
    <div css={css(styles.wrapper)}>
      <div css={css(styles.mobileWrapper)}>
      <FilterIcon />
      <span css={css(styles.label)}>
        {_t("applied_filters_{filterQuantity}", {
          filterQuantity,
        })}{" "}
        {resultQuantity !== undefined &&
          _t("results_{resultQuantity}", {
            resultQuantity,
          })}
      </span>
      </div>
      <span onClick={onClear} css={css(styles.clear)}>
        {_t("clear_all")}
      </span>
    </div>
  );
};

export default FiltersSummary;
