import React from "react";
import { DatabaseIcon } from "evergreen-ui";
import themeOptions from "system/theme";

const NoData: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className={"no-data"}>
      <div className={"no-data__icon-wrap"}>
        <DatabaseIcon color={themeOptions.colors.gray400} />
      </div>
      <div className={"no-data__text"}>{text}</div>
    </div>
  );
};

export default NoData;
