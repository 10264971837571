import React, { useMemo } from "react";
import Card from "components/atoms/Card";
import { css } from "@emotion/react";
import Input from "components/atoms/Input";
import Select from "components/atoms/Select";
import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";
import DotsBG from "images/dots-bg.jpg";
import { useTrans } from "system/translations/hooks";
import InfoList from "components/atoms/InfoList";
import { Heading, Text, UploadIcon } from "evergreen-ui";
import Btn from "components/atoms/Btn";
import Divider from "components/atoms/Divider";
import FilesPreview from "modules/formTemplates/components/FilesPreview";

type FieldItemType = {
  name: string;
  type: string;
  label: string;
  placeholder?: string;
  files?: any;
  condition: string;
  value: string;
  fieldWidth?: string;
  fieldAlign?: string;
  textAlign?: string;
  rules?: { type: string; value: string; message: string }[];
  options?: { label: string; value: string }[];
};
const styles = ({
  primaryColor,
  secondaryColor,
  headlineColor,
  paragraphColor,
  errorColor,
  mainBgColor,
  fieldsBorderColor,
  resetButtonActiveColor,
  submitButtonActiveColor,
  verticalInnerSpace = 0,
  horizontalInnerSpace = 0,
  fieldsSpace = 0,
  bgBorderRadius = 0,
  fieldsBorderRadius = 0,
  actionBtnColor,
  actionBtnActiveColor,
}: any) =>
  createStyles({
    fieldsWrap: {
      padding: `${verticalInnerSpace} ${horizontalInnerSpace}`,
      background: mainBgColor || themeOptions.colors.white,
      borderRadius: bgBorderRadius,
      display: "flex",
      flexWrap: "wrap",
    },
    fieldItem: ({
      fieldWidth = "100%",
      fieldAlign = `0 5px 0 5px`,
      textAlign = "left",
      marginBottom,
    }: any) => ({
      margin: fieldAlign,
      marginBottom: marginBottom || fieldsSpace,
      width: `calc(${fieldWidth} - 10px)`,
      textAlign: textAlign,
      label: {
        color: paragraphColor,
      },
      button: {
        background: "transparent",
        borderColor: `${fieldsBorderColor} !important`,
        borderRadius: fieldsBorderRadius,
        "&:active, &:focus, &:hover": {
          borderColor: `${primaryColor} !important`,
          boxShadow: "none !important",
          background: "transparent !important",
          color: "#000",
        },
        '&[class*="-Btn-resetButton"]': {
          color: paragraphColor,
          borderColor: `${primaryColor} !important`,
          background: "transparent",
          "&:hover, &:active": {
            background: `${resetButtonActiveColor} !important`,
            borderColor: `${resetButtonActiveColor} !important`,
          },
        },
        '&[class*="-Btn-submitButton"]': {
          color: "#fff",
          borderColor: `${primaryColor} !important`,
          background: `${primaryColor} !important`,
          "&:hover, &:active": {
            background: `${submitButtonActiveColor} !important`,
            borderColor: `${submitButtonActiveColor} !important`,
          },
        },
        '&[class*="-Btn-actionButton"]': {
          color: "#fff",
          borderColor: `${actionBtnColor} !important`,
          background: `${actionBtnColor} !important`,
          "&:hover, &:active": {
            background: `${actionBtnActiveColor} !important`,
            borderColor: `${actionBtnActiveColor} !important`,
          },
        },
      },
      '[class*="-itemValue-InfoList"]': {
        color: primaryColor,
      },
      '[class*="-divider-line"]': {
        borderColor: fieldsBorderColor,
      },
      '[class*="-InfoList"]': {
        background: secondaryColor,
        borderRadius: fieldsBorderRadius,
      },
      headline: {
        color: headlineColor,
      },
      paragraph: {
        color: paragraphColor,
      },
    }),
    resetButton: {
      label: "resetButton",
      borderColor: `${primaryColor} !important`,
    },
    submitButton: {
      label: "submitButton",
    },
    actionButton: {
      label: "actionButton",
    },
    inputStyles: {
      borderRadius: fieldsBorderRadius,
      borderColor: fieldsBorderColor,
      "&:active, &:focus, &:hover": {
        borderColor: primaryColor,
        boxShadow: "none",
      },
    },
    fieldsTitle: {
      textAlign: "center",
      marginBottom: "20px",
      color: paragraphColor,
    },
  });

const FieldsPreview: React.FC<{
  fields: FieldItemType[];
  customStyles?: any;
}> = ({ fields, customStyles }) => {
  const { _t } = useTrans();
  const generalStyles = useMemo(() => {
    return styles({
      primaryColor: customStyles?.primaryColor,
      secondaryColor: customStyles?.secondaryColor,
      resetButtonActiveColor: customStyles?.resetButtonActiveColor,
      submitButtonActiveColor: customStyles?.submitButtonActiveColor,
      headlineColor: customStyles?.headlineColor,
      paragraphColor: customStyles?.paragraphColor,
      errorColor: customStyles?.errorColor,
      mainBgColor: customStyles?.mainBgColor,
      fieldsBorderColor: customStyles?.fieldsBorderColor,
      verticalInnerSpace: customStyles?.verticalInnerSpace,
      horizontalInnerSpace: customStyles?.horizontalInnerSpace,
      fieldsSpace: customStyles?.fieldsSpace,
      bgBorderRadius: customStyles?.bgBorderRadius,
      fieldsBorderRadius: customStyles?.fieldsBorderRadius,
      actionBtnColor: customStyles?.actionBtnColor,
      actionBtnActiveColor: customStyles?.actionBtnActiveColor,
    });
  }, [customStyles]);

  if (!fields || !fields?.length) {
    return null;
  }
  return (
    <Card
      wrapperClass={css({
        position: "relative",
        padding: "40px",
        backgroundImage: `url(${DotsBG})`,
        backgroundSize: "50%",
      })}
    >
      <div css={css(generalStyles.fieldsWrap)}>
        {customStyles?.fieldsTitle && (
          <div css={css(generalStyles.fieldsTitle)}>
            {customStyles?.fieldsTitle}
          </div>
        )}
        {fields?.map((item: FieldItemType, idx) => {
          if (item?.type?.includes("input")) {
            return (
              <div
                key={idx + item.name}
                css={css(
                  generalStyles.fieldItem({
                    fieldWidth: item.fieldWidth,
                    fieldAlign: item.fieldAlign,
                    textAlign: item.textAlign,
                  })
                )}
              >
                <Input
                  additionalStyles={generalStyles.inputStyles}
                  value={item?.value}
                  label={item.label}
                  placeholder={item.placeholder}
                />
              </div>
            );
          }
          if (item?.type?.includes("files")) {
            return (
              <div
                key={idx + item.name}
                css={css(
                  generalStyles.fieldItem({
                    fieldWidth: item.fieldWidth,
                    fieldAlign: item.fieldAlign,
                    textAlign: item.textAlign,
                  })
                )}
              >
                <FilesPreview files={item?.files} />
              </div>
            );
          }
          if (item?.type?.includes("select")) {
            return (
              <div
                key={idx + item.name}
                css={css(
                  generalStyles.fieldItem({
                    fieldWidth: item.fieldWidth,
                    fieldAlign: item.fieldAlign,
                    textAlign: item.textAlign,
                  })
                )}
              >
                <Select
                  onChange={() => {}}
                  additionalStyles={generalStyles.inputStyles}
                  value={item?.value}
                  label={item.label}
                  placeholder={item.placeholder}
                  options={item.options}
                />
              </div>
            );
          }
          if (item?.type?.includes("infoList")) {
            return (
              <div
                key={idx + item.name}
                css={css(
                  generalStyles.fieldItem({
                    fieldWidth: item.fieldWidth,
                    fieldAlign: item.fieldAlign,
                    textAlign: item.textAlign,
                  })
                )}
              >
                <InfoList options={item.options} />
              </div>
            );
          }
          if (item?.type?.includes("headline")) {
            return (
              <div
                key={idx + item.name}
                css={css(
                  generalStyles.fieldItem({
                    fieldWidth: item.fieldWidth,
                    fieldAlign: item.fieldAlign,
                    textAlign: item.textAlign,
                  })
                )}
              >
                <Heading className={"headline"}>{item.value}</Heading>
              </div>
            );
          }
          if (item?.type?.includes("paragraph")) {
            return (
              <div
                key={idx + item.name}
                css={css(
                  generalStyles.fieldItem({
                    fieldWidth: item.fieldWidth,
                    fieldAlign: item.fieldAlign,
                    textAlign: item.textAlign,
                  })
                )}
              >
                <Text className={"paragraph"}>{item.value}</Text>
              </div>
            );
          }
          if (item?.type?.includes("divider")) {
            return (
              <div
                key={idx + item.name}
                css={css(
                  generalStyles.fieldItem({
                    fieldWidth: item.fieldWidth,
                  })
                )}
              >
                <Divider dashed additionalClass={css(`margin: 0`)} />
              </div>
            );
          }
          if (item?.type?.includes("reset_button")) {
            return (
              <div
                key={idx + item.name}
                css={css(
                  generalStyles.fieldItem({
                    fieldWidth: item.fieldWidth,
                    fieldAlign: item.fieldAlign,
                    textAlign: item.textAlign,
                  })
                )}
              >
                <Btn css={css(generalStyles.resetButton)} width={"100%"}>
                  <span>{item.value}</span>
                </Btn>
              </div>
            );
          }
          if (item?.type?.includes("submit_button")) {
            return (
              <div
                key={idx + item.name}
                css={css(
                  generalStyles.fieldItem({
                    fieldWidth: item.fieldWidth,
                    fieldAlign: item.fieldAlign,
                    textAlign: item.textAlign,
                  })
                )}
              >
                <Btn css={css(generalStyles.submitButton)} width={"100%"}>
                  <span>{item.value}</span>
                </Btn>
              </div>
            );
          }
          if (item?.type?.includes("upload_button")) {
            console.log("item", item);
            return (
              <div
                key={idx + item.name}
                css={css(
                  generalStyles.fieldItem({
                    fieldWidth: item.fieldWidth,
                    fieldAlign: item.fieldAlign,
                    textAlign: item.textAlign,
                  })
                )}
              >
                <Btn
                  iconBefore={UploadIcon}
                  css={css(generalStyles.actionButton)}
                  width={"100%"}
                >
                  <span>{item.value}</span>
                </Btn>
              </div>
            );
          }
          return false;
        })}
      </div>
    </Card>
  );
};

export default FieldsPreview;
