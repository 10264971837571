import { isValidEmail, isValidPassword } from "system/helpers/validationHelper";
import * as Yup from "yup";
import { CreateEditUserValidationParams } from "./types";
import { TransType } from "system/translations/types";

export const createEditUserValidation = (
  _t: TransType,
  params: CreateEditUserValidationParams
) => {
  return Yup.object().shape({
    /*    admin: Yup.boolean().required(_t("required")),*/
    name: Yup.string().required(_t("required")),
    password: !params.isEdit
      ? isValidPassword(_t).required(_t("required"))
      : isValidPassword(_t).notRequired(),
    securityGroupId: Yup.number().required(_t("required")),
    email: isValidEmail(_t),
    merchants: Yup.array()
      .of(Yup.number())
      .min(1, _t("missed_merchant"))
      .required(_t("missed_merchant")),
  });
};
