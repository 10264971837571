import { Column as ColumnType } from "react-table";
import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "services/formBuilder/types";
import { useDefaultFieldsConfig } from "services/formBuilder/hooks";
import DotsMenu from "components/atoms/DotsMenu";
import { CreateEditFormConfigParams } from "modules/countries/types";
import InfoTag from "components/atoms/InfoTag";
import { statusOptions } from "modules/countries/constants";

export const useTableColumns = (): ColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): ColumnType[] => [
      {
        Header: _t("code"),
        accessor: "code",
        id: "codeLiteral",
      },
      {
        Header: _t("country_name"),
        accessor: "name",
        id: "description",
      },
      {
        Header: _t("status"),
        Cell: (props: any) => {
          const status = props?.row?.original?.enabled
            ? "active"
            : "deactivated";
          const label = props?.row?.original?.enabled ? "Enabled" : "Disabled";
          return (
            <>
              <InfoTag
                type={"secondary"}
                size={"sm"}
                status={status}
                label={label}
              />
            </>
          );
        },
      },
      {
        maxWidth: 65,
        Header: "",
        id: "UpdateDeleteMenu",
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const options = statusOptions(_t);
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.filterValue({
        componentProps: { showSearch: true, label: _t("search") },
        columnParams: {
          md: 4,
          lg: 4,
        },
      }),
      fields.filters.status({
        componentProps: { options },
      }),
      fields.general.submitBtn(),
    ];
  }, []);
  return formConfig;
};

export const useCreateEditConfig = (
  params: CreateEditFormConfigParams
): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.code(),
      fields.formFields.name(),
      fields.formFields.enabled(),
      fields.general.divider(),
      fields.general.saveBtn(),
    ];
  }, []);
  return formConfig;
};
