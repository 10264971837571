import React from "react";
import { Content, Grid, Row, Column } from "carbon-components-react";
import HeaderWrapper from "../../components/organisms/Header";
import { useTransactionSocket } from "services/websocket/hooks";
import themeOptions from "system/theme";
import { isMobile } from "system/theme/globalStyles";

export const MainTemplate: any = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  useTransactionSocket();
  const leftMargin = isMobile ? 0 : "240px";

  return (
    <>
      <HeaderWrapper />
      <Content
        style={{
          padding: "20px",
          paddingTop: themeOptions.topIndent,
          paddingBottom: 0,
          marginLeft: leftMargin,
        }}
      >
        <Grid>
          <Row>
            <Column>
              <div style={{ position: "relative" }}>{children}</div>
            </Column>
          </Row>
        </Grid>
      </Content>
    </>
  );
};

export default MainTemplate;
