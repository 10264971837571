import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  wrapper: {
    display: "flex",
    flexDirection: "revert",
    background: themeOptions.colors.white,
    padding: `${themeOptions.spacing.xs} ${themeOptions.spacing.sm}`,
    alignItems: "center",
    color: themeOptions.colors.gray600,
    fontSize: "12px",
    marginBottom: themeOptions.spacing.xxs,
    borderRadius: themeOptions.borders.xs,
    [themeOptions.maxMedia.md]: {
      justifyContent: "space-between"
    }
  },
  label: { marginLeft: themeOptions.spacing.xs },
  clear: {
    cursor: "pointer",
    marginLeft: themeOptions.spacing.md,
    color: themeOptions.colors.blue400,
  },
  mobileWrapper: {
    display: "flex",
    alignItems: "center",

  }
});

export default styles;
