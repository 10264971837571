import React from "react";
import { Pane, Tab, Tablist } from "evergreen-ui";
import { TabItem, TabsProps } from "components/atoms/Tabs/types";
import themeOptions from "system/theme";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";

const Tabs: React.FC<TabsProps> = ({
  tabs,
  selected,
  forceTab = false,
  onChange,
  appearance = "primary",
}) => {
  const [selectedTab, setSelectedTab] = React.useState(selected);
  const selectTabHandler = (tab: string) => {
    setSelectedTab(tab);
    onChange && onChange(tab);
  };
  return (
    <>
      <Tablist
        borderBottom={`1px solid ${themeOptions.colors.gray300}`}
        marginBottom={themeOptions.spacing.sm}
      >
        {Object.values(tabs).map((tab: any, index) => {
          return (
            <RoleWrapper accessTo={tab?.accessTo}>
              <Tab
                appearance={appearance}
                key={tab.name}
                id={tab.name}
                onSelect={() => selectTabHandler(tab.name)}
                isSelected={tab.name === selectedTab}
                aria-controls={`panel-${tab}`}
              >
                {tab.header}
              </Tab>
            </RoleWrapper>
          );
        })}
      </Tablist>
      <Pane padding={0} flex="1">
        {Object.values(tabs).map((tab: TabItem, index) => {
          const Component = tab.body;
          if (forceTab && tab.name !== selectedTab) {
            return false;
          }
          return (
            <RoleWrapper accessTo={tab?.accessTo}>
              <Pane
                key={tab.name}
                id={`panel-${tab.name}`}
                role="tabpanel"
                aria-labelledby={tab.name}
                aria-hidden={tab.name !== selectedTab}
                display={tab.name === selectedTab ? "block" : "none"}
              >
                <Component {...tab.bodyProps} />
              </Pane>
            </RoleWrapper>
          );
        })}
      </Pane>
    </>
  );
};

export default Tabs;
