import React, { useRef, useState } from "react";
import { useTrans } from "system/translations/hooks";
import { useCreateFormTemplate } from "../apiHooks";
import {
  createEditValidation,
  createEditGeneralValidation,
} from "../validation";
import {
  useCreateEditFieldsConfig,
  useCreateEditGeneralConfig,
  useCreateEditStylesConfig,
} from "../constantHooks";
import { Heading } from "evergreen-ui";
import FormView from "modules/formTemplates/components/FormView";
import { getSubmittedData, useChangeHandlers, useShowFunctions } from "../hooks";

const Create: React.FC = () => {
  const initialStyles = {
    primaryColor: "#000",
    secondaryColor: "#000",
    headlineColor: "#000",
    textColor: "#ddd",
    errorColor: "#ff0000",
    mainBgColor: "#fff",
    fieldsBorderColor: "#000",
    verticalInnerSpace: "4",
    horizontalInnerSpace: "4",
    fieldsSpace: "4",
    bgBorderRadius: "4",
    fieldsBorderRadius: "4",
  };
  const isEdit = false;
  const generalFormRef: any = useRef(null);
  const fieldsFormRef: any = useRef(null);
  const stylesFormRef: any = useRef(null);
  const formGeneralConfig = useCreateEditGeneralConfig({ isEdit });
  const formStylesConfig = useCreateEditStylesConfig({ isEdit });
  const formFieldsConfig = useCreateEditFieldsConfig({ isEdit });
  const [fields, setFields] = useState<any>([]);
  const [styles, setStyles] = useState<any>(initialStyles);
  const [selectedTab, setSelectedTab] = useState("fields");
  const { _t } = useTrans();
  const { mutateAsync: onCreate, isLoading } = useCreateFormTemplate();
  const getHeaderComponent = ({ title = _t("fields_order") }: any) => <Heading size={700}>{title}</Heading>
  const { showOrder, showFields, showStyles } = useShowFunctions({
    fieldsFormRef,
    stylesFormRef,
    getHeaderComponent,
    styles,
    fields,
    _t,
  })
  const { onFieldsValuesChange, onStylesValuesChange }  = useChangeHandlers({ setFields, setStyles })

  const onSubmit = async (data: any) => {
    generalFormRef?.current?.formik?.handleSubmit();
    if (generalFormRef?.current?.formik?.isValid) {
      const { reqData }  = getSubmittedData({
        generalFormRef,
        data,
        styles,
      })
      await onCreate(reqData);
    }
  };

  return (
    <>
      <FormView
        sectionTitle={_t("create_new_template")}
        generalFormRef={generalFormRef}
        generalFormConfig={formGeneralConfig}
        generalFormInitialValues={{
          enabled: true,
        }}
        generalFormValidationSchema={createEditGeneralValidation(_t, {
          isEdit,
        })}
        fields={fields}
        fieldsFormRef={fieldsFormRef}
        fieldsFormConfig={formFieldsConfig}
        fieldsFormInitialValues={{}}
        fieldsFormCreateEditValidation={createEditValidation(_t, {
          isEdit,
        })}
        onFieldsValuesChange={onFieldsValuesChange}
        stylesFormRef={stylesFormRef}
        stylesFormConfig={formStylesConfig}
        stylesFormInitialValues={{ styles: initialStyles }}
        onStylesValuesChange={onStylesValuesChange}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        showOrder={showOrder}
        showFields={showFields}
        showStyles={showStyles}
        customStyles={styles?.styles}
        onSubmit={onSubmit}
      />

      {/*      <SectionTitle title={_t("create_new_template")} />
      <Card
        wrapperClass={css({
          marginBottom: themeOptions.spacing.sm,
          padding: themeOptions.spacing.sm,
          paddingBottom: themeOptions.spacing.xs,
        })}
      >
        <FormBuilder
          showSubmit={false}
          formItemsConfig={formGeneralConfig}
          ref={generalFormRef}
          formProps={{
            onSubmit: () => {},
            initialValues: {
              enabled: true,
            },
            validationSchema: createEditGeneralValidation(_t, {
              isEdit,
            }),
            rowStyles: { alignItems: "flex-end" },
          }}
        />
      </Card>
      <Row>
        <Col lg={7} md={12}>
          <div
            style={{
              background: themeOptions.colors.white,
              padding: "10px 16px",
              borderRadius: themeOptions.borders.sm,
              display: "grid",
              gridTemplateColumns: "1fr 60px",
              alignItems: "center",
            }}
          >
            <Tablist>
              <Tab
                onSelect={() => setSelectedTab("fields")}
                key={"fields"}
                id={"fields"}
                aria-controls={`panel-fields`}
                isSelected={"fields" === selectedTab}
              >
                {_t("fields_settings")}
              </Tab>
              <Tab
                onSelect={() => setSelectedTab("styles")}
                key={"styles"}
                id={"styles"}
                aria-controls={`panel-styles`}
                isSelected={"styles" === selectedTab}
              >
                {_t("styles")}
              </Tab>
            </Tablist>
            <DrawerSettings
              showOrder={showOrder}
              showFields={showFields}
              showStyles={showStyles}
            />
          </div>
          <Pane
            key={"fields"}
            id={`panel-fields`}
            role="tabpanel"
            aria-labelledby={fields}
            display={"fields" === selectedTab ? "block" : "none"}
          >
            <FormBuilder
              showSubmit={false}
              formItemsConfig={formFieldsConfig}
              ref={fieldsFormRef}
              formProps={{
                onSubmit: onSubmit,
                initialValues: {},
                onFormValuesChange: onFieldsValuesChange,
                validationSchema: createEditValidation(_t, {
                  isEdit,
                }),
              }}
            />
          </Pane>
          <Pane
            key={"styles"}
            id={`panel-styles`}
            role="tabpanel"
            aria-labelledby={fields}
            display={"styles" === selectedTab ? "block" : "none"}
          >
            <FormBuilder
              showSubmit={false}
              formItemsConfig={formStylesConfig}
              ref={stylesFormRef}
              formProps={{
                onSubmit: () => {},
                onFormValuesChange: onStylesValuesChange,
                initialValues: { styles: initialStyles },
                rowStyles: { alignItems: "flex-end" },
              }}
            />
          </Pane>
        </Col>
        <Col lg={5} md={12}>
          <FieldsPreview fields={fields?.fields} customStyles={styles} />
        </Col>
      </Row>*/}
    </>
  );
};

export default Create;
