import React, { useEffect } from "react";
import * as ace from "brace";
import "brace/mode/javascript";
import "brace/theme/monokai";

const JsonEditor = ({
  value,
  onChange,
  labelText,
  id = "javascript-editor",
}: any) => {
  let editor: any = null;
  const onChangeHandler = (value: any) => {
    onChange && onChange(value);
    /*    try {
      JSON.parse(value);
      /!*  setFieldValid(true);*!/
    } catch (e) {
      /!*  setFieldValid(false);*!/
    }*/
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    editor = ace.edit(id);
    editor.$blockScrolling = Infinity;
    editor.getSession().setMode("ace/mode/javascript");
    if (value) {
      try {
        const val = JSON.parse(value);
        editor.setValue(JSON.stringify(val, null, 2));
      } catch (e) {
        editor.setValue(value, null, 2);
      }
    }
    editor.addEventListener("change", () => onChangeHandler(editor.getValue()));

    return () => {
      editor && editor.destroy();
      editor.removeEventListener("change", () =>
        onChangeHandler(editor.getValue())
      );
    };
  }, []);

  return (
    <>
      {labelText}
      <div
        style={{ height: "200px" }}
        id={id}
        className={"json-editor-container"}
      />
    </>
  );
};

export default JsonEditor;
