import React from "react";
import { useModal } from "services/modal/hooks";
import { useTrans } from "system/translations/hooks";
import { ConfirmArgTypes, ConfirmResTypes } from "services/modal/types";
import ConfirmModalButtons from "components/atoms/ActionButtons";
import ConfirmModalBody from "components/atoms/ConfirmModalBody";

export const useConfirmModal = (): ConfirmResTypes => {
  const { showModal, hideModal } = useModal();
  const { _t } = useTrans();

  const onConfirm = (params: ConfirmArgTypes) => {
    const onOk = () => {
      params.onOk && params.onOk();
      hideModal();
    };
    const onCancel = () => {
      hideModal();
    };
    showModal({
      size: "sm",
      title: params?.title || _t("are_you_sure"),
      component: ConfirmModalBody,
      componentProps: {
        text: params?.bodyText,
      },
      isShowScale: Object.keys(params).includes("isShowScale")
        ? params?.isShowScale
        : true,
      footer: ConfirmModalButtons,
      footerProps: {
        onOk: onOk,
        onCancel: onCancel,
        onOkText: params?.onOkText ? params?.onOkText : _t("on_ok"),
        onOkKind: params?.onOkKind,
        iconBefore: params?.iconBefore,
      },
    });
  };

  return { onConfirm };
};
