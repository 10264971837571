import React, { useReducer } from "react";
import mainReducer, { initialState } from "system/store/reducer";

const StoreContext: any = React.createContext(null);
const StoreProvider = ({ children }: { children?: React.ReactNode }) => {
  const [store, dispatch] = useReducer(mainReducer, initialState);

  return (
    <StoreContext.Provider value={{ store, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
export { StoreContext };
