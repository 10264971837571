import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const sidebarMobWidth = '100vw';

const styles = createStyles({
  container: {
    position: "fixed",
    zIndex: 19,
    top: "60px",
    bottom: 0,
    left: 0,
    overflow: "hidden",
    maxWidth: "240px",
    height: "100%",
    background: themeOptions.colors.c1,
  },
  containerMob: {
    top: "47px",
    width: "100%",
    maxWidth: sidebarMobWidth,
    boxShadow: themeOptions.boxShadow.main,
    height: "100%",
  },
  minimized: {
    left: `-${sidebarMobWidth}`,
  },
  menuItem: {
    backgroundColor: "transparent",
    paddingLeft: themeOptions.spacing.xxs,
    "&:hover, &:active": {
      backgroundColor: `${themeOptions.colors.gray200} !important`,
    },
  },
  overlay: {
    position: "fixed",
    zIndex: 18,
    top: "47px",
    background: "red",
    height: "100%",
    width: "100%",
    backgroundColor: "rgb(0 0 0 / 41%)",
  },
  activeMenuItem: {
    backgroundColor: `${themeOptions.colors.gray200} !important`,
    "& > div": {
      svg: {
        "*": {
          fill: themeOptions.colors.blue400,
        },
      },
    },
  },
});

export default styles;
