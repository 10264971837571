import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";
import { Pane } from "evergreen-ui";
import React from "react";

const styles = createStyles({
  container: {
    display: "flex",
    alignItems: "center",
    gap: themeOptions.spacing.xs,
    height: "max-content",
    marginBottom: "6px"
  },
});

export default styles;
