import React, { useEffect, useMemo, useRef, useState } from "react";
import { RadioButton } from "carbon-components-react";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import Input from "components/atoms/Input";
import { format, parseISO } from "date-fns";
import { useOutsideClick } from "system/helpers/hooks";
import styles from "components/molecules/DateRange/styles";
import { CalendarIcon, CaretRightIcon, Radio } from "evergreen-ui"; // theme css file
import { useTrans } from "system/translations/hooks";
import { useDrawer } from "services/drawer/hooks";
import HeaderMobileFilters from "../HeaderMobileFilters";
import DateRangeMobile from "../DateRangeMobile";
import { isMobile } from "system/theme/globalStyles";

const DateRange = ({
  value,
  onChange,
  name,
  fromName,
  toName,
  onFormValueChange,
  dateFormat,
  showPeriodSwitcher = true,
}: any) => {
  const checkDate = (date: string) => {
    const parsedDate: any = parseISO(date);
    return parsedDate != "Invalid Date" ? parsedDate : new Date();
  };
  const handlerChange = (val: any) => {
    const from = val["selection"].startDate; //&& format(val["selection"].startDate, "yyyy-MM-dd");
    const to = val["selection"].endDate; //&& format(val["selection"].endDate, "yyyy-MM-dd");
    onChange(format(from, dateFormat), `${name}.${fromName}`);
    onChange(format(to, dateFormat), `${name}.${toName}`);
  };
  const ref = useRef(null);
  const [clickOutSide] = useOutsideClick(ref);
  const data = useMemo(() => {
    return {
      startDate: checkDate(value?.[fromName]),
      endDate: checkDate(value?.[toName]),
    };
  }, [value, fromName, toName]);
  useEffect(() => {
    setShow(false);
  }, [clickOutSide]);
  const [type, setType] = useState("created");
  const [show, setShow] = useState(false);
  const { _t } = useTrans();
  const { showDrawer, hideDrawer, updateDrawer } = useDrawer()
  useEffect(() => {
    onFormValueChange("searchFrom", type);
  }, [type]);
  const renderStaticRangeLabel = () => (
    <div>
      Show period since:
      <div>
        <RadioButton
          onClick={() => setType("created")}
          checked={type === "created"}
          name={"created"}
          labelText={"Transaction was created"}
        />
        <RadioButton
          onClick={() => setType("updated")}
          checked={type === "updated"}
          name={"updated"}
          labelText={"Last updated"}
        />
      </div>
    </div>
  );

  useEffect(() => {    
    updateDrawer({
      header: HeaderMobileFilters,
      headerProps: {
        title: _t("date_range"),
        onClose: () => hideDrawer("dateRange"),
      },
      componentProps: {
        value,
        onChange,
        name,
        fromName,
        toName,
        onFormValueChange,
        dateFormat,
      },
      component: DateRangeMobile,
    }, "dateRange")
  },[value]);

  const showDateRange = () => {
    showDrawer({
      header: HeaderMobileFilters,
      headerProps: {
        title: _t("date_range"),
        onClose: () => hideDrawer("dateRange"),
      },
      componentProps: {
        value,
        onChange,
        name,
        fromName,
        toName,
        onFormValueChange,
        dateFormat,
      },
      component: DateRangeMobile,
    },"dateRange");
  }

  return (
    <div css={styles.wrapper} ref={ref}>
      <div onClick={() => isMobile ? showDateRange() : setShow(!show)}>
        {isMobile 
          ? <div css={styles.mobileContainer}>
              <div>
                <div css={styles.mobileTitleText}>{_t("date_range")}:</div>
                <div css={styles.mobileSubtitleText}>
                  {`${format(data?.startDate, dateFormat)} - ${format(
                    data?.endDate,
                    dateFormat
                  )}`}
                </div>
              </div>
              <CaretRightIcon /> 
          </div>
          : <div css={styles.valuePreview}>
            {format(data?.startDate, dateFormat)} -{" "}
            {format(data?.endDate, dateFormat)}
            <CalendarIcon css={styles.valuePreviewIcon} size={12} />
          </div>
        }
      </div>
      {show ? (
        <div css={styles.container} className={"date-range__body"}>
          <DateRangePicker
            onChange={(item) => handlerChange(item)}
            months={2}
            moveRangeOnFirstSelection={false}
            renderStaticRangeLabel={renderStaticRangeLabel}
            ariaLabels={{}}
            inputRanges={[]}
            ranges={[
              {
                startDate: data?.startDate,
                endDate: data?.endDate,
                key: "selection",
              },
            ]}
            /*            staticRanges={[
              {
                hasCustomRendering: true,
                range: () => ({}),
                isSelected() {
                  return false;
                },
              },
              ...defaultStaticRanges,
            ]}*/
            direction="horizontal"
            // direction="vertical"
          />
          {showPeriodSwitcher ? (
            <div css={styles.period}>
              <Radio
                size={16}
                onClick={() => setType("created")}
                checked={type === "created"}
                name={"created"}
                label="Transaction was created"
              />
              <Radio
                size={16}
                onClick={() => setType("updated")}
                checked={type === "updated"}
                name={"updated"}
                label="Last updated"
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default DateRange;
