import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  container: {
    padding: `${themeOptions.spacing.xs} ${themeOptions.spacing.sm}`,
    background: themeOptions.colors.white,
    color: themeOptions.colors.gray800,
    borderRadius: themeOptions.borders.xs,
    width: "320px",
    display: "grid",
    gridTemplateColumns: "24px 1fr 20px",
    alignItems: "flex-start",
    gap: "10px",
    boxShadow: themeOptions.boxShadow.main,
  },
  body: {},
  bodyTitle: {},
  bodyDescription: {},
  close: {
    justifySelf: "flex-end",
    alignSelf: "center",
    cursor: "pointer",
    color: themeOptions.colors.gray700,
    "&:hover": {
      color: themeOptions.colors.gray800,
    },
  },
});

export default styles;
