import React from "react";
import FormBuilder from "services/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useCreatePayment } from "../apiHooks";
import Card from "../../../components/atoms/Card";
import { useHistory } from "react-router-dom";
import { createEditValidation } from "../validation";
import { useCreateEditConfig } from "../constantHooks";
import SectionTitle from "components/atoms/SectionTitle";
import { timeToDeclineDefault } from "../constants";

const Create: React.FC = () => {
  const isEdit = false;
  const { _t } = useTrans();
  const history = useHistory();
  const { mutateAsync: onCreate, isLoading } = useCreatePayment();
  const formConfig = useCreateEditConfig({ isEdit, isLoading });
  const onSubmit = async (data: any) => {
    if (data.type === "ALL") {
      await onCreate({ ...data, type: "DEPOSITS" });
      await onCreate({ ...data, type: "WITHDRAWALS" });
    } else {
      await onCreate(data);
    }
    history.goBack();
  };

  return (
    <>
      <SectionTitle title={_t("create_new_method")} />
      <Card>
        <FormBuilder
          showSubmit={false}
          formItemsConfig={formConfig}
          formProps={{
            onSubmit: onSubmit,
            initialValues: { timeToDecline: timeToDeclineDefault },
            validationSchema: createEditValidation(_t, {
              isEdit,
            }),
          }}
        />
      </Card>
    </>
  );
};

export default Create;
