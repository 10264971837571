import React from "react";
import Verified from "modules/auth/components/verified";
import NotLoggedTemplate from "templates/NotLogged";

const VerifiedPage = () => {
  return (
    <NotLoggedTemplate>
      <Verified />
    </NotLoggedTemplate>
  );
};

export default VerifiedPage;
