import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ArrowLeftIcon, Heading } from "evergreen-ui";
import themeOptions from "system/theme";
import { css } from "@emotion/react";
import { SectionTitleProps } from "components/atoms/SectionTitle/types";
import styles from "components/atoms/SectionTitle/styles";
import { useModifyCrumbsPathnames } from "services/breadcrumbs/hooks";
import Breadcrumbs from "services/breadcrumbs/components/Breadcrumbs";

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  backLocation,
  extra,
}) => {
  const location: any = useLocation();
  const { pathnamesModified, pathnamesOriginal } = useModifyCrumbsPathnames();
  const back = backLocation ? backLocation : location?.state?.pathname;
  const hasBreadcrumbs = pathnamesModified.length > 1;
  return (
    <>
      {hasBreadcrumbs && (
        <Breadcrumbs
          pathnamesModified={pathnamesModified}
          pathnamesOriginal={pathnamesOriginal}
        />
      )}
      <div
        css={css(
          styles.container,
          styles.withExtra,
          hasBreadcrumbs && styles.withBreadcrumbs
        )}
      >
        {title && (
          <div css={css(styles.title)}>
            {back && (
              <Link to={back}>
                <ArrowLeftIcon
                  marginRight={10}
                  color={themeOptions.colors.gray600}
                />
              </Link>
            )}
            <Heading size={800}>{title}</Heading>
          </div>
        )}
        {extra && <div css={css(styles.extra)}>{extra}</div>}
      </div>
    </>
  );
};

export default SectionTitle;
