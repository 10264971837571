import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import SectionTitle from "components/atoms/SectionTitle";
import Loader from "components/atoms/Loader";
import Card from "components/atoms/Card";
import { Text, HistoryIcon } from "evergreen-ui";
import FormBuilder from "services/formBuilder/FormBuilder";
import ActionLog from "modules/merchantPaymentMethods/components/ActionLog";
import {
  useGetMethodPaymentDetails,
  useUpdateMethodPaymentDetails,
} from "modules/merchantPaymentMethods/apiHooks";
import { createEditValidation } from "modules/merchantPaymentMethods/validation";
import { useEditDetailsConfig } from "modules/merchantPaymentMethods/constantHooks";
import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";
import { css } from "@emotion/react";
import { useModal } from "services/modal/hooks";

const styles = createStyles({
  lastUpdatedTitle: {
    color: themeOptions.colors.gray600,
    display: "flex",
    alignItems: "center",
    gap: themeOptions.spacing.xxs,
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
    },
  },
});

const PaymentDetails: React.FC = () => {
  const history = useHistory();
  const { _t } = useTrans();
  const { showModal } = useModal();
  const routeParams: any = useParams();
  const methodId = routeParams?.id;
  const { data, isLoading } = useGetMethodPaymentDetails({
    methodId,
  });
  const { mutateAsync: onUpdate, isLoading: isUpdating } =
    useUpdateMethodPaymentDetails();
  const initialData = useMemo(() => {
    return data?.map((item: any) => {
      let res: any = {
        enabled: item?.enabled,
        key: item?.key,
        merchantPaymentMethodId: item?.merchantPaymentMethodId,
        value: item?.value,
      };
      if (item?.id) {
        res["id"] = item?.id;
      }
      return res;
    });
  }, [data]);
  const formConfig = useEditDetailsConfig();
  const onSubmit = async (data: any) => {
    await onUpdate(data?.fields);
    /*    history.goBack();*/
  };
  const showActionLog = () => {
    showModal({
      componentProps: {
        methodId,
      },
      component: ActionLog,
      title: _t("action_log"),
      width: "90%",
    });
  };
  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <>
      <SectionTitle
        title={_t("payment_details")}
        extra={
          <Text css={css(styles.lastUpdatedTitle)} onClick={showActionLog}>
            <HistoryIcon size={12} />
            {_t("last_edited")}
          </Text>
        }
      />
      <Card>
        {isUpdating && <Loader formOverlay />}
        <FormBuilder
          formItemsConfig={formConfig}
          formProps={{
            onSubmit: onSubmit,
            initialValues: {
              fields: initialData,
            },
            validationSchema: createEditValidation(_t, {
              isEdit: false,
            }),
          }}
        />
      </Card>
    </>
  );
};

export default PaymentDetails;
