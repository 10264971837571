import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    alignItems: "center",
    gap: themeOptions.spacing.sm,
    marginBottom: themeOptions.spacing.sm,
    [themeOptions.maxMedia.md]: {
      gridTemplateColumns: "repeat(2, 1fr)",
      justifyContent: "start",
    },
  },
  title: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    alignItems: "center",
    gap: themeOptions.spacing.sm,
  },
  withExtra: {},
  withBreadcrumbs: { paddingTop: themeOptions.spacing.xlg },
  extra: { justifySelf: "end" },
});

export default styles;
