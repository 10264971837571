import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import FormBuilder from "services/formBuilder/FormBuilder";
import { useCreateMerchantBlackList } from "modules/merchantBlackList/apiHooks";
import { createEditValidation } from "modules/merchantBlackList/validation";
import { useCreateEditConfig } from "modules/merchantBlackList/constantHooks";
import { useDrawer } from "services/drawer/hooks";
import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";
import { css } from "@emotion/react";
import ActionButtons from "components/atoms/ActionButtons";

const styles = createStyles({
  wrapper: {
    display: "grid",
    height: "100%",
    gridTemplateRows: "1fr 72px",
  },
  container: {
    padding: themeOptions.spacing.lg,
  },
  buttonsWrapper: {
    padding: themeOptions.spacing.md,
    borderTop: `1px solid ${themeOptions.colors.gray300}`,
    display: "grid",
    justifyContent: "flex-end",
  },
});

const Create: React.FC<{ merchantId?: number }> = ({ merchantId }) => {
  const { _t } = useTrans();
  const formikRef: any = useRef(null);
  const { hideDrawer } = useDrawer();
  const { mutate: onCreate, isLoading } =
    useCreateMerchantBlackList(merchantId);
  const formConfig = useCreateEditConfig();
  const onSubmit = async () => {
    formikRef?.current?.formik?.handleSubmit();
    if (formikRef?.current?.formik?.isValid) {
      const values = formikRef?.current?.formik?.values;
      await onCreate(values);
      hideDrawer();
    }
  };

  return (
    <div css={css(styles.wrapper)}>
      <div css={styles.container}>
        <FormBuilder
          formItemsConfig={formConfig}
          showSubmit={false}
          ref={formikRef}
          formProps={{
            submitBtnLabel: _t("create"),
            onSubmit: onSubmit,
            initialValues: {},
            validationSchema: createEditValidation(_t, {
              isEdit: false,
            }),
          }}
        />
      </div>
      <div css={styles.buttonsWrapper}>
        <ActionButtons
          onOkKind={"secondary"}
          onOk={onSubmit}
          onCancel={hideDrawer}
          isLoading={isLoading}
          onOkText={_t("add_email_address")}
        />
      </div>
    </div>
  );
};

export default Create;
