import React, { useState } from "react";
import { useTrans } from "system/translations/hooks";
import { PlusIcon } from "evergreen-ui";
import SectionTitle from "components/atoms/SectionTitle";
import Methods from "modules/merchantPaymentMethods/components/Methods";
import Tabs from "components/atoms/Tabs";
import ButtonLink from "components/atoms/ButtonLink";
import { APP_ROUTES } from "system/router/constants";
import { generatePath, useParams } from "react-router-dom";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";

const MerchantPaymentMethods = () => {
  const { _t } = useTrans();
  const [selectedTab, setSelected] = useState("deposit");
  const routeParams: any = useParams();

  const tabs = {
    pending: {
      name: "deposit",
      header: _t("deposit"),
      headerProps: {},
      body: Methods,
      bodyProps: {
        tableName: "DEPOSITS",
      },
    },
    transaction_history: {
      name: "withdrawal",
      header: _t("withdrawal"),
      headerProps: {},
      body: Methods,
      bodyProps: {
        tableName: "WITHDRAWALS",
      },
    },
  };
  return (
    <>
      <SectionTitle
        title={_t("methods")}
        extra={
          <RoleWrapper accessTo={"ROLE_MODIFY_MERCHANT_PM"}>
            <ButtonLink
              kind={"secondary"}
              iconBefore={PlusIcon}
              linkTo={generatePath(
                APP_ROUTES.merchants.payment_methods.create,
                {
                  merchantId: routeParams?.merchantId,
                }
              )}
              label={_t("add_method")}
            />
          </RoleWrapper>
        }
      />
      <Tabs tabs={tabs} onChange={setSelected} selected={selectedTab} />
    </>
  );
};

export default MerchantPaymentMethods;
