import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  dividerContainer: {
    display: "flex",
    alignItems: "center",
    margin: `${themeOptions.spacing.sm} 0`,
  },
  dividerText: {
    height: "20px",
    fontSize: "12px",
    lineHeight: "16px",
    color: themeOptions.colors.gray600,
    paddingRight: themeOptions.spacing.sm,
    whiteSpace: "nowrap",
  },
  dividerLine: {
    width: "100%",
    height: "1px",
    background: themeOptions.colors.gray300,
  },
  dividerDashedLine: {
    background: "transparent",
    border: "1px dashed",
    borderColor: themeOptions.colors.gray300,
    label: "divider-line",
  },
});

export default styles;
