import React from "react";
import { DotsMenuItem, DotsMenuProps } from "./types";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { Popover, Menu, Position, MoreIcon } from "evergreen-ui";
import Btn from "components/atoms/Btn";
import themeOptions from "system/theme";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

const DotsMenu: React.FC<DotsMenuProps> = ({ items, clickParams = {} }) => {
  const getLinkWrapper = (id: number, getLink: any) => {
    let result = getLink ? getLink(id) : "";
    return result;
  };
  return (
    <Popover
      position={Position.BOTTOM_RIGHT}
      statelessProps={{
        padding: 0,
      }}
      content={
        <Menu>
          <Menu.Group>
            {items.map(
              (
                {
                  icon,
                  onClick,
                  title,
                  intent,
                  accessTo,
                  getLink,
                }: DotsMenuItem,
                idx
              ) => {
                return (
                  <RoleWrapper accessTo={accessTo}>
                    <Link
                      css={css({
                        textDecoration: "unset",
                        color: themeOptions.colors.gray600,
                      })}
                      to={getLinkWrapper(clickParams.id, getLink)}
                    >
                      <Menu.Item
                        css={css({
                          padding: 0,
                          color: themeOptions.colors.gray800,
                        })}
                        intent={intent}
                        icon={icon}
                        onClick={(e: any) => {
                          onClick && onClick(clickParams);
                          e.preventDefault();
                        }}
                      >
                        {title}
                      </Menu.Item>
                    </Link>
                    {idx + 1 !== items.length && <Menu.Divider />}
                  </RoleWrapper>
                );
              }
            )}
          </Menu.Group>
        </Menu>
      }
    >
      <div>
        <Btn
          boxShadow={"#fff"}
          borderColor={themeOptions.colors.gray300}
          kind={"minimal"}
          hasIconOnly
          iconBefore={<MoreIcon color={themeOptions.colors.gray600} />}
        />
      </div>
      {/*    <div>rfd</div>*/}
    </Popover>
    /*
      *     <Popover
      position={Position.BOTTOM_LEFT}
      content={
        <Menu>
          <Menu.Group>
            <Menu.Item icon={PeopleIcon}>Share...</Menu.Item>
            <Menu.Item icon={CircleArrowRightIcon}>Move...</Menu.Item>
            <Menu.Item icon={EditIcon} secondaryText="⌘R">
              Rename...
            </Menu.Item>
          </Menu.Group>
          <Menu.Divider />
          <Menu.Group>
            <Menu.Item icon={TrashIcon} intent="danger">
              Delete...
            </Menu.Item>
          </Menu.Group>
        </Menu>
      }
    >
      <Button marginRight={16}>With Icons</Button>
    </Popover>*/

    /*    <OverflowMenu className={"dots-menu"} menuOffset={{ left: -100, top: -40 }}>
      {items.map(({ icon, onClick, title, type, accessTo, getLink }: DotsMenuItem) => {
        return (
          <RoleWrapper accessTo={accessTo}>
            <OverflowMenuItem
              itemText={
                <div
                  className={classNames(
                    "dots-menu__item",
                    type && `dots-menu__item--${type}`
                  )}
                >
                  {icon && <span>{icon}</span>}
                  <span>{title}</span>
                </div>
              }
              href = {getLinkWrapper(clickParams.id, getLink)}
              onClick={(e: any) => {
                onClick && onClick(clickParams);
                e.preventDefault()
              }}
              style = {{textDecoration: "unset"}}
            />
          </RoleWrapper>
        );
      })}
    </OverflowMenu>*/
  );
};

export default DotsMenu;
