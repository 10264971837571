import React, { useState } from "react";
import { InputPasswordProps } from "./types";
import { EyeOpenIcon, EyeOffIcon, TextInputField } from "evergreen-ui";
import { css } from "@emotion/react";
import styles from "components/atoms/InputPassword/styles";

const InputPassword: React.FC<InputPasswordProps> = ({
  id = "input",
  label,
  value,
  onChange,
  hideLabel = false,
  style = {},
  placeholder,
  size = "xl",
  onRemove,
  error,
  ...rest
}) => {
  const [showPass, setShowPass] = useState(false);
  return (
    <div css={css(styles.wrapper)}>
      <TextInputField
        placeholder={placeholder}
        type={showPass ? "text" : "password"}
        marginBottom={0}
        style={style}
        id={id}
        onChange={(e: any) => onChange && onChange(e?.target?.value)}
        value={value}
        label={label}
        {...rest}
      />
      <div css={css(styles.icon)} onClick={() => setShowPass(!showPass)}>
        {showPass ? <EyeOffIcon /> : <EyeOpenIcon />}
      </div>
    </div>
  );
};

export default InputPassword;
