import React, { useState } from "react";
import MainSidebar from "modules/layout/MainSidebar";
import { useTrans } from "system/translations/hooks";
import { useGetCurrentAdmin, useLogout } from "modules/auth/apiHooks";
import logo from "images/logo.svg";
import {
  Menu,
  Pane,
  Popover,
  MenuIcon,
  KeyIcon,
  LogOutIcon,
  MenuClosedIcon,
} from "evergreen-ui";
import styles from "components/organisms/Header/styles";
import { css } from "@emotion/css";
import { css as reactCss } from "@emotion/react";
import themeOptions from "system/theme";
import { useModal } from "services/modal/hooks";
import ChangePassword from "modules/admin/components/ChangePassword";
import { isMobile } from "system/theme/globalStyles";

const HeaderWrapper: any = () => {
  const { _t } = useTrans();
  const { data } = useGetCurrentAdmin();
  const [isSideMinimized, setSideMinimize] = useState(isMobile);
  const { mutate: onLogout } = useLogout();
  const { showModal } = useModal();
  const showChangePassword = () => {
    showModal({
      title: _t("change_password"),
      component: ChangePassword,
    });
  };
  const setSideExpand = () => {
    setSideMinimize(!isSideMinimized);
  };

  return (
    <>
      <Pane css={reactCss(styles.container)}>
        <Pane css={reactCss(styles.logoWrap)}>
          {isMobile ? (
            isSideMinimized ? (
              <MenuIcon
                onClick={setSideExpand}
                color={themeOptions.colors.white}
              />
            ) : (
              <MenuClosedIcon
                onClick={setSideExpand}
                color={themeOptions.colors.white}
              />
            )
          ) : null}
          <img src={logo} alt={"logo"} />
        </Pane>
        <Pane css={reactCss(styles.userWrap)}>
          <Popover
            position={"bottom-right"}
            trigger={"click"}
            statelessProps={{
              className: css(styles.popoverWrapper),
            }}
            content={({ close }) => (
              <div onClick={close}>
                <Menu>
                  <Menu.Group>
                    <Pane
                      is={"a"}
                      onClick={(e: any) => {
                        e.preventDefault();
                        showChangePassword();
                      }}
                    >
                      <Menu.Item icon={<KeyIcon size={12} />}>
                        {_t("change_password")}
                      </Menu.Item>
                    </Pane>

                    <Menu.Divider />
                    <Menu.Item
                      icon={<LogOutIcon size={12} />}
                      onClick={onLogout}
                    >
                      {_t("logout")}
                    </Menu.Item>
                  </Menu.Group>
                </Menu>
              </div>
            )}
          >
            <Pane css={reactCss(styles.userName)}>
              {`${_t("welcome")}, ${data?.name}`}
            </Pane>
          </Popover>
        </Pane>
      </Pane>
      <MainSidebar
        isSideMinimized={isSideMinimized}
        onSideMinimize={setSideMinimize}
      />
    </>
  );
};

export default HeaderWrapper;
