import React from "react";
import { useHistory } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import AuthWrapper from "modules/auth/components/wrappers/AuthWrapper";
import { APP_ROUTES } from "system/router/constants";
import ForgotPasswordForm from "./forms/forgotPasswordForm";

const ForgotPassword: React.FC = () => {
  const { _t } = useTrans();
  const history = useHistory();
  const backHandler = () => {
    history.push(APP_ROUTES.login);
  };

  return (
    <AuthWrapper
      title={_t("forgot_password")}
      subtitle={_t("forgot_password_help_text")}
      backHandler={backHandler}
    >
      <ForgotPasswordForm />
    </AuthWrapper>
  );
};

export default ForgotPassword;
