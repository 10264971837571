export const entityTypes = [
  "TRANSACTIONS",
  "USERS",
  "ROLES",
  "MERCHANTS",
  "MERCHANT_BALANCE",
  "STATISTICS",
  "ACTION_LOG",
  "HISTORY",
  "APP_SETTINGS",
  "TRANSLATIONS",
  "MONITORING_SETTINGS",
  "PAYMENT_METHOD",
  "COUNTRIES",
  "CREDENTIALS",
  "RESPONSE_CODES",
  "LANGUAGES",
  "PAYMENT_GW",
  "PROPERTIES",
];
export const actionTypes = ["VIEW", "UPDATE", "DELETE", "CREATE"];
