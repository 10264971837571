import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  item: {
    marginBottom: themeOptions.spacing.sm,
  },
  rowItem: {
    margin: 0,
  },
  gridItem: {
    padding: "0",
    width: "100%",
  },
});

export default styles;
