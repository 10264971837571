import React, { useState } from "react";
import {
  Table as EvergreenTable,
  ChevronUpIcon,
  ChevronDownIcon,
} from "evergreen-ui";
import classNames from "classnames";
import styles from "../styles";
import { css } from "@emotion/react";
import { isMobile } from "system/theme/globalStyles";

const TableBody = ({
  getTableBodyProps,
  totalColumnsWidth,
  page,
  prepareRow,
  state,
  cellProps,
  onExpand,
  renderRowSubComponent,
  height,
  isVirtualBody,
}: any) => {
  const Component =
    isMobile || !isVirtualBody ? EvergreenTable : EvergreenTable.VirtualBody;
  const [rowId, setRowId] = useState(0);

  return (
    <Component
      className="rt-tbody"
      height={isMobile ? "auto" : height}
      {...getTableBodyProps()}
      style={
        isMobile
          ? { borderLeft: 0, borderRight: 0 }
          : { minWidth: totalColumnsWidth }
      }
      overscanCount={page?.length >= 20 ? 20 : page?.length}
    >
      {page.map((row: any, idx: number) => {
        prepareRow(row);
        return (
          <div className={"rt-tr-group"} css={css(styles.tableRowGroup)}>
            <EvergreenTable.Row
              css={css(
                styles.tableRow,
                isMobile && row.id !== rowId && styles.tableRowClose
              )}
              height={isMobile ? "auto" : 48}
              key={"row_" + row.id + state.pageIndex + idx}
              role="row"
              className={classNames("rt-tr -odd")}
              {...row.getRowProps()}
            >
              {row.cells.map((cell: any, idx: number) => {
                const stylesInitial = {
                  minWidth: cell.column.minWidth || cell.column.totalWidth,
                  flex: `${cell.column.totalWidth} 0 auto`,
                  width: cell.column.totalWidth,
                  maxWidth: cell.column.maxWidth || cell.column.totalWidth,
                  display: "flex",
                  alignItems: "center",
                };
                return (
                  <EvergreenTable.TextCell
                    css={css(
                      !cell.column.Header && styles.dotsMenu,
                      isMobile &&
                        cell.row.cells.length > 10 &&
                        idx > 8 &&
                        row.id !== rowId &&
                        styles.hiddenCells,
                      cell.column.isMobileTitle && styles.tableCellMobileTittle,
                      styles.tableCells
                    )}
                    className={classNames("rt-td", `rt-td-${idx}`)}
                    style={isMobile ? {} : stylesInitial}
                    role="cell"
                    key={"cell_" + cell.row.values.id}
                    {...cell.getCellProps()}
                    textProps={{
                      whiteSpace: "initial",
                      overflowX: "initial",
                      overflowY: "initial",
                    }}
                  >
                    {isMobile ? (
                      <div>
                        <div css={styles.tableCellMobileSubtittle}>
                          {isMobile &&
                            !cell.column.isMobileTitle &&
                            cell.column.Header}
                        </div>
                        <div
                          css={css(
                            cell.column.isMobileTitle
                              ? styles.tableCellMobileTittleText
                              : styles.tableCellMobileSubtittleText
                          )}
                        >
                          {cell.render("Cell", {
                            ...cellProps,
                            isExpanded: state.expanded[row.id],
                            onExpand: (e: any) => onExpand(e, row),
                          })}
                        </div>
                      </div>
                    ) : (
                      cell.render("Cell", {
                        ...cellProps,
                        isExpanded: state.expanded[row.id],
                        onExpand: (e: any) => onExpand(e, row),
                      })
                    )}
                  </EvergreenTable.TextCell>
                );
              })}
              {isMobile && row.cells.length >= 10 && (
                <button
                  onClick={() => {
                    setRowId(row.id === rowId ? 0 : row.id);
                  }}
                  css={css(
                    styles.openButton,
                    row.id === rowId && styles.openButtonActive
                  )}
                >
                  {row.id === rowId ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </button>
              )}
            </EvergreenTable.Row>
            {row.isExpanded ? (
              <div key={"expanded_" + row.id} role="row" {...row.getRowProps()}>
                <div className={"expanded-row--wrap"} role="cell">
                  {renderRowSubComponent({ row })}
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </Component>
  );
};

export default TableBody;
