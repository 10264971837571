import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  headerWrapper: {
    padding: themeOptions.spacing.xlg,
    borderBottom: `1px solid ${themeOptions.colors.gray300}`,
    height: "72px",
    [themeOptions.maxMedia.md]: {
      height: "50px",
      padding: 0
    }
  },
  footerWrapper: {
    padding: themeOptions.spacing.lg,
    borderTop: `1px solid ${themeOptions.colors.gray300}`,
    height: "72px",
    display: "flex",
    justifyContent: "flex-end",
  },
  bodyWrapper: ({ withoutFooter, hasSpacing = true }: any) => ({
    height: `calc(100vh - 72px - ${withoutFooter ? "0px" : "72px"})`,
    overflow: "auto",
    padding: hasSpacing ? themeOptions.spacing.xlg : 0,
  }),
});

export default styles;
