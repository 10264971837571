import React from "react";
import { Spinner } from "evergreen-ui";
import { LoaderProps } from "./types";
import { css } from "@emotion/react";
import styles from "components/atoms/Loader/styles";

const Loader: React.FC<LoaderProps> = ({
  size = 20,
  formOverlay = false,
  bgOpacity = 50,
  description,
}) => {
  return (
    <div
      css={css(styles.wrapper, formOverlay && styles.formOverlay(bgOpacity))}
    >
      <Spinner css={css(styles.spinner)} size={size} />
      {description && (
        <span className={"bx--loading__text"}>{description}</span>
      )}
    </div>
  );
};

export default Loader;
