import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  wrapper: {
    display: "flex",
  },
  searchInput: {
    width: "100%",
  },
  searchBtn: {
    svg: {
      fill: themeOptions.colors.gray600,
    },
  },
});

export default styles;
