import React from "react";
import { ToggleProps } from "components/atoms/Toggle/types";
import { Heading, Switch, Text } from "evergreen-ui";
import themeOptions from "system/theme";
import styles from "components/atoms/Toggle/styles";
import { css } from "@emotion/react";

const Toggle: React.FC<ToggleProps> = ({
  id = "Toggle",
  label,
  value,
  onChange,
  name,
  vertical = true,
}) => {
  const changeHandler = () => {
    onChange(!value);
  };

  return (
    <>
      <div css={css(styles.container)}>
        <Switch
          name={name}
          id={id}
          checked={value}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />
        <Text cursor={"pointer"} onClick={changeHandler}>
          {label}
        </Text>
      </div>
    </>
    /*    <CarbonToggle
      className={"toggle-custom"}
      key={id}
      id={id}
      labelA={labelA}
      labelB={labelB}
      defaultToggled={withControlledValue ? undefined : value}
      toggled={withControlledValue ? value : undefined}
      onToggle={(e) => {
        onChange(e);
      }}
      labelText={label}
    />*/
  );
};

export default Toggle;
