import React, { useMemo } from "react";
import FormBuilder from "services/formBuilder/FormBuilder";
import Table from "services/table";
import { useModal } from "services/modal/hooks";
import { useTrans } from "system/translations/hooks";
import {
  useFilters,
  useGetSortMenuMobileItems,
  useListPagination,
  useListSort,
  useMobileFiltersDrawer,
  usePrepareForm,
} from "system/helpers/hooks";
import { useGetPayments, useDeletePayment } from "modules/payments/apiHooks";
import {
  useTableColumns,
  useSearchFormConfig,
} from "modules/payments/constantHooks";
import { PaymentsProps } from "modules/payments/types";
import config from "system/config";
import Info from "modules/payments/components/Info";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ButtonLink from "components/atoms/ButtonLink";
import { DotsMenuItem } from "components/atoms/DotsMenu/types";
import { DeleteIcon, EditIcon, PlusIcon} from "evergreen-ui";
import SectionTitle from "components/atoms/SectionTitle";
import { useConfirmModal } from "services/modal/predefinedModals";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { useDrawer } from "services/drawer/hooks";
import globalStyles, { isMobile } from "system/theme/globalStyles";
import themeOptions from "system/theme";
import FilterMobileButtons from "components/molecules/FilterMobileButtons";

const Payments: React.FC<PaymentsProps> = () => {
  const history = useHistory();
  const { _t } = useTrans();
  const { onConfirm } = useConfirmModal();
  const { showModal } = useModal();
  const { showDrawer, hideDrawer } = useDrawer()
  const filterInitialValues = {};
  const searchFormConfig = useSearchFormConfig();
  const {
    mobileSearchFormConfig,
    filterParams,
    filters,
    setFilters
  } = usePrepareForm(searchFormConfig, filterInitialValues)
  const { showFilters, filtersMobile, setFiltersMobile } = useMobileFiltersDrawer(filterParams);
  const { sort, setSort } = useListSort({});
  const { pagination, setPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const { sortMenuItems } =  useGetSortMenuMobileItems('Last created','code', setSort);
  const requestParams = useMemo(
    () => ({ ...filters, ...pagination, ...sort }),
    [filters, pagination, sort]
  );
  const { data, isLoading } = useGetPayments(requestParams);
  const { mutate: onDelete } = useDeletePayment();
  const columns = useTableColumns();
  const showDetails = (id: string) => {
    showModal({
      componentProps: {
        id: id,
      },
      component: Info,
    });
  };
  const onFilterSubmit = async (data: any) => {
    setPagination(config.defaultInitialPagination);
    isMobile
      ? setFilters({ ...data, ...filtersMobile })
      : setFilters(data);
    hideDrawer('filters');
  };
  const onUpdate = ({ id }: any) => {
    const path = APP_ROUTES.payments.update;
    const link = generatePath(path, { id });
    history.push(link, history.location);
  };
  const onDeleteWithConfirm = ({ id, allData }: any) => {
    onConfirm({
      onOk: () => onDelete({ id }),
      title: `${_t("delete")} ${allData?.code}?`,
      bodyText: _t("action_cannot_be_undone"),
      onOkKind: "danger",
      onOkText: _t("delete"),
    });
  };

  let actionMenuItems: DotsMenuItem[] = [
    {
      title: _t("edit"),
      onClick: onUpdate,
      icon: <EditIcon size={12} />,
      accessTo: "ROLE_MODIFY_PAYMENT_METHODS",
    },
    {
      title: _t("deactivate"),
      onClick: onDeleteWithConfirm,
      icon: <DeleteIcon size={12} />,
      intent: "danger",
      accessTo: "ROLE_MODIFY_PAYMENT_METHODS",
    },
  ];
  return (
    <>
      <div css={globalStyles.mobileHeader}>
        <SectionTitle
          title={_t("methods")}
          extra={
            <RoleWrapper accessTo={"ROLE_MODIFY_PAYMENT_METHODS"}>
              <ButtonLink
                kind={"secondary"}
                iconBefore={PlusIcon}
                linkTo={APP_ROUTES.payments.create}
                label={_t("create_methods")}
              />
            </RoleWrapper>
          }
        />
        <FormBuilder
          formItemsConfig={isMobile ? mobileSearchFormConfig : searchFormConfig}
          showSubmit={false}
          formProps={{
            submitBtnLabel: _t("apply_filters"),
            onSubmit: onFilterSubmit,
            initialValues: {},
            values: filters,
          }}
        />
        {isMobile && 
          <FilterMobileButtons
            showFilters={showFilters}
            sortMenuItems={sortMenuItems}
            setFiltersMobile={setFiltersMobile}
            setFilters={setFilters}
            filters={filtersMobile}
            totalElements={data?.totalElements || 0}
            filterInitialValues={filterInitialValues}
          />
          }
      </div>
      <Table
        totalRecords={data?.totalElements}
        columns={columns}
        pageIndex={pagination.page}
        pageSize={pagination.perPage}
        data={data?.content || []}
        isLoading={isLoading}
        isPagination
        onPagination={setPagination}
        onSort={setSort}
        cellProps={{ showDetails, actionMenuItems }}
      />
    </>
  );
};

export default Payments;
