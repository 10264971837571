import React, { useState } from "react";
import { useTrans } from "system/translations/hooks";
import themeOptions from "system/theme";
import { createStyles } from "types/emotion-styles";
import { css } from "@emotion/react";
import { useDrawer } from "services/drawer/hooks";
import Btn from "components/atoms/Btn";
import TextArea from "components/atoms/TextArea";

const styles = createStyles({
  wrapper: {
    display: "grid",
    height: "100%",
    gridTemplateRows: "1fr 72px",
  },
  container: {
    padding: themeOptions.spacing.md,
    color: themeOptions.colors.gray700,
  },
  buttonsWrapper: {
    padding: themeOptions.spacing.md,
    borderTop: `1px solid ${themeOptions.colors.gray300}`,
    display: "grid",
    justifyContent: "flex-end",
    gap: "10px",
    gridTemplateColumns: "max-content max-content",
  },
});

const JsonDrawer: React.FC<{
  json: any;
  isJson?: boolean;
  onSubmit: (val: any) => any;
}> = ({ json, isJson = false, onSubmit }) => {
  const { _t } = useTrans();
  const [data, setData] = useState(json);
  const { hideDrawer } = useDrawer();
  const onSave = () => {
    try {
      onSubmit(JSON.parse(data));
      hideDrawer();
    } catch (e) {}
  };

  return (
    <div css={css(styles.wrapper)}>
      <div css={styles.container}>
        {isJson ? (
          <TextArea height={500} onChange={setData} value={data} />
        ) : (
          data
        )}
      </div>
      <div css={styles.buttonsWrapper}>
        <Btn kind={"primary"} onClick={onSave}>
          {_t("save_changes")}
        </Btn>{" "}
        <Btn kind={"custom"} onClick={() => hideDrawer()}>
          {_t("close")}
        </Btn>
      </div>
    </div>
  );
};

export default JsonDrawer;
