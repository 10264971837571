import React from "react";
import { Checkbox as CheckboxEvergreen, CheckboxProps } from "evergreen-ui";

const Checkbox: React.FC<CheckboxProps> = ({
  id = "Checkbox",
  label,
  value,
  onChange,
}) => {
  return (
    <CheckboxEvergreen
      id={id}
      marginTop={8}
      marginBottom={8}
      label={label}
      checked={!!value}
      onChange={(e: any) => (onChange ? onChange(e.target.checked) : undefined)}
    />
  );
};

export default Checkbox;
