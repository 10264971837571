import { createStyles } from "types/emotion-styles";

const styles = createStyles({
  wrapper: {
    position: "relative",
  },
  copyBtn: {
    position: "absolute",
    top: "35px",
    right: "15px",
  },
});

export default styles;
