import * as Yup from "yup";
import { TransType } from "system/translations/types";

export const updateTransactionValidation = (_t: TransType) => {
  return Yup.object().shape({
    rejectionReasonId: Yup.string().required(_t("required")),
    rejectionReasonText: Yup.string().when(
      "rejectionReasonId",
      (rejectionReasonId) => {
        return rejectionReasonId == "5"
          ? Yup.string().required(_t("required"))
          : Yup.string().notRequired();
      }
    ),
  });
};
