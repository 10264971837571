import React, { useMemo, useState } from "react";
import Toggle from "components/atoms/Toggle";
import JsonEditor from "components/atoms/JsonEditor";
import Input from "components/atoms/Input";
import { TextInput } from "evergreen-ui";


const JsonOrInput = ({
  value,
  onChange,
  labelText,
  labelA = "JsonEditor", 
  labelB = "Input",
  name = "" ,
  ...rest 
}: any) => {
  const initial = useMemo(() => {
    let result;
    try {
      JSON.parse(value);
      result = !!JSON.parse(value);
    } catch (e) {
      result = false;
    }
    return result;
  }, []);

  const [toggle, setToggle] = useState(initial);
  return (
    <>
      <Toggle
        label={toggle ? labelA : labelB}
        onChange={setToggle}
        value={toggle}
      />
      {labelText}
      {toggle ? (
        <JsonEditor id={name} value={value} onChange={onChange} {...rest}/>
      ) : (
        <TextInput name={name} width={"100%"} value={value} onChange={(e: any) => onChange && onChange(e?.target?.value)} {...rest} />
      )}
    </>
  );
};

export default JsonOrInput;
