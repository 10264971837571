import { defaultTheme } from "evergreen-ui";
import { ClientDataType } from "modules/transactions/types";

const colors = {
  ...defaultTheme.colors,
  c1: "#F8F9FD",
  c2: "#a7aab6",
  c3: "#072652",
  yellow400: "#FFB020",
  yellowGreen100: "#FDFFD2",
  yellowGreen400: "#c0c637",
  b50: '#F3F6FF',
  b400: "#3378FF",
  n300: "#E6E8F0",
  n600: "#8F95B2",
  n700: "#696F8C",
  n800: "#474D66",
  n900: '#101840',
};

const topIndent = "70px";

const spacing = {
  xxs: "4px",
  xs: "8px",
  sm: "12px",
  md: "16px",
  lg: "20px",
  xlg: "24px",
  xxlg: "32px",
};
type BreakpointsType = {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xlg: string;
  xxlg: string;
};

const breakpoints = {
  xs: "320px",
  sm: "480px",
  md: "812px",
  lg: "1024px",
  xlg: "1200px",
  xxlg: "1920px",
};
const borders = {
  xs: "4px",
  sm: "8px",
};
const boxShadow = {
  main: "0px 2px 4px rgba(16, 24, 64, 0.08)",
};

let minMedia = { ...breakpoints };
let maxMedia = { ...breakpoints };
let key: keyof typeof breakpoints;
for (key in breakpoints) {
  minMedia[key] = `@media (min-width: ${breakpoints[key]})`;
  maxMedia[key] = `@media (max-width: ${breakpoints[key]})`;
}

const themeOptions = {
  colors,
  spacing,
  borders,
  boxShadow,
  breakpoints,
  minMedia,
  maxMedia,
  topIndent,
};

export default themeOptions;
