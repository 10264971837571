import React, { useMemo } from "react";
import FormBuilder from "services/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useDeleteUser, useGetUser, useUpdateUser } from "../apiHooks";
import Card from "components/atoms/Card";
import { useParams } from "react-router-dom";
import { createEditUserValidation } from "../validation";
import Loader from "components/atoms/Loader";
import Settings from "modules/users/components/Settings";
import { useCreateEditConfig } from "modules/users/constantHooks";
import { useConfirmModal } from "services/modal/predefinedModals";
import Btn from "components/atoms/Btn";
import SectionTitle from "components/atoms/SectionTitle";

const Update: React.FC = () => {
  const isEdit = true;
  const { _t } = useTrans();
  const { onConfirm } = useConfirmModal();
  const routeParams: any = useParams();
  const { mutate: onUpdate } = useUpdateUser();
  const { mutate: onDelete } = useDeleteUser();
  const { data: userData, isLoading } = useGetUser(routeParams?.id);
  const formConfig = useCreateEditConfig({
    isEdit,
  });
  const onSubmit = async (data: any) => {
    const normalize = { ...data };
    if (isEdit && !normalize.password) {
      delete normalize.password;
    }
    onUpdate({ data: normalize, id: routeParams?.id });
  };
  const onDeleteWithConfirm = () => {
    onConfirm({
      onOk: () => onDelete({ id: routeParams?.id }),
      title: `${_t("deactivate_user")}?`,
      bodyText: (
        <span
          className="form__content"
          dangerouslySetInnerHTML={{
            __html: _t("user_deactivation_description_{userName}", {
              userName: <b>{userData?.name}</b>,
            }),
          }}
        />
      ),
      onOkKind: "danger",
      onOkText: _t("deactivate"),
    });
  };

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <>
      <SectionTitle
        title={_t("edit_user")}
        extra={<Btn onClick={onDeleteWithConfirm}>{_t("deactivateUser")}</Btn>}
      />
      <Card>
        <FormBuilder
          formItemsConfig={formConfig}
          formProps={{
            submitBtnLabel: _t("save_changes"),
            submitBtnKind: "primary",
            onSubmit: onSubmit,
            initialValues: {
              ...userData,
            },
            validationSchema: createEditUserValidation(_t, {
              isEdit,
            }),
          }}
        />
      </Card>
      {userData && <Settings userData={userData} />}
    </>
  );
};

export default Update;
