import React from "react";
import classNames from "classnames";
import { Col as Column } from "react-flexbox-grid";
import { css } from "@emotion/react";
import styles from "services/formBuilder/styles";

const FieldItem = ({
  emptySpace,
  columnParams: { className, style, ...rest },
  Component,
  additionalProps,
  name,
  componentProps,
  error,
  onBlur,
  onChange,
  formik,
  formProps,
  getNestedValue,
  showFiltersDrawer,
  ...restComponentProps
}: any) => {
  return (
    <>
      {emptySpace && <Column {...emptySpace} />}
      <Column
        style={style}
        {...rest}
        css={css(styles.item)}
        className={classNames(className && className)}
      >
        <Component
          {...componentProps}
          {...additionalProps}
          {...restComponentProps}
          name={name}
          showFiltersDrawer={showFiltersDrawer}
          id={name}
          value={getNestedValue(formik.values, name)}
          isInvalid={!!error}
          error={error}
          validationMessage={error || null}
          onBlur={(directFieldName?: string) => {
            const fieldName: string = directFieldName || name;
            onBlur(fieldName);
          }}
          onFormValueChange={(fieldName: string, value: any) =>
            onChange(fieldName, value)
          }
          onChange={(value: any, directFieldName?: string) => {
            const fieldName: string = directFieldName || name;
            onChange(fieldName, value);
            formProps.onFormValueChange &&
              formProps.onFormValueChange(fieldName, formik);
            componentProps.onFieldChange &&
              componentProps.onFieldChange(value, formik.values, {
                setFieldValue: formik.setFieldValue,
                setFieldTouched: formik.setFieldTouched,
              });
          }}
        >
          {componentProps.children}
        </Component>
      </Column>
    </>
  );
};

export default FieldItem;
