import React from "react";
import {
  FileUploader as EvergreenFileUploader,
  FileUploaderProps,
  FileCard,
  MimeType,
} from "evergreen-ui";

const FileUploader: React.FC<
  FileUploaderProps & { value: any; onChange: any }
> = ({ value = [], onChange }) => {
  const handleChange = (files: any) => {
    onChange([...value, ...files]);
  };
  const handleRejected = (file: any) => {
    console.log("onRejected", file);
  };
  const handleRemove = (file: any) => {
    const updatedFiles = value.filter(
      (existingFile: any) => existingFile !== file
    );
    onChange(updatedFiles);
  };
  const acceptedMimeTypes = [MimeType.svg];
  return (
    <div style={{ width: "100%" }}>
      <EvergreenFileUploader
        /*       acceptedMimeTypes={acceptedMimeTypes}*/
        label="Upload File"
        description="You can upload 1 file. File can be up to 10 MB."
        maxSizeInBytes={10 * 1024 ** 2}
        maxFiles={4}
        onChange={handleChange}
        onRejected={handleRejected}
        values={value}
        renderFile={(file: any, index) => {
          const { name, size, type } = file;
          /*const fileRejection = fileRejections.find(
            (fileRejection) => fileRejection.file === file
          );
          const { message } = fileRejection || {};*/
          const fileCardProps: any = {};
          console.log("size", file);
          if (!!size) {
            fileCardProps.sizeInBytes = size;
          } else {
            if (typeof file === "string") {
              const filePath: any = file.split("/");
              fileCardProps.description = decodeURI(
                filePath[filePath.length - 1]
              );
            }
          }
          if (name) {
            fileCardProps.name =
              name?.slice(0, 30) + (file?.name.length > 30 ? "..." : "");
          }
          return (
            <React.Fragment key={`${file.name}-${index}`}>
              <FileCard
                {...fileCardProps}
                key={name}
                /* isInvalid={fileRejection != null}*/
                onRemove={() => handleRemove(file)}
                type={type}
                /*  validationMessage={message}*/
              />
            </React.Fragment>
          );
        }}
      />
    </div>
  );
};

export default FileUploader;
