import React, { useMemo } from "react";
import FormBuilder from "services/formBuilder/FormBuilder";
import Table from "services/table";
import { useModal } from "services/modal/hooks";
import { useTrans } from "system/translations/hooks";
import {
  useFilters,
  useGetSortMenuMobileItems,
  useListPagination,
  useListSort,
  useMobileFiltersDrawer,
  usePrepareForm,
} from "system/helpers/hooks";
import { useDeleteMerchant, useGetMerchants } from "modules/merchants/apiHooks";
import {
  useTableColumns,
  useSearchFormConfig,
} from "modules/merchants/constantHooks";
import { MerchantsProps } from "modules/merchants/types";
import config from "../../system/config";
import Info from "modules/merchants/components/Info";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ButtonLink from "components/atoms/ButtonLink";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { DotsMenuItem } from "components/atoms/DotsMenu/types";
import { CreditCardIcon, DeleteIcon, EditIcon, PlusIcon } from "evergreen-ui";
import SectionTitle from "components/atoms/SectionTitle";
import { useConfirmModal } from "services/modal/predefinedModals";
import { useDrawer } from "services/drawer/hooks";
import globalStyles, { isMobile } from "system/theme/globalStyles";
import FilterMobileButtons from "components/molecules/FilterMobileButtons";

const Merchants: React.FC<MerchantsProps> = () => {
  const history = useHistory();
  const { _t } = useTrans();
  const { showModal } = useModal();
  const { showDrawer, hideDrawer } = useDrawer();
  const filterInitialValues = {};
  const searchFormConfig = useSearchFormConfig();
  const { mobileSearchFormConfig, filterParams, filters, setFilters } =
    usePrepareForm(searchFormConfig, filterInitialValues);
  const { sort, setSort } = useListSort({});
  const { pagination, setPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const { sortMenuItems } = useGetSortMenuMobileItems("By ID", "name", setSort);
  const requestParams = useMemo(
    () => ({ ...filters, ...pagination, ...sort }),
    [filters, pagination, sort]
  );
  const { onConfirm } = useConfirmModal();
  const { data, isLoading } = useGetMerchants(requestParams);
  const { mutateAsync: onDelete } = useDeleteMerchant();
  const columns = useTableColumns();
  const showDetails = (id: string) => {
    showModal({
      componentProps: {
        id: id,
      },
      component: Info,
    });
  };
  const getUrlPath = (
    merchantsType: "whitelist" | "blacklist" | "payment_methods"
  ) => {
    return (id: number) => {
      const path = APP_ROUTES.merchants[merchantsType]?.index;
      const link = generatePath(path, {
        merchantId: id,
      });
      return link;
    };
  };
  const goByUrlPath = (
    merchantsType: "whitelist" | "blacklist" | "payment_methods"
  ) => {
    return ({ id }: any) => {
      const link = getUrlPath(merchantsType)(id);
      history.push(link, history.location);
    };
  };

  const { showFilters, filtersMobile, setFiltersMobile } =
    useMobileFiltersDrawer(filterParams);

  const onFilterSubmit = async (data: any) => {
    setPagination(config.defaultInitialPagination);
    isMobile ? setFilters({ ...data, ...filtersMobile }) : setFilters(data);
    hideDrawer("filters");
  };

  const onUpdate = ({ id }: any) => {
    const path = APP_ROUTES.merchants.update;
    const link = generatePath(path, { id });
    history.push(link, history.location);
  };
  const onDeleteWithConfirm = ({ id, allData }: any) => {
    const merchantName = allData.name;
    onConfirm({
      onOk: () => onDelete({ id }),
      title: `${_t("deactivate_user")}?`,
      bodyText: (
        <span
          className="form__content"
          dangerouslySetInnerHTML={{
            __html: _t("merchant_deactivation_description_{merchantName}", {
              merchantName: <b>{merchantName}</b>,
            }),
          }}
        />
      ),
      onOkKind: "danger",
      onOkText: _t("deactivate"),
    });
  };
  let actionMenuItems: DotsMenuItem[] = [
    {
      title: _t("edit"),
      onClick: onUpdate,
      icon: <EditIcon size={12} />,
      accessTo: "ROLE_MODIFY_MERCHANTS",
    },
    {
      title: _t("payment_methods"),
      onClick: goByUrlPath("payment_methods"),
      getLink: getUrlPath("payment_methods"),
      icon: <CreditCardIcon />,
      accessTo: "ROLE_VIEW_MERCHANT_PM",
    },
    /*    {
      title: _t("ip_whitelisting"),
      onClick: goByUrlPath("whitelist"),
      getLink: getUrlPath("whitelist"),
      icon: <Security16 />,
      accessTo: "ROLE_MODIFY_MERCHANT_WHITELIST",
    },
    {
      title: _t("blacklist"),
      onClick: goByUrlPath("blacklist"),
      getLink: getUrlPath("blacklist"),
      icon: <SubtractAlt16 />,
      accessTo: "ROLE_MODIFY_MERCHANT_BLACKLIST",
    },*/
    {
      title: _t("deactivate"),
      onClick: onDeleteWithConfirm,
      icon: <DeleteIcon size={12} />,
      intent: "danger",
      accessTo: "ROLE_MODIFY_MERCHANTS",
    },
  ];
  return (
    <>
      <div css={globalStyles.mobileHeader}>
        <SectionTitle
          title={_t("merchants")}
          extra={
            <>
              <RoleWrapper accessTo={"ROLE_CREATE_MERCHANTS"}>
                <ButtonLink
                  kind={"secondary"}
                  iconBefore={PlusIcon}
                  linkTo={APP_ROUTES.merchants.create}
                  label={_t("create_merchant")}
                />
              </RoleWrapper>
            </>
          }
        />
        <FormBuilder
          formItemsConfig={isMobile ? mobileSearchFormConfig : searchFormConfig}
          showSubmit={false}
          formProps={{
            submitBtnLabel: _t("apply_filters"),
            onSubmit: onFilterSubmit,
            initialValues: {},
          }}
        />
        {isMobile && (
          <FilterMobileButtons
            showFilters={showFilters}
            sortMenuItems={sortMenuItems}
            setFiltersMobile={setFiltersMobile}
            setFilters={setFilters}
            filters={filtersMobile}
            totalElements={data?.totalElements || 0}
            filterInitialValues={filterInitialValues}
          />
        )}
      </div>
      <Table
        totalRecords={data?.totalElements}
        columns={columns}
        pageIndex={pagination.page}
        pageSize={pagination.perPage}
        data={data?.content || []}
        isLoading={isLoading}
        isPagination
        onPagination={setPagination}
        onSort={setSort}
        cellProps={{ showDetails, actionMenuItems }}
      />
    </>
  );
};

export default Merchants;
