import React from "react";
import { generatePath, useHistory } from "react-router-dom";
import ButtonLink from "components/atoms/ButtonLink";
import Table from "services/table";
import { APP_ROUTES } from "system/router/constants";
import { useTrans } from "system/translations/hooks";
import { useDeleteRole, useGetRoles } from "modules/roles/apiHooks";
import Loader from "components/atoms/Loader";
import { useConfirmModal } from "services/modal/predefinedModals";
import { useTableColumns } from "modules/roles/constantHooks";
import { useDeleteWithConfirm } from "./hooks";
import { EditIcon, PlusIcon, TrashIcon } from "evergreen-ui";
import SectionTitle from "components/atoms/SectionTitle";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import globalStyles from "system/theme/globalStyles";

const Roles = () => {
  const { _t } = useTrans();
  const history = useHistory();
  const { onConfirm } = useConfirmModal();
  const { data = [], isLoading } = useGetRoles();
  const { mutate: onDelete } = useDeleteRole();
  const columns = useTableColumns();
  const onUpdate = ({ id }: any) => {
    const link = generatePath(APP_ROUTES.roles.update, { id });
    history.push(link, history.location);
  };
  const onDeleteWithConfirm = ({ id, allData }: any) => {
    onConfirm({
      onOk: () => onDelete({ id }),
      title: `${_t("delete")} ${allData?.name}?`,
      bodyText: _t("delete_role_description"),
      onOkKind: "danger",
      onOkText: _t("deactivate"),
    });
  };
  const actionMenuItems = [
    { title: _t("edit"), onClick: onUpdate, icon: <EditIcon size={12} /> },
    {
      title: _t("delete"),
      onClick: onDeleteWithConfirm,
      icon: <TrashIcon size={12} />,
      intent: "danger",
    },
  ];
  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <>
      <div css={globalStyles.mobileHeader}>
        <SectionTitle
          title={_t("roles")}
          extra={
            <ButtonLink
              kind={"secondary"}
              iconBefore={PlusIcon}
              linkTo={APP_ROUTES.roles.create}
              label={_t("create_new_role")}
            />
          }
        />
      </div>
      <Table
        columns={columns}
        data={data || []}
        isLoading={isLoading}
        cellProps={{ actionMenuItems }}
        isPagination
        totalRecords={data?.length}
        showSettingsIcon={false}
      />
    </>
  );
};

export default Roles;
