import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  link: {
    color: themeOptions.colors.blue400,
    textDecoration: "none",
  },
});

export default styles;
