import React from "react";
import { css } from "@emotion/react";
import styles from "components/atoms/Card/styles";
import { Text, Heading } from "evergreen-ui";
import themeOptions from "system/theme";

const Card: React.FC<{
  children?: React.ReactNode;
  wrapperClass?: any;
  headerWrapperClass?: any;
  title?: any;
  collapsed?: boolean;
  extra?: any;
  onHeaderClick?: () => any;
}> = ({
  children,
  title,
  extra,
  wrapperClass,
  collapsed = false,
  headerWrapperClass,
  onHeaderClick,
}) => {
  return (
    <div css={css(styles.container, wrapperClass)}>
      {title || extra ? (
        <div
          onClick={() => onHeaderClick && onHeaderClick()}
          css={css(
            styles.header,
            collapsed && styles.headerCollapsed,
            headerWrapperClass && headerWrapperClass
          )}
        >
          <Heading size={400} css={css(styles.title)}>
            {title}
          </Heading>
          <div css={css(styles.extra)}>{extra}</div>
        </div>
      ) : null}
      <div css={css(styles.body, collapsed && styles.collapsed)}>
        {children}
      </div>
    </div>
  );
};

export default Card;
