import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  wrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 16px",
    background: themeOptions.colors.white,
    border: "1px solid",
    borderColor: themeOptions.colors.gray300,
    padding: `${themeOptions.spacing.xxs} ${themeOptions.spacing.sm}`,
    marginBottom: themeOptions.spacing.xxs,
    borderRadius: themeOptions.borders.sm,
    alignItems: "center",
  },
  icon: {},
});

export default styles;
