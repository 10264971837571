import React from "react";
import Update from "modules/merchantPaymentMethods/components/Update";

const MerchantPaymentMethodsUpdatePage = () => {
  return (
    <div>
      <Update />
    </div>
  );
};

export default MerchantPaymentMethodsUpdatePage;
