import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_MERCHANT_WHITE_LIST,
  API_CREATE_MERCHANT_WHITE_LIST,
  API_UPDATE_MERCHANT_WHITE_LIST,
  API_DELETE_MERCHANT_WHITE_LIST,
  API_GET_MERCHANT_WHITE_LIST_BY_ID,
} from "system/api/apiUrls";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { useTrans } from "system/translations/hooks";

const getMerchantWhiteList = "getMerchantWhiteList";
const getMerchantWhiteListById = "getMerchantWhiteListById";

export const useGetMerchantWhiteList = (
  merchantId: number,
  pagination: any
) => {
  const [apiRequest] = useApiRequest();
  return useQuery([getMerchantWhiteList, merchantId, pagination], () =>
    apiRequest({
      url: generateUrlQuery(
        generatePath(API_GET_MERCHANT_WHITE_LIST, { merchantId }),
        pagination
      ),
      method: "get",
    })
  );
};
export const useGetMerchantWhiteListById = (
  merchantId: number,
  id: number | string
) => {
  const [apiRequest] = useApiRequest();
  return useQuery([getMerchantWhiteListById, merchantId, id], () =>
    apiRequest({
      url: generatePath(API_GET_MERCHANT_WHITE_LIST_BY_ID, { merchantId, id }),
      method: "get",
    })
  );
};

export const useCreateMerchantWhiteList = (merchantId: number) => {
  const { _t } = useTrans();
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_CREATE_MERCHANT_WHITE_LIST, { merchantId }),
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantWhiteList);
        apiSuccessMessage(_t("successfully_added_new_ip_to_whitelist"));
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useUpdateMerchantWhiteList = (merchantId: number) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_MERCHANT_WHITE_LIST, {
          id: data.id,
          merchantId,
        }),
        method: "put",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantWhiteList);
        queryClient.invalidateQueries(getMerchantWhiteListById);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useDeleteMerchantWhiteList = (merchantId: number) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_MERCHANT_WHITE_LIST, {
          id: data.id,
          merchantId,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantWhiteList);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
