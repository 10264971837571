import themeOptions from "system/theme/index";

const globalStyles: any = {
  body: {
    background: themeOptions.colors.c1,
    overscrollBehavior: "none",
  },
  ".css-1sugtjn": {
    top: "auto",
    bottom: 0,
    left: 0,
    margin: 0,
    marginLeft: themeOptions.spacing.xxlg,
    marginBottom: themeOptions.spacing.sm,
    textAlign: "left",
    zIndex: 9999,
    "& > div": {
      alignItems: "flex-start",
    },
  },
  "input[type='number']": {
    fontFamily:
      '"SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important',
  },
  mobileHeader: {
    [themeOptions.maxMedia.md]: {
      backgroundColor: themeOptions.colors.white,
      margin: "-23px -20px 6px",
      padding: "20px 20px 24px",
    }
  },
  submitBtnForMobileFilters: {
    position: "absolute",
    height: "37px",
    bottom: "35px",
    left: "20px",
    width: "42%",
  }
};

export default globalStyles;

export const isMobile = window.matchMedia(`(max-width: ${themeOptions.breakpoints.md})`).matches;
