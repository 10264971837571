import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  wrapper: {
    display: "flex",
    gap: themeOptions.spacing.sm,
  },
});

export default styles;
