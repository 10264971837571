import React from "react";
import { useHistory } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import { APP_ROUTES } from "system/router/constants";
import Btn from "components/atoms/Btn";
import { ArrowRightIcon, ConfirmIcon } from "evergreen-ui";

const Verified: React.FC = () => {
  const { _t } = useTrans();
  const history = useHistory();
  const goTo = () => history.push(APP_ROUTES.login);

  return (
    <div className={"verified__container"}>
      <div className={"verified__icon"}>
        <ConfirmIcon size={24} />
      </div>
      <div className={"verified__title"}>{_t("your_email_verified")}</div>
      <div className={"verified__subtitle"}>{_t("you_can_login")}</div>
      <Btn onClick={goTo} iconBefore={ArrowRightIcon}>
        {_t("go_to_login")}
      </Btn>
    </div>
  );
};

export default Verified;
