import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";


const styles = createStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 20px",
    height: "100%",
    background: themeOptions.colors.c3,
    zIndex: 3,
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "26px",
    color: themeOptions.colors.white,
  },
});

export default styles;
