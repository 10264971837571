import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  API_UPDATE_CURRENCIES,
  API_CREATE_CURRENCIES,
  API_GET_CURRENCIES,
  API_GET_CURRENCY,
  API_DELETE_CURRENCIES,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import { CurrenciesDataType, CurrencyDataType } from "modules/currencies/types";

const getCurrenciesKey = "getCurrenciesKey";
const getCurrencyKey = "getCurrencyKey";
const getCurrenciesLanguagesKey = "getCurrenciesLanguagesKey";
export const useGetCurrencies = (filters: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getCurrenciesKey, filters],
    (): Promise<CurrenciesDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_CURRENCIES, filters),
        method: "get",
      })
  );
};
export const useGetCurrency = (id: string) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getCurrencyKey, id],
    (): Promise<CurrencyDataType> =>
      apiRequest({
        url: generatePath(API_GET_CURRENCY, { id }),
        method: "get",
      })
  );
};

export const useCreateCurrencies = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_CREATE_CURRENCIES, {
          id: data.id,
        }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getCurrenciesKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useDeleteCurrencies = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_CURRENCIES, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getCurrenciesKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useUpdateCurrencies = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_CURRENCIES, {
          id: data.id,
        }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getCurrenciesKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
