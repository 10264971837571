import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_FORM_TEMPLATES,
  API_GET_FORM_TEMPLATE,
  API_CREATE_FORM_TEMPLATE,
  API_UPDATE_FORM_TEMPLATE,
  API_DELETE_FORM_TEMPLATE,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { useToast } from "services/toast/hooks";
import { useTrans } from "system/translations/hooks";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  FormTemplateDataType,
  FormTemplatesDataType,
} from "modules/formTemplates/types";

const getFormTemplatesKey = "getFormTemplatesKey";
const getFormTemplateKey = "getFormTemplateKey";
export const useGetFormTemplates = (filter = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getFormTemplatesKey, filter],
    (): Promise<FormTemplatesDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_FORM_TEMPLATES, filter),
        method: "get",
      })
  );
};

export const useGetFormTemplate = (id: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getFormTemplateKey, id],
    (): Promise<FormTemplateDataType> =>
      apiRequest({
        url: generatePath(API_GET_FORM_TEMPLATE, { id }),
        method: "get",
      })
  );
};

export const useCreateFormTemplate = () => {
  const { _t } = useTrans();
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_FORM_TEMPLATE,
        method: "put",
        data: data,
        headers: {
          "content-type": "multipart/form-data",
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getFormTemplatesKey);
        apiSuccessMessage(_t("successfully_created_new_form_template"));
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useDeleteFormTemplate = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_FORM_TEMPLATE, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getFormTemplatesKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useUpdateFormTemplate = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_FORM_TEMPLATE, { id: data?.id }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getFormTemplatesKey);
        queryClient.invalidateQueries(getFormTemplateKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
