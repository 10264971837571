import React from "react";
import FiltersSummary from "components/atoms/FiltersSummary";
import SortMenuMobile from "components/atoms/SortMenuMobile";
import { Button, CaretDownIcon, FilterIcon } from "evergreen-ui";
import themeOptions from "system/theme";
import { useTrans } from "system/translations/hooks";
import { FilterMobileButtonsProps } from "./types";
import styles from "./styles";

const FilterMobileButtons: React.FC<FilterMobileButtonsProps> = ({
  showFilters,
  sortMenuItems,
  setFiltersMobile,
  setFilters,
  totalElements,
  filters,
  filterInitialValues
}) => {
  const { _t } = useTrans();
  const selectedValuePreviewLength = () => {
    let selectedValue: any[] = []
    for (const key in filters) {
      if (filters[key] &&
        key !== 'fromDate' &&
        key !== "toDate" &&
        key !== "updatedDate" &&
        filters[key] !== filterInitialValues[key]
        ) {
          selectedValue = [...selectedValue, key]
      }
    }    
    return selectedValue.length
  }
  return (
    <>
      <div css={styles.buttonsContainer}>
        <SortMenuMobile items={sortMenuItems} />
        {showFilters && <Button
          appearance="minimal"
          style={{ color: themeOptions.colors.n900 }}
          onClick={() => showFilters()}
          iconBefore={<FilterIcon color={themeOptions.colors.gray600} />}
          iconAfter={CaretDownIcon}
        >
          {_t("filters")}
        </Button>}
      </div>
      {setFilters && setFiltersMobile && selectedValuePreviewLength() > 0 && 
      <div css={styles.filterSummaryContainer}>
        <FiltersSummary
          onClear={() => {
            setFilters({})
            setFiltersMobile({})
          }}
          filterQuantity={selectedValuePreviewLength()}
          resultQuantity={totalElements || 0}
        />
      </div>
      }
    </>
  );
};

export default FilterMobileButtons;
