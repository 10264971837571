import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "30px 1fr",
    height: "56px",
    border: "1px solid",
    borderColor: themeOptions.colors.gray300,
    alignItems: "center",
    padding: `${themeOptions.spacing.xs} ${themeOptions.spacing.sm}`,
  },
  infoTitle: {
    display: "block",
    color: themeOptions.colors.gray900,
  },
  infoDescription: {
    display: "block",
    color: themeOptions.colors.gray700,
  },
});

export default styles;
