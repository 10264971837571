import React from "react";
import { ConfirmModalBodyProps } from "components/atoms/ConfirmModalBody/types";
import { Text } from "evergreen-ui";
import themeOptions from "system/theme";

const ConfirmModalBody: React.FC<ConfirmModalBodyProps> = ({ text }) => {
  return (
    <Text color={themeOptions.colors.gray700} size={400}>
      {text}
    </Text>
  );
};

export default ConfirmModalBody;
