import React from "react";
import { useGetTransaction, useUpdateTransaction } from "../apiHooks";
import Loader from "components/atoms/Loader";
import Input from "components/atoms/Input";
import { useTrans } from "system/translations/hooks";
import { Pane } from "evergreen-ui";
import themeOptions from "system/theme";
import Divider from "components/atoms/Divider";
import ActionButtons from "components/atoms/ActionButtons";
import { createStyles } from "types/emotion-styles";
import { css } from "@emotion/react";
import FormBuilder from "services/formBuilder/FormBuilder";
import { useUpdateTransactionConfig } from "modules/transactions/constantHooks";
import { updateTransactionValidation } from "modules/transactions/validation";
import { useModal } from "services/modal/hooks";
import { Text } from "evergreen-ui";
const styles = createStyles({
  wrapper: {
    display: "grid",
    /*    height: "100%",
    gridTemplateRows: "1fr 72px",*/
  },
  container: {
    padding: themeOptions.spacing.md,
  },
  buttonsWrapper: {
    padding: themeOptions.spacing.md,
    display: "grid",
    justifyContent: "flex-end",
  },
});

const DeclineForm = ({ transactionId }: { transactionId: string }) => {
  const { _t } = useTrans();
  const { hideModal } = useModal();
  const { mutateAsync, isLoading: isUpdating } = useUpdateTransaction();
  const formConfig = useUpdateTransactionConfig({ isLoading: isUpdating });
  const onSubmit = async (data: any) => {
    const requestData = {
      data: { status: "DECLINED", ...data },
      id: transactionId.trim(),
    };
    await mutateAsync(requestData);
    hideModal();
  };
  const onReset = () => {
    hideModal();
  };

  return (
    <div css={css(styles.wrapper)}>
      <Text
        color={themeOptions.colors.gray700}
        marginBottom={themeOptions.spacing.md}
        size={400}
      >
        {_t("decline_transaction_descriptions")}
      </Text>
      <FormBuilder
        formItemsConfig={formConfig}
        showSubmit={false}
        formProps={{
          onSubmit: onSubmit,
          onReset: onReset,
          initialValues: {},
          validationSchema: updateTransactionValidation(_t),
        }}
      />
    </div>
  );
};

export default DeclineForm;
