import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  labelContainer: {
    display: "flex",
    color: themeOptions.colors.gray700,
    cursor: "pointer",
    height: "32px",
    alignItems: "center",
    gap: "4px",
    padding: "0 4px",
    fontSize: "12px",
    "&:hover": {
      background: themeOptions.colors.gray50,
      color: themeOptions.colors.blue400,
      '[class*="-select-labelArrow"]': {
        fill: themeOptions.colors.blue400,
      },
    },
    '&[aria-expanded="true"]': {
      '[class*="-select-labelArrow"]': {
        transform: "rotate(180deg)",
      },
    },
  },
  editLabelContainer: {
    border: "1px solid",
    borderColor: "transparent",
    borderRadius: themeOptions.borders.sm,
    color: themeOptions.colors.blue400,
    width: "fit-content",
    "&:hover": {
      background: themeOptions.colors.white,
      border: "1px solid",
      borderColor: themeOptions.colors.gray300,
      '[class*="-select-labelEditIcon"]': {
        display: "block",
      },
    },
  },
  notAssignedLabel: {
    borderColor: themeOptions.colors.orange500,
    color: themeOptions.colors.gray800,
    '[class*="-select-labelEditIcon"]': {
      display: "block",
    },
  },
  labelEditIcon: {
    padding: "4px",
    background: themeOptions.colors.gray200,
    color: themeOptions.colors.gray700,
    borderRadius: themeOptions.borders.xs,
    label: "select-labelEditIcon",
    display: "none",
    marginLeft: themeOptions.spacing.xxs,
    width: "20px",
    minWidth: "20px",
  },
  labelArrow: {
    fill: themeOptions.colors.gray700,
    label: "select-labelArrow",
    lineHeight: "12px",
    height: "12px",
  },
  selectLabel: {
    fontWeight: 500,
    color: themeOptions.colors.gray900,
    marginBottom: themeOptions.spacing.xs,
    display: "block",
  },
  disabledLabel: {
    pointerEvents: "none",
  },
  labelValue: {},
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderColor: themeOptions.colors.gray400,
    color: themeOptions.colors.gray600,
    "&:hover": {
      color: themeOptions.colors.blue400,
    },
    '&[aria-expanded="true"]': {
      color: themeOptions.colors.blue400,
      svg: {
        transform: "rotate(180deg)",
      },
    },
  },
  errorSelect: {
    borderColor: themeOptions.colors.red500,
  },
  errorLabel: {
    color: themeOptions.colors.red500,
  },
});

export default styles;
