import React, { useMemo, useState } from "react";
import Input from "components/atoms/Input";
import { getProperty } from "dot-prop";
import Select from "components/atoms/Select";
import { Col, Row } from "react-flexbox-grid";
import {
  TrashIcon,
  Pane,
  DuplicateIcon,
  CaretUpIcon,
  CaretDownIcon,
} from "evergreen-ui";
import Card from "components/atoms/Card";
import { css } from "@emotion/react";
import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";
import { useTrans } from "system/translations/hooks";
import Divider from "components/atoms/Divider";
import Btn from "components/atoms/Btn";
import FileUploader from "components/atoms/FileUploader";
const styles = createStyles({
  removeField: {
    cursor: "pointer",
    color: themeOptions.colors.gray600,
    "&:hover": {
      color: themeOptions.colors.red500,
    },
  },
  orderField: {
    cursor: "pointer",
    marginRight: themeOptions.spacing.xs,
    color: themeOptions.colors.gray600,
    "&:hover": {
      color: themeOptions.colors.gray700,
    },
  },
  settingsField: {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  },
  removeItem: {
    cursor: "pointer",
    height: "30px",
    color: themeOptions.colors.gray600,
    "&:hover": {
      color: themeOptions.colors.red500,
    },
  },
  fieldItem: {
    marginBottom: themeOptions.spacing.sm,
  },
});
const FieldItem = ({
  name,
  onChange,
  onBlur,
  onFormValueChange,
  values,
  onFormChange,
  index,
  parentName,
}: any) => {
  console.log("values", values);
  const { _t } = useTrans();
  const [isCollapsed, setCollapse] = useState(false);
  const onCollapse = () => {
    setCollapse(!isCollapsed);
  };
  const onDuplicate = () => {
    const newVal = [...values[parentName]];
    newVal.splice(index, 0, values?.[parentName]?.[index]);
    onFormChange(parentName, newVal);
  };
  const onRemove = () => {
    const newValues = [...values?.[parentName]];
    newValues.splice(index, 1);
    onFormChange(parentName, newValues);
  };
  const addRule = () => {
    const rules: any = getProperty(values, `${name}.rules`) || [];
    const newRules = [...rules];
    newRules.push({ type: "", value: "" });
    onFormValueChange(`${name}.rules`, newRules);
  };
  const addOption = () => {
    const options: any = getProperty(values, `${name}.options`) || [];
    const newOptions = [...options];
    newOptions.push({ label: "", value: "" });
    onFormValueChange(`${name}.options`, newOptions);
  };
  const onRemoveRule = (idx: number) => {
    const rules: any = getProperty(values, `${name}.rules`) || [];
    rules.splice(idx, 1);
    onFormValueChange(`${name}.rules`, rules);
  };
  const onRemoveOption = (idx: number) => {
    const options: any = getProperty(values, `${name}.options`) || [];
    options.splice(idx, 1);
    onFormValueChange(`${name}.options`, options);
  };
  const fieldsRules = useMemo(() => {
    const rules: any = getProperty(values, `${name}.rules`);
    return rules;
  }, [values]);
  const fieldsOptions: any = useMemo(() => {
    const options: any = getProperty(values, `${name}.options`);
    return options;
  }, [values]);
  const typeOptions = [
    { label: "Input text", value: "input:text" },
    /*    { label: "Input number", value: "input:number" },*/
    { label: "Select", value: "select" },
    /*    { label: "Multi select", value: "select:multi" },*/
    { label: "File Uploader", value: "files" },
    { label: "Info list", value: "infoList" },
    { label: "Headline", value: "headline" },
    { label: "Paragraph", value: "paragraph" },
    { label: "Divider", value: "divider" },
    { label: "Upload button", value: "upload_button" },
    { label: "Reset button", value: "reset_button" },
    { label: "Submit button", value: "submit_button" },
  ];
  const conditionOptions = [
    { label: "Default", value: "default" },
    { label: "Hidden - soft", value: "hidden:soft" },
    { label: "Hidden - force", value: "hidden:force" },
  ];
  const fieldRulesOptions = [
    { label: "Required", value: "required" },
    { label: "Is string", value: "string" },
    { label: "Is number", value: "number" },
    { label: "Min", value: "min" },
    { label: "Max", value: "max" },
    { label: "Matches (regex)", value: "matches" },
    { label: "Unique", value: "unique" },
  ];
  const fileRulesOptions = [
    { label: "Required", value: "required" },
    { label: "Size", value: "file_size" },
    { label: "File format", value: "file_format" },
  ];
  const fieldAlignOptions = [
    { label: "Left", value: "0 auto 0 5px" },
    { label: "Center", value: "0 auto 0 auto" },
    { label: "Right", value: "0 5px 0 auto" },
  ];
  const textAlignOptions = [
    { label: "Left", value: "left" },
    { label: "Center", value: "center" },
    { label: "Right", value: "right" },
  ];
  const fieldTypeValue: any = getProperty(values, `${name}.type`);
  const isSelect = fieldTypeValue?.includes("select");
  const isInput = fieldTypeValue?.includes("input");
  const isFiles = fieldTypeValue?.includes("files");
  const isUploadButton = fieldTypeValue?.includes("upload_button");
  const isDataEntryField = isSelect || isInput;
  const rulesOptions = isUploadButton ? fileRulesOptions : fieldRulesOptions;
  return (
    <Card
      title={getProperty(values, `${name}.name`)}
      collapsed={isCollapsed}
      onHeaderClick={onCollapse}
      extra={
        <>
          {isCollapsed ? (
            <CaretDownIcon css={css(styles.orderField)} onClick={onCollapse} />
          ) : (
            <CaretUpIcon css={css(styles.orderField)} onClick={onCollapse} />
          )}
        </>
      }
      wrapperClass={css({
        position: "relative",
        padding: themeOptions.spacing.sm,
        border: `1px solid ${themeOptions.colors.gray300}`,
      })}
      headerWrapperClass={css({
        height: !isCollapsed ? "30px" : "20px",
        borderBottom: !isCollapsed
          ? `1px solid ${themeOptions.colors.gray300}`
          : "none",
        cursor: "pointer",
      })}
    >
      <Row>
        <Col lg={12} md={12} css={css(styles.fieldItem)}>
          <Select
            onChange={(value: any) => {
              const fieldName: string = `${name}.type`;
              onChange(value, fieldName);
            }}
            value={getProperty(values, `${name}.type`)}
            label={_t("field_type")}
            placeholder={_t("field_type")}
            options={typeOptions}
          />
        </Col>
        {isFiles && (
          <Col lg={12} md={12} css={css(styles.fieldItem)}>
            <FileUploader
              onChange={(value: any) => {
                const fieldName: string = `${name}.files`;
                onChange(value, fieldName);
              }}
              value={getProperty(values, `${name}.files`)}
            />
          </Col>
        )}
        {/*        {isUploadButton && (
          <Col lg={12} md={12} css={css(styles.fieldItem)}>
            <Btn type={"button"} label={_t("upload")} />
          </Col>
        )}*/}
        {fieldTypeValue?.includes("select") && (
          <Col lg={12} md={12} css={css(styles.fieldItem)}>
            <div>
              {fieldsOptions &&
                fieldsOptions?.map((item: any, idx: number) => {
                  return (
                    <Row
                      style={{
                        alignItems: "flex-end",
                      }}
                    >
                      <Col
                        lg={1}
                        md={1}
                        style={{ textAlign: "right" }}
                        css={css(styles.fieldItem)}
                      >
                        <TrashIcon
                          css={css(styles.removeItem)}
                          onClick={() => onRemoveOption(idx)}
                        />
                      </Col>
                      <Col lg={5} md={5} css={css(styles.fieldItem)}>
                        <Input
                          name={`${name}.options[${idx}].label`}
                          value={getProperty(
                            values,
                            `${name}.options[${idx}].label`
                          )}
                          label={_t("label")}
                          onBlur={() => onBlur(`${name}.options[${idx}].label`)}
                          onChange={(value: any) => {
                            const fieldName: string = `${name}.options[${idx}].label`;
                            onChange(value, fieldName);
                          }}
                        />
                      </Col>
                      <Col lg={5} md={5} css={css(styles.fieldItem)}>
                        <Input
                          name={`${name}.options[${idx}].value`}
                          value={getProperty(
                            values,
                            `${name}.options[${idx}].value`
                          )}
                          label={_t("value")}
                          onBlur={() => onBlur(`${name}.options[${idx}].value`)}
                          onChange={(value: any) => {
                            const fieldName: string = `${name}.options[${idx}].value`;
                            onChange(value, fieldName);
                          }}
                        />
                      </Col>
                    </Row>
                  );
                })}
              <div
                style={{ marginTop: "10px", marginBottom: "10px" }}
                className={"fields-array__top__add"}
                onClick={addOption}
              >
                + {_t("add_option")}
              </div>
            </div>
          </Col>
        )}
        {fieldTypeValue?.includes("infoList") && (
          <Col lg={12} md={12} css={css(styles.fieldItem)}>
            <div>
              {fieldsOptions &&
                fieldsOptions?.map((item: any, idx: number) => {
                  return (
                    <Row
                      style={{
                        alignItems: "flex-end",
                      }}
                    >
                      <Col
                        lg={1}
                        md={1}
                        style={{ textAlign: "right" }}
                        css={css(styles.fieldItem)}
                      >
                        <TrashIcon
                          css={css(styles.removeItem)}
                          onClick={() => onRemoveOption(idx)}
                        />
                      </Col>
                      <Col lg={5} md={5} css={css(styles.fieldItem)}>
                        <Input
                          name={`${name}.options[${idx}].key`}
                          value={getProperty(
                            values,
                            `${name}.options[${idx}].key`
                          )}
                          label={_t("key")}
                          onBlur={() => onBlur(`${name}.options[${idx}].key`)}
                          onChange={(value: any) => {
                            const fieldName: string = `${name}.options[${idx}].key`;
                            const fieldNameValue: string = `${name}.options[${idx}].value`;
                            onChange(value, fieldName);
                            onChange(value, fieldNameValue);
                          }}
                        />
                      </Col>
                    </Row>
                  );
                })}
              <div
                style={{ marginTop: "10px", marginBottom: "10px" }}
                className={"fields-array__top__add"}
                onClick={addOption}
              >
                + {_t("add_option")}
              </div>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={6} md={6} css={css(styles.fieldItem)}>
          <Input
            name={`${name}.name`}
            value={getProperty(values, `${name}.name`)}
            label={_t("field_name")}
            onBlur={() => onBlur(`${name}.name`)}
            onChange={(value: any) => {
              const fieldName: string = `${name}.name`;
              onChange(value, fieldName);
            }}
          />
        </Col>
        {isDataEntryField && (
          <>
            <Col lg={6} md={6} css={css(styles.fieldItem)}>
              <Input
                name={`${name}.label`}
                value={getProperty(values, `${name}.label`)}
                label={_t("field_label")}
                onBlur={() => onBlur(`${name}.label`)}
                onChange={(value: any) => {
                  const fieldName: string = `${name}.label`;
                  onChange(value, fieldName);
                }}
              />
            </Col>
            <Col lg={6} md={6} css={css(styles.fieldItem)}>
              <Input
                name={`${name}.placeholder`}
                value={getProperty(values, `${name}.placeholder`)}
                label={_t("field_placeholder")}
                onBlur={() => onBlur(`${name}.placeholder`)}
                onChange={(value: any) => {
                  const fieldName: string = `${name}.placeholder`;
                  onChange(value, fieldName);
                }}
              />
            </Col>
          </>
        )}
        {!isFiles && (
          <Col lg={6} md={6} css={css(styles.fieldItem)}>
            <Input
              name={`${name}.value`}
              value={getProperty(values, `${name}.value`)}
              label={_t("value")}
              onBlur={() => onBlur(`${name}.value`)}
              onChange={(value: any) => {
                const fieldName: string = `${name}.value`;
                onChange(value, fieldName);
              }}
            />
          </Col>
        )}

        <Col lg={6} md={6} css={css(styles.fieldItem)}>
          <Select
            onChange={(value: any) => {
              const fieldName: string = `${name}.fieldAlign`;
              onChange(value, fieldName);
            }}
            value={getProperty(values, `${name}.fieldAlign`)}
            label={_t("field_align")}
            options={fieldAlignOptions}
          />
        </Col>
        <Col lg={6} md={6} css={css(styles.fieldItem)}>
          <Input
            name={`${name}.fieldWidth`}
            value={getProperty(values, `${name}.fieldWidth`)}
            label={_t("field_width") + "(%)"}
            onBlur={() => onBlur(`${name}.fieldWidth`)}
            onChange={(value: any) => {
              const fieldName: string = `${name}.fieldWidth`;
              onChange(value, fieldName);
            }}
          />
        </Col>
        {isDataEntryField && (
          <Col lg={6} md={6} css={css(styles.fieldItem)}>
            <Select
              onChange={(value: any) => {
                const fieldName: string = `${name}.textAlign`;
                onChange(value, fieldName);
              }}
              value={getProperty(values, `${name}.textAlign`)}
              label={_t("field_text_align")}
              options={textAlignOptions}
            />
          </Col>
        )}
        {isDataEntryField && (
          <Col lg={6} md={6} css={css(styles.fieldItem)}>
            <Select
              onChange={(value: any) => {
                const fieldName: string = `${name}.condition`;
                onChange(value, fieldName);
              }}
              value={getProperty(values, `${name}.condition`)}
              label={_t("field_condition")}
              options={conditionOptions}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={12} md={12} css={css(styles.fieldItem)}>
          {(isDataEntryField || isUploadButton) && (
            <>
              {fieldsRules?.map((item: any, idx: number) => {
                return (
                  <Row style={{ alignItems: "flex-end" }}>
                    <Col
                      lg={1}
                      md={1}
                      style={{ textAlign: "right" }}
                      css={css(styles.fieldItem)}
                    >
                      <TrashIcon
                        css={css(styles.removeItem)}
                        onClick={() => onRemoveRule(idx)}
                      />
                    </Col>
                    <Col lg={3} md={3} css={css(styles.fieldItem)}>
                      <Select
                        onChange={(value: any) => {
                          const fieldName: string = `${name}.rules[${idx}].type`;
                          onChange(value, fieldName);
                        }}
                        value={getProperty(
                          values,
                          `${name}.rules[${idx}].type`
                        )}
                        label={_t("rule_type")}
                        options={rulesOptions}
                      />
                    </Col>
                    <Col lg={4} md={4} css={css(styles.fieldItem)}>
                      <Input
                        name={`${name}.rules[${idx}].value`}
                        value={getProperty(
                          values,
                          `${name}.rules[${idx}].value`
                        )}
                        label={_t("value")}
                        onBlur={() => onBlur(`${name}.rules[${idx}].value`)}
                        onChange={(value: any) => {
                          const fieldName: string = `${name}.rules[${idx}].value`;
                          onChange(value, fieldName);
                        }}
                      />
                    </Col>
                    <Col lg={4} md={4} css={css(styles.fieldItem)}>
                      <Input
                        name={`${name}.rules[${idx}].message`}
                        value={getProperty(
                          values,
                          `${name}.rules[${idx}].message`
                        )}
                        label={_t("error_message")}
                        onBlur={() => onBlur(`${name}.rules[${idx}].message`)}
                        onChange={(value: any) => {
                          const fieldName: string = `${name}.rules[${idx}].message`;
                          onChange(value, fieldName);
                        }}
                      />
                    </Col>
                  </Row>
                );
              })}
            </>
          )}
          {(isDataEntryField || isUploadButton) && (
            <div
              style={{ marginTop: "10px" }}
              className={"fields-array__top__add"}
              onClick={addRule}
            >
              + {_t("add_validation_rule")}
            </div>
          )}
          <Divider />
          <Pane display={"flex"} gap={themeOptions.spacing.xs}>
            <Btn
              type={"button"}
              iconBefore={DuplicateIcon}
              onClick={onDuplicate}
            >
              {_t("duplicate")}
            </Btn>
            <Btn type={"button"} iconBefore={TrashIcon} onClick={onRemove}>
              {_t("remove")}
            </Btn>
            <Btn type={"button"} kind={"custom"} onClick={onCollapse}>
              {_t("collapse")}
            </Btn>
          </Pane>
        </Col>
      </Row>
    </Card>
  );
};

export default FieldItem;
