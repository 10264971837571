import React, { useMemo } from "react";
import FormBuilder from "services/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useGetPayment, useUpdatePayment } from "../apiHooks";
import Card from "../../../components/atoms/Card";
import { useHistory, useParams } from "react-router-dom";
import { createEditValidation } from "../validation";
import { useCreateEditConfig } from "../constantHooks";
import Loader from "components/atoms/Loader";
import SectionTitle from "components/atoms/SectionTitle";
import { timeToDeclineDefault } from "../constants";

const Update: React.FC = () => {
  const isEdit = true;
  const { _t } = useTrans();
  const history = useHistory();
  const routeParams: any = useParams();
  const { mutateAsync: onUpdate, isLoading: isUpdating } = useUpdatePayment();
  const { data: data, isLoading } = useGetPayment(routeParams?.id);
  const formConfig = useCreateEditConfig({ isEdit, isLoading: isUpdating });
  const initialUsers = useMemo(() => {
    return data?.users?.map((item) => item.id);
  }, [data]);
  const onSubmit = async (data: any) => {
    /*    await onUpdate({ data: data, id: routeParams?.id });
    history.goBack();*/
    if (data.type === "ALL") {
      await onUpdate({
        data: { ...data, type: "DEPOSITS" },
        id: routeParams?.id,
      });
      await onUpdate({
        data: { ...data, type: "WITHDRAWALS" },
        id: routeParams?.id,
      });
    } else {
      await onUpdate({ data: data, id: routeParams?.id });
    }
    history.goBack();
  };
  /*
  const onDeactivate = () => {
    onUpdate({ data: { ...merchantData, disable: true }, id: routeParams?.id });
  };
*/

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <>
      <SectionTitle title={data?.code || _t("method")} />
      <Card>
        <FormBuilder
          formItemsConfig={formConfig}
          showSubmit={false}
          formProps={{
            onSubmit: onSubmit,
            initialValues: {
              code: data?.code,
              description: data?.description,
              enabled: data?.enabled,
              id: data?.id,
              type: data?.type,
              usersWithAccess: initialUsers,
              maxAmount: data?.maxAmount,
              minAmount: data?.minAmount,
              timeToDecline: data?.timeToDecline || timeToDeclineDefault
            },
            validationSchema: createEditValidation(_t, {
              isEdit,
            }),
          }}
        />
      </Card>
    </>
  );
};

export default Update;
