import React, { useMemo } from "react";
import FormBuilder from "services/formBuilder/FormBuilder";
import Table from "services/table";
import { useTrans } from "system/translations/hooks";
import {
  useFilters,
  useGetSortMenuMobileItems,
  useListPagination,
  useListSort,
  useMobileFiltersDrawer,
  usePrepareForm,
} from "system/helpers/hooks";
import { useGetActionLog } from "modules/actionLog/apiHooks";
import {
  useTableColumns,
  useSearchFormConfig,
} from "modules/actionLog/constantHooks";
import config from "system/config";
import { format } from "date-fns";
import SectionTitle from "components/atoms/SectionTitle";
import { useDrawer } from "services/drawer/hooks";
import globalStyles, { isMobile } from "system/theme/globalStyles";
import FilterMobileButtons from "components/molecules/FilterMobileButtons";

const ActionLog: React.FC = () => {
  const { _t } = useTrans();
  const { hideDrawer } = useDrawer()
  const filterInitialValues = useMemo(
    () => ({
      fromDate: format(new Date(), config.dateFormat),
      toDate: format(new Date(), config.dateFormat),
    }),
    []
  );
  const normalizeReqData = (data: any, initialValues: any = {}) => {
    const normalizeDate = {
      ...data,
    };
    if (data?.date) {
      normalizeDate.fromDate = data?.date?.fromDate;
      normalizeDate.toDate = data?.date?.toDate;
    }
    !isMobile && delete normalizeDate.date;
    delete normalizeDate.searchFrom;
    return normalizeDate;
  };
  const searchFormConfig = useSearchFormConfig();
  const {
    mobileSearchFormConfig,
    filterParams,
    filters,
    setFilters
  } = usePrepareForm(searchFormConfig, filterInitialValues, normalizeReqData)
  const { showFilters, filtersMobile, setFiltersMobile } = useMobileFiltersDrawer(filterParams);
  const { sort, setSort } = useListSort({});
  const { pagination, setPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const { sortMenuItems } =  useGetSortMenuMobileItems('Last updated','userEmail', setSort)
  const requestParams = useMemo(
    () => ({ ...filters, ...pagination, ...sort }),
    [filters, pagination, sort]
  );
  const { data, isLoading } = useGetActionLog(requestParams);
  const columns = useTableColumns();
  const onFilterSubmit = async (data: any) => {
    const normalizeDate = normalizeReqData(data)
    setPagination(config.defaultInitialPagination);
    isMobile
      ? setFilters({ ...normalizeDate, ...filtersMobile })
      : setFilters(normalizeDate);
    hideDrawer('filters');
  };
  return (
    <>
      <div css={globalStyles.mobileHeader}>
        <SectionTitle title={_t("action_log")} />
        <FormBuilder
          formItemsConfig={isMobile ? mobileSearchFormConfig : searchFormConfig}
          showSubmit={false}
          formProps={{
            submitBtnLabel: _t("apply_filters"),
            onSubmit: onFilterSubmit,
            initialValues: filterInitialValues,
          }}
        />
        {isMobile &&
          <FilterMobileButtons
            showFilters={showFilters}
            sortMenuItems={sortMenuItems}
            setFiltersMobile={setFiltersMobile}
            setFilters={setFilters}
            filters={filtersMobile}
            totalElements={data?.totalElements || 0}
            filterInitialValues={filterInitialValues}
          />
        }
      </div>
      <Table
        totalRecords={data?.totalElements}
        onPagination={setPagination}
        columns={columns}
        onSort={setSort}
        isLoading={isLoading}
        data={data?.content || []}
        isPagination
        pageIndex={pagination.page}
        pageSize={pagination.perPage}
      />
    </>
  );
};

export default ActionLog;
