import React from "react";
import AssignSelect from "components/molecules/AssignSelect";
import { useTrans } from "system/translations/hooks";
import { Pane, Tooltip } from "evergreen-ui";
import { useConfirmModal } from "services/modal/predefinedModals";
import {
  useAssignTransaction,
  useResetAssignTransaction,
} from "modules/transactions/apiHooks";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";

const TransactionAssign = ({ transaction }: any) => {
  const { _t } = useTrans();
  const { onConfirm } = useConfirmModal();
  const {
    mutate: onAssign,
    isLoading: isAssigning,
    variables: assignVariables,
  } = useAssignTransaction();
  const {
    mutate: onReset,
    isLoading: isResetting,
    variables: resetVariables,
  } = useResetAssignTransaction();
  const selected = !!transaction?.agent;
  const onClick = (val: boolean) => {
    if (val) {
      onConfirm({
        onOk: () => {
          onReset({ id: transaction?.transactionUUID });
        },
        title: `${_t("reset_assignment")} ?`,
        bodyText: _t("reset_assignment_description"),
        onOkKind: "primary",
        onOkText: _t("reset"),
      });
    } else {
      onAssign({ id: transaction?.transactionUUID });
    }
  };
  const label = !selected
    ? _t("not_assigned")
    : `${transaction?.agent?.firstName}`; //${transaction?.agent?.lastName}
  if (transaction?.transactionStatus !== "PENDING") {
    return <div>{label}</div>;
  }

  return (
    <RoleWrapper accessTo={"ROLE_MANAGE_TRANSACTIONS"}>
      <Tooltip
        position={"right"}
        content={selected ? _t("reset_assignment") : _t("assign_to_yourself")}
      >
        <div>
          <AssignSelect
            isLoading={
              (isAssigning &&
                assignVariables?.id === transaction?.transactionUUID) ||
              (isResetting &&
                resetVariables?.id === transaction?.transactionUUID)
            }
            onClick={onClick}
            label={label}
            selected={selected}
          />
        </div>
      </Tooltip>
    </RoleWrapper>
  );
};

export default TransactionAssign;
