import { Link } from "carbon-components-react";
import InfoTag from "components/atoms/InfoTag";
import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../../services/formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useDefaultFieldsConfig } from "services/formBuilder/hooks";
import {
  paymentTypeOptions,
  methodStatusOptions,
} from "modules/payments/constants";
import { generatePath, useHistory } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import { usePaymentsModuleData } from "modules/payments/hooks";
import { TableColumnType } from "services/table/types";
import globalStyles, { isMobile } from "system/theme/globalStyles";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const history = useHistory();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("method_name"),
        id: "code",
        isMobileTitle: true,
        Cell: (props: any) => {
          return (
            <Link
              onClick={() =>
                history.push(
                  generatePath(APP_ROUTES.payments.update, {
                    id: props?.row?.original?.id,
                  }),
                  history.location
                )
              }
            >
              {props?.row?.original?.code}
            </Link>
          );
        },
      },
      {
        Header: _t("type"),
        id: "type",
        accessor: "type",
      },
      {
        Header: _t("status"),
        Cell: (props: any) => {
          const status = props?.row?.original?.enabled
            ? "active"
            : "deactivated";
          return (
            <>
              <InfoTag
                type={"secondary"}
                size={"sm"}
                status={status}
                label={status}
              />
            </>
          );
        },
      },
      {
        width: 40,
        Header: "",
        id: "actionMenu",
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const statusOptions = methodStatusOptions(_t);
  const typeOptions = paymentTypeOptions(_t);
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.filterValue({
        componentProps: {
          showSearch: true,
          SearchIconDescription: _t("search"),
        },
        openDrawer: true,
        columnParams: {
          md: 6,
          lg: 6,
        },
        name: "name",
      }),
      fields.filters.status({
        componentProps: {
          options: statusOptions,
        },
      }),
      fields.filters.type({
        componentProps: {
          options: typeOptions,
        },
      }),
      fields.general.submitBtn({
        componentProps: {
          css: isMobile && globalStyles.submitBtnForMobileFilters,
        },
      }),
    ];
  }, []);
  return formConfig;
};

export const useCreateEditConfig = ({
  isEdit,
  isLoading,
}: {
  isEdit: boolean;
  isLoading?: boolean;
}): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const typeOptions = paymentTypeOptions(_t);
  const { usersOptions } = usePaymentsModuleData();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.type({
        componentProps: {
          options: typeOptions,
        },
        columnParams: {
          md: 6,
          lg: 6,
        },
      }),
      fields.formFields.code({
        componentProps: {
          label: _t("method_name"),
          placeholder: _t("method_name"),
        },
        columnParams: {
          md: 6,
          lg: 6,
        },
        emptySpace: {
          md: 12,
          lg: 12,
        },
      }),
      fields.formFields.minAmount({
        columnParams: {
          md: 3,
          lg: 3,
        },
        emptySpace: {
          md: 12,
          lg: 12,
        },
      }),
      fields.formFields.maxAmount({
        columnParams: {
          md: 3,
          lg: 3,
        },
      }),
      fields.formFields.timeToDecline({
        columnParams: {
          md: 6,
          lg: 6,
        },
        emptySpace: {
          md: 12,
          lg: 12,
        },
      }),
      fields.formFields.usersWithAccess({
        componentProps: {
          isOpen: !isMobile,
          twoColumns: !isMobile,
          options: usersOptions,
          isMobileCreateEdit: isMobile,
        },
        columnParams: {
          md: 8,
          lg: 8,
        },
      }),
      fields.formFields.description({
        columnParams: {
          md: 6,
          lg: 6,
        },
      }),
      fields.formFields.enabled(),
      fields.general.divider(),
      fields.general.saveBtn({
        componentProps: {
          kind: isEdit ? "primary" : "secondary",
          label: isEdit ? _t("save_changes") : _t("create_method"),
          isLoading: isLoading,
        },
      }),
    ];
  }, [isLoading, usersOptions]);
  return formConfig;
};
