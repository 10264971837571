import React from "react";
import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";
import { InfoSignIcon } from "evergreen-ui";
import { ipExamples } from "../constants";
import { useTrans } from "system/translations/hooks";

const styles = createStyles({
  infoContainer: {
    backgroundColor: themeOptions.colors.b50,
    padding: themeOptions.spacing.md,
    borderRadius: "8px",
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  infoHeader: {
    color: themeOptions.colors.n900,
    fontWeight: 550,
    fontSize: '14px',
    lineHeight: '20px',
  },
  infoExampleList: {
    marginLeft: '48px',
    lineHeight: '20px'
  },
  infoExampleItem: {
    listStyleType: 'initial',
    color: themeOptions.colors.n800
  },
  iconContainer: {
    minWidth: '16px'
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px'
  }
});

const DrawerInfo = () => {
  const { _t } = useTrans();
  return (
    <div css={styles.infoContainer}>
    <div css={styles.headerContainer}>
      <div css={styles.iconContainer}>
        <InfoSignIcon color={themeOptions.colors.b400}/>
      </div>
      <div css={styles.infoHeader}>{_t('ip_whitelist_info_header')}</div>
    </div>
      <ul css={styles.infoExampleList}>
        {ipExamples.map((example: string) => <li css={styles.infoExampleItem}>{example}</li>)}
      </ul>
  </div>
  );
};

export default DrawerInfo;
