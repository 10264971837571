import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  container: {
    display: "flex",
    alignItems: "center",
    gap: themeOptions.spacing.xs,
  },
  icon: {
    cursor: "pointer",
    color: themeOptions.colors.gray600,
    "&:hover": {
      color: themeOptions.colors.gray700,
    },
  },
});

export default styles;
