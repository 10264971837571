import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  wrapper: {
    display: "flex",
    gap: themeOptions.spacing.sm,
    justifyContent: "center",
  },
  button: {
    cursor: "pointer",
    border: `1px solid ${themeOptions.colors.gray300}`,
    borderRadius: themeOptions.borders.sm,
    height: "100px",
    width: "100%",
    maxWidth: "220px",
    display: "grid",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
    alignContent: "center",
    gap: themeOptions.spacing.sm,
    color: themeOptions.colors.gray700,
  },
  approve: {
    borderColor: themeOptions.colors.green400,
    background: themeOptions.colors.green100,
  },
  decline: {
    borderColor: themeOptions.colors.red500,
    background: themeOptions.colors.red25,
  },
});

export default styles;
