import React from "react";
import { InfoListProps } from "components/atoms/InfoList/types";
import styles from "components/atoms/InfoList/styles";
import { css } from "@emotion/react";

const InfoList: React.FC<InfoListProps> = ({ options }) => {
  return (
    <div css={css(styles.container)}>
      {options?.map((item: any) => {
        return (
          <div css={css(styles.item)}>
            <div css={css(styles.itemLabel)}>{item.key}</div>
            <div css={css(styles.itemValue)}>{item.value}</div>
          </div>
        );
      })}
    </div>
  );
};

export default InfoList;
