import React, { useEffect, useState } from "react";

const FilesPreview = ({ files }: any) => {
  const [links, setLink] = useState<any>([]);
  const getFilesSrc = (file: any) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    });
  };
  const modifyFileLinks = async (files: any) => {
    const linksData = await Promise.all(
      files.map(async (item: any) => {
        if (typeof item === "string") {
          return item;
        }
        return await getFilesSrc(item);
      })
    );
    setLink(linksData);
  };
  useEffect(() => {
    if (Array.isArray(files)) {
      modifyFileLinks(files);
    }
  }, [files]);
  return (
    <>
      {links.map((item: any, idx: number) => (
        <img
          alt={""}
          key={idx}
          style={{ maxWidth: "120px", margin: "5px" }}
          src={item}
        />
      ))}
    </>
  );
};

export default FilesPreview;
