import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_MERCHANT_PAYMENTS,
  API_GET_MERCHANT_PAYMENT_BY_ID,
  API_CREATE_MERCHANT_PAYMENT,
  API_UPDATE_MERCHANT_PAYMENT,
  API_DELETE_MERCHANT_PAYMENT,
  API_GET_METHOD_PAYMENT_DETAILS,
  API_GET_METHOD_PAYMENT_DETAILS_HISTORY,
  API_UPDATE_METHOD_PAYMENT_DETAILS,
  API_DELETE_METHOD_PAYMENT_DETAIL,
} from "system/api/apiUrls";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import {
  PaymentsDataType,
  PaymentItemType,
  PaymentDetailsDataType,
  PaymentDetailsActionLogDataType,
} from "modules/merchantPaymentMethods/types";
import { useTrans } from "system/translations/hooks";

const getMerchantPaymentMethods = "getMerchantPaymentMethods";
const getMerchantPaymentMethodsById = "getMerchantPaymentMethodsById";
const getMethodPaymentDetails = "getMethodPaymentDetails";
const getMethodPaymentDetailsHistory = "getMethodPaymentDetailsHistory";

export const useGetMerchantPaymentMethods = (pagination: any) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantPaymentMethods, pagination],
    (): Promise<PaymentsDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_MERCHANT_PAYMENTS, pagination),
        method: "get",
      })
  );
};
export const useGetMerchantPaymentMethodsById = (
  merchantId: number,
  id: number | string
) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantPaymentMethodsById, merchantId, id],
    (): Promise<PaymentItemType> =>
      apiRequest({
        url: generatePath(API_GET_MERCHANT_PAYMENT_BY_ID, { id }),
        method: "get",
      })
  );
};

export const useCreateMerchantPaymentMethods = () => {
  const { _t } = useTrans();
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  const message = _t("successfully_added_method");
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_MERCHANT_PAYMENT,
        method: "put",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantPaymentMethods);
        apiSuccessMessage(message);
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useUpdateMerchantPaymentMethods = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_MERCHANT_PAYMENT, {
          id: data.id,
        }),
        method: "post",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantPaymentMethods);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useDeleteMerchantPaymentMethods = (merchantId: number) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_MERCHANT_PAYMENT, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantPaymentMethods);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useGetMethodPaymentDetails = ({
  methodId,
}: {
  methodId: string;
}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMethodPaymentDetails, methodId],
    (): Promise<PaymentDetailsDataType> =>
      apiRequest({
        url: generatePath(API_GET_METHOD_PAYMENT_DETAILS, { methodId }),
        method: "get",
      }),
    {
      staleTime: 60 * 1000,
    }
  );
};

export const useGetMethodPaymentDetailsHistory = (
  methodId: string,
  filters: any
) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMethodPaymentDetailsHistory, filters, methodId],
    (): Promise<PaymentDetailsActionLogDataType> =>
      apiRequest({
        url: generateUrlQuery(
          generatePath(API_GET_METHOD_PAYMENT_DETAILS_HISTORY, { methodId }),
          filters
        ),
        method: "get",
      }),
    {
      staleTime: 60 * 1000,
    }
  );
};

export const useUpdateMethodPaymentDetails = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_UPDATE_METHOD_PAYMENT_DETAILS,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMethodPaymentDetails);
        queryClient.invalidateQueries(getMethodPaymentDetailsHistory);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useDeleteMethodPaymentDetail = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    ({ id }: { id: number }) =>
      apiRequest({
        url: generatePath(API_DELETE_METHOD_PAYMENT_DETAIL, { id }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMethodPaymentDetails);
        queryClient.invalidateQueries(getMethodPaymentDetailsHistory);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
