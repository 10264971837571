import { TransType } from "./types";

const defaultTranslations = {
  name: "Name",
  email: "Email",
  comment: "Comment",
  ip: "Ip",
  role: "Role",
  active: "Active",
  inactive: "Inactive",
  tfa: "Tfa",
  last_login: "Last login",
  last_ip_session: "Last IP session",
  roles: "Roles",
  title: "Title",
  code2: "Code2",
  code3: "Code3",
  no_data_text: "No data, change request params",
  no_data: "No data",
  merchants: "Merchants",
  status: "Status",
  sub: "Sub",
  message: "Message",
  search: "Search",
  submit: "Submit",
  clear_all_filters: "Clear all filters",
  reset: "Reset",
  search_by_name: "Search by name",
  date_range: "Date range",
  user: "User",
  user_id: "User id",
  kyc_max_amount: "Kyc max amount",
  max_amount: "Max amount",
  min_amount: "Min amount",
  parameters: "Parameters",
  action: "Action",
  date: "Date",
  user_ip: "User ip",
  entity_type: "Entity type",
  action_type: "Action type",
  entity_name: "Entity name",
  apply_filters: "Apply filters",
  summary: "Summary",
  no_date_selected: "No date selected",
  deactivated: "Deactivated",
  deactivateUser: "Deactivate user",
  key: "Key",
  old_password: "Old password",
  new_password: "New password",
  confirm_password: "Confirm password",
  merchant: "Merchant",
  merchant_id: "Merchant id",
  event_type: "Event type",
  record_type: "Record type",
  transaction_status_after: "Transaction status after",
  transaction_status_before: "Transaction status before",
  transaction_incoming_id: "Transaction incoming id",
  transactionUUID: "Transaction UUID",
  payment_gateway_code: "Payment gateway code",
  gateway: "Gateway",
  currency: "Currency",
  custom_uuid: "Custom Uuid",
  deposit_methods: "Deposit methods",
  withdrawal_methods: "Withdrawal methods",
  transaction: "Transaction",
  uuid: "UUID",
  uuids: "UUIDs",
  id: "Id",
  provider: "Provider",
  credential: "Credential",
  mask_sensitive_data: "Mask sensitive data",
  mask_sensitive: "Mask sensitive",
  method: "Method",
  create: "Create",
  merchant_monitoring_settings: "Merchant monitoring settings",
  edit: "Edit",
  delete: "Delete",
  deactivate: "Deactivate",
  alerts: "Alerts",
  amount: "Amount",
  processed_amount: "Processed amount",
  type: "Type",
  transactions: "Transactions",
  dashboard: "Dashboard",
  change_transactions_status: "Change transaction status",
  request_history: "Request history",
  users: "Users",
  merchants_balance: "Merchants balance",
  balance: "Balance",
  merchants_fees: "Merchants' fees",
  settlement_details: "Settlement Details",
  balance_history: "Balance History",
  statistics: "Statistics",
  action_log: "Action log",
  history: "History",
  app_settings: "App settings",
  translations: "Translations",
  monitoring_settings: "Monitoring settings",
  user_management: "User Management",
  monitoring_rules: "Monitoring rules",
  monitoring_merchant: "Monitoring merhcant",
  create_user: "Create new user",
  change_password: "Change password",
  change: "Change",
  create_merchant: "Create merchant",
  create_payment_method: "Create payment method",
  edit_user: "Edit user",
  import: "Import",
  export_CSV: "Export CSV",
  refresh_all: "Refresh all",
  cancel: "Cancel",
  on_ok: "Ok",
  resend_ipn: "Resend ipn",
  ipn_sent: "IPN sent",
  password: "Password",
  save_changes: "Save changes",
  create_method: "Create method",
  invalid_email: "Invalid email",
  required: "Required",
  invalid: "Invalid",
  required_email: "Email required",
  deactivateTranslation: "Deactivate translations",
  edit_translation: "Edit translation",
  create_translation: "Create translation",
  create_key: "Create key",
  description: "Description",
  priority: "Priority",
  edit_role: "Edit role",
  delete_role: "Delete role",
  "language{fieldName}": "{fieldName}",
  payment_gateways: "Payment gateways",
  payment_gateway: "Payment gateway",
  success_rate_threshold: "Success rate threshold",
  success_rate_check_length: "Success rate check length",
  errors_in_row_threshold: "Errors in row threshold",
  errors_in_row_check_enabled: "Errors in row check enabled",
  success_rate_check_enabled: "Success rate check enabled",
  max_cumulative_amount: "Max cumulative amount",
  max_cumulative_amount_check_enabled: "Max cumulative amount check enabled",
  max_single_amount_check_enabled: "Max single amount check enabled",
  cumulative_amount_check_time_frame: "Cumulative amount check time frame",
  cumulative_amount_check_length: "Cumulative amount check length",
  max_single_amount: "Max single amount",
  currencies: "Currencies",
  gateway_name: "Gateway",
  gateway_transaction_id: "Gateway transactionId",
  ipn: "Ipn",
  payment_methods: "Payment methods",
  countries_supported: "Countries supported",
  operation: "Operation",
  operation_types: "Operation types",
  transaction_statuses: "Transaction statuses",
  transaction_types: "Transaction types",
  export_fields: "Export fields",
  updated_date: "Updated",
  processed_date: "Processed date",
  created_date: "Created",
  payment_method: "Payment method",
  reference_number: "Reference number",
  client: "Client",
  country: "Country",
  converted_amount: "Converted amount",
  credit_card: "Credit card",
  merchant_client_id: "Merchant client id",
  card_number: "Card number",
  customer_email: "Customer email",
  customer: "Customer",
  merchant_order_no: "Merchant order No",
  psp_order_no: "PSP order No",
  history_log: "History log",
  state: "State",
  city: "City",
  postcode: "Postcode",
  address: "Address",
  street: "Street",
  wallet_id: "WalletId",
  com_type: "ComType",
  "3d_status": "3d_status",
  mcc: "Mcc",
  descriptor: "Descriptor",
  ipns: "Ipns",
  partial_refund: "Partial refund",
  partial_capture: "Partial capture",
  all_countries: "All countries",
  default: "Default",
  refundable: "Refundable",
  stored_supported: "Stored supported",
  voucher_auto: "Voucher auto",
  dynamic_descriptor: "Dynamic descriptor",
  avs_check: "AvsCheck",
  disable: "Disable",
  api_v2: "API V2",
  allow_dynamic_descriptor: "Allow dynamic descriptor",
  add_sub_merchant: "Add sub merchant",
  sub_merchant: "Sub merchants",
  deposit: "Deposit",
  all: "All",
  withdrawal: "Withdrawal",
  payment_type: "Types",
  payment_provider: "Payment provider",
  payment_credentials: "Payment credentials",
  update: "Update",
  update_merchant_payment: "Update merchant payment",
  create_merchant_blacklist: "Create blacklist",
  create_merchant_whitelist: "Create whitelist",
  create_setting: "Create setting",
  update_blacklist_email: "Update blacklist email",
  update_whitelist: "Update whitelist",
  create_role: "Create role",
  create_new_role: "Create new role",
  ip_whitelisting: "IP whitelisting",
  blacklist: "Blacklist",
  whitelist: "Whitelist",
  credentials: "Credentials",
  createdDate: "Created date",
  apiSecret: "Api secret",
  updatedDate: "Updated date",
  apiKey: "Api key",
  settings: "Settings",
  set_up_via_google_auth: "Set up via google authentication",
  go_to_login: "Go to login page",
  your_email_verified: "Your email has been verified",
  you_can_login: "You can now login to your account",
  enter_key_manually: "or enter key manually using application",
  open_menu: "Open menu",
  api_documentation: "API Documentation",
  checkout_integration: "Checkout Integration",
  are_you_sure: "Are you sure?",
  "merchant_details_for_{merchant}": "Merchant details for {merchant}",
  transaction_details: "Transaction details",
  remember_me: "Remember me",
  continue: "Continue",
  forgot_password: "Forgot password",
  log_in_to: "Log in to your account",
  all_rights_reserved: "Ozzypay ©2022 - All rights reserved",
  forgot_password_help_text:
    "Enter your account email and we'll send you an email to reset the password",
  send_instructions: "Send instructions",
  success: "Success",
  export_csv_notification: "The report was sent to the email",
  forgot_password_email_sent:
    "You will receive an email with instructions about how to reset your password",
  user_not_confirmed: "User not confirmed, please check your email",
  reset_password: "Reset password",
  other_details: "Other details",
  reset_password_help_text: "Enter a new password for your account",
  back_to_login: "Back to login",
  new_password_placeholder: "Enter new password",
  confirm_new_password: "Confirm new password",
  password_must_be_longer: "Password must be longer",
  password_must_be_shorter: "Password must be shorter",
  password_must_contain_at_least_one_number:
    "Password must contain at least one number",
  password_must_contain_at_least_one_uppercase:
    "Password must contain at least one uppercase letter",
  password_must_contain_at_least_one_special_character:
    "Password must contain at least one special character (#?!@$%^&*-)",
  password_must_contain_at_least_one_lowercase:
    "Password must contain at least one lowercase letter",
  invalid_password_format: "Invalid password format!",
  passwords_must_match: "Passwords must match",
  password_changed: "Password changed",
  password_changed_message:
    "Your password was successfully changed. Your can now use your new password to log into your account.",
  loading: "Loading...",
  code: "Code",
  enter_code: "Enter code",
  verify: "Verify",
  tfa_authentication: "2FA Authentication",
  tfa_authentication_help_text:
    "Enter the code displayed in your Google Authenticator app",
  must_be_a_number: "Must be a number",
  must_be_lower_than_max: "Must be lower then max",
  must_be_greater_than_min: "Must be greater then min",
  must_be_greater: "Must be greater",
  "verify_code_require_{length}_digit": "Code should be {length}-digit",
  "tfa_contact_support_via_mail_{link}":
    "If you’ve lose your device or can’t use your app, please contact {link}",
  verify_qr_title: "Set up via Google Authenticator",
  verify_qr_step_1:
    "1. Download and install the Google Authentificator app on your mobile device.",
  verify_qr_step_2: "2. Scan the QR code",
  verify_qr_step_2_extra: "or enter key manually using the application:",
  verify_qr_step_3:
    "3. Enter the  6-digit code generated by Google Authentificator.",
  request_header: "Request header",
  request_params: "Request params",
  request_data: "Request data",
  response_header: "Response header",
  response_data: "Response data",
  auth_tfa_description:
    "Protect account by requiring an additional layar of security to log in",
  reset_tfa: "Reset Two-factor Authentication",
  reset_tfa_description:
    "User «{userName}» will be immediately reset from 2FA. This action cannot be undone.",
  auth_tfa: "Two-factor Authentication",
  email_confirmation: "Email confirmation",
  email_confirmation_description:
    "The confirmation link will be sent to the email address registered to this account",
  reset_password_description:
    "User will receive an email with instructions about how to reset the password",
  send_email_link: "Send email with a reset link",
  resend_confirmation_link: "Resend confirmation link",
  selectedCredentials: "selectedCredentials",
  selectedCurrencies: "selectedCurrencies",
  selectedPaymentMethods: "selectedPaymentMethods",
  paymentMethods: "paymentMethods",
  credentialType: "credentialType",
  recipient: "Recipient",
  alert_type: "Alert type",
  menu: "Menu",
  logout: "Logout",
  copiedUUID: "UUID copied!",
  cumulative_amount: "Cumulative amount",
  errors_in_a_row: "Errors in a row",
  single_amount: "Single amount",
  success_rate: "Success rate",
  select_all: "Select All",
  missed_merchant: "No merchant has been selected",
  remove: "Remove",
  selected_small: "selected",
  value: "Value",
  "deactivating_of_{user}_header_text": "Deactivate {user} account?",
  deactivating_of_user_body_text:
    "After deactivation, user will lose access to the account.\nBut you can reactivate it at any time.",
  "last_updated_{time}_at": "Last updated {time} ago",
  welcome: "Welcome",
  secretKey: "Secret key",
  agents: "Agents",
  users_management: "Users management",
  rejection_reasons: "Rejection reasons",
  rejection_reason: "Rejection reason",
  atm_fees: "ATM fees",
  transaction_assignment: "Transaction assignment",
  countries: "Countries",
  currency_name: "Currency name",
  create_currency: "Create currency",
  edit_currency: "Edit currency",
  delete_currency: "Delete currency",
  send_instruction: "Send instruction",
  clear_all: "Clear all",
  "applied_filters_{filterQuantity}": "{filterQuantity} Filters applied.",
  "results_{resultQuantity}": "{resultQuantity} results",
  results_per_page: "Results per page",
  transaction_id: "Transaction Id",
  approve_decline: "Approve/Decline",
  done_by: "Done by",
  merchant_transaction_id: "Merchant transaction Id",
  sender: "Sender",
  receiver: "Receiver",
  bank_code: "Bank code",
  client_name: "Client name",
  methods: "Methods",
  create_methods: "Create methods",
  form_template: "Form template",
  method_name: "Method name",
  action_cannot_undone: "This action cannot be undone.",
  delete_key: "Delete key",
  approve: "Approve",
  approve_transaction: "Approve transaction",
  approve_transaction_descriptions:
    "Are you sure you want to approve this transaction? This action cannot be undone.",
  decline_transaction_descriptions:
    "Please, select a reason for declining this transaction.",
  decline: "Decline",
  decline_transaction: "Decline transaction",
  enabled: "Enabled",
  disabled: "Disabled",
  edit_country: "Edit country",
  delete_country: "Delete country",
  country_name: "Country name",
  create_country: "Create country",
  create_rule: "Create rule",
  assignment_rules: "Transaction assignment rules",
  atm: "Create country",
  users_with_access: "Users with access",
  create_new_method: "Create new method",
  tab_pending: "Pending",
  transaction_history: "Transaction history",
  all_transactions: "All transactions",
  merchant_information: "Merchant information",
  add_ip_address: "Add ip address",
  add_email_address: "Add email address",
  add_method: "Add method",
  edit_method: "Edit method",
  password_format_description:
    "At least 8 or more characters, Include lowercase and uppercase alphabetic characters, numbers and symbols.",
  deactivate_user: "Deactivate User",
  "user_deactivation_description_{userName}":
    "After deactivation, user «{userName}» will lose access to the account. But you can reactivate it at any time.",
  "merchant_deactivation_description_{merchantName}":
    "After deactivation, users will lose access to the merchant «{merchantName}». But you can reactivate it at any time.",
  action_cannot_be_undone: "This action cannot be undone.",
  delete_email: "Delete email",
  delete_role_description:
    "This will permanently delete the role. Deleting this role will delete all associated settings for users.",
  security: "Security",
  security_settings: "Security settings",
  commission: "Сommission %",
  assign_to_yourself: "Assign to yourself",
  not_assigned: "Not assigned",
  reset_assignment: "Reset assignment",
  reset_assignment_description:
    "Are you sure you want reset assignment? This action cannot be undone.",
  reset_tfa_notification: "Two-factor Authentification reset successful",
  reset_tfa_notification_description:
    "To log into an account, the user needs to setup 2fa.",
  successfully_added_new_ip_to_whitelist:
    "You have successfully added a new IP to whitelist.",
  successfully_added_new_email_to_blacklist:
    "You have successfully added a new email to blacklist.",
  successfully_created_new_payment_method:
    "You have successfully created a new payment method.",
  successfully_created_new_form_template:
    "You have successfully created a new form template",
  close: "Close",
  successfully_added_method: "You have successfully added method.",
  copied_successfully: "Copied successfully",
  delete_method: "Delete method",
  field_label: "Field label",
  field_placeholder: "Field placeholder",
  field_name: "Field name",
  field_type: "Field type",
  label: "Label",
  field_condition: "Field condition",
  default_value: "Default value",
  rule_type: "Rule type",
  error_message: "Error message",
  add_validation_rule: "Add validation rule",
  add_option: "Add option",
  add_key: "Add key",
  create_template: "Create template",
  template_name: "Template name",
  styles: "Styles",
  fields_settings: "Fields settings",
  fields_order: "Fields order",
  fields_json: "Fields json",
  fields_styles_json: "Fields styles json",
  json_fields_preview: "Json fields preview",
  json_styles_preview: "Json styles preview",
  create_new_template: "Create new template",
  edit_template: "Edit template",
  form_preview: "Form preview",
  collapse: "Collapse",
  currency_length_error: "Must be 3 characters",
  currency_case_error: "Three characters must be in uppercase",
  uppercase_only: "Uppercase only",
  edit_order: "Edit order",
  json_settings: "JSON settings",
  form_elements: "Form elements",
  style_customization: "Style Customization",
  duplicate: "Duplicate",
  general_colors: "General Colors",
  font_colors: "Font Colors",
  space: "Space",
  other: "Other",
  field_align: "Field align",
  field_width: "Field width",
  field_text_align: "Field text align",
  payment_details: "Payment details",
  add_payment_details: "Add the payment details",
  not_used_credentials: "Credentials that are not in use",
  last_edited: "Last edited",
  changed_value: "Changed value",
  filters: "Filters",
  "{form_name}": "{form_name}",
  selected: "selected",
  "select_{multiselect_name}": "Select {multiselect_name}...",
  sort: "Sort",
  last_updated: "Last updated",
  last_created: "Last created",
  sort_A_Z: "Sort A-Z",
  sort_Z_A: "Sort Z-A",
  by_id: "By ID",
  "{sort_default_name}": "{sort_default_name}",
  redirect_enabled: "Redirect enabled",
  old_value: "Old value",
  upload: "Upload",
  bottom_description: "Bottom description",
  ip_whitelist_info_header:
    "A list of whitelist entries of IPv4 or IPv6 addresses and optional network masks, e.g.:",
  pending_status: "Pending",
  waiting_status: "Waiting",
  approved_status: "Approved",
  canceled_status: "Canceled",
  declined_status: "Declined",
  error_status: "Error",
  merchant_customer_id: "Merchant customer id",
  value_input: "Value ( Input )",
  value_json: "Value ( JsonEditor )",
  decline_timeout_min_label: "Decline timeout (minutes)",
};

export default defaultTranslations;
