import { useContext } from "react";
import DrawerContext from "services/drawer/DrawerContext";

export const useDrawer = () => {
  const defaultDrawer = "default";
  const drawer: any = useContext(DrawerContext);
  const showDrawer = (params: {}, name = defaultDrawer) =>
    drawer.showDrawer(params, name);
  const updateDrawer = (params: {}, name = defaultDrawer) => {
    drawer && drawer.updateDrawer(params, name);
  };

  const hideDrawer = (name = defaultDrawer) => drawer.hideDrawer(name);
  const hideAllDrawer = () => drawer.hideAllDrawer();

  return { showDrawer, hideDrawer, updateDrawer, hideAllDrawer };
};
