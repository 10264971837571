import React, { useMemo } from "react";
import DescriptionList from "components/molecules/DescriptionList";
import { useGetClient } from "modules/transactions/apiHooks";
import Loader from "components/atoms/Loader";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { ClientDataType } from "modules/transactions/types";

const ClientDetails = ({ transactionId }: any) => {
  const { data, isLoading } = useGetClient(transactionId);
  const listData: any = useMemo(() => {
    const res = [];
    let key: keyof ClientDataType;
    for (key in data) {
      res.push({ title: [key], value: data?.[key] });
    }
    return res;
  }, [data]);
  if (isLoading) {
    return <Loader formOverlay />;
  }
  return <div>Waiting for api (need to discuss)</div>;
};

const CustomerDetailsWrapped = (props: any) => {
  return (
    /*  <RoleWrapper accessTo={"ROLE_VIEW_TRANSACTION_CLIENT"}>*/
    <ClientDetails {...props} />
    /*</RoleWrapper>*/
  );
};

export default CustomerDetailsWrapped;
