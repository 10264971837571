import React, { useState } from "react";
import { useTrans } from "system/translations/hooks";
import Tabs from "components/atoms/Tabs";
import Transactions from "modules/transactions/components/Transactions";
import SectionTitle from "components/atoms/SectionTitle";
import { useParams } from "react-router-dom";
import { useGetMerchant } from "modules/merchants/apiHooks";
import UpdateMerchant from "modules/merchants/components/UpdateMerchant";
import MerchantBlackList from "modules/merchantBlackList";
import MerchantWhiteList from "modules/merchantWhiteList";

const Update: React.FC = () => {
  const { _t } = useTrans();
  const [selectedTab, setSelected] = useState("merchant");
  const routeParams: any = useParams();
  const { data: merchantData } = useGetMerchant(routeParams?.id);
  const tabs = {
    merchant: {
      name: "merchant",
      header: _t("merchant_information"),
      headerProps: {},
      body: UpdateMerchant,
      accessTo: [
        "ROLE_MODIFY_MERCHANTS",
        "ROLE_VIEW_MERCHANT_CREDENTIALS",
        "ROLE_VIEW_MERCHANT_INFORMATION",
      ],
    },
    whitelist: {
      name: "whitelist",
      header: _t("whitelist"),
      headerProps: {},
      body: MerchantWhiteList,
      bodyProps: {},
      accessTo: "ROLE_MODIFY_MERCHANT_WHITELIST",
    },
    blacklist: {
      name: "blacklist",
      header: _t("blacklist"),
      headerProps: {},
      body: MerchantBlackList,
      bodyProps: {},
      accessTo: "ROLE_MODIFY_MERCHANT_BLACKLIST",
    },
  };

  return (
    <>
      <SectionTitle title={merchantData?.name || _t("merchant")} />
      <Tabs tabs={tabs} onChange={setSelected} selected={selectedTab} />
    </>
  );
};

export default Update;
