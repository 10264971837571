import React from "react";
import { css } from "@emotion/react";
import { Heading } from "evergreen-ui";
import { useTrans } from "system/translations/hooks";
import { createStyles } from "types/emotion-styles";

const styles = createStyles({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const ClientDetailsHeader: React.FC<{
  title: string;
}> = ({ title }) => {
  const { _t } = useTrans();
  return (
    <div css={css(styles.wrapper)}>
      <Heading size={700}>{title}</Heading>{" "}
    </div>
  );
};

export default ClientDetailsHeader;
