import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "services/formBuilder/types";
import { useDefaultFieldsConfig } from "services/formBuilder/hooks";
import { TableColumnType } from "services/table/types";
import { CaretDownIcon, CaretUpIcon, Pane } from "evergreen-ui";
import themeOptions from "system/theme";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("transactionUUID"),
        id: "transactionUUID",
        accessor: "transactionUUID",
        width: 200,
        Cell: ({ isExpanded, onExpand, row, showDetails, ...rest }: any) => {
          return (
            <Pane
              display={"flex"}
              gap={themeOptions.spacing.xxs}
              alignItems={"center"}
              cursor={"pointer"}
              onClick={onExpand}
            >
              <span>{isExpanded ? <CaretUpIcon /> : <CaretDownIcon />}</span>
              <span>{row?.original?.transactionUUID}</span>
            </Pane>
          );
        },
      },
      {
        Header: _t("merchant"),
        id: "merchant",
        accessor: "merchant",
      },
      {
        Header: _t("event_type"),
        id: "recordType",
        accessor: "recordType",
      },
      {
        Header: _t("transaction_status_before"),
        id: "transactionStatusBefore",
        accessor: "transactionStatusBefore",
        isMobileTitle: true,
      },
      {
        Header: _t("transaction_status_after"),
        id: "transactionStatusAfter",
        accessor: "transactionStatusAfter",
      },
      {
        Header: _t("transaction_incoming_id"),
        id: "merchantTransactionId",
        accessor: "merchantTransactionId",
      },
      {
        Header: _t("updated_date"),
        id: "created",
        accessor: "created",
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.transactionId({
        columnParams: {
          md: 4,
          lg: 3,
        },
        componentProps: {
          showSearch: true,
        },
      }),
    ];
  }, []);
  return formConfig;
};
