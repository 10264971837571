import React from "react";
import { css } from "@emotion/react";
import { Heading } from "evergreen-ui";
import { createStyles } from "types/emotion-styles";

const styles = createStyles({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const DrawerHeader: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div css={css(styles.wrapper)}>
      <Heading size={700}>{text}</Heading>
    </div>
  );
};

export default DrawerHeader;
