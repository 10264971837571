import React from "react";
import Login from "modules/auth/components/login";
import NotLoggedTemplate from "templates/NotLogged";

const LoginPage = () => {
  return (
    <NotLoggedTemplate>
      <Login />
    </NotLoggedTemplate>
  );
};

export default LoginPage;
