import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  contentWrapper: {
    padding: 0,
  },
  emptyFooter: {
    paddingBottom: themeOptions.spacing.xxlg,
  },
  footerWrapper: {
    display: "flex",
    gap: themeOptions.spacing.sm,
  },
});

export default styles;
