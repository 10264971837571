import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "services/formBuilder/FormBuilder";
import {
  useGetMerchantPaymentMethodsById,
  useUpdateMerchantPaymentMethods,
} from "modules/merchantPaymentMethods/apiHooks";
import { createEditValidation } from "modules/merchantPaymentMethods/validation";
import { useCreateEditConfig } from "modules/merchantPaymentMethods/constantHooks";
import Loader from "components/atoms/Loader";
import SectionTitle from "components/atoms/SectionTitle";

const Update: React.FC = () => {
  const isEdit = true;
  const history = useHistory();
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { mutateAsync: onUpdate } = useUpdateMerchantPaymentMethods();
  const { data, isLoading } = useGetMerchantPaymentMethodsById(
    routeParams?.merchantId,
    routeParams?.id
  );
  const formConfig = useCreateEditConfig({ isEdit });
  const onSubmit = async (data: any) => {
    await onUpdate({ data: data, id: routeParams?.id });
    history.goBack();
  };
  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <>
      <SectionTitle title={_t("edit_method")} />
      <Card>
        <FormBuilder
          showSubmit={false}
          formItemsConfig={formConfig}
          formProps={{
            submitBtnLabel: _t("update"),
            onSubmit: onSubmit,
            initialValues: {
              commission: data?.commission,
              type: data?.type,
              maskSensitiveData: data?.maskSensitiveData,
              redirectEnabled: data?.redirectEnabled,
              enabled: data?.enabled,
              paymentMethodId: data?.paymentMethod?.id,
              id: data?.id,
              merchantId: routeParams?.merchantId,
            },
            validationSchema: createEditValidation(_t, {
              isEdit: false,
            }),
          }}
        />
      </Card>
    </>
  );
};

export default Update;
