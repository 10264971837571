import { toaster } from "evergreen-ui";
import { AddToastType } from "services/toast/types";

export const useToast = () => {
  const addToast = ({ kind, title, description }: AddToastType) => {
    toaster?.[kind](title, { description });
  };

  return { addToast };
};
