import React, { useMemo } from "react";
import { generatePath, useHistory } from "react-router-dom";
import ButtonLink from "components/atoms/ButtonLink";
import FormBuilder from "services/formBuilder/FormBuilder";
import Table from "services/table";
import { APP_ROUTES } from "system/router/constants";
import { useTrans } from "system/translations/hooks";
import {
  useFilters,
  useGetSortMenuMobileItems,
  useListPagination,
  useListSort,
} from "system/helpers/hooks";
import {
  useDeleteTranslations,
  useGetTranslations,
  useGetTranslationsLanguages,
} from "modules/translations/apiHooks";
import { useConfirmModal } from "services/modal/predefinedModals";
import {
  useSearchFormConfig,
  useTableColumns,
} from "modules/translations/constantHooks";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import config from "system/config";
import { EditIcon, PlusIcon, TrashIcon } from "evergreen-ui";
import SectionTitle from "components/atoms/SectionTitle";
import globalStyles, { isMobile } from "system/theme/globalStyles";
import FilterMobileButtons from "components/molecules/FilterMobileButtons";

const Translations = () => {
  const { _t } = useTrans();
  const history = useHistory();
  const { onConfirm } = useConfirmModal();
  const { mutate: onDelete } = useDeleteTranslations();
  const searchFormConfig = useSearchFormConfig();
  const filterInitialValues = {};
  const { filters, setFilters } = useFilters(filterInitialValues);
  const { sort, setSort } = useListSort({});
  const { pagination, setPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const { sortMenuItems } = useGetSortMenuMobileItems(
    "Default",
    "key",
    setSort
  );
  const requestParams = useMemo(
    () => ({ ...filters, ...pagination, ...sort }),
    [filters, pagination, sort]
  );
  const { data, isLoading } = useGetTranslations(requestParams);
  const { data: languages } = useGetTranslationsLanguages();
  const languagesData = useMemo(
    () => languages?.map((item) => item.isoCode),
    [languages]
  );
  const columns = useTableColumns(languagesData);
  const onFilterSubmit = async (data: any) => setFilters(data);
  const onUpdate = ({ id, allData }: any) => {
    const link = generatePath(APP_ROUTES.translations.update, { id });
    history.push(link, history.location);
  };
  const onDeleteWithConfirm = ({ id, allData }: any) => {
    onConfirm({
      onOk: () => onDelete({ id }),
      onOkKind: "danger",
      onOkText: _t("delete"),
      bodyText: _t("action_cannot_undone"),
      title: (
        <>
          {_t("delete_key")} {allData?.key}
        </>
      ),
    });
  };
  const actionMenuItems = [
    { title: _t("edit"), onClick: onUpdate, icon: <EditIcon size={12} /> },
    {
      title: _t("delete"),
      onClick: onDeleteWithConfirm,
      icon: <TrashIcon size={12} />,
      i: "danger",
    },
  ];
  const modifiedData = useMemo(() => {
    return data?.content.map((item) => {
      const key = Object.keys(item)[0];
      return { key, ...item[key] };
    });
  }, [data?.content]);
  return (
    <>
      <div css={globalStyles.mobileHeader}>
        <SectionTitle
          title={_t("translations")}
          extra={
            <>
              <RoleWrapper accessTo={"ROLE_MODIFY_SETTINGS"}>
                <ButtonLink
                  kind={"secondary"}
                  iconBefore={PlusIcon}
                  linkTo={APP_ROUTES.translations.create}
                  label={_t("create_translation")}
                />
              </RoleWrapper>
            </>
          }
        />
        <FormBuilder
          formItemsConfig={searchFormConfig}
          formProps={{
            submitBtnLabel: _t("apply_filters"),
            onSubmit: onFilterSubmit,
            initialValues: {},
          }}
          showSubmit={false}
        />
        {isMobile && <FilterMobileButtons sortMenuItems={sortMenuItems} />}
      </div>
      <Table
        totalRecords={data?.totalElements}
        columns={columns}
        pageIndex={pagination.page}
        pageSize={pagination.perPage}
        data={modifiedData || []}
        isLoading={isLoading}
        isPagination
        onPagination={setPagination}
        onSort={setSort}
        cellProps={{ actionMenuItems }}
      />
    </>
  );
};

export default Translations;
