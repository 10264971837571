import { TransType } from "system/translations/types";

export const methodStatusOptions = (_t: TransType) => [
  { label: _t("active"), value: "active" },
  { label: _t("deactivated"), value: "deactivated" },
];
export const paymentTypeOptions = (_t: TransType) => [
  /*  { label: _t("all"), value: "ALL" },*/
  { label: _t("deposit"), value: "DEPOSITS" },
  { label: _t("withdrawal"), value: "WITHDRAWALS" },
];
