import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  container: {
    padding: `${themeOptions.spacing.sm} ${themeOptions.spacing.md}`,
    borderRadius: themeOptions.borders.sm,
    background: themeOptions.colors.gray100,
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: themeOptions.spacing.sm,
    "&:last-child": {
      margin: 0,
    },
  },
  itemLabel: {
    label: "itemLabel",
  },
  itemValue: {
    label: "itemValue",
    color: themeOptions.colors.green600,
  },
});

export default styles;
