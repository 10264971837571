import React from "react";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "services/formBuilder/FormBuilder";
import { useCreateCountries } from "modules/countries/apiHooks";
import { createEditValidation } from "modules/countries/validation";
import { useCreateEditConfig } from "modules/countries/constantHooks";
import SectionTitle from "components/atoms/SectionTitle";
import { useHistory } from "react-router-dom";

const Create: React.FC = () => {
  const { _t } = useTrans();
  const history = useHistory();
  const { mutateAsync: onCreate } = useCreateCountries();
  const formConfig = useCreateEditConfig({
    isEdit: false,
  });
  const onSubmit = async (data: any) => {
    const { key, ...rest } = data;
    await onCreate({ data: rest, id: key });
    history.goBack();
  };

  return (
    <>
      <SectionTitle title={_t("create_translation")} />
      <Card>
        <FormBuilder
          formItemsConfig={formConfig}
          showSubmit={false}
          formProps={{
            onSubmit: onSubmit,
            initialValues: {},
            validationSchema: createEditValidation(_t, {
              isEdit: false,
            }),
          }}
        />
      </Card>
    </>
  );
};

export default Create;
