import React from "react";
import { css } from "@emotion/react";
import styles from "components/atoms/OrderedList/styles";

const OrderedList: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <ol css={css(styles.container)}>{children}</ol>;
};

export default OrderedList;
