import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { path } from "system/helpers/pathHelper";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { SidebarProps } from "components/organisms/Sidebar/types";
import { Pane, Menu, CaretDownIcon, CaretUpIcon } from "evergreen-ui";
import { css } from "@emotion/react";
import styles from "components/organisms/Sidebar/styles";
import { isMobile } from "system/theme/globalStyles";

const Sidebar: React.FC<SidebarProps> = ({
  menuList,
  isSideMinimized,
  onSideMinimize,
  activeLocation,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [openedSubmenu, setOpenedSubmenu] = useState<any>({});
  const linkHandler = (link: string) => history.push(link);
  const checkMatch = (url: string) => {
    const route = location?.pathname + location.search;
    return route === url || (route === "/" && url === path("/:lang/"));
  };
  const onGroupClick = (name: string) => {
    setOpenedSubmenu({ ...openedSubmenu, [name]: !openedSubmenu?.[name] });
  };
  
  return (
    <>
      <Pane
        css={css(
          styles.container,
          isMobile && styles.containerMob,
          isSideMinimized && styles.minimized
        )}
      >
        <Menu>
          {menuList.map((item, index: number) =>
            item.submenu ? (
              <RoleWrapper key={index} accessTo={item?.accessTo}>
                <Menu.Item
                  key={index}
                  onClick={() => onGroupClick(`${index}`)}
                  css={css(
                    styles.menuItem,
                    checkMatch(item.link) && styles.activeMenuItem
                  )}
                  icon={item.icon}
                  secondaryText={
                    !!openedSubmenu?.[index] ? (
                      <CaretUpIcon size={12} />
                    ) : (
                      <CaretDownIcon size={12} />
                    )
                  }
                >
                  {item.title}
                </Menu.Item>
                <Pane
                  display={!!openedSubmenu?.[index] ? "block" : "none"}
                  marginLeft={"28px"}
                >
                  {item.submenu.map((subItem: any, index: number) => (
                    <RoleWrapper key={index} accessTo={subItem?.accessTo}>
                      <Menu.Item
                        is={"a"}
                        key={index}
                        href={subItem.link}
                        css={css(
                          styles.menuItem,
                          checkMatch(subItem.link) && styles.activeMenuItem
                        )}
                        onClick={(e: any) => {
                          e.preventDefault();
                          linkHandler(subItem.link);
                          isMobile && onSideMinimize(!isSideMinimized)
                        }}
                      >
                        {subItem.title}
                      </Menu.Item>
                    </RoleWrapper>
                  ))}
                </Pane>
              </RoleWrapper>
            ) : (
              <RoleWrapper key={index} accessTo={item.accessTo}>
                <Menu.Item
                  is={"a"}
                  key={index}
                  icon={item.icon}
                  href={item.link}
                  css={css(
                    styles.menuItem,
                    checkMatch(item.link) && styles.activeMenuItem
                  )}
                  onClick={(e: any) => {
                    e.preventDefault();
                    linkHandler(item.link);
                    isMobile && onSideMinimize(!isSideMinimized)
                  }}
                >
                  {item.title}
                </Menu.Item>
              </RoleWrapper>
            )
          )}
        </Menu>
      </Pane>
      {!isSideMinimized && isMobile ? <div css={styles.overlay} /> : null}
    </>
  );
};

export default Sidebar;
