import React from "react";
import { Pagination as EvergreenPagination } from "evergreen-ui";
import { PaginationProps } from "components/molecules/Pagination/types";
import { css } from "@emotion/react";
import styles from "components/molecules/Pagination/styles";
import Select from "components/atoms/Select";
import { arrayOptionsMap } from "system/helpers/helperFunctions";

const Pagination: React.FC<PaginationProps> = ({
  onChange,
  page,
  totalPages,
  pageSize,
  totalRecords = 0,
  pageSizes = [],
  previewSuffix = "",
}) => {
  const onItemPerPage = (pageSize: number) => {
    if (page * pageSize <= totalRecords) {
      onChange({ page: page, pageSize: pageSize });
    } else {
      onChange({ page: 1, pageSize: pageSize });
    }
  };
  const pageSizesOptions = arrayOptionsMap(pageSizes, {
    labelKey: "id",
    valueKey: "id",
  });
  return (
    <div css={css(styles.wrapper)}>
      <div css={css(styles.paginationContainer)}>
        <EvergreenPagination
          onPageChange={(page) => onChange({ page: page, pageSize: pageSize })}
          totalPages={totalPages}
          page={page}
          onNextPage={() => onChange({ page: page + 1, pageSize: pageSize })}
          onPreviousPage={() => {
            onChange({ page: page - 1, pageSize: pageSize });
          }}
        />
        <div css={css(styles.pageSizeContainer)}>
          {totalRecords && (
            <div css={css(styles.paginationTotalRecords)}>
              {page * pageSize - pageSize + 1} – {page * pageSize} of{" "}
              {totalRecords} items
            </div>
          )}
          <Select
            previewSuffix={previewSuffix}
            options={pageSizesOptions}
            value={pageSize}
            preViewType={"text"}
            onChange={onItemPerPage}
            width={150}
          />
        </div>
      </div>
      {/*      <div css={css(styles.pageSizeContainer)}>
        <Select
          previewSuffix={previewSuffix}
          options={pageSizesOptions}
          value={pageSize}
          preViewType={"text"}
          onChange={onItemPerPage}
        />
      </div>*/}
    </div>
  );
};

export default Pagination;
