import React from "react";
import styles from "components/atoms/TransactionToast/styles";
import { TransactionToastProps } from "components/atoms/TransactionToast/types";
import { CrossIcon, Text, Heading } from "evergreen-ui";
import themeOptions from "system/theme";
import toast from "react-hot-toast";

const TransactionToast: React.FC<TransactionToastProps> = ({
  toastData,
  icon,
  title,
  description,
  amount,
  currency,
}) => {
  if (!toastData?.visible) {
    return null;
  }

  return (
    <div key={toastData.toastId} css={styles.container}>
      {icon}
      <div css={styles.body}>
        <Heading
          marginBottom={themeOptions.spacing.xxs}
          color={themeOptions.colors.gray800}
          size={400}
        >
          {title}
        </Heading>
        {amount && (
          <Text size={300} css={styles.bodyDescription}>
            {amount} {currency}
          </Text>
        )}
        {description && (
          <Text size={300} css={styles.bodyDescription}>
            {description}
          </Text>
        )}
      </div>
      <div css={styles.close} onClick={() => toast.dismiss(toastData.id)}>
        <CrossIcon />
      </div>
    </div>
  );
};

export default TransactionToast;
