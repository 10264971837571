import React from "react";
import * as Yup from "yup";
import FormBuilder from "services/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { isValidEmail, isValidPassword } from "system/helpers/validationHelper";
import { useLoginFormConfig } from "modules/auth/constantHooks";
import Loader from "components/atoms/Loader";

interface LoginFormProps {
  onSuccess: (data: any) => void;
  onLoginAsync: (data: any) => any;
  isLoading: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({
  onSuccess,
  isLoading,
  onLoginAsync,
}) => {
  const { _t } = useTrans();
  const formConfig = useLoginFormConfig();
  const onSubmit = async (data: any) => {
    const res = await onLoginAsync(data);

    if (res?.tfaenabled) {
      onSuccess && onSuccess({ rememberMe: data.rememberMe });
    }
  };

  const SignupSchema = Yup.object().shape({
    /*  email: isValidEmail(_t).required(),*/
    /*   password: isValidPassword(_t).required(),*/
  });

  return (
    <>
      {isLoading && <Loader formOverlay />}
      <FormBuilder
        formItemsConfig={formConfig}
        formProps={{
          onSubmit: onSubmit,
          initialValues: { email: "", password: "", rememberMe: false },
          validationSchema: SignupSchema,
          isLoading,
          rowStyles: { alignItems: "center" },
        }}
      />
    </>
  );
};

export default LoginForm;
