import { Link } from "carbon-components-react";
import InfoTag from "components/atoms/InfoTag";
import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../../services/formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useDefaultFieldsConfig } from "services/formBuilder/hooks";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { statusOptions } from "modules/merchants/constants";
import { APP_ROUTES } from "system/router/constants";
import { generatePath, useHistory } from "react-router-dom";
import { TableColumnType } from "services/table/types";
import globalStyles, { isMobile } from "system/theme/globalStyles";
import { usePermissionAccess } from "modules/admin/helperHooks";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const history = useHistory();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("id"),
        id: "id",
        accessor: "id",
        maxWidth: 50,
      },
      {
        Header: _t("name"),
        id: "name",
        isMobileTitle: true,
        Cell: (props: any) => {
          const link = generatePath(APP_ROUTES.merchants.update, {
            id: props.row.original.id,
          });
          return (
            <RoleWrapper
              defaultComponent={props?.row?.original?.name}
              accessTo={[
                "ROLE_MODIFY_MERCHANTS",
                "ROLE_VIEW_MERCHANT_CREDENTIALS",
                "ROLE_VIEW_MERCHANT_INFORMATION",
              ]}
            >
              <Link
                onClick={() => {
                  history.push(link, history.location);
                }}
              >
                {props?.row?.original?.name}
              </Link>
            </RoleWrapper>
          );
        },
      },
      {
        Header: _t("status"),
        Cell: (props: any) => {
          const status = !props?.row?.original?.disable
            ? "active"
            : "deactivated";
          return (
            <>
              <InfoTag
                type={"secondary"}
                size={"sm"}
                status={status}
                label={status}
              />
            </>
          );
        },
      },
      {
        width: 40,
        Header: "",
        id: "actionMenu",
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const options = statusOptions(_t);
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.filterValue({
        componentProps: {
          showSearch: true,
          SearchIconDescription: _t("search"),
        },
        openDrawer: true,
        columnParams: {
          md: 4,
          lg: 4,
        },
        name: "name",
      }),
      fields.filters.status({
        componentProps: {
          options: options,
        },
      }),
      fields.general.submitBtn({
        componentProps: {
          css: isMobile && globalStyles.submitBtnForMobileFilters,
        },
      }),
    ];
  }, []);
  return formConfig;
};

export const useEditConfig = ({
  isLoading,
}: {
  isLoading?: boolean;
}): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const { checkPermission, isSuperAdmin } = usePermissionAccess();
  const accessIpn =
    isSuperAdmin || checkPermission("ROLE_VIEW_MERCHANT_INFORMATION");
  const accessEdit = isSuperAdmin || checkPermission("ROLE_MODIFY_MERCHANTS");
  const accessCredentials =
    isSuperAdmin || checkPermission("ROLE_VIEW_MERCHANT_CREDENTIALS");
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.name({
        componentProps: { disabled: !accessEdit },
      }),
      fields.formFields.descriptor({
        componentProps: { disabled: !accessEdit },
      }),
      fields.formFields.ipn({
        hidden: !accessIpn,
      }),
      fields.formFields.apiKey({
        hidden: !accessCredentials,
        componentProps: { disabled: true },
      }),
      fields.formFields.secretKey({
        hidden: !accessCredentials,
        componentProps: { disabled: true },
      }),
      fields.general.divider(),
      fields.general.saveBtn({
        hidden: !accessEdit && !accessIpn,
        componentProps: {
          kind: "primary",
          label: _t("save_changes"),
          isLoading: isLoading,
        },
      }),
    ];
  }, [isLoading]);
  return formConfig;
};
export const useCreateConfig = ({
  isLoading,
}: {
  isLoading?: boolean;
}): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const { checkPermission, isSuperAdmin } = usePermissionAccess();
  const accessCreate = isSuperAdmin || checkPermission("ROLE_CREATE_MERCHANTS");
  const formConfig = useMemo((): FormConfigType => {
    let additionalFields: any = [];
    return [
      fields.formFields.name(),
      fields.formFields.descriptor(),
      fields.formFields.ipn(),
      ...additionalFields,
      fields.general.divider(),
      fields.general.saveBtn({
        hidden: !accessCreate,
        componentProps: {
          kind: "secondary",
          label: _t("create_merchant"),
          isLoading: isLoading,
        },
      }),
    ];
  }, [isLoading]);
  return formConfig;
};
