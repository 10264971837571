import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  settingsIcon: {
    position: "absolute",
    top: themeOptions.spacing.xs,
    right: themeOptions.spacing.xs,
    background: "rgb(255 255 255 / 76%)",
    zIndex: 5,
    cursor: "pointer",
    padding: "10px",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "all 0.3s",
    },
  },
  openButton: {
    height: "40px",
    border: "0px",
    width: "100%",
    background: themeOptions.colors.blue25,
    borderRadius: "8px",
  },
  openButtonActive: {
    background: themeOptions.colors.white,
    boxShadow: themeOptions.boxShadow.main,
  },
  hiddenCells: {
    display: "none",
  },
  tableMobile: {
    [themeOptions.maxMedia.md]: {
      margin: `0px -${themeOptions.spacing.lg}`,
    },
  },
  tableCells: {
    [themeOptions.maxMedia.md]: {
      minWidth: "49%",
      alignItems: "flex-start",
      maxWidth: "49%",
      flexDirection: "column",
      padding: "0px 0px !important",
    },
  },
  tableRow: {
    [themeOptions.maxMedia.md]: {
      flexWrap: "wrap",
      columnGap: "2%",
      padding: "16px 20px 20px",
      rowGap: themeOptions.spacing.xs,
    },
  },
  tableRowClose: {
    [themeOptions.maxMedia.md]: {
      backgroundColor: "initial !important",
    },
  },
  tableRowGroup: {
    [themeOptions.maxMedia.md]: {
      marginBottom: "6px",
    },
  },
  dotsMenu: {
    [themeOptions.maxMedia.md]: {
      order: "-99",
      alignItems: "flex-end !important",
      maxWidth: "19% !important",
      minWidth: "19% !important",
    },
  },
  tableCellMobileTittle: {
    [themeOptions.maxMedia.md]: {
      maxWidth: "79% !important",
      minWidth: "79% !important",
      order: "-100",
      fontWeight: "600",
      "a.bx--link": {
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        color: themeOptions.colors.b400,
      },
    },
  },
  tableCellMobileTittleText: {
    [themeOptions.maxMedia.md]: {
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "24px",
      color: themeOptions.colors.n900,
      overflowWrap: "anywhere",
    },
  },
  tableCellMobileSubtittle: {
    [themeOptions.maxMedia.md]: {
      marginBottom: "2px",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "20px",
      color: themeOptions.colors.n900,
    },
  },
  tableCellMobileSubtittleText: {
    [themeOptions.maxMedia.md]: {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      color: themeOptions.colors.n800,
      overflowWrap: "anywhere",
    },
  },
});

export default styles;
