import { createStyles } from "types/emotion-styles";

const styles = createStyles({
  wrapper: {},
  removeWrapper: {
    display: "inline-flex",
    width: "100%",
  },
  removeBtn: {
    marginLeft: "20px",
    padding: "13px 14px",
    background: "rgba(red, 0.1)",
    borderRadius: "48px",
    marginTop: "22px",
    cursor: "pointer",
    svg: {
      fill: "red",
    },
    "&:hover": {
      svg: {
        transform: "scale(1.2)",
        transition: "all 0.2s",
      },
      background: "rgba(red, 0.14)",
    },
  },
});

export default styles;
