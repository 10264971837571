import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  container: {
    position: "relative",
  },
  picker: {
    position: "absolute",
    bottom: "4px",
    left: "5px",
    width: "24px",
    height: "26px",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    "&::-webkit-color-swatch": {
      border: "none",
      borderRadius: themeOptions.borders.xs,
    },
  },
  input: {
    paddingLeft: themeOptions.spacing.xxlg,
  },
});

export default styles;
