import React from "react";
import FormBuilder from "services/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useGetMerchant, useUpdateMerchant } from "../apiHooks";
import Card from "../../../components/atoms/Card";
import { useHistory, useParams } from "react-router-dom";
import { createEditValidation } from "../validation";
import { useEditConfig } from "../constantHooks";
import Loader from "components/atoms/Loader";
import SectionTitle from "components/atoms/SectionTitle";

const UpdateMerchant: React.FC = () => {
  const isEdit = true;
  const { _t } = useTrans();
  const history = useHistory();
  const routeParams: any = useParams();
  const { mutateAsync: onUpdate, isLoading: isUpdating } = useUpdateMerchant();
  const { data: merchantData, isLoading } = useGetMerchant(routeParams?.id);
  const formConfig = useEditConfig({ isLoading: isUpdating });
  const onSubmit = async (data: any) => {
    await onUpdate({ data: data, id: routeParams?.id });
    history.goBack();
  };
  /*
  const onDeactivate = () => {
    onUpdate({ data: { ...merchantData, disable: true }, id: routeParams?.id });
  };
*/

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <>
      <Card>
        <FormBuilder
          formItemsConfig={formConfig}
          showSubmit={false}
          formProps={{
            submitBtnLabel: _t("save_changes"),
            submitBtnKind: "primary",
            onSubmit: onSubmit,
            initialValues: { ...merchantData },
            validationSchema: createEditValidation(_t, {
              isEdit,
            }),
          }}
        />
      </Card>
    </>
  );
};

export default UpdateMerchant;
