import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";
import searchIcon from "images/icons/search.svg";

const styles = createStyles({
  label: {
    color: themeOptions.colors.gray700,
    label: "select-label",
    fontSize: "12px",
    lineHeight: "16px",
  },
  singleLabel: {
    fontWeight: 500,
    color: themeOptions.colors.gray900,
    marginBottom: themeOptions.spacing.xs,
  },
  labelQuantity: {
    color: themeOptions.colors.gray700,
    marginLeft: themeOptions.spacing.xxs,
    label: "select-quantity",
  },
  labelAll: {
    marginRight: themeOptions.spacing.xxs,
    label: "select-labelAll",
    fontSize: "12px",
    lineHeight: "16px",
  },
  labelArrow: {
    svg: {
      fill: themeOptions.colors.gray700,
      height: "12px",
      [themeOptions.maxMedia.md]: {
        fill: themeOptions.colors.n600,
        height: '14px'
        },
    },
    label: "select-labelArrow",
    lineHeight: "12px",
  },
  labelWrap: {
    display: "flex",
  },
  labelSingle: {
    marginLeft: "10px",
  },
  quantityValues: {
    background: themeOptions.colors.gray200,
    color: themeOptions.colors.blue900,
    padding: "4px 7px",
    borderRadius: "100%",
    marginRight: themeOptions.spacing.xxs,
    fontSize: "12px",
    label: "select-quantity-values",
  },
  selectWrap: {
    '.dropdown-container[aria-expanded="true"]': {
      background: themeOptions.colors.gray50,
      '[class*="-select-labelArrow"]': {
        transform: "rotate(180deg)",
      },
    },
    ".dropdown-container": {
      borderColor: "transparent",
      background: "transparent",
      [themeOptions.maxMedia.md]: {
        borderColor: themeOptions.colors.gray300,
      },
      "&:focus-within": {
        boxShadow: "none",
        borderColor: "transparent",
      },
      ".panel-content": {
        boxShadow: themeOptions.boxShadow.main,
        borderTop: `1px solid ${themeOptions.colors.gray300}`,
      },
    },
    ".dropdown-content": {
      paddingTop: "0",
      minWidth: "200px",
      zIndex: 2,
    },
    ".dropdown-heading": {
      cursor: "pointer",
      padding: `0 ${themeOptions.spacing.xxs}`,
      height: "32px",
      [themeOptions.maxMedia.md]: {
        padding: `1px ${themeOptions.spacing.sm} 1px ${themeOptions.spacing.md}`,
      },
      "&:hover": {
        color: themeOptions.colors.blue400,
        '[class*="-select-label"], [class*="-select-labelAll"] ': {
          color: themeOptions.colors.blue400,
        },
        '[class*="-select-quantity-values"]': {
          background: themeOptions.colors.blue400,
          color: themeOptions.colors.white,
        },
        '[class*="-select-labelArrow"]': {
          svg: {
            fill: themeOptions.colors.blue400,
          },
        },
      },
    },
    ".options": {
      width: "100%",
      "label.select-item": {
        borderBottom: `2px solid ${themeOptions.colors.gray300}`,
      },
      "li label.select-item": {
        borderBottom: `1px solid ${themeOptions.colors.gray300}`,
      },
    },
    ".options > label:first-child": {
      position: "relative",
      "&:before": {
        content: '"Select All"',
        fontSize: "12px",
        fontWeight: 500,
        color: themeOptions.colors.gray500,
      },
      "&:after": {
        content: '" - Clear All"',
        fontSize: "12px",
        fontWeight: 500,
        color: themeOptions.colors.gray500,
      },
      '&[aria-selected="true"]': {
        "&:after": {
          color: themeOptions.colors.blue400,
        },
      },
      '&[aria-selected="false"]': {
        "&:before": {
          color: themeOptions.colors.blue400,
        },
      },
      ".item-renderer": {
        display: "none",
      },
    },
    ".search": {
      borderBottom: `1px solid ${themeOptions.colors.gray300}`,
      position: "relative",
      "&:before": {
        content: '""',
        width: "15px",
        height: "15px",
        backgroundImage: `url(${searchIcon})`,
        display: "block",
        backgroundRepeat: "no-repeat",
        position: "absolute",
        top: "9px",
        left: "10px",
      },
      input: {
        background: themeOptions.colors.gray50,
        paddingLeft: "30px",
        fontSize: "12px",
        "&:focus": {
          background: themeOptions.colors.gray100,
        },
        height: "32px",
      },
    },
    ".item-renderer": {
      alignItems: "center",
      gap: themeOptions.spacing.xs,
      color: themeOptions.colors.gray800,
      input: {
        accentColor: themeOptions.colors.blue500,
      },
    },
    ".select-item.selected": {
      background: "transparent",
    },
  },
  isError: {
    cursor: "pointer",
  },
  hasSelected: {
    ".options > label:first-child": {
      position: "relative",
      "&:before": {
        content: '"Select All"',
        fontSize: "12px",
        fontWeight: 500,
        color: themeOptions.colors.blue400,
      },
      "&:after": {
        content: '" - Clear All"',
        fontSize: "12px",
        fontWeight: 500,
        color: themeOptions.colors.blue400,
      },
    },
  },
  menuFullWidth: {
    ".dropdown-content": {
      width: "fit-content",
    },
  },
  twoColumns: {
    height: "100%",
    gridGap: "0px",
    display: "grid",
    gap: ".5px",
    gridTemplate: '"leftColumn rightColumn"',
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "307px 0px",
    ".search": {
      input: {
        height: "34px",
      },
    },
    ".dropdown-container": {
      border: "none",
    },
    ".dropdown-content": {
      width: "100%",
      border: "1px solid",
      borderColor: themeOptions.colors.gray300,
      borderTopLeftRadius: themeOptions.borders.xs,
      borderBottomLeftRadius: themeOptions.borders.xs,
      ".panel-content": {
        borderTop: "none",
      },
    },
    ".multi-select": {
      gridArea: "leftColumn",
    },
    ".options": {
      maxHeight: "270px",
      height: "270px",
    },
    ".dropdown-heading": {
      display: "none",
    },
  },
  twoColumnsBottom: {
    backgroundColor: themeOptions.colors.white,
    gridArea: "bottomCounter",
    padding: themeOptions.spacing.sm,
  },
  twoColumnsRight: {
    border: "1px solid",
    borderColor: themeOptions.colors.gray300,
    backgroundColor: themeOptions.colors.white,
    borderTopRightRadius: themeOptions.borders.xs,
    borderBottomRightRadius: themeOptions.borders.xs,
    display: "flex",
    flexDirection: "column",
    gridArea: "rightColumn",
    ul: {
      display: "flex",
      flexDirection: "column",
      maxHeight: "270px",
      overflowY: "auto",
      li: {
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        height: "35px",
        padding: "10px",
        justifyContent: "space-between",
        borderBottom: "1px solid #e5e9eb",
        "&:first-child": {
          height: "36px",
          minHeight: "36px",
          borderBottom: "2px solid #e5e9eb",
          margin: 0,
          justifyContent: "flex-start",
        },
      },
    },
  },
  twoColumnsRightCounter: {
    borderBottom: "1px solid #e5e9eb",
    padding: "4px 10px",
    lineHeight: "26px",
  },
  twoColumnsRightRemover: {
    height: "36px",
    marginBottom: "7px",
    padding: "10px",
    color: themeOptions.colors.blue400,
    fontSize: "12px",
  },
  mobileContainer: {
    display: "flex",
    width: "100vw",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${themeOptions.colors.n300}`,
    padding: "10px 24px",
    margin: "-24px -24px 12px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: themeOptions.colors.n800
  },
  mobileRenderer: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "80vw",
    textOverflow: "ellipsis",
    marginTop: "2px",
  },
  mobileLabel: {
    marginBottom: themeOptions.spacing.xs,
    color: themeOptions.colors.n900,
    fontWeight: 500,
  },
  mobileLabelText: {
    color: themeOptions.colors.n600,
    label: "select-label",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 400,
  },
  mobileLabelTextSelected: {
    color: themeOptions.colors.n800,
    label: "select-label",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 400,
  },

});

export default styles;
