import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "services/formBuilder/types";
import { useDefaultFieldsConfig } from "services/formBuilder/hooks";
import { CreateEditFormConfigParams } from "modules/assignmentRules/types";

export const useCreateEditConfig = (
  params: CreateEditFormConfigParams
): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.codeLiteral({
        componentProps: {
          disabled: params.isEdit,
        },
      }),
      /* fields.formFields.countryName(),*/
      fields.formFields.enabled(),
      fields.general.divider(),
      fields.general.saveBtn(),
    ];
  }, []);
  return formConfig;
};
