import React from "react";
import FormBuilder from "services/formBuilder/FormBuilder";
import Table from "services/table";
import { useTrans } from "system/translations/hooks";
import { useFilters } from "system/helpers/hooks";
import { useGetHistoryLog } from "modules/historyLog/apiHooks";
import {
  useTableColumns,
  useSearchFormConfig,
} from "modules/historyLog/constantHooks";
import SectionTitle from "components/atoms/SectionTitle";
import { HistoryLogProps } from "modules/historyLog/types";
import Details from "modules/historyLog/components/Details";

const HistoryLog: React.FC<HistoryLogProps> = ({
  showOnlyTable = false,
  transactionId,
}) => {
  const { _t } = useTrans();
  const initialFilters = { transactionId };
  const { filters, setFilters } = useFilters(initialFilters);
  const { data, isLoading } = useGetHistoryLog(filters);
  const columns = useTableColumns();
  const searchFormConfig = useSearchFormConfig();
  const onFilterSubmit = async (data: any) => {
    setFilters(data);
  };

  return (
    <>
      {!showOnlyTable ? (
        <>
          <SectionTitle title={_t("history_log")} />
          <FormBuilder
            formItemsConfig={searchFormConfig}
            formProps={{
              onSubmit: onFilterSubmit,
              initialValues: {},
            }}
          />
        </>
      ) : null}
      <Table
        totalRecords={data?.length}
        columns={columns}
        isLoading={isLoading}
        data={data || []}
        rowSubComponent={<Details />}
        isVirtualBody={false}
      />
    </>
  );
};

export default HistoryLog;
