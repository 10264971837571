import React, { useMemo } from "react";
import FormBuilder from "services/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useChangePassword } from "modules/admin/apiHooks";
import { useChangePasswordFormConfig } from "modules/admin/constantHooks";
import { changePasswordValidation } from "modules/admin/validation";
import { useGetCurrentAdmin } from "modules/auth/apiHooks";
import { Text } from "evergreen-ui";
import themeOptions from "system/theme";
import { useModal } from "services/modal/hooks";
import Loader from "components/atoms/Loader";

const ChangePassword: React.FC = () => {
  const { _t } = useTrans();
  const { hideModal } = useModal();
  const { mutate, isLoading } = useChangePassword();
  const { data } = useGetCurrentAdmin();
  const formConfig = useChangePasswordFormConfig({ isLoading });
  const onSubmit = async (val: any) => {
    const password = val.password;
    const oldPassword = val.oldPassword;
    const requestData = { id: data?.id, data: { password, oldPassword } };
    mutate(requestData);
  };
  const onReset = () => {
    hideModal();
  };
  const validationSchema = useMemo(() => changePasswordValidation(_t), []);

  return (
    <>
      {isLoading && <Loader formOverlay description={_t("loading")} />}
      <Text
        display={"block"}
        marginBottom={themeOptions.spacing.sm}
        color={themeOptions.colors.gray700}
        size={400}
      >
        {_t("password_format_description")}
      </Text>
      <FormBuilder
        formItemsConfig={formConfig}
        showSubmit={false}
        formProps={{
          onSubmit: onSubmit,
          onReset: onReset,
          validationSchema: validationSchema,
        }}
      />
    </>
  );
};

export default ChangePassword;
