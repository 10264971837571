import React from "react";
import { AuthWrapperProps } from "modules/auth/types";
import { useTrans } from "system/translations/hooks";
import authLogo from "images/authLogo.svg";
import { css } from "@emotion/react";
import styles from "modules/auth/components/wrappers/authWrapperStyles";
import { ArrowLeftIcon, Heading } from "evergreen-ui";
import ButtonLink from "components/atoms/ButtonLink";
import OrderedList from "components/atoms/OrderedList";
import ListItem from "components/atoms/ListItem";

const AuthWrapper: React.FC<AuthWrapperProps> = ({
  children,
  classname,
  title,
  subtitle,
  actionLink,
  actionText,
  backHandler,
  footer,
  qrCode = null,
  manual_code,
  statusIcon,
}) => {
  const { _t } = useTrans();
  return (
    <div css={css(styles.container)}>
      <div css={css(styles.img)} />
      <div css={css(styles.contentWrap)}>
        <div css={css(styles.logo)}>
          <img src={authLogo} alt="" />
        </div>
        <div css={css(styles.content)}>
          {statusIcon && (
            <div css={css(styles.statusIconWrap)}>{statusIcon}</div>
          )}
          {!!title && (
            <div css={css(styles.titleWrap)}>
              {backHandler && (
                <span css={css(styles.backBtn)} onClick={backHandler}>
                  <ArrowLeftIcon size={14} />
                </span>
              )}
              <Heading css={css(styles.title)} size={700}>
                {title}
              </Heading>
            </div>
          )}
          {!!subtitle && (
            <Heading css={css(styles.subtitle)} size={400}>
              {subtitle}
            </Heading>
          )}
          {qrCode && (
            <>
              <Heading css={css(styles.description)} size={400}>
                {_t("set_up_via_google_auth")}
              </Heading>
              <OrderedList>
                <ListItem>
                  Download and install the Google Authenticator app on your
                  mobile device
                </ListItem>
                <ListItem>
                  Scan the QR code
                  <div css={css(styles.qrWrap)}>
                    <div>
                      <img
                        src={qrCode}
                        style={{ width: "130px", height: "130px" }}
                      />
                    </div>
                    <div>
                      {_t("enter_key_manually")}: <br /> {manual_code}
                    </div>
                  </div>
                </ListItem>
                <ListItem>
                  Enter the 6-digit code generated by Google Authenticator
                </ListItem>
              </OrderedList>
            </>
          )}
          {children}
          {footer && footer}
          {!!actionLink && (
            <ButtonLink
              kind={"custom"}
              linkTo={actionLink}
              label={actionText}
            />
          )}
        </div>
        {
          <Heading css={css(styles.footerText)} size={400}>
            {_t("all_rights_reserved")}
          </Heading>
        }
      </div>
    </div>
  );
};

export default AuthWrapper;
