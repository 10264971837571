import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  container: {
    width: "100%",
    margin: "-24px 0px",
    ".rdrDateRangePickerWrapper": {
      margin: "0px -20px"
    },
    ".rdrDateRangePickerWrapper .rdrCalendarWrapper": {
      display: "none",
    },
    ".rdrDefinedRangesWrapper": {
      borderRight: "initial",
    },
    ".rdrStaticRange": {
      borderBottom: "none",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      color: themeOptions.colors.n800,
    },
    ".rdrMonth": {
      width: "initial",
    },
    ".rdrStaticRangeLabel": {
      padding: "12px 20px",
      backgroundColor : `${themeOptions.colors.white} !important`,
    },
    ".rdrStaticRangeSelected": {
      color: `${themeOptions.colors.b400} !important`,
    },
  },
  sectionContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 0px",
    color: themeOptions.colors.b400,
  },
  mobileCalendar: {
    position: "absolute",
    bottom: "30px",
    right: "0",
    width: "288px",
    height: "340px",
    border: `1px solid ${themeOptions.colors.n300}`,
  },
  sectionTitle: {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    textTransform: "uppercase",
    color: themeOptions.colors.n600,
  },
  sectionText: {
    color: themeOptions.colors.n800,
  }

});

export default styles;
