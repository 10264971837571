import React from "react";
import { useHistory } from "react-router-dom";
import { ButtonLinkProps } from "./types";
import Btn from "components/atoms/Btn";

const ButtonLink: React.FC<ButtonLinkProps> = ({
  label,
  linkTo,
  addHref = false,
  ...rest
}) => {
  const history = useHistory();
  const onClickHandler = () => {
    history.push(linkTo, history.location);
  };

  return (
    <Btn onClick={onClickHandler} {...rest}>
      {label}
    </Btn>
  );
};

export default ButtonLink;
