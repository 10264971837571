import React from "react";
import { css } from "@emotion/react";
import { Heading } from "evergreen-ui";
import { useTrans } from "system/translations/hooks";
import { createStyles } from "types/emotion-styles";

const styles = createStyles({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const TransactionDetailsHeader: React.FC<{
  transactionStatus: string;
  transactionId: string;
}> = ({ transactionStatus, transactionId }) => {
  const { _t } = useTrans();
  return (
    <div css={css(styles.wrapper)}>
      <Heading size={700}>
        {_t("transaction_id")}: {transactionId}
      </Heading>
      <Heading size={700}>{transactionStatus}</Heading>
    </div>
  );
};

export default TransactionDetailsHeader;
