import React, { useMemo } from "react";
import { useGetFormTemplate } from "../apiHooks";
import DescriptionList from "../../../components/molecules/DescriptionList";
import Loader from "components/atoms/Loader";
import { FormTemplateDataType } from "modules/formTemplates/types";

const Info = ({ id }: any) => {
  const { data, isLoading } = useGetFormTemplate(id);
  const listData: any = useMemo(() => {
    const res = [];
    let key: keyof FormTemplateDataType;
    for (key in data) {
      res.push({ title: [key], value: data?.[key] });
    }
    return res;
  }, [data]);
  if (isLoading) {
    return <Loader formOverlay />;
  }
  return <DescriptionList list={listData} />;
};

export default Info;
