import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getToken } from "system/helpers/helperFunctions";
import { PrivateRouteProps } from "system/router/types";
import { APP_ROUTES } from "./constants";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  accessTo,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      !!getToken() ? (
        <RoleWrapper accessTo={accessTo} redirect>
          <Component {...props} />
        </RoleWrapper>
      ) : (
        <Redirect to={APP_ROUTES.login} />
      )
    }
  />
);

export default PrivateRoute;
