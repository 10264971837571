import React from "react";
import {
  Pane,
  ArrowLeftIcon,
  CrossIcon,
} from "evergreen-ui";
import styles from "./styles";

const HeaderMobileFilters: React.FC<{
  title: string,
  onClose: () => void
}> = ({ title, onClose }) => {
  return (
      <Pane css={styles.container}>
        <ArrowLeftIcon 
        onClick={onClose}
        />
        {title}
        <CrossIcon onClick={onClose}/>
      </Pane>
  );
};

export default HeaderMobileFilters;
