import Select from "components/atoms/Select";
import { useTrans } from "system/translations/hooks";
import { FormConfigItemType } from "services/formBuilder/types";
import MultiSelect from "components/atoms/MultiSelect";
import SearchWithFieldSelect from "components/atoms/SearchWithFieldSelect";
import Search from "components/atoms/Search";
import DateRange from "components/molecules/DateRange";
import Btn from "components/atoms/Btn";
import Input from "components/atoms/Input";
import TextArea from "components/atoms/TextArea";
import InputPassword from "components/atoms/InputPassword";
import config from "system/config";
import CheckboxPanel from "components/molecules/CheckboxPanel";
import Toggle from "components/atoms/Toggle";
import Checkbox from "components/atoms/Checkbox";
import React from "react";
import Divider from "components/atoms/Divider";
import TextLink from "components/atoms/Link";
import ActionButtons from "components/atoms/ActionButtons";
import ApproveDecline from "components/atoms/ApproveDecline";
import { SearchIcon } from "evergreen-ui";

type FieldsType = {
  filters: {
    merchantId: (config?: any) => FormConfigItemType;
    merchantIdSingle: (config?: any) => FormConfigItemType;
    paymentMethod: (config?: any) => FormConfigItemType;
    paymentGateway: (config?: any) => FormConfigItemType;
    operation: (config?: any) => FormConfigItemType;
    status: (config?: any) => FormConfigItemType;
    type: (config?: any) => FormConfigItemType;
    transactionType: (config?: any) => FormConfigItemType;
    fields: (config?: any) => FormConfigItemType;
    filterValue: (config?: any) => FormConfigItemType;
    filterField: (config?: any) => FormConfigItemType;
    date: (config?: any) => FormConfigItemType;
    securityGroupId: (config?: any) => FormConfigItemType;
    merchants: (config?: any) => FormConfigItemType;
    active: (config?: any) => FormConfigItemType;
    filterName: (config?: any) => FormConfigItemType;
    filterWithSelectFieldForInput: (config?: any) => FormConfigItemType;
    currency: (config?: any) => FormConfigItemType;
    userEmail: (config?: any) => FormConfigItemType;
    transactionId: (config?: any) => FormConfigItemType;
    actionType: (config?: any) => FormConfigItemType;
    entityType: (config?: any) => FormConfigItemType;
    request: (config?: any) => FormConfigItemType;
    key: (config?: any) => FormConfigItemType;
    SearchInputWithSelect: (config?: any) => FormConfigItemType;
    transactionStatus: (config?: any) => FormConfigItemType;
  };
  general: {
    submitBtn: (config?: any) => FormConfigItemType;
    actionButtons: (config?: any) => FormConfigItemType;
    saveBtn: (config?: any) => FormConfigItemType;
    link: (config?: any) => FormConfigItemType;
    searchBtn: (config?: any) => FormConfigItemType;
    divider: (config?: any) => FormConfigItemType;
  };
  formFields: {
    key: (config?: any) => FormConfigItemType;
    transKeys: (config?: any) => FormConfigItemType;
    approveDecline: (config?: any) => FormConfigItemType;
    name: (config?: any) => FormConfigItemType;
    kycMaxAmount: (config?: any) => FormConfigItemType;
    merchantIdSingle: (config?: any) => FormConfigItemType;
    alertType: (config?: any) => FormConfigItemType;
    paymentGatewayCode: (config?: any) => FormConfigItemType;
    successRateCheckLength: (config?: any) => FormConfigItemType;
    successRateThreshold: (config?: any) => FormConfigItemType;
    errorsInARowThreshold: (config?: any) => FormConfigItemType;
    successRateCheckEnabled: (config?: any) => FormConfigItemType;
    maxSingleAmountCheckEnabled: (config?: any) => FormConfigItemType;
    maxSingleAmount: (config?: any) => FormConfigItemType;
    maxCumulativeAmount: (config?: any) => FormConfigItemType;
    cumulativeAmountCheckTimeFrame: (config?: any) => FormConfigItemType;
    cumulativeAmountCheckLength: (config?: any) => FormConfigItemType;
    maxCumulativeAmountCheckEnabled: (config?: any) => FormConfigItemType;
    errorsInARowCheckEnabled: (config?: any) => FormConfigItemType;
    maxAmount: (config?: any) => FormConfigItemType;
    usersWithAccess: (config?: any) => FormConfigItemType;
    minAmount: (config?: any) => FormConfigItemType;
    timeToDecline: (config?: any) => FormConfigItemType;
    countriesSupported: (config?: any) => FormConfigItemType;
    description: (config?: any) => FormConfigItemType;
    parameters: (config?: any) => FormConfigItemType;
    descriptor: (config?: any) => FormConfigItemType;
    allCountries: (config?: any) => FormConfigItemType;
    defaultToggle: (config?: any) => FormConfigItemType;
    refundable: (config?: any) => FormConfigItemType;
    storedSupported: (config?: any) => FormConfigItemType;
    voucherAuto: (config?: any) => FormConfigItemType;
    authority: (config?: any) => FormConfigItemType;
    password: (config?: any) => FormConfigItemType;
    oldPassword: (config?: any) => FormConfigItemType;
    confirm_password: (config?: any) => FormConfigItemType;
    email: (config?: any) => FormConfigItemType;
    securityGroupId: (config?: any) => FormConfigItemType;
    merchants: (config?: any) => FormConfigItemType;
    paymentMethods: (config?: any) => FormConfigItemType;
    paymentMethod: (config?: any) => FormConfigItemType;
    paymentMethodId: (config?: any) => FormConfigItemType;
    transactionUUID: (config?: any) => FormConfigItemType;
    comment: (config?: any) => FormConfigItemType;
    commission: (config?: any) => FormConfigItemType;
    ip: (config?: any) => FormConfigItemType;
    transactionStatus: (config?: any) => FormConfigItemType;
    rejectionReasonId: (config?: any) => FormConfigItemType;
    rejectionReasonText: (config?: any) => FormConfigItemType;
    paymentType: (config?: any) => FormConfigItemType;
    needToMaskSensitiveData: (config?: any) => FormConfigItemType;
    maskSensitiveData: (config?: any) => FormConfigItemType;
    activeToggle: (config?: any) => FormConfigItemType;
    code: (config?: any) => FormConfigItemType;
    type: (config?: any) => FormConfigItemType;
    rememberMe: (config?: any) => FormConfigItemType;
    authenticationCode: (config?: any) => FormConfigItemType;
    state: (config?: any) => FormConfigItemType;
    city: (config?: any) => FormConfigItemType;
    postcode: (config?: any) => FormConfigItemType;
    address: (config?: any) => FormConfigItemType;
    street: (config?: any) => FormConfigItemType;
    walletId: (config?: any) => FormConfigItemType;
    comType: (config?: any) => FormConfigItemType;
    ThreeDStatus: (config?: any) => FormConfigItemType;
    mcc: (config?: any) => FormConfigItemType;
    ipns: (config?: any) => FormConfigItemType;
    ipn: (config?: any) => FormConfigItemType;
    allowPartialRefund: (config?: any) => FormConfigItemType;
    allowPartialCapture: (config?: any) => FormConfigItemType;
    apiV2: (config?: any) => FormConfigItemType;
    allowDynamicDescriptor: (config?: any) => FormConfigItemType;
    avsCheck: (config?: any) => FormConfigItemType;
    disableToggle: (config?: any) => FormConfigItemType;
    selectedPaymentMethods: (config?: any) => FormConfigItemType;
    selectedCurrencies: (config?: any) => FormConfigItemType;
    selectedCredentials: (config?: any) => FormConfigItemType;
    priority: (config?: any) => FormConfigItemType;
    secretKey: (config?: any) => FormConfigItemType;
    apiKey: (config?: any) => FormConfigItemType;
    enabled: (config?: any) => FormConfigItemType;
    redirectEnabled: (config?: any) => FormConfigItemType;
    currencyName: (config?: any) => FormConfigItemType;
    codeLiteral: (config?: any) => FormConfigItemType;
  };
};

export const useDefaultFieldsConfig = (): FieldsType => {
  const { _t } = useTrans();
  const fields = {
    filters: {
      merchantId: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        name: "merchantId",
        valuePreview: true,
        componentProps: {
          options: componentProps?.options,
          label: _t("merchants"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      merchantIdSingle: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        name: "merchantId",
        componentProps: {
          options: componentProps?.options,
          hideLabel: true,
          ...componentProps,
        },
        columnParams: {
          md: 3,
          lg: 3,
          ...columnParams,
        },
        ...rest,
      }),
      paymentMethod: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        name: "paymentMethod",
        valuePreview: true,
        componentProps: {
          options: componentProps?.options,
          label: _t("payment_methods"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      paymentGateway: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        name: "paymentGateway",
        valuePreview: true,
        componentProps: {
          options: componentProps?.options,
          label: _t("payment_gateways"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      operation: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        name: "operation",
        valuePreview: true,
        componentProps: {
          options: componentProps?.options,
          label: _t("operation_types"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      status: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        name: "status",
        valuePreview: true,
        componentProps: {
          options: componentProps?.options,
          label: _t("status"),
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      transactionType: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        name: "type",
        valuePreview: true,
        componentProps: {
          options: componentProps?.options,
          label: _t("transaction_types"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      transactionStatus: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        name: "status",
        componentProps: {
          options: config.transactionStatuses || componentProps?.options,
          label: _t("status"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      type: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        name: "type",
        valuePreview: true,
        componentProps: {
          options: componentProps?.options,
          label: _t("type"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      fields: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        name: "fields",
        valuePreview: true,
        componentProps: {
          options: componentProps?.options,
          label: _t("export_fields"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      filterValue: ({
        componentProps,
        columnParams,
        emptySpace,
        name = "",
        ...rest
      }: any = {}) => ({
        component: Search,
        name: name ? name : "filterValue",
        componentProps: {
          label: _t("search"),
          placeholder: _t("search"),
          searchIconDescription: _t("search"),
          ...componentProps,
        },
        columnParams: {
          /*      md: { span: 5 },
          lg: { span: 5 },*/
          ...columnParams,
        },
        emptySpace: {
          /*    md: 12,
          lg: 12,*/
          ...emptySpace,
        },
        ...rest,
      }),
      filterField: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Select,
        name: "filterField",
        componentProps: {
          options: componentProps?.options,
          hideLabel: true,
          ...componentProps,
        },
        columnParams: {
          /*    md: 3,
          lg: 3,*/
          ...columnParams,
        },
        ...rest,
      }),
      date: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: DateRange,
        name: "date",
        valuePreview: true,
        componentProps: {
          label: _t("date_range"),
          fromName: "fromDate",
          toName: "toDate",
          dateFormat: config.dateFormat,
          ...componentProps,
        },
        columnParams: {
          ...columnParams,
        },
        ...rest,
      }),
      securityGroupId: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        name: "securityGroupId",
        componentProps: {
          options: componentProps?.options,
          label: _t("roles"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      merchants: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        name: "merchants",
        componentProps: {
          options: componentProps?.options,
          label: _t("merchants"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      active: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        name: "active",
        componentProps: {
          options: componentProps?.options,
          label: _t("active"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      filterName: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Search,
        name: "name",
        componentProps: {
          label: _t("search_by_name"),
          placeholder: _t("search_by_name"),
          ...componentProps,
        },
        columnParams: {
          ...columnParams,
        },
        ...rest,
      }),
      filterWithSelectFieldForInput: ({
        componentProps,
        columnParams,
        emptySpace,
        ...rest
      }: any = {}) => ({
        component: SearchWithFieldSelect,
        name: "searchWithFieldSelect",
        componentProps: {
          label: _t("search"),
          placeholder: _t("search"),
          ...componentProps,
        },
        columnParams: {
          md: 9,
          lg: 9,
        },
        emptySpace: {
          sm: { span: 10 },
          lg: { span: 10 },
          md: { span: 10 },
          ...emptySpace,
        },
        ...rest,
      }),
      SearchInputWithSelect: ({
        componentProps,
        columnParams,
        emptySpace,
        ...rest
      }: any = {}) => ({
        component: SearchWithFieldSelect,
        name: "searchInputWithSelect",
        componentProps: {
          label: _t("search"),
          placeholder: _t("search"),
          ...componentProps,
        },
        columnParams: {
          md: 4,
          lg: 4,
        },
        emptySpace: {
          ...emptySpace,
        },
        ...rest,
      }),
      currency: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        name: "currency",
        componentProps: {
          options: componentProps?.options,
          label: _t("currencies"),
          light: true,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      entityType: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        name: "entityType",
        valuePreview: true,
        componentProps: {
          options: componentProps?.options,
          label: _t("entity_type"),
          light: false,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      actionType: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        name: "actionType",
        valuePreview: true,
        componentProps: {
          options: componentProps?.options,
          label: _t("action_type"),
          light: false,
          wrapClassPrefix: "menu-full-width",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      userEmail: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Search,
        name: "userEmail",
        componentProps: {
          label: _t("email"),
          placeholder: _t("email"),
          ...componentProps,
        },
        columnParams: {
          ...columnParams,
        },
        ...rest,
      }),
      transactionId: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Search,
        name: "transactionId",
        componentProps: {
          label: _t("transaction_id"),
          placeholder: _t("transaction_id"),
          ...componentProps,
        },
        columnParams: {
          ...columnParams,
        },
        ...rest,
      }),
      request: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Search,
        name: "request",
        componentProps: {
          label: _t("search"),
          placeholder: _t("search"),
          SearchIconDescription: _t("search"),
          showSearch: true,
          ...componentProps,
        },
        columnParams: {
          ...columnParams,
        },
        ...rest,
      }),
      key: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Search,
        name: "key",
        componentProps: {
          label: _t("search"),
          placeholder: _t("search"),
          SearchIconDescription: _t("search"),
          showSearch: true,
          ...componentProps,
        },
        columnParams: {
          ...columnParams,
        },
        ...rest,
      }),
    },
    formFields: {
      transactionUUID: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "transactionUUID",
        componentProps: {
          label: _t("transactionUUID"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      type: ({ componentProps, columnParams, name, ...rest }: any = {}) => ({
        component: Select,
        name: name || "type",
        componentProps: {
          options: componentProps?.options,
          label: _t("type"),
          placeholder: _t("type"),
          ...componentProps,
        },
        columnParams: {
          md: 12,
          lg: 12,
          ...columnParams,
        },
        ...rest,
      }),
      paymentGatewayCode: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Select,
        name: "paymentGatewayCode",
        componentProps: {
          options: componentProps?.options,
          label: _t("payment_gateway_code"),
          ...componentProps,
        },
        columnParams: {
          md: 12,
          lg: 12,
          ...columnParams,
        },
        ...rest,
      }),
      successRateCheckLength: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "successRateCheckLength",
        componentProps: {
          label: _t("success_rate_check_length"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      successRateThreshold: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "successRateThreshold",
        componentProps: {
          label: _t("success_rate_threshold"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      errorsInARowThreshold: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "errorsInARowThreshold",
        componentProps: {
          label: _t("errors_in_row_threshold"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      comment: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: TextArea,
        name: "comment",
        componentProps: {
          label: _t("comment"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      rejectionReasonText: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: TextArea,
        name: "rejectionReasonText",
        componentProps: {
          label: _t("comment"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      description: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: TextArea,
        name: "description",
        componentProps: {
          label: _t("description"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      transactionStatus: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Select,
        name: "status",
        componentProps: {
          options: config.transactionStatuses || componentProps?.options,
          label: _t("status"),
          light: true,
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      rejectionReasonId: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Select,
        name: "rejectionReasonId",
        componentProps: {
          options: componentProps?.options,
          label: _t("rejection_reason"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      key: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "key",
        componentProps: {
          label: _t("key"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      transKeys: ({
        componentProps,
        columnParams,
        name,
        ...rest
      }: any = {}) => ({
        component: TextArea,
        name: name,
        componentProps: {
          label: (param: any) =>
            _t("language{fieldName}", { fieldName: param.fieldName }),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      approveDecline: ({
        componentProps,
        columnParams,
        name,
        ...rest
      }: any = {}) => ({
        component: ApproveDecline,
        name: "status",
        componentProps: {
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      name: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "name",
        componentProps: {
          label: _t("name"),
          placeholder: _t("name"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      merchantIdSingle: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Select,
        name: "merchantId",
        componentProps: {
          options: componentProps?.options,
          label: _t("merchant"),
          ...componentProps,
        },
        columnParams: {
          md: 12,
          lg: 12,
          ...columnParams,
        },
        ...rest,
      }),
      paymentMethod: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Select,
        name: "paymentMethod",
        componentProps: {
          options: componentProps?.options,
          label: _t("payment_method"),
          placeholder: _t("payment_method"),
          ...componentProps,
        },
        columnParams: {
          md: 12,
          lg: 12,
          ...columnParams,
        },
        ...rest,
      }),
      paymentMethodId: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Select,
        name: "paymentMethodId",
        componentProps: {
          options: componentProps?.options,
          label: _t("payment_method"),
          placeholder: _t("payment_method"),
          ...componentProps,
        },
        columnParams: {
          md: 12,
          lg: 12,
          ...columnParams,
        },
        ...rest,
      }),
      kycMaxAmount: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "kycMaxAmount",
        componentProps: {
          label: _t("kyc_max_amount"),
          placeholder: _t("kyc_max_amount"),
          id: "kycMaxAmount",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      maxSingleAmount: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "maxSingleAmount",
        componentProps: {
          label: _t("max_single_amount"),
          placeholder: _t("max_single_amount"),
          id: "maxSingleAmount",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      maxCumulativeAmount: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "maxCumulativeAmount",
        componentProps: {
          label: _t("max_cumulative_amount"),
          placeholder: _t("max_cumulative_amount"),
          id: "maxCumulativeAmount",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      cumulativeAmountCheckTimeFrame: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "cumulativeAmountCheckTimeFrame",
        componentProps: {
          label: _t("cumulative_amount_check_time_frame"),
          placeholder: _t("cumulative_amount_check_time_frame"),
          id: "cumulativeAmountCheckTimeFrame",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      cumulativeAmountCheckLength: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "cumulativeAmountCheckLength",
        componentProps: {
          label: _t("cumulative_amount_check_length"),
          placeholder: _t("cumulative_amount_check_length"),
          id: "cumulativeAmountCheckLength",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      alertType: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Select,
        name: "alertType",
        componentProps: {
          label: _t("alert_type"),
          placeholder: _t("alert_type"),
          id: "alert_type",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      countriesSupported: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        name: "countriesSupported",
        componentProps: {
          options: componentProps?.options,
          label: _t("countries_supported"),
          type: "default",
          labelWithAll: false,
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      usersWithAccess: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        name: "usersWithAccess",
        componentProps: {
          options: componentProps?.options,
          label: _t("users_with_access"),
          type: "default",
          labelWithAll: false,
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      maxAmount: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "maxAmount",
        componentProps: {
          label: _t("max_amount"),
          placeholder: _t("max_amount"),
          id: "maxAmount",
          type: "number",
          min: "0",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      ipns: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "ipns",
        componentProps: {
          label: _t("ipns"),
          placeholder: _t("ipns"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      ipn: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "ipn",
        componentProps: {
          label: _t("ipn"),
          placeholder: _t("ipn"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      descriptor: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "descriptor",
        componentProps: {
          label: _t("descriptor"),
          placeholder: _t("descriptor"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      parameters: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "parameters",
        componentProps: {
          label: _t("parameters"),
          placeholder: _t("parameters"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      minAmount: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "minAmount",
        componentProps: {
          label: _t("min_amount"),
          placeholder: _t("min_amount"),
          type: "number",
          min: "0",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      timeToDecline: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "timeToDecline",
        componentProps: {
          label: _t("decline_timeout_min_label"),
          placeholder: _t("decline_timeout_min_label"),
          type: "number",
          min: "0",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      commission: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "commission",
        componentProps: {
          label: _t("commission"),
          placeholder: _t("commission"),
          type: "number",
          min: "0",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      mcc: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "mcc",
        componentProps: {
          label: _t("mcc"),
          placeholder: _t("mcc"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      ThreeDStatus: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "3dStatus",
        componentProps: {
          label: _t("3d_status"),
          placeholder: _t("3d_status"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      address: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "address",
        componentProps: {
          label: _t("address"),
          placeholder: _t("address"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      street: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "street",
        componentProps: {
          label: _t("street"),
          placeholder: _t("street"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      walletId: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "walletId",
        componentProps: {
          label: _t("wallet_id"),
          placeholder: _t("wallet_id"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      comType: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "comType",
        componentProps: {
          label: _t("com_type"),
          placeholder: _t("com_type"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      city: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "city",
        componentProps: {
          label: _t("city"),
          placeholder: _t("city"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      postcode: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "postcode",
        componentProps: {
          label: _t("postcode"),
          placeholder: _t("postcode"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      state: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "state",
        componentProps: {
          label: _t("state"),
          placeholder: _t("state"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      password: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: InputPassword,
        name: "password",
        componentProps: {
          label: _t("password"),
          placeholder: _t("password"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      confirm_password: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: InputPassword,
        name: name || "confirm_password",
        componentProps: {
          label: _t("confirm_password"),
          placeholder: _t("confirm_password"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      oldPassword: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: InputPassword,
        name: name || "oldPassword",
        componentProps: {
          label: _t("old_password"),
          placeholder: _t("old_password"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      email: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "email",
        componentProps: {
          label: _t("email"),
          placeholder: _t("email"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      securityGroupId: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Select,
        name: "securityGroupId",
        componentProps: {
          options: componentProps?.options,
          label: _t("roles"),
          light: true,
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      merchants: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: MultiSelect,
        name: "merchants",
        componentProps: {
          options: componentProps?.options,
          label: _t("merchants"),
          titleText: _t("merchants"),
          light: false,
          labelWithAll: false,
          type: "default",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      paymentMethods: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        name: "paymentMethods",
        componentProps: {
          options: componentProps?.options,
          label: _t("payment_methods"),
          titleText: _t("payment_methods"),
          light: false,
          labelWithAll: false,
          type: "default",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      authority: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: CheckboxPanel,
        name: name,
        componentProps: {
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      paymentType: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Select,
        name: "paymentType",
        componentProps: {
          options: componentProps?.options,
          label: _t("payment_type"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      needToMaskSensitiveData: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "needToMaskSensitiveData",
        componentProps: {
          label: _t("mask_sensitive_data"),
          id: "needToMaskSensitiveData",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      maskSensitiveData: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "maskSensitiveData",
        componentProps: {
          label: _t("mask_sensitive_data"),
          id: "maskSensitiveData",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      errorsInARowCheckEnabled: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "errorsInARowCheckEnabled",
        componentProps: {
          label: _t("errors_in_row_check_enabled"),
          id: "errorsInARowCheckEnabled",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      successRateCheckEnabled: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "successRateCheckEnabled",
        componentProps: {
          label: _t("success_rate_check_enabled"),
          id: "successRateCheckEnabled",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      maxCumulativeAmountCheckEnabled: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "maxCumulativeAmountCheckEnabled",
        componentProps: {
          label: _t("max_cumulative_amount"),
          id: "maxCumulativeAmountCheckEnabled",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      enabled: ({ componentProps, name, columnParams, ...rest }: any = {}) => ({
        component: Toggle,
        name: "enabled",
        componentProps: {
          label: _t("active"),
          id: "active",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      redirectEnabled: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "redirectEnabled",
        componentProps: {
          label: _t("redirect_enabled"),
          id: "active",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      maxSingleAmountCheckEnabled: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "maxSingleAmountCheckEnabled",
        componentProps: {
          label: _t("max_single_amount"),
          id: "maxSingleAmountCheckEnabled",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      defaultToggle: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Toggle,
        name: "default",
        componentProps: {
          label: _t("default"),
          id: "defaultToggle",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      allCountries: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Toggle,
        name: "allCountries",
        componentProps: {
          label: _t("all_countries"),
          id: "allCountries",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      refundable: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Toggle,
        name: "refundable",
        componentProps: {
          label: _t("refundable"),
          id: "refundable",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      storedSupported: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "storedSupported",
        componentProps: {
          label: _t("stored_supported"),
          id: "storedSupported",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      voucherAuto: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Toggle,
        name: "voucherAuto",
        componentProps: {
          label: _t("voucher_auto"),
          id: "voucherAuto",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      allowPartialRefund: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "allowPartialRefund",
        componentProps: {
          label: _t("partial_refund"),
          placeholder: _t("partial_refund"),
          id: "allowPartialRefund",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      allowPartialCapture: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "allowPartialCapture",
        componentProps: {
          label: _t("partial_capture"),
          placeholder: _t("partial_capture"),
          id: "allowPartialCapture",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      allowDynamicDescriptor: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "allowDynamicDescriptor",
        componentProps: {
          label: _t("allow_dynamic_descriptor"),
          placeholder: _t("allow_dynamic_descriptor"),
          id: "allowDynamicDescriptor",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      disableToggle: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Toggle,
        name: "disable",
        componentProps: {
          label: _t("disable"),
          placeholder: _t("disable"),
          id: "disableToggle",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      avsCheck: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Toggle,
        name: "avsCheck",
        componentProps: {
          label: _t("avs_check"),
          placeholder: _t("avs_check"),
          id: "avsCheck",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      apiV2: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Toggle,
        name: "API V2",
        componentProps: {
          label: _t("api_v2"),
          placeholder: _t("api_v2"),
          id: "API V2",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      activeToggle: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Toggle,
        name: "active",
        componentProps: {
          label: _t("active"),
          id: "active",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      ip: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "ip",
        componentProps: {
          label: _t("ip"),
          placeholder: _t("ip"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      secretKey: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "secretKey",
        componentProps: {
          label: _t("secretKey"),
          placeholder: _t("secretKey"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      apiKey: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "apiKey",
        componentProps: {
          label: _t("apiKey"),
          placeholder: _t("apiKey"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      code: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "code",
        componentProps: {
          label: _t("code"),
          placeholder: _t("code"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      currencyName: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "description",
        componentProps: {
          label: _t("currency_name"),
          placeholder: _t("currency_name"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      codeLiteral: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Input,
        name: "codeLiteral",
        componentProps: {
          label: _t("code"),
          placeholder: _t("code"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      authenticationCode: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Input,
        name: "authenticationCode",
        componentProps: {
          label: _t("enter_code"),
          placeholder: _t("code"),
          className: "bx--text-input--inverted",
          autoComplete: "off",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      rememberMe: ({
        componentProps,
        name,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: Checkbox,
        name: "rememberMe",
        componentProps: {
          label: _t("remember_me"),
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      selectedPaymentMethods: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        name: "selectedPaymentMethods",
        componentProps: {
          options: componentProps?.options,
          label: _t("payment_methods"),
          type: "default",
          labelWithAll: false,
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      selectedCurrencies: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        name: "selectedCurrencies",
        componentProps: {
          options: componentProps?.options,
          label: _t("currency"),
          type: "default",
          labelWithAll: false,
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      selectedCredentials: ({
        componentProps,
        columnParams,
        ...rest
      }: any = {}) => ({
        component: MultiSelect,
        name: "selectedCredentials",
        componentProps: {
          options: componentProps?.options,
          label: _t("credentials"),
          type: "default",
          labelWithAll: false,
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
      priority: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Toggle,
        name: "priority",
        componentProps: {
          label: _t("priority"),
          id: "priority",
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          ...columnParams,
        },
        ...rest,
      }),
    },
    general: {
      submitBtn: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Btn,
        name: "",
        componentProps: {
          kind: "primary",
          type: "submit",
          label: _t("apply_filters"),
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      cancelBtn: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Btn,
        name: "",
        componentProps: {
          kind: "default",
          type: "reset",
          label: _t("cancel"),
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      searchBtn: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Btn,
        name: "",
        componentProps: {
          type: "submit",
          label: "",
          hasIconOnly: true,
          iconBefore: SearchIcon,
          style: {
            color: "#000",
            borderColor: "#fff",
            backgroundColor: "#fff",
          },
          className: "bx--search-button",
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      saveBtn: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Btn,
        name: "",
        componentProps: {
          kind: "primary",
          type: "submit",
          label: _t("save_changes"),
          ...componentProps,
        },
        columnParams: {
          className: "maxWidthContent",
          ...columnParams,
        },
        ...rest,
      }),
      actionButtons: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: ActionButtons,
        name: "",
        componentProps: {
          ...componentProps,
        },
        columnParams: {
          ...columnParams,
        },
        ...rest,
      }),
      link: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: TextLink,
        name: "",
        componentProps: {
          label: "set label",
          ...componentProps,
        },
        columnParams: {
          lg: 6,
          md: 6,
          sm: 6,
          xs: 6,
          ...columnParams,
        },
        ...rest,
      }),
      divider: ({ componentProps, columnParams, ...rest }: any = {}) => ({
        component: Divider,
        name: "",
        componentProps: {
          ...componentProps,
        },
        columnParams: {
          lg: 12,
          md: 12,
          sm: 12,
          ...columnParams,
        },
        ...rest,
      }),
    },
  };

  return fields;
};
