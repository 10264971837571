import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  useExpanded,
  useColumnOrder,
  useResizeColumns,
} from "react-table";
import { ReactTableProps } from "services/table/types";
import { defaultPageSize, defaultPageSizes } from "services/table/constants";
import Loader from "components/atoms/Loader";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import classNames from "classnames";
import NoData from "components/atoms/NoData";
import Pagination from "components/molecules/Pagination";
import { useTrans } from "system/translations/hooks";
import {
  CaretDownIcon,
  CaretUpIcon,
  DoubleCaretVerticalIcon,
  Heading,
  MoreIcon,
  Table as EvergreenTable,
} from "evergreen-ui";
import "services/table/react-table.scss";
import { useDrawer } from "services/drawer/hooks";
import styles from "services/table/styles";
import themeOptions from "system/theme";
import ColumnsSettings from "services/table/ColumnsSettings";
import TableBody from "services/table/components/TableBody";
import { isMobile } from "system/theme/globalStyles";

const Table: React.FC<ReactTableProps> = ({
  columns,
  data = [],
  pageSize = defaultPageSize,
  pageSizes = defaultPageSizes,
  isPagination = false,
  isLoading,
  onPagination,
  onSort,
  pageIndex = 0,
  filters,
  rowSubComponent: SubComponent,
  cellProps,
  totalRecords = 0,
  tableName = "",
  hiddenColumns = [],
  forceHiddenColumns = [],
  showTotalNoData = true,
  afterHeadComponent,
  showSettingsIcon = true,
  isVirtualBody = true,
}) => {
  const { showDrawer } = useDrawer();
  const { _t } = useTrans();
  const tableHeadRef: any = useRef(null);
  const tableRef: any = useRef(null);
  const [bodyHeight, setBodyHeight] = useState(200);
  const tableColumns = useMemo(() => {
    return columns?.filter((item) => {
      const id = item?.id || "";
      return !forceHiddenColumns.includes(id);
    });
  }, [columns]);
  const pageSizesLabelSuffix = _t("results_per_page");
  const getTableName = () => {
    const currentLocationArray = window.location.pathname.split("/");
    return !currentLocationArray.some((item) => item)
      ? "transactions"
      : currentLocationArray[currentLocationArray.length - 1]
      ? currentLocationArray[currentLocationArray.length - 1]
      : currentLocationArray[currentLocationArray.length - 2];
  };

  const getStoragedData = (itemName: string, defaultValue: any = {}) => {
    return localStorage.getItem(itemName)
      ? JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem(itemName))))
      : defaultValue;
  };

  const currentTable = useMemo(() => tableName || getTableName(), []);

  const updateStorageData = (newStorageData: any, storageKey: string) => {
    const storagedData = getStoragedData(storageKey);
    const result = {
      ...storagedData,
      [currentTable]: newStorageData,
    };
    localStorage.setItem(storageKey, JSON.stringify(result));
  };

  const useControlledState = (state: any) => {
    const hiddenColumns = state.hiddenColumns;
    const columnWidths = state.columnResizing.columnWidths;
    const columnOrder = state.columnOrder;
    !isLoading && updateStorageData(columnWidths, "cachedColumnWidths");
    !isLoading && updateStorageData(hiddenColumns, "hiddenColumns");
    !isLoading && updateStorageData(columnOrder, "columnOrder");
    useMemo(() => {
      onSort && onSort(state.sortBy);
    }, [state.sortBy]);
    return state;
  };

  const getTableStoragedData = (storageKey: string, defValue: any) =>
    getStoragedData(storageKey)?.[currentTable]
      ? getStoragedData(storageKey)?.[currentTable]
      : defValue;

  const initialState: any = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    columnOrder: getTableStoragedData("columnOrder", []),
    columnResizing: {
      columnWidths: getTableStoragedData("cachedColumnWidths", {}),
    },
    hiddenColumns: [
      ...hiddenColumns,
      ...getTableStoragedData("hiddenColumns", []),
    ],
  };
  let useTableOptions = {
    columns: tableColumns,
    data,
    initialState: initialState,
    manualPagination: !!onPagination,
    /*    pageCount: Math.ceil(totalRecords / pageSize) + 1,*/
    manualSortBy: !!onSort,
    /*   onPagination: !!onPagination,*/
    useControlledState,
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    gotoPage,
    setPageSize,
    setAllFilters,
    totalColumnsWidth,
    setColumnOrder,
    allColumns,
    visibleColumns,
    toggleHideColumn,
  } = useTable(
    useTableOptions,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useColumnOrder,
    useResizeColumns
  );
  const onPageChange = (e: { page: number; pageSize: any }) => {
    if (onPagination) {
      onPagination({ page: e.page, perPage: e.pageSize });
      setPageSize(e.pageSize);
    } else {
      gotoPage(e.page - 1);
      setPageSize(e.pageSize);
    }
  };
  useEffect(() => {
    filters && Array.isArray(filters) && setAllFilters(filters);
  }, [filters]);
  const onExpand = (event: any, row: any) => {
    const toggleRowExpandedProps = row.getToggleRowExpandedProps();
    toggleRowExpandedProps.onClick(event);
  };
  const renderRowSubComponent = React.useCallback(({ row }: any) => {
    const Component: any = React.cloneElement(SubComponent, {
      rowData: row?.original || {},
    });
    return SubComponent ? Component : "No sub component";
  }, []);
  const selectedPage = !!onPagination ? state.pageIndex : state.pageIndex + 1;
  useEffect(() => {
    const { innerHeight } = window;
    const tableTopPosition = tableRef?.current?.getBoundingClientRect().top;
    if (tableRef?.current) {
      let tableHeight = innerHeight - tableTopPosition - 115;
      const dataLength = data?.length;
      const rowHeight = 48;
      const rowsHeight = dataLength * rowHeight;
      if (tableHeight > rowsHeight && !!rowsHeight) {
        tableHeight = rowsHeight;
      }
      if (rowsHeight >= 256 && tableHeight < 200) {
        tableHeight = 200;
      }
      setBodyHeight(Math.floor(tableHeight));
    }
  }, [tableRef, data]);
  useEffect(() => {
    if (tableRef?.current && data) {
      document.addEventListener("scroll", scrollHandler);
    }
  }, [tableRef?.current]);
  useEffect(
    () => () => {
      document.removeEventListener("scroll", scrollHandler);
    },
    []
  );
  const scrollHandler = () => {
    const headTop = tableRef?.current?.getBoundingClientRect().top;
    if (tableHeadRef?.current?.style && headTop < -5) {
      tableHeadRef.current.style.position = "absolute";
      tableHeadRef.current.style.top = `${Math.abs(headTop) + 48}px`;
    } else if (tableHeadRef?.current?.style) {
      tableHeadRef.current.style.position = "static";
    }
  };
  const getItemStyle = (isDragging: any, draggableStyle: any) => {
    let styles = {
      ...draggableStyle,
    };
    if (isDragging) {
      styles.background = "lightgrey";
    }
    return styles;
  };
  const reorder = (
    list: any[],
    startIndex: number,
    endIndex: number
  ): any[] => {
    const result: any[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEndHandler = (result: any) => {
    const newList = reorder(
      visibleColumns,
      result.source.index,
      result.destination.index
    );
    const listId = newList.map((item) => item.id);
    setColumnOrder(listId);
  };
  const columnHider = (id: any, value: any) => {
    toggleHideColumn(id, !value);
  };
  const showSettings = () => {
    showDrawer({
      header: () => <Heading size={700}>{"Customize columns"}</Heading>,
      component: ColumnsSettings,
      componentProps: { columns: allColumns, setColumnOrder, columnHider },
    });
  };
  if (!data?.length && !isLoading && showTotalNoData) {
    return <NoData text={_t("no_data_text")} />;
  }

  return (
    <div>
      <div className="ReactTable" ref={tableRef} css={styles.tableMobile}>
        {showSettingsIcon && !isMobile && (
          <div css={styles.settingsIcon} onClick={showSettings}>
            <MoreIcon color={themeOptions.colors.gray600} />
          </div>
        )}
        <EvergreenTable {...getTableProps()} className={"rt-table"} role="grid">
          {!isMobile && (
            <EvergreenTable.Head
              height={48}
              className="rt-thead -header"
              style={{ minWidth: totalColumnsWidth, width: "100%" }}
            >
              <DragDropContext onDragEnd={onDragEndHandler}>
                <Droppable droppableId="list" direction="horizontal">
                  {(provided, snap) => (
                    <div
                      ref={provided.innerRef}
                      className={"rt-tr-group"}
                      {...provided.droppableProps}
                    >
                      {headerGroups.map((headerGroup: any) => (
                        <div
                          ref={tableHeadRef}
                          key={"headerGroup_" + headerGroup.id}
                          role="row"
                          {...headerGroup.getHeaderGroupProps()}
                          className={"rt-tr"}
                        >
                          {headerGroup.headers.map((column: any, idx: any) => {
                            const styles: any = {
                              width: column.totalWidth,
                            };
                            if (!snap.isDraggingOver) {
                              styles.maxWidth =
                                column.maxWidth || column.totalWidth;
                              styles.flex = `${column.totalWidth} 0 auto`;
                              styles.minWidth =
                                column.minWidth || column.totalWidth;
                            }
                            return (
                              <div style={{ position: "relative", ...styles }}>
                                <Draggable
                                  draggableId={column.id}
                                  key={column.id + idx}
                                  index={idx}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <EvergreenTable.TextHeaderCell
                                        key={"th_" + column?.Header + idx}
                                        role="columnheader"
                                        {...column.getHeaderProps(
                                          column.getSortByToggleProps()
                                        )}
                                        className={classNames(
                                          "rt-th",
                                          "rt-resizable-header",
                                          "-cursor-pointer",
                                          `rt-th-${idx}`
                                        )}
                                      >
                                        <div
                                          aria-describedby="table-sort-19"
                                          style={{ background: "transparent" }}
                                        >
                                          <>
                                            <span>
                                              {column.render("Header")}
                                            </span>
                                            {column.canSort ? (
                                              <>
                                                <span>
                                                  {!column.isSorted ? (
                                                    <DoubleCaretVerticalIcon
                                                      className={"sort-icon"}
                                                    />
                                                  ) : null}
                                                  {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                      <CaretUpIcon
                                                        className={
                                                          "sort-icon--sorted"
                                                        }
                                                      />
                                                    ) : (
                                                      <CaretDownIcon
                                                        className={
                                                          "sort-icon--sorted"
                                                        }
                                                      />
                                                    )
                                                  ) : null}
                                                </span>
                                              </>
                                            ) : null}
                                          </>
                                        </div>
                                      </EvergreenTable.TextHeaderCell>
                                    </div>
                                  )}
                                </Draggable>
                                <div
                                  key={"resizer" + idx}
                                  {...column.getResizerProps()}
                                  className={`rt-resizer ${
                                    column.isResizing ? "isResizing" : ""
                                  }`}
                                />
                              </div>
                            );
                          })}
                        </div>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </EvergreenTable.Head>
          )}
          {afterHeadComponent && afterHeadComponent}
          {isLoading && <Loader formOverlay bgOpacity={50} />}
          <TableBody
            getTableBodyProps={getTableBodyProps}
            totalColumnsWidth={totalColumnsWidth}
            page={page}
            prepareRow={prepareRow}
            state={state}
            cellProps={cellProps}
            onExpand={onExpand}
            renderRowSubComponent={renderRowSubComponent}
            height={bodyHeight}
            isVirtualBody={isVirtualBody}
          />
        </EvergreenTable>
        {isPagination && data?.length ? (
          <div className={"bx--pagination__wrap"}>
            <Pagination
              onChange={onPageChange}
              pageSize={state.pageSize}
              page={selectedPage}
              totalRecords={totalRecords}
              pageSizes={pageSizes}
              previewSuffix={pageSizesLabelSuffix}
              totalPages={Math.ceil(totalRecords / state.pageSize)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Table;
