import React, { useMemo } from "react";
import { generatePath, useHistory } from "react-router-dom";
import ButtonLink from "components/atoms/ButtonLink";
import FormBuilder from "services/formBuilder/FormBuilder";
import Table from "services/table";
import { APP_ROUTES } from "system/router/constants";
import { useTrans } from "system/translations/hooks";
import {
  useFilters,
  useListPagination,
  useListSort,
} from "system/helpers/hooks";
import {
  useDeleteCountries,
  useGetCountries,
} from "modules/countries/apiHooks";
import { useConfirmModal } from "services/modal/predefinedModals";
import {
  useSearchFormConfig,
  useTableColumns,
} from "modules/countries/constantHooks";
import config from "system/config";
import { EditIcon, PlusIcon, TrashIcon } from "evergreen-ui";
import SectionTitle from "components/atoms/SectionTitle";

const Countries = () => {
  const { _t } = useTrans();
  const history = useHistory();
  const { onConfirm } = useConfirmModal();
  const { mutate: onDelete } = useDeleteCountries();
  const searchFormConfig = useSearchFormConfig();
  const filterInitialValues = {};
  const { filters, setFilters } = useFilters(filterInitialValues);
  const { sort, setSort } = useListSort({});
  const { pagination, setPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const requestParams = useMemo(
    () => ({ ...filters, ...pagination, ...sort }),
    [filters, pagination, sort]
  );
  const { data, isLoading } = useGetCountries(requestParams);
  const columns = useTableColumns();
  const onFilterSubmit = async (data: any) => setFilters(data);
  const onUpdate = ({ id, allData }: any) => {
    const link = generatePath(APP_ROUTES.countries.update, { id });
    history.push(link, history.location);
  };
  const onDeleteWithConfirm = ({ id }: any) => {
    onConfirm({ onOk: () => onDelete({ id }) });
  };
  const actionMenuItems = [
    { title: _t("edit"), onClick: onUpdate, icon: <EditIcon size={12} /> },
    {
      title: _t("delete"),
      onClick: onDeleteWithConfirm,
      icon: <TrashIcon size={12} />,
      intent: "danger",
    },
  ];
  return (
    <>
      <SectionTitle
        title={_t("countries")}
        extra={
          <ButtonLink
            kind={"secondary"}
            iconBefore={PlusIcon}
            linkTo={APP_ROUTES.countries.create}
            label={_t("create_country")}
          />
        }
      />
      <FormBuilder
        formItemsConfig={searchFormConfig}
        formProps={{
          submitBtnLabel: _t("apply_filters"),
          onSubmit: onFilterSubmit,
          initialValues: {},
        }}
        showSubmit={false}
      />
      <Table
        totalRecords={data?.totalElements}
        columns={columns}
        pageIndex={pagination.page}
        pageSize={pagination.perPage}
        data={data?.content || []}
        isLoading={isLoading}
        isPagination
        onPagination={setPagination}
        onSort={setSort}
        cellProps={{ actionMenuItems }}
      />
    </>
  );
};

export default Countries;
