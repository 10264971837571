import React from "react";
import Card from "components/atoms/Card";
import Table from "services/table";
import { useTrans } from "system/translations/hooks";
import { useTableColumns } from "modules/merchantWhiteList/constantHooks";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import {
  useGetMerchantWhiteList,
  useDeleteMerchantWhiteList,
} from "modules/merchantWhiteList/apiHooks";
import { useListPagination } from "system/helpers/hooks";
import config from "system/config";
import { useConfirmModal } from "services/modal/predefinedModals";
import { EditIcon, TrashIcon } from "evergreen-ui";
import TableHeadAddButton from "components/atoms/TableHeadAddButton";
import Create from "modules/merchantWhiteList/components/Create";
import DrawerHeader from "modules/merchantWhiteList/components/DrawerHeader";
import Update from "modules/merchantWhiteList/components/Update";
import { useDrawer } from "services/drawer/hooks";

const MerchantWhiteList = () => {
  const { _t } = useTrans();
  const { showDrawer } = useDrawer();
  const routeParams: any = useParams();
  const { onConfirm } = useConfirmModal();
  const { pagination, setPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const { data, isLoading } = useGetMerchantWhiteList(
    routeParams?.id,
    pagination
  );
  const { mutate: onDelete } = useDeleteMerchantWhiteList(routeParams?.id);
  const columns = useTableColumns();
  const onUpdate = ({ id, allData }: any) => {
    showDrawer({
      component: Update,
      componentProps: {
        merchantId: routeParams?.id,
        id: id,
      },
      header: DrawerHeader,
      headerProps: {
        text: allData?.ip,
      },
      bodyWrapperProps: {
        hasSpacing: false,
      },
    });
  };
  const onDeleteWithConfirm = ({ id, allData }: any) => {
    onConfirm({
      onOk: () => onDelete({ id }),
      title: `${_t("delete")} ${allData?.ip}?`,
      bodyText: _t("action_cannot_be_undone"),
      onOkKind: "danger",
      onOkText: _t("delete"),
    });
  };
  const actionMenuItems = [
    { title: _t("edit"), onClick: onUpdate, icon: <EditIcon size={12} /> },
    {
      title: _t("delete"),
      onClick: onDeleteWithConfirm,
      icon: <TrashIcon size={12} />,
      intent: "danger",
    },
  ];
  const onCreate = () => {
    showDrawer({
      component: Create,
      componentProps: {
        merchantId: routeParams?.id,
      },
      header: DrawerHeader,
      headerProps: {
        text: _t("add_ip_address"),
      },
      bodyWrapperProps: {
        hasSpacing: false,
      },
    });
  };

  return (
    <>
      {/*      <ButtonLink
        kind={"secondary"}
        iconBefore={PlusIcon}
        linkTo={generatePath(APP_ROUTES.merchants.whitelist.create, {
          merchantId: routeParams?.id,
        })}
        label={_t("create")}
      />*/}
      <Card>
        <Table
          totalRecords={data?.length}
          onPagination={setPagination}
          columns={columns}
          isLoading={isLoading}
          data={data || []}
          isPagination
          afterHeadComponent={
            <TableHeadAddButton
              onClick={onCreate}
              text={_t("add_ip_address")}
            />
          }
          showTotalNoData={false}
          showSettingsIcon={false}
          pageIndex={pagination.page}
          pageSize={pagination.perPage}
          cellProps={{
            actionMenuItems,
          }}
        />
      </Card>
    </>
  );
};

export default MerchantWhiteList;
