import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ModalTypes, ModalParams } from "services/modal/types";
import "styles/modules/modal.scss";
import { Dialog } from "evergreen-ui";
import { css } from "@emotion/react";
import styles from "services/modal/styles";
import themeOptions from "system/theme";
import { isMobile } from "system/theme/globalStyles";

const Modal: React.FC<ModalTypes> = forwardRef(({ hideModal }, ref) => {
  const [visible, setVisible] = useState<any>({});
  const [params, setParams] = useState<{ [key: string]: ModalParams }>({});
  const [focus, setFocus] = useState("default");
  useImperativeHandle(ref, () => ({
    modalOpen(name: string, param: any) {
      setParams({ ...params, [name]: param });
      setFocus(name);
      setVisible({ ...visible, [name]: true });
    },
    modalUpdate(name: string, param: any) {
      setParams({ ...params, [name]: { ...params[name], ...param } });
    },
    modalClose(name: any) {
      const modalParams = { ...params };
      delete modalParams[name];
      setParams(modalParams);
      setVisible({ ...visible, [name]: false });
    },
  }));
  const contentPadding = isMobile
    ? themeOptions.spacing.md
    : themeOptions.spacing.md;

  return (
    <>
      {Object.keys(visible).map((name, idx) => {
        const {
          component: Content,
          componentProps,
          body,
          style,
          title,
          width,
          footer: FooterContent,
          footerProps,
        } = params?.[name] || {};
        const ownStyles = {
          minWidth: "320px",
          zIndex: name === focus ? 9001 : 9000,
          ...style,
        };

        return (
          <>
            <Dialog
              key={name}
              {...ownStyles}
              width={width}
              isShown={visible?.[name]}
              title={title}
              hasFooter={false}
              /*             contentContainerProps={{
                paddingLeft: contentPadding,
                paddingRight: contentPadding,
              }}
              containerProps={{
                paddingLeft: contentPadding,
                paddingRight: contentPadding,
              }}*/
              onCloseComplete={() => hideModal(name)}
              footer={
                FooterContent && (
                  <div css={css(styles.footerWrapper)}>
                    <FooterContent {...footerProps} />
                  </div>
                )
              }
            >
              <div css={css(styles.contentWrapper)}>
                {Content ? <Content {...componentProps} /> : body}
              </div>
              {!FooterContent && <div css={css(styles.emptyFooter)} />}
            </Dialog>
            {/*            <CarbonModal
              key={name}
              title={title}
              open={visible?.[name]}
              style={{ ...ownStyles }}
              onRequestClose={() => hideModal(name)}
              size={size}
              title={modalTitle(title, name, isShowScale)}
              modalLabel={modalLabel}
              passiveModal={passiveModal}
              className={classNames(
                name === "mobile-menu" && "bx--modal--mobile--menu",
                !!fullScreen?.[name] && "bx--modal--full-screen",
                name !== focus && "bx--modal--focused"
              )}
            >
              <div className={"bx--modal-content__body"}>
                {Content ? <Content {...componentProps} /> : body}
              </div>
              {FooterContent && (
                <div className={"bx--modal-content__footer"}>
                  <FooterContent {...footerProps} />
                </div>
              )}
            </CarbonModal>*/}
          </>
        );
      })}
    </>
  );
});

export default Modal;
