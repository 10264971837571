import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_MERCHANT_BLACK_LIST,
  API_CREATE_MERCHANT_BLACK_LIST,
  API_UPDATE_MERCHANT_BLACK_LIST,
  API_DELETE_MERCHANT_BLACK_LIST,
  API_GET_MERCHANT_BLACK_LIST_BY_ID,
} from "system/api/apiUrls";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  BlackListDataByIdType,
  BlackListDataType,
} from "modules/merchantBlackList/types";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { useTrans } from "system/translations/hooks";

const getMerchantBlackList = "getMerchantBlackList";
const getMerchantBlackListById = "getMerchantBlackListById";

export const useGetMerchantBlackList = (
  merchantId: number,
  pagination: any
) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantBlackList, merchantId, pagination],
    (): Promise<BlackListDataType> =>
      apiRequest({
        url: generateUrlQuery(
          generatePath(API_GET_MERCHANT_BLACK_LIST, { merchantId }),
          pagination
        ),
        method: "get",
      })
  );
};
export const useGetMerchantBlackListById = (merchantId: any, id: any) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantBlackListById, merchantId, id],
    (): Promise<BlackListDataByIdType> =>
      apiRequest({
        url: generatePath(API_GET_MERCHANT_BLACK_LIST_BY_ID, {
          merchantId,
          id,
        }),
        method: "get",
      })
  );
};

export const useCreateMerchantBlackList = (merchantId: any) => {
  const { _t } = useTrans();
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_CREATE_MERCHANT_BLACK_LIST, {
          merchantId,
          email: data.email,
        }),
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantBlackList);
        apiSuccessMessage(_t("successfully_added_new_email_to_blacklist"));
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useUpdateMerchantBlackList = (merchantId: any) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_MERCHANT_BLACK_LIST, {
          id: data.id,
          merchantId,
        }),
        method: "put",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantBlackList);
        queryClient.invalidateQueries(getMerchantBlackListById);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useDeleteMerchantBlackList = (merchantId: number) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_MERCHANT_BLACK_LIST, {
          id: data.id,
          merchantId,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantBlackList);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
