import React from "react";
import { CopyButtonWrapperProps } from "./types";
import { useToast } from "services/toast/hooks";
import { DuplicateIcon } from "evergreen-ui";
import { useTrans } from "system/translations/hooks";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styles from "components/atoms/CopyButtonWrapper/styles";
import { css } from "@emotion/react";

const CopyButtonWrapper: React.FC<CopyButtonWrapperProps> = ({
  copyValue,
  children,
  toastTitle,
}: any) => {
  const toastHandler = useToast();
  const { _t } = useTrans();

  return (
    <CopyToClipboard
      onCopy={() =>
        toastHandler.addToast({
          title: toastTitle || _t("copiedUUID"),
          kind: "success",
        })
      }
      text={copyValue}
    >
      <div css={css(styles.container)}>
        <DuplicateIcon css={css(styles.icon)} />
        {children}
      </div>
    </CopyToClipboard>
  );
};

export default CopyButtonWrapper;
