import { createStyles } from "types/emotion-styles";

const styles = createStyles({
  container: {
    marginLeft: "1.5rem",
    counterReset: "ordered-list-counter",
  },
});

export default styles;
