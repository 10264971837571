import * as Yup from "yup";
import { TransType } from "system/translations/types";
import { CreateEditValidationParams } from "modules/countries/types";

export const createEditValidation = (
  _t: TransType,
  params: CreateEditValidationParams
) => {
  return Yup.object().shape({
    code: Yup.string().required(_t("required")),
  });
};
