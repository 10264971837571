import React from "react";
import FormBuilder from "../../../services/formBuilder/FormBuilder";
import { useTrans } from "../../../system/translations/hooks";
import { useCreateMerchant } from "../apiHooks";
import Card from "../../../components/atoms/Card";
import { useHistory } from "react-router-dom";
import { createEditValidation } from "../validation";
import { useCreateConfig } from "../constantHooks";
import SectionTitle from "components/atoms/SectionTitle";

const Create: React.FC = () => {
  const isEdit = false;
  const { _t } = useTrans();
  const history = useHistory();
  const { mutateAsync: onCreate, isLoading } = useCreateMerchant();
  const formConfig = useCreateConfig({ isLoading });
  const onSubmit = async (data: any) => {
    await onCreate(data);
    history.goBack();
  };

  return (
    <>
      <SectionTitle title={_t("create_merchant")} />
      <Card>
        <FormBuilder
          showSubmit={false}
          formItemsConfig={formConfig}
          formProps={{
            onSubmit: onSubmit,
            initialValues: {
              allowDynamicDescriptor: false,
              allowPartialCapture: false,
              allowPartialRefund: false,
              avsCheck: false,
              disable: false,
            },
            validationSchema: createEditValidation(_t, {
              isEdit,
            }),
          }}
        />
      </Card>
    </>
  );
};

export default Create;
