import { createStyles } from "types/emotion-styles";
import themeOptions from "system/theme";

const styles = createStyles({
  wrapper: {
    padding: `${themeOptions.spacing.md} ${themeOptions.spacing.lg}`,
    background: themeOptions.colors.blue50,
  },
  container: {
    display: "flex",
    gap: themeOptions.spacing.xs,
    alignItems: "center",
    color: themeOptions.colors.blue400,
    cursor: "pointer",
  },
});

export default styles;
