import React from "react";
import { Button, ButtonProps } from "evergreen-ui";
import Loader from "components/atoms/Loader";
import { css } from "@emotion/react";
import styles from "components/atoms/Btn/styles";

const Btn: React.FC<
  ButtonProps & {
    label?: string;
    isLoading?: boolean;
    kind?: string;
    hasIconOnly?: boolean;
    children?: any;
  }
> = ({
  label,
  isLoading = false,
  hasIconOnly = false,
  disabled,
  kind = "default",
  children,
  iconBefore,
  ...rest
}) => {
  return (
    <Button
      css={css(styles.container, hasIconOnly && styles.iconOnly)}
      appearance={kind}
      disabled={disabled || isLoading}
      {...rest}
      iconBefore={isLoading ? null : iconBefore}
    >
      {isLoading ? <Loader /> : label || children}
    </Button>
  );
};

export default Btn;
