import React from "react";
import { InputProps } from "components/atoms/Input/types";
import { TextInputField, SmallCrossIcon } from "evergreen-ui";
import styles from "components/atoms/Input/styles";
import { css } from "@emotion/react";

const InputWrap = ({ hasWrap, children }: any) =>
  hasWrap ? (
    <div css={css(styles.removeWrapper)}>{children}</div>
  ) : (
    <>{children}</>
  );

const Input: React.FC<InputProps> = ({
  id = "input",
  label,
  value,
  onChange,
  hideLabel = false,
  style = {},
  placeholder,
  size = "xl",
  onRemove,
  error,
  additionalStyles,
  ...rest
}) => {
  return (
    <InputWrap hasWrap={!!onRemove}>
      <TextInputField
        css={css(styles.wrapper, additionalStyles && additionalStyles)}
        placeholder={placeholder}
        marginBottom={0}
        style={style}
        id={id}
        onChange={(e: any) => onChange && onChange(e?.target?.value)}
        value={value}
        label={label}
        {...rest}
      />
      {onRemove && (
        <div css={css(styles.removeBtn)} onClick={onRemove}>
          <SmallCrossIcon />
        </div>
      )}
    </InputWrap>
  );
};

export default Input;
